import React from 'react'
import { connect } from 'react-redux';

class DetailsGrandTotal extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      portfolio_data : this.props.portfolio_data
    }
}
componentWillReceiveProps(nextProps) {
 this.setState({ portfolio_data: nextProps.portfolio_data});  
}
    renderTotalForDetails(){
        let anovSum = 0.00;
        let bookedPlSum = 0.00;
        let notionalPl = 0.00;
        let productPl = 0;
        let totalDetails = {};
        if(Object.keys(this.state.portfolio_data).length!==0){
          this.state.portfolio_data.details.map((details_,i) =>{
          bookedPlSum += parseFloat(details_.bookedPnl);
          anovSum += parseFloat(details_.Anov);
          notionalPl += parseFloat(details_.unRealPnl);
          })
          return (
           <tr>
             <td><strong>GRAND TOTAL</strong></td>
             <td>&nbsp;</td>
             <td>&nbsp;</td>
             <td>&nbsp;</td>
             <td>&nbsp;</td>
             <td>&nbsp;</td>
             <td>&nbsp;</td>
             <td>&nbsp;</td>
             <td>&nbsp;</td>
             <td>&nbsp;</td>
             <td>&nbsp;</td>
             <td><strong>{parseFloat(anovSum).toFixed(2)}</strong></td>
             <td><strong>{parseFloat(bookedPlSum).toFixed(2)}</strong></td>
             <td><strong>{parseFloat(notionalPl).toFixed(2)}</strong></td>
             <td><strong>{(parseFloat(bookedPlSum) + parseFloat(notionalPl)).toFixed(2)}</strong></td>
           </tr>
        );
          }
       }
render(){
    return(
            <tbody style={{backgroundColor:'#81bae0'}}>      
               {this.renderTotalForDetails()}
            </tbody>
    )
}
}

const mapStateToProps = (state) => {
  return {

  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
     // showErrorDialog: (errordata) => showErrorDialog(dispatch, errordata),
      //commonDialog: (payload) => commonDialog(dispatch, payload),
      //toggleDialog: (payload) => toggleDialog(payload, dispatch)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailsGrandTotal); 