import React from 'react'
import { connect } from 'react-redux';

class SummaryGrandTotal extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            portfolio_data: this.props.portfolio_data
         };
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ portfolio_data: nextProps.portfolio_data });  
    }
    renderTotalForSummary(){
        let lastClient = null;

        let totalMTMSum = 0;
        let totalANOVSum = 0;
        let totalSummary = {};
        if(Object.keys(this.state.portfolio_data).length!==0){
          this.state.portfolio_data.summary.map((summary_,i) =>{
           
                totalMTMSum += parseFloat(summary_.mtm);
                totalANOVSum += parseFloat(summary_.anov);

         })
         return (
           <tr>
             <td><strong>Gross MTM TOTAL</strong></td>
             <td>&nbsp;</td>
             <td>&nbsp;</td>
             <td>&nbsp;</td>
             <td><strong>{parseFloat(totalMTMSum).toFixed(2)}</strong></td>
             <td><strong>{parseFloat(totalANOVSum).toFixed(2)}</strong></td>
           </tr>
        );}
       }
    render(){
        return(
            <tbody style={{backgroundColor:'#81bae0'}}>
                {this.renderTotalForSummary()}
            </tbody>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
       // showErrorDialog: (errordata) => showErrorDialog(dispatch, errordata),
        //commonDialog: (payload) => commonDialog(dispatch, payload),
        //toggleDialog: (payload) => toggleDialog(payload, dispatch)
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SummaryGrandTotal); 