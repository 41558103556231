import { StoreKeys } from '../common/constants';

const intialState = {
    redirectLink:null,
    loginwarningMsg:null,
    showLoginWarningDialog:false
}

const loginWarning = (state = intialState, action) => {
    if (action.type === StoreKeys.SHOW_LOGIN_WARNING) {      
        const {loginwarningMsg,redirectLink,showLoginWarningDialog} = action.payload;
        return Object.assign({}, state, { 
            loginwarningMsg:loginwarningMsg?loginwarningMsg:'',
            showLoginWarningDialog:showLoginWarningDialog,
            redirectLink:redirectLink ? redirectLink :''
        });
    }
    else {
        return state;
    }
};

export default loginWarning;