import { UrlExtensions, BaseUrl } from '../../common/constants';
import { httpPost } from '../base/api'

// === position Details ====
export const getNetPostions = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.NET_POSITIONS;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// === position transfer log ====
export const getPostionsTransferLog = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.NET_POSITIONS_TRANSFER_LOG;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}


// === transfer position ====
export const transferPosition = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.NET_POSITIONS_TRANSFER;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

