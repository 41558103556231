import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import TabbarBaseWidget from '../base/TabbarBaseWidget';
import {
    commonDropDownType, TradeActions, orderDropdownOptions, orderEntryTypes, productType,
    assetClasses, tabMap,instrumentType
} from "../../common/constants"
import Dropdown from '../../controls/Dropdown'
import ToggleSwitch from '../../controls/ToggleSwitch'
import { getMyOrders } from '../../actions/orders/myordersrequests'
import { showOrderDialog, showCancelOrderDialog, showCancelOrderDialogCallback, onOrderSuccess } from '../../actions/orderentry/orderentryaction'
import { setScripInfo } from '../../actions/scripdetails/scripdetailsaction'
import { stringSort, numericSort } from '../../common/utils'
import { getAssetTabs, isMCXPermitted, isNCDEXPermitted, getCommoditySelExchange } from '../../common/permissions'
import { progressBar } from '../../controls/progress'
import Rowitem from './Rowitem';

class MyOrders extends Component {

    constructor(props) {
        super(props);
        var tabs = getAssetTabs();
        this.state = {
            inProgress: false,
            error: '',
            tabs,
            selectedsegment: props.tab ? props.tab : tabs[0],
            selectedfilter: orderDropdownOptions[0].value,
            selectedexchange: props.exchangeSelected ? props.exchangeSelected : (assetClasses.commodity !== tabs[0] ? 'NSE' : getCommoditySelExchange()),
            myordersdata: [],
            qty_label: 'QTY',
            showOrderIndex: '',
            showAdvanceIndex: '',
            sortAsc: {
                sym_sort: false, prd_type_sort: false,
                trade_price_sort: false, totalQty_sort: false, outQty: false,
                tradeQty: false, status: false, order_type_sort: false
            }
        }
        this.onModifyOrderClick = this.onModifyOrderClick.bind(this);
        this.onCancelOrderClick = this.onCancelOrderClick.bind(this);
        this.onSuccessfulOrderCancel = this.onSuccessfulOrderCancel.bind(this);
        this.onOrderSuccess = this.onOrderSuccess.bind(this);
    }

    componentDidMount() {
        this.onTabClicked(this.state.selectedsegment);
        this.props.onOrderSuccess(this.onOrderSuccess);
    }

    onTabClicked(tabName) {

        switch (tabName) {
            case assetClasses.equity:
                this.setState({
                    selectedsegment: assetClasses.equity, selectedexchange: (this.props.exchangeSelected=='NSE' || this.props.exchangeSelected=='BSE' ) ? this.props.exchangeSelected : 'NSE',
                    qty_label: 'QTY'
                }, () => {
                    if (this.refs.exchangeSwitch) {
                        if (this.state.selectedexchange === 'BSE')
                            this.refs.exchangeSwitch.setSelected('2');
                        else
                            this.refs.exchangeSwitch.setSelected('1');
                    }
                    this.placeMyOrdersRequest()
                });
                break;
            case assetClasses.fno:
                this.setState({
                    selectedsegment: assetClasses.fno, selectedexchange: (this.props.exchangeSelected=='NFO' || this.props.exchangeSelected=='BFO' ) ? this.props.exchangeSelected : 'NFO',
                    qty_label: 'QTY'
                }, () => {
                    if (this.refs.exchangeSwitch) {
                        if (this.state.selectedexchange === 'BFO')
                            this.refs.exchangeSwitch.setSelected('2');
                        else
                            this.refs.exchangeSwitch.setSelected('1');
                    }
                    this.placeMyOrdersRequest();
                });
                break;
            case assetClasses.spread:
                this.setState({
                    selectedsegment: assetClasses.spread, selectedexchange: (this.props.exchangeSelected=='NSE' || this.props.exchangeSelected=='BSE' ) ? this.props.exchangeSelected : 'NSE',
                    qty_label: 'QTY'
                }, () => {
                    if (this.refs.exchangeSwitch) {
                        if (this.state.selectedexchange === 'BSE')
                            this.refs.exchangeSwitch.setSelected('2');
                        else
                            this.refs.exchangeSwitch.setSelected('1');
                    }
                    this.placeMyOrdersRequest();
                });
                break;
            case assetClasses.currency:
                this.setState({
                    selectedsegment: assetClasses.currency, selectedexchange: (this.props.exchangeSelected=='NSE' || this.props.exchangeSelected=='BSE' ) ? this.props.exchangeSelected : 'NSE',
                    qty_label: 'LOT'
                }, () => {
                    if (this.refs.exchangeSwitch) {
                        if (this.state.selectedexchange === 'BSE')
                            this.refs.exchangeSwitch.setSelected('2');
                        else
                            this.refs.exchangeSwitch.setSelected('1');
                    }
                    this.placeMyOrdersRequest();
                });
                break;
            case assetClasses.commodity:
                this.setState({
                    selectedsegment: assetClasses.commodity, selectedexchange: (this.props.exchangeSelected=='MCX' || this.props.exchangeSelected=='NCDEX') ? this.props.exchangeSelected : getCommoditySelExchange(),
                    qty_label: 'LOT'
                }, () => {
                    if (this.refs.exchangeSwitch) {
                        if (this.state.selectedexchange === 'NCDEX')
                            this.refs.exchangeSwitch.setSelected('2');
                        else
                            this.refs.exchangeSwitch.setSelected('1');
                    }
                    this.placeMyOrdersRequest()
                });
                break;
            default: break;
        }
    }

    onFilterSelected(selected) {
        var existing = orderDropdownOptions.filter(i => i.name === selected);
        if (existing.length > 0)
            this.setState({ selectedfilter: existing[0].value }, () => this.placeMyOrdersRequest());
    }

    onExchangeSelected(selected) {
        if (this.state.selectedsegment === assetClasses.commodity) {
            if (selected === '1') {
                this.setState({ selectedexchange: 'MCX' }, () => this.placeMyOrdersRequest());
            }
            else if (selected === '2') {
                this.setState({ selectedexchange: 'NCDEX' }, () => this.placeMyOrdersRequest());
            }
        }
        else if(this.state.selectedsegment === assetClasses.fno) {
            if (selected === '1') {
                this.setState({ selectedexchange: 'NFO' }, () => this.placeMyOrdersRequest());
            }
            else if (selected === '2') {
                this.setState({ selectedexchange: 'BFO' }, () => this.placeMyOrdersRequest());
            }
        }
        else {
            if (selected === '1') {
                this.setState({ selectedexchange: 'NSE' }, () => this.placeMyOrdersRequest());
            }
            else if (selected === '2') {
                this.setState({ selectedexchange: 'BSE' }, () => this.placeMyOrdersRequest());
            }
        }
    }

    onModifyOrderClick(order, cvLeg, bracketLeg) {
        var orderdetails = this.getOrderDetails(order, cvLeg, bracketLeg);
        this.props.showOrderDialog(orderdetails);
    }

    onCancelOrderClick(order, cvLeg, bracketLeg) {
        var orderdetails = this.getOrderDetails(order, cvLeg, bracketLeg);
        orderdetails.orderId = orderdetails.correspondingorder && orderdetails.correspondingorder.orderid;
        this.props.showCancelOrderDialogCallback(this.onSuccessfulOrderCancel);
        this.props.showCancelOrderDialog(orderdetails);
    }

    onSuccessfulOrderCancel() {
        this.placeMyOrdersRequest();
    }

    onOrderSuccess(orderDetails) {
        this.setState({ selectedsegment: tabMap[orderDetails.symobj.instr] }, () => {
            this.onTabClicked(tabMap[orderDetails.symobj.instr]);
        })
    }

    getOrderDetails(order, cvLeg, bracketLeg) {
        var item = order;
        var isinDp;
        if (item.prdType === productType.SHORTSELL) {
            isinDp = false;
        }
        let instrType = item.sym.instr;
        

        var orderdetails = {
            orderentrytype: orderEntryTypes.MODIFY,

            action: item.ordAction.toLowerCase() === "buy" ? TradeActions.BUY : TradeActions.SELL,
            symobj: item.sym,
            displaySym: item.displaySym,
            company: item.company,
            last: "0.00",
            change: "0.00",
            changeper: "0.00",

            // fill other details
            correspondingorder: {
                orderid: item.orderId,
                price: item.limitPrice,
                quantity: item.remQty,
                producttype: item.prdType,
                ordertype: item.ordType.toLowerCase(),
                validitytype: item.ordDuration,
                triggerprice: item.triggerPrice,
                discquantity: item.disQty,
                isinDp: isinDp,
                isAmo: item.isAmo,
                tradedQty: item.tradedQty,
                
            },
            
        };
        if(instrType === instrumentType.STOCK_FUTURE_SPREAD || instrType === instrumentType.INDEX_FUTURE_SPREAD || instrType === instrumentType.CURRENCY_FUTURE_SPREAD){
            orderdetails.nestRequestId = item.nestRequestId;
        }
        if (productType[item.prdType] === productType.BO) {
            orderdetails.syomOrderId = item.orderId;
            orderdetails.boStatus = item.boStatus;
        } else if (productType[item.prdType] === productType.CO) {
            orderdetails.correspondingorder.triggerprice = item.leg1.triggerPrice;
            orderdetails.correspondingorder.quantity = item.remQty;
        }
        if (cvLeg) {
            orderdetails.correspondingorder.orderid = item.leg1.orderId;
            orderdetails.correspondingorder.quantity = item.leg1.remQty;
            orderdetails.correspondingorder.tradedQty = item.leg1.tradedQty;
            orderdetails.correspondingorder.slCoverDisable = false;
            orderdetails.correspondingorder.ispricedisabled = true;
            orderdetails.action = item.leg1.ordAction.toLowerCase() === "buy" ? TradeActions.BUY : TradeActions.SELL;
        } else {
            orderdetails.correspondingorder.slCoverDisable = true;
            orderdetails.correspondingorder.ispricedisabled = false;
        }
        return orderdetails;
    }


    onCollapseClick() {
        this.props.history.push("/home/dashboard");
    }


    onSymbolClick = (row) => {
        this.props.setScripInfo(row.sym);
        this.props.history.push("/home/scripdetails");
    }

    onClickRefresh() {
        this.placeMyOrdersRequest();
    }

    placeMyOrdersRequest() {
        this.setState({ inProgress: true, error: '', myordersdata: [] });
        var requestData = {
            request: {
                data: {
                    filters: [
                        { key: 'ordStatus', value: this.state.selectedfilter },
                        { key: 'exc', value: this.state.selectedexchange }
                    ],
                    tab: this.state.selectedsegment.toLowerCase()
                }
            }
        };

        getMyOrders(requestData, this.parseMyOrdersResponse.bind(this, this.state.selectedsegment), this.parseMyOrdersError.bind(this, this.state.selectedsegment));
    }

    parseMyOrdersResponse(selectedTab, responseData) {
        if (selectedTab === this.state.selectedsegment) {
            this.setState({ inProgress: false, myordersdata: responseData.response.data.orders }, () => {

            });
        }
    }

    parseMyOrdersError(selectedTab, error) {
        if (selectedTab === this.state.selectedsegment) {
            this.setState({ inProgress: false, error: error.message });
        }
    }

    onShowDetailsClick = (index) => {
        if (this.state.showOrderIndex === index) {
            this.setState({ showOrderIndex: '' });
        } else {
            this.setState({ showOrderIndex: index, showAdvanceIndex: '' });
        }
    }

    onShowAdvanceClick = (index) => {
        if (this.state.showAdvanceIndex === index) {
            this.setState({ showAdvanceIndex: '' });
        } else {
            this.setState({ showAdvanceIndex: index, showOrderIndex: '' });
        }

    }
    onSortClicked = (field) => {
        var temp = this.state.myordersdata, sortTemp = this.state.sortAsc;
        switch (field) {
            case "sym_sort":
                sortTemp.sym_sort = !sortTemp.sym_sort;
                this.setState({ sortAsc: sortTemp });
                if (this.state.sortAsc.sym_sort) {
                    temp = stringSort(this.state.myordersdata, "displaySym", "ASC");
                } else {
                    temp = stringSort(this.state.myordersdata, "displaySym", "DSC");
                }
                break;
            case "prdType_sort":
                sortTemp.prd_type_sort = !sortTemp.prd_type_sort;
                this.setState({ sortAsc: sortTemp });
                if (this.state.sortAsc.prd_type_sort) {
                    temp = stringSort(this.state.myordersdata, "prdType", "ASC");
                } else {
                    temp = stringSort(this.state.myordersdata, "prdType", "DSC");
                }
                break;
            case "price_sort":
                sortTemp.trade_price_sort = !sortTemp.trade_price_sort;
                this.setState({ sortAsc: sortTemp });
                if (this.state.sortAsc.trade_price_sort) {
                    temp = numericSort(this.state.myordersdata, "limitPrice", "ASC");
                } else {
                    temp = numericSort(this.state.myordersdata, "limitPrice", "DSC");
                }
                break;
            case "totQty_sort":
                sortTemp.totalQty_sort = !sortTemp.totalQty_sort;
                this.setState({ sortAsc: sortTemp });
                if (this.state.sortAsc.totalQty_sort) {
                    temp = numericSort(this.state.myordersdata, "qty", "ASC");
                } else {
                    temp = numericSort(this.state.myordersdata, "qty", "DSC");
                }
                break;
            case "outStn_sort":
                sortTemp.outQty = !sortTemp.outQty;
                this.setState({ sortAsc: sortTemp });
                if (this.state.sortAsc.outQty) {
                    temp = numericSort(this.state.myordersdata, "remQty", "ASC");
                } else {

                    temp = numericSort(this.state.myordersdata, "remQty", "DSC");
                }
                break;
            case "status_sort":
                sortTemp.status = !sortTemp.status;
                this.setState({ sortAsc: sortTemp });
                if (this.state.sortAsc.status) {
                    temp = stringSort(this.state.myordersdata, "ordStatus", "ASC");
                } else {

                    temp = stringSort(this.state.myordersdata, "ordStatus", "DSC");
                }
                break;
            case "trdQty_sort":
                sortTemp.tradeQty = !sortTemp.tradeQty;
                this.setState({ sortAsc: sortTemp });
                if (this.state.sortAsc.tradeQty) {
                    temp = numericSort(this.state.myordersdata, "tradedQty", "ASC");
                } else {

                    temp = numericSort(this.state.myordersdata, "tradedQty", "DSC");
                }
                break;
            case "ordType_sort":
                sortTemp.order_type_sort = !sortTemp.order_type_sort;
                this.setState({ sortAsc: sortTemp });
                if (this.state.sortAsc.order_type_sort) {
                    temp = stringSort(this.state.myordersdata, "ordType", "ASC");
                } else {

                    temp = stringSort(this.state.myordersdata, "ordType", "DSC");
                }
                break;
        }
        
        this.setState({ myordersdata: temp });
    }

    render() {
        var errorHolder = (
            <div className="error-holder">
                {this.state.error}
            </div>
        );

        var additionalWidgetHeader = (
            <div>
                {this.state.inProgress ? '' :
                    <div className="watchlistoptions" style={{ paddingRight: "20px", cursor: 'pointer' }}>
                        <img src="assets/svg/refresh.svg" style={{ height: 16 }} onClick={this.onClickRefresh.bind(this)} alt="refresh" />
                    </div>
                }
            </div>
        );

        var exchange1 = (this.state.selectedsegment === assetClasses.commodity) ? (isMCXPermitted() ? 'MCX' : undefined) : (this.state.selectedsegment === assetClasses.fno)?'NFO':'NSE';
        var exchange2 = (this.state.selectedsegment === assetClasses.commodity) ? (isNCDEXPermitted() ? 'NCDEX' : undefined) : (this.state.selectedsegment === assetClasses.fno)?'BFO':'BSE';
        return (
            <TabbarBaseWidget activeTab={this.state.selectedsegment} title="MY ORDERS" tabs={this.state.tabs} onTabClicked={this.onTabClicked.bind(this)}
                onCollapseClick={this.onCollapseClick.bind(this)}
                additionalTabHeader={additionalWidgetHeader} >
                <div className="myorders-tab-content tab-content expanded">
                    <div id="equity" className="row tab-pane in active" style={{ height: "calc(100% - 30px)" }}>
                        <div className="row">
                            <div className="col-6 text-left">
                                <div className="widget-title-component marginleft order-toggle">
                                    {(this.state.selectedsegment === assetClasses.currency || this.state.selectedsegment === assetClasses.spread) ? '' : <ToggleSwitch ref="exchangeSwitch" button1={exchange1} button2={exchange2} onChange={this.onExchangeSelected.bind(this)} />}
                                </div>
                            </div>
                            <div className="col-6 text-right">
                                <div className="ideas-filter-drop-down">
                                    <Dropdown
                                        style="gainIndicesDrop"
                                        dropdownType={commonDropDownType.DEFAULT}
                                        list={orderDropdownOptions}
                                        alignDefault={true}
                                        onSelectionChanged={this.onFilterSelected.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                        {this.state.error ? errorHolder :
                            this.state.inProgress ? progressBar() :
                                <div style={{ height: "100%" }}>
                                    <div className="row fillwidth contentpanel myorders-row">
                                        <div className="col-9 contentpanel">
                                            <div className="row myorders-header">
                                                <div className="col-2  text-left">
                                                    <span className="cursor-pointer" onClick={(e) => { this.onSortClicked("sym_sort") }}>
                                                        <img src='assets/svg/sort.svg' className="fa-sort" id="sym_sort" ></img>
                                                        SYMBOL
                                                    </span>
                                                </div>
                                                <div className="col-2 text-right nopadding">
                                                    <span className="cursor-pointer" onClick={(e) => { this.onSortClicked("prdType_sort") }}>
                                                        <img src='assets/svg/sort.svg' className="fa-sort" id="sym_sort" ></img>
                                                        PRODUCT TYPE
                                                    </span>
                                                </div>
                                                <div className="col-2 text-right" >
                                                    <span className="cursor-pointer" onClick={(e) => { this.onSortClicked("price_sort") }}>
                                                        <img src='assets/svg/sort.svg' className="fa-sort" id="sym_sort" ></img>
                                                        <span className="break-line">PRICE</span>
                                                    </span>
                                                </div>
                                                <div className="col-1 text-right" onClick={(e) => { this.onSortClicked("totQty_sort") }}>
                                                    <span className="cursor-pointer" >
                                                        <img src='assets/svg/sort.svg' className="fa-sort" id="sym_sort" ></img>
                                                        TOTAL {this.state.qty_label}
                                                    </span>
                                                </div>
                                                <div className="col-1 text-right nopadding">
                                                    <span className="cursor-pointer" onClick={(e) => { this.onSortClicked("outStn_sort") }}>
                                                        <img src='assets/svg/sort.svg' className="fa-sort" id="sym_sort" ></img>
                                                        OUTSTD {this.state.qty_label}
                                                    </span>
                                                </div>
                                                <div className="col-2 text-right">
                                                    <span className="cursor-pointer" onClick={(e) => { this.onSortClicked("status_sort") }}>
                                                        <img src='assets/svg/sort.svg' className="fa-sort" id="sym_sort" ></img>
                                                        STATUS
                                                    </span>
                                                </div>
                                                <div className="col-1 text-right nopadding">
                                                    <span className="cursor-pointer" onClick={(e) => { this.onSortClicked("trdQty_sort") }}>
                                                        <img src='assets/svg/sort.svg' className="fa-sort" id="sym_sort" ></img>
                                                        TRADED {this.state.qty_label}
                                                    </span>
                                                </div>
                                                <div className="col-1 text-right">
                                                    <span className="cursor-pointer" onClick={(e) => { this.onSortClicked("ordType_sort") }}>
                                                        <img src='assets/svg/sort.svg' className="fa-sort" id="sym_sort" ></img>
                                                        ORDER TYPE
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                        </div>
                                    </div>
                                    <div id="accordion" style={{ height: "calc(100% - 55px)", overflowY: "auto" }}>
                                   
                                        {this.state.myordersdata.map((row, key) => {
                                            return (
                                                <Rowitem row={row} key={key} selectedsegment={this.state.selectedsegment} qty_label={this.state.qty_label}
                                                    onSymbolClick={this.onSymbolClick} onModifyOrderClick={this.onModifyOrderClick} onCancelOrderClick={this.onCancelOrderClick} />
                                            )
                                        })}
                                    </div>

                                </div>}
                    </div>


                </div>
            </TabbarBaseWidget>
        );
    }
}

const takeInstrument = function (orderDetails) {
    if (orderDetails && orderDetails.symobj && orderDetails.symobj.instr) {
        return orderDetails.symobj.instr;
    } else
        return false;
}

const takeExchange = function (orderDetails) {
    if (orderDetails && orderDetails.symobj && orderDetails.symobj.exc) {
        return orderDetails.symobj.exc;
    } else {
        return false;
    }
}
const mapStateToProps = ({ orderentry, common: { showDialog } }) => {

    var instr = takeInstrument(orderentry.orderDetails);
    var exchange = takeExchange(orderentry.orderDetails);
    return {
        tab: instr ? tabMap[instr] : false,
        exchangeSelected: exchange,
        showDialog
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showOrderDialog: (data) => showOrderDialog(dispatch, data),
        showCancelOrderDialog: (data) => showCancelOrderDialog(dispatch, data),
        showCancelOrderDialogCallback: (callback) => showCancelOrderDialogCallback(dispatch, callback),
        setScripInfo: (data) => setScripInfo(dispatch, data),
        onOrderSuccess: (callback) => onOrderSuccess(dispatch, callback)
    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyOrders));