import React, { Component } from 'react';
import {tConv24, comaSeparator} from '../../common/utils';
import Dialog from '../../controls/Dialog';
import { isEquityPermitted, isFnOPermitted, isCurrencyPermitted, isCommodityPermitted } from "../../common/permissions";

class IPORow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showIPODetails: false,
            showDailog:false,
            message:''
        }
    }

    onShowDetailsClick = () => {
        this.setState({ showIPODetails: !(this.state.showIPODetails) });
    }

    placeOrder= (event) =>{
        event.stopPropagation();
        this.props.onClickBid("placeOrder", this.props.ipoData);
    }

    showMessageBox= (event, msg) =>{
        event.stopPropagation();
        this.setState({ showDailog: true , message:msg});
    }

    alreadyPlacedBid(){
        this.setState({ showDailog: false, message:'' });
    }
    
    componentDidMount() {
        window.$('.panel-collapse').on('show.bs.collapse', function () {
            window.$(this).parent().find('.hot-news-arrow').addClass('up');
        });

        window.$('.panel-collapse').on('hide.bs.collapse', function () {
            window.$(this).parent().find('.hot-news-arrow').removeClass('up');
        });

        
    }

    checkTime(startTime, endTime){
        var today = new Date();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        if(startTime<=time && endTime>=time)
        {
            return false;
        }
        else
        {
            return true;
        }
    }

render(){
    return (
        <div className="row">
            <div key={this.props.key} className="col-lg-12 col-md-12 col-sm-12 contentpanel transfer-log-row fillwidth" style={{ cursor: 'pointer' }}
                data-toggle="collapse" data-parent="#accordion" href={'#ipoRow' + this.props.ipoData.symbol}>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 myorder-column">
                        <div className="row">
                            <div className="col-lg-11 col-md-11 col-sm-11">
                                <div className="row">
                                    {this.props.ipoData.symbol} - {this.props.ipoData.name}
                                </div>
                                <div className="row fund-padding">                        
                                    <div className="col-3 funds-title">
                                        Opens From <br></br><strong> {this.props.ipoData.biddingStartDate} {tConv24(this.props.ipoData.dailyStartTime)}</strong>  
                                    </div>                    
                                    <div className="col-3 funds-title">
                                        Closes On <br></br><strong> {this.props.ipoData.biddingEndDate} {tConv24(this.props.ipoData.dailyEndTime)}</strong>
                                    </div> 
                                    {this.props.bid?
                                    <div className="col-3 text-center" >    
                                    {(isEquityPermitted()===false && isFnOPermitted()===false && isCurrencyPermitted()===false && isCommodityPermitted()===true) ? 
                                         <button className="bidButton" onClick={(e) => {
                                                this.showMessageBox(e, "You have not subscribed to trade in this segment. Please contact your RM or customer service.")
                                            }} >BID</button> :
                                       this.checkTime(this.props.ipoData.dailyStartTime, this.props.ipoData.dailyEndTime)?
                                            <button className="bidButton" onClick={(e) => {
                                                this.showMessageBox(e, "Your bid could not be placed because the market is closed for now. "+
                                                "Please try between "+tConv24(this.props.ipoData.dailyStartTime)+" to "+tConv24(this.props.ipoData.dailyEndTime)+".")
                                            }} >BID</button>
                                            :
                                        this.props.ipoData.isBidPlaced=="Y"?
                                            <button className="bidButton" onClick={(e) => {
                                                this.showMessageBox(e, "You have already placed a bid in the selected IPO.")
                                            }} >BID</button>
                                        :
                                        <button className="bidButton" onClick={(e) => {
                                                this.placeOrder(e)
                                            }} >BID</button>}
                                    </div> :''}
                                </div>
                            </div>
                            <div className="col-md-1 text-center" >
                                <a className="contentpanel" >
                                    <i ref={"arrow" + this.props.ipoData.symbol} className="fa fa-angle-down hot-news-arrow" style={{ color: "white", marginLeft: "15px" }} aria-hidden="true"></i>
                                </a>
                            </div>
                                                   
                        </div>
                    </div>
                </div>
            </div>
            <div id={"ipoRow" + this.props.ipoData.symbol} className="col-lg-12 col-md-12 col-sm-12 panel-collapse collapsing">
                <div className="jumbotron panel-body ipo-accordion">
                    <div className="row funds-title">                        
                        <div className="col-3">
                            Issue Size <br></br>
                            <strong> <i className="fa fa-rupee"></i> {comaSeparator(this.props.ipoData.issueSize)} (in Cr.)</strong>  
                        </div>                        
                        <div className="col-3">
                            Price Band  <br></br><strong> <i className="fa fa-rupee"></i> {comaSeparator(this.props.ipoData.minPrice.toFixed(2))} - {comaSeparator(this.props.ipoData.maxPrice.toFixed(2))}</strong>
                        </div>
                        <div className="col-3">Min. Investment Amt. <br></br>
                            <strong> <i className="fa fa-rupee"></i> {comaSeparator(this.props.ipoData.minimumInvestmentAmt)}</strong>
                        </div>
                        <div className="col-3">Max. Investment Amt. <br></br>
                            <strong> <i className="fa fa-rupee"></i> {comaSeparator(this.props.ipoData.maximumInvestmentAmt)}</strong>
                        </div>
                        <div className="col-3">
                            Issue Type <br></br>
                            <strong> {this.props.ipoData.type}</strong>  
                        </div>
                        <div className="col-3">
                            Lot Size <br></br> <strong> {this.props.ipoData.lotSize}</strong>
                        </div>
                        <div className="col-3">
                            Minimum Bid Quantity <br></br> <strong> {this.props.ipoData.minBidQuantity}</strong>
                        </div>
                    </div>                
                </div>            
            </div>
            {this.state.showDailog?
                <Dialog header="Message" message={this.state.message}
                    positiveButtonName="OK" positiveClick={this.alreadyPlacedBid.bind(this)}/>
                :''}
            
        </div>
    );

    }
}

export default IPORow;