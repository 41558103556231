import React from 'react';
import { getOrderLog } from '../../actions/orders/myordersrequests'
import { CommonErrorMessages, productType, assetClasses,instrumentType } from '../../common/constants'
import { comaSeparator } from '../../common/utils'
import { progressBar } from '../../controls/progress'
class OrderLogModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focussed: true,
            inProgress: false,
            error: '',
            orders: [],
            showLog: false
        }
    }

    placeOrderLogRequest(e) {
        this.setState({ inProgress: true, error: '', orders: [], showLog: true });
        var requestData = {
            request: {
                data: {
                    orderId: this.props.orderid,
                    sym: this.props.symobj
                }
            }
            
        };

        getOrderLog(requestData, this.parseOrderLogResponse.bind(this), this.parseOrderLogError.bind(this));
    }

    parseOrderLogResponse(responseData) {
        try {
            var list = responseData.response.data.orderLog;
            console.log(list);
            if (list && list.length > 0)
                this.setState({ inProgress: false, orders: list });
            else
                this.setState({ inProgress: false, error: CommonErrorMessages.NO_DATA });
        }
        catch (ex) {
            console.log('pk exception');
            this.setState({ inProgress: false, error: CommonErrorMessages.UNABLE_LOAD });
        }
    }

    parseOrderLogError(error) {
        this.setState({ inProgress: false, error: error.message });
    }

    closeDialog() {
        this.setState({ showLog: false });
    }

    render() {
        var errorHolder = (
            <div className="error-holder" style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                <span> {this.state.error} </span>
            </div>
        );

        return (
            <div className="col-4 nopadding">
                {this.props.outstandingordershistory ?
                    <div className="pending-orders-btn-holder">
                        <button type="button" className="pending-orders-btn" data-toggle="tooltip" title="History" onClick={this.placeOrderLogRequest.bind(this)}>H</button>
                    </div>
                    :
                    <button type="button" className="form-control myorder-btn history-btn" onClick={this.placeOrderLogRequest.bind(this)}>
                        HISTORY
                    </button>
                }
                {
                    this.state.showLog ?

                        <div className={"modal fade dialog-show in"} id="myModal" role="dialog">
                            <div className="modal-dialog order-log-modal">
                                <div className="mtf-header">
                                    <span>ORDER LOG</span>
                                </div>
                                {
                                    this.state.inProgress ? progressBar() :
                                        this.state.error ? errorHolder :
                                            <div className="mtf-body">
                                                <div>
                                                    <span>
                                                        <span style={{ fontSize: "16px" }}>{this.props.displaySym}</span>
                                                        <span style={{ fontSize: "12px", color: "#86878A" }}> {this.props.exchange}</span>
                                                    </span>
                                                </div>
                                                <div style={{ paddingTop: "5px" }}>
                                                    <span>
                                                        <span style={{ fontSize: "12px", color: "#86878A" }}>  Order Reference No : </span> {this.props.orderid}
                                                    </span>
                                                </div>
                                                <div className="row" style={{ paddingTop: "10px" }}>
                                                    <div className="col-3"></div>
                                                    <div className="col-1 nopadding text-right">{this.props.selectedsegment === assetClasses.currency ? 'LOTS' : 'QTY'}</div>
                                                    <div className="col-2 nopadding text-right">PRICE</div>
                                                    {(
                                                        this.props.selectedsegment === assetClasses.spread  || this.props.symobj.instr ===  instrumentType.STOCK_FUTURE_SPREAD || this.props.symobj.instr === instrumentType.INDEX_FUTURE_SPREAD ? '' :

                                                            <div className="col-1 nopadding text-right">DISC. QTY</div>
                                                    )}
                                                    {(
                                                        this.props.selectedsegment === assetClasses.spread || this.props.symobj.instr ===  instrumentType.STOCK_FUTURE_SPREAD || this.props.symobj.instr === instrumentType.INDEX_FUTURE_SPREAD ? '' :
                                                            <div className="col-2 nopadding text-right">TRIGGER PRICE</div>
                                                    )}
                                                    {
                                                        this.props.symobj.assetClasses !== assetClasses.commodity ?
                                                            <div className="col-1 nopadding text-right">VALIDITY</div> : null
                                                    }
                                                    <div className="col-2 text-right">DATE & TIME</div>
                                                </div>
                                                <ul className="list-unstyled orders-list">
                                                    {this.state.orders.map((row, key) => {
                                                        return (
                                                            <li key={key}>
                                                                <div className="row">
                                                                    <div className="col-3 nopadding text-right">
                                                                        <span>
                                                                            <span className="status-margin history-content" style={{ color: "#13BD89" }}>
                                                                                {row.ordStatus}
                                                                            </span>
                                                                            <span className="connecting-circle">
                                                                                <i className="fa fa-circle circle-first" />
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-1 nopadding text-right">{row.qty}</div>
                                                                    <div className="col-2 nopadding text-right">{comaSeparator(row.ordPrice)}</div>
                                                                    {(
                                                                        this.props.selectedsegment === assetClasses.spread || this.props.symobj.instr ===  instrumentType.STOCK_FUTURE_SPREAD || this.props.symobj.instr === instrumentType.INDEX_FUTURE_SPREAD ? '' :
                                                                            <div className="col-1 nopadding text-right">{this.props.disclosedQty}</div>
                                                                    )}
                                                                    {(
                                                                        this.props.selectedsegment === assetClasses.spread || this.props.symobj.instr ===  instrumentType.STOCK_FUTURE_SPREAD || this.props.symobj.instr === instrumentType.INDEX_FUTURE_SPREAD ? '' :
                                                                            <div className="col-2 nopadding text-right">{
                                                                                productType[this.props.productType] === productType.BO ? "0.00" :
                                                                                    this.props.triggerPrice
                                                                            }</div>)}
                                                                    {
                                                                        this.props.symobj.assetClasses !== assetClasses.commodity ?
                                                                            <div className="col-1 nopadding text-right">{row.ordDuration}</div> : null
                                                                    }
                                                                    <div className="col-2 text-right" style={{ whiteSpace: "nowrap" }}>{row.lUpdatedTime}</div>
                                                                    {
                                                                        this.state.orders.length - 1 === key ? ''
                                                                            : <div className="col-3 text-right right-connecting-border"></div>
                                                                    }
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                }
                                <div className="mtf-footer">
                                    <div className="buttons">
                                        <button type="button" className="order-log-buttons" onClick={this.closeDialog.bind(this)}>CLOSE</button>
                                    </div>
                                </div>
                            </div>
                        </div> : ''
                }
            </div>
        );
    }



};
export default OrderLogModal;
