import React from "react"
import { isValidWatchlistName } from '../../validators/watchlistValidations'

class BasicDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputtext: '',
            error: ''
        }
        this.handleChange = this.handleChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.handlePossitiveButton = this.handlePossitiveButton.bind(this);
        this.handleNegativeButton = this.handleNegativeButton.bind(this);
    }

    setError(err) {
        this.setState({ error: err });
    }

    getInputText() {
        return this.state.inputtext;
    }

    handleError(err) {
        if (err) {
            this.setState({ error: err });
        }
        else {
            // success case
            this.setState({ inputtext: '', error: '' });
        }
    }

    handleChange(e) {
        this.setState({ inputtext: e.target.value, error: '' });
    }

    onKeyPress(e) {
        if (e.key === 'Enter') {
            this.handlePossitiveButton();
        } 
        if(e.which === 35 || e.keyCode === 35){
            e.preventDefault();
        }
    }

    handleNegativeButton() {
        if (this.props.type === "textinput") {
            this.setState({ inputtext: '', error: '' });
        }

        if (this.props.onNegativeButtonClick)
            this.props.onNegativeButtonClick();
    }

    handlePossitiveButton() {
        if (this.props.type === "textinput") {
            var err = isValidWatchlistName(this.state.inputtext);
            if (err === '') {
                if (this.props.onPossitiveButtonClick)
                    this.props.onPossitiveButtonClick();
            }
            else {
                this.setState({ error: err });
            }
        }
        else if (this.props.type === "message") {
            if (this.props.onPossitiveButtonClick)
                this.props.onPossitiveButtonClick();
        }
    }
    
    render() {

        var mbody = (<div />);
        if (this.props.type === "textinput") {
            mbody = (
                <div className="fillwidth">
                    <input className="modal-body-textbox" type="text" placeholder="Enter watchlist name" autoFocus
                        value={this.state.inputtext} onChange={this.handleChange} onKeyPress={this.onKeyPress} ref={(input)=>{input && input.focus()}}
                        maxLength={10} />

                    <div className="error-holder"> {this.state.error} </div>
                </div>
            );
        }
        else if (this.props.type === "message") {
            mbody = (
                <p className="modal-body-content"> {this.props.message} </p>
            );
        }


        return (
            <div className={"modal fade" + (this.props.showDialog ? "in dialog-show disabledbackground" : "  dialog-hide")} id={this.props.id} role="dialog">
                <div className="modal-dialog small">
                    <div className="modal-header">
                        {this.props.title}
                        <img src='assets/svg/close.svg' className="closebtn" data-toggle="tooltip" title="Close" onClick={this.handleNegativeButton} alt="close"/>
                    </div>
                    <div className="modal-body">
                        {mbody}
                    </div>
                    <div className="modal-footer">
                        <div className="col-6 col-lg-6 col-md-6">
                            <button type="button" className="footerbtn" data-dismiss="modal" onClick={this.handleNegativeButton}>{this.props.negativebuttontext}</button>
                        </div>
                        <div className="col-6 col-lg-6 col-md-6">
                            <button type="button" className="footerbtn" data-dismiss="modal" onClick={this.handlePossitiveButton}>{this.props.possitivebuttontext}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BasicDialog;