import { UrlExtensions, BaseUrl } from '../../common/constants';
import { httpPost } from '../base/api'

export const getImportantLinks = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.GET_IMPORTANTLINKS;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('imp succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}


export const getBhavCopy = function (requestData, responseCallback, errorCallback, segment) {
    var target = BaseUrl + UrlExtensions.GET_BHAVCOPY;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('BHAV succeeded with JSON response', response);        
        responseCallback(response, segment);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error, segment);
    });
}


export const getExchangeMessages = function (requestData, responseCallback, errorCallback, selectedsegment) {
    var target = BaseUrl + UrlExtensions.GET_EXCHANGEMESSAGES;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('BHAV succeeded with JSON response', response);        
        responseCallback(response, selectedsegment);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error, selectedsegment);
    });
}


export const getLoginAlerts = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.GET_LOGINALERTS;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('LoginALert succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}
export const logRiskDisc = function (requestData) {
    var target = BaseUrl + UrlExtensions.LOG_RISK;
    httpPost(target, requestData);
}

export const getExchangeTimings = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.GET_EXCHANGETIMINGS;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('ExchangeTimings succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

export const getMarketStatus = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.GET_MARKETSTATUS;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('MarketStatus succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

export const getCorporateActions = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.GET_CORPORATE_ACTIONS;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('ExchangeTimings succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}