import React from "react"
import { Popover, OverlayTrigger } from "react-bootstrap"

class CustomPopover extends React.Component {

    render() {
        const popoverHoverFocus = (
            <Popover className="custom-popover background-disable" id="ideas-popover">

                <div className="row description-header">
                    {this.props.popupstatus ?
                        <span>
                            Status:
                            <span className="status-green"> {this.props.popupstatus} </span>
                        </span>
                        : ''}
                    <img src='assets/svg/close.svg' className="closebtn" data-toggle="tooltip" title="Close" onClick={() => { this.refs.customPopover.hide() }} alt="close"></img>
                </div>
                {this.props.popupdate ?
                    <div className="row description-header">
                        <span style={{ padding: '4px' }}><img src='assets/svg/clock.svg' style={{width: '14px', height: '14px', marginRight: '5px'}} alt="clock"/>
                        </span>
                        <span style={{marginTop: '5px', marginRight: '84px'}}>  {this.props.popupdate} </span>
                    </div>
                    : ''}
                <div className="description-text">
                    {this.props.popuptext}
                    {this.props.children && this.props.children}
                </div>
            </Popover>
        );

        return (
            <OverlayTrigger ref="customPopover" trigger={['click']}
                placement={this.props.placement ? this.props.placement : "bottom"}
                overlay={popoverHoverFocus}
                container={this.props.container && this.props.container}
                rootClose
            >
                <i> {this.props.header} </i>
            </OverlayTrigger>
        );

    }
}

export default CustomPopover;