import React, { Component } from 'react';
import { connect } from 'react-redux';
import { placeOrder, placeSpreadOrder, modifyOrder } from '../../actions/orderentry/orderentryrequests'
import { toggleOrderDialog, showErrorDialog, setShowMarketDepth, onOrderSuccess } from '../../actions/orderentry/orderentryaction'
import { validityTypes, orderEntryTypes, advanceOrderTypes, productTypes_Equity } from '../../common/constants'
import { getConfigByKey } from '../../common/utils'
import { progressBar } from '../../controls/progress';
import { showOrderDialog } from '../../actions/orderentry/orderentryaction'
import SpreadOrderInput from './SpreadOrderInput';
import OrderInput from './OrderInput';
import OrderConfirmation from './OrderConfirmation';
import OrderResult from './OrderResult';
import SurvPopup from './SurvPopup';

class OrderEntryWindow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inProgress: false,
            currentpage: 'orderinput',
            currentorderdetails: {},
            previousinputs: {},
            sqrOffTimeLimit: 20,
            marginMode: 'M',
            orderConfirmationInputs: {},
            orderResult: {},
            ordAction: 'S'
        }
        this.setOrderDetails = this.setOrderDetails.bind(this);
        this.onCancelClicked = this.onCancelClicked.bind(this);
        this.getOrderInputComponent = this.getOrderInputComponent.bind(this);
        this.onOrderSuccess = this.onOrderSuccess.bind(this);

    }

    componentDidMount() {
        this.props.onOrderSuccess(this.onOrderSuccess);
    }

    componentWillReceiveProps(newprops) {
        if (newprops.orderDetails) {
            try {
                this.setOrderDetails(newprops.orderDetails);
                this.setState({ sqrOffTimeLimit: getConfigByKey('sqrOffTimeLimit') });
            }
            catch (ex) { }
        }
    }

    setOrderDetails(orderdetails) {
        this.setState({ currentpage: 'orderinput', currentorderdetails: orderdetails }, () => {
        });
    }

    onOrderInputConfirm(orderinputs) {
        this.setState({ previousinputs: orderinputs, currentpage: 'orderconfirmation' }, () => {
            this.setState({ orderConfirmationInputs: orderinputs });
        });
    }

    onOrderConfirmation() {
        if(this.state.orderConfirmationInputs.survId==='')
        {
            this.startPlaceOrder();
        }else{
            this.setState({ currentpage: 'survpop' }, () => {
            });
        }
        
    }

    onOrderModify() {
        this.setState({ currentpage: 'orderinput' }, () => {
        });
    }

    onOrderResultRetry() {
        this.setState({ currentpage: 'orderinput' }, () => {
        });
    }

    onCancelClicked() {
        this.setState(function (prevState) {
            return {
                currentpage: 'orderinput',
                currentorderdetails: {},
                previousinputs: {},
            };
        }, function () {
            this.props.toggleOrderDialog(false);
        }.bind(this));
    }

    onOrderSuccess() {
        this.props.showOrderDialog(this.state.previousinputs);
        this.props.toggleOrderDialog(false);
        this.setState({
            currentpage: 'orderinput', currentorderdetails: {},
            previousinputs: {},
        }, () => {
            this.openMyOrders();
            this.props.onOrderSuccessCallback(this.state.currentorderdetails);
        });
        //this.props.onOrderSuccessCallback(this.state.currentorderdetails);
    }

    setShowMarketDepthCB(cb) {
        this.props.setShowMarketDepth(cb);
    }

    openMyOrders() {
        this.props.history.push("/home/myorders");
    }

    startPlaceOrder() {
        var datapart;
        if (this.state.currentorderdetails.symobj) {
            if (this.state.currentorderdetails.symobj.instr.indexOf('SPR') === -1) {
                datapart = this.frameDatapart()
            }
            else {
                datapart = this.frameSpreadDatapart()
            }
        }
        var requestData = {
            request: {
                data: datapart
            }
        };
        this.setState({ inProgress: true });
        if (this.state.currentorderdetails.orderentrytype === orderEntryTypes.NEW
            || this.state.currentorderdetails.orderentrytype === orderEntryTypes.SQUAREOFF) {
            if (this.state.currentorderdetails.symobj) {
                if (this.state.currentorderdetails.symobj.instr.indexOf('SPR') === -1) {
                    placeOrder(requestData, this.parsePlaceOrderResponse.bind(this, false), this.parsePlaceOrderError.bind(this));
                }
                else {
                    placeSpreadOrder(requestData, this.parsePlaceOrderResponse.bind(this, false), this.parsePlaceOrderError.bind(this));
                }
            }
        }
        else if (this.state.currentorderdetails.orderentrytype === orderEntryTypes.MODIFY) {
            modifyOrder(requestData, this.parsePlaceOrderResponse.bind(this, true), this.parsePlaceOrderError.bind(this));
        }
    }

    frameDatapart() {
        console.log("aaaaaaaaaaaaaa ", this.state.previousinputs)
        var data = {};
        if (this.state.currentorderdetails.orderentrytype === orderEntryTypes.MODIFY) {
            data.orderId = this.state.currentorderdetails.correspondingorder.orderid;
            data.isOffline = this.state.currentorderdetails.correspondingorder.amo;
            data.tradedQty = this.state.currentorderdetails.correspondingorder.tradedQty
        }

        data.ordAction = this.state.previousinputs.action.toLowerCase();
        data.sym = this.state.previousinputs.symobj;
        data.ordType = this.state.previousinputs.ordertype.value;
        data.qty = this.state.previousinputs.quantity ? this.state.previousinputs.quantity.toString() : '';

        data.isSquareoff = this.state.previousinputs.isSquareoff;
        if (this.state.previousinputs.indp || this.state.previousinputs.producttype !== productTypes_Equity[1])
            data.isinDp = 'true';
        else if (this.state.previousinputs.producttype === productTypes_Equity[1]
            && this.state.previousinputs.producttype !== advanceOrderTypes[0] &&
            this.state.previousinputs.producttype !== advanceOrderTypes[1]
            && this.state.previousinputs.producttype !== advanceOrderTypes[2]) {
            data.isinDp = 'false'
        }

        data.limitPrice = this.state.previousinputs.price ? this.state.previousinputs.price.toString() : '';
        data.ordDuration = this.state.previousinputs.ordervalidity.value;
        if (this.state.previousinputs.ordervalidity === validityTypes[2]) {
            //vtd
            data.vtdDate = this.state.previousinputs.vtddate;
        }

        data.prdType = this.state.previousinputs.producttype && this.state.previousinputs.producttype.value;

        if (this.state.previousinputs.producttype.value === advanceOrderTypes[1].value) {
            data.coTriggerPrice = this.state.previousinputs.coTriggerPrice;
            data.isAmo = false;
        } else if (this.state.previousinputs.producttype.value === advanceOrderTypes[2].value) {
            data.boTriggerPrice = this.state.previousinputs.boTriggerPrice;
            data.boTargetPrice = this.state.previousinputs.boTargetPrice;
            data.boTrailingSL = this.state.previousinputs.boTrailingSL;
            data.isAmo = false;
        } else {
            if (this.state.previousinputs.slprice)
                data.triggerPrice = this.state.previousinputs.slprice.toString();
            else {
                data.triggerPrice = '0';
            }
            data.isAmo = this.state.previousinputs.isAmo;
        }

        if (this.state.previousinputs.discquantity)
            data.disQty = this.state.previousinputs.discquantity.toString();
        else
            data.disQty = '';
        //cover order
        if (this.state.previousinputs.isCoverLegModify) {
            data.limitPrice = '0.00';
        }

        console.log("bbbbbbb ", data)
        return data;
    }

    frameSpreadDatapart() {
        var data = {};

        if (this.state.currentorderdetails.orderentrytype === orderEntryTypes.MODIFY) {
            data.orderId = this.state.currentorderdetails.correspondingorder.orderid;
        }
        data.sym = this.state.previousinputs.symobj;
        data.qty = this.state.previousinputs.quantity ? this.state.previousinputs.quantity.toString() : '';
        data.limitPrice = this.state.previousinputs.price;
        data.priceDiff = this.state.previousinputs.price;   
        data.ordType = "limit";
        data.prdType = this.state.previousinputs.producttype.value;

        if(this.state.previousinputs.action == 'BUY'){
            data.ordAction = 'S';
            data.ordAction2 = 'B';
        }else if(this.state.previousinputs.action == 'SELL'){
            data.ordAction = 'B';
            data.ordAction2 = 'S';
        }

        data.price = this.state.orderConfirmationInputs.price;
        if (this.state.currentorderdetails.orderentrytype === orderEntryTypes.MODIFY) {
            data.ordDuration = this.state.currentorderdetails.correspondingorder.validitytype;
            data.tradedQty =this.state.currentorderdetails.correspondingorder.tradedQty;
            data.nestRequestId = this.state.currentorderdetails.nestRequestId;
        }
        return data;
    }


    parsePlaceOrderResponse(ismodify, responseData) {

        let { response: { data } } = responseData;

        this.setState({ inProgress: false, currentpage: 'orderresult' }, () => {
            let result = {
                isSuccess: true,
                symbol: this.state.previousinputs.scrip,
                exchange: this.state.previousinputs.symobj.exc,
                headline: ismodify ? 'Your order has been modified. Please check current status in order book!' : 'Your order has been accepted. Please check current status in order book!',
                message: data.msg,
                orderId: 'Order Id: ' + data.orderId
            };
            this.setState({ orderResult: result });
        });

    }

    parsePlaceOrderError(error) {
        this.setState({ inProgress: false, currentpage: 'orderresult' }, () => {
            let result = {
                isSuccess: false,
                symbol: this.state.previousinputs.scrip,
                exchange: this.state.previousinputs.symobj.exc,
                headline: 'Please try again!',
                message: error.message
            };
            this.setState({ orderResult: result });
        });
    }

    getOrderInputComponent() {

        // if (this.state.currentorderdetails && this.state.currentorderdetails.symobj
        //     && this.state.currentorderdetails.symobj.instr && this.state.currentorderdetails.symobj.instr.indexOf('SPR') !== -1) {

        //     return (
        //         <SpreadOrderInput />
        //     )
        // }
        // else {
        return (
            <OrderInput
                ref={orderinput => { this.orderinput = orderinput }}
                onNegativeButtonClick={this.onCancelClicked}
                currentorderdetails={this.state.currentorderdetails}
                previousinputs={this.state.previousinputs}
                setParentOrderDetails={this.setOrderDetails}
                onPlaceOrderClick={this.onOrderInputConfirm.bind(this)}
                AddBasketRequestClick={this.onOrderInputConfirm.bind(this)}
                showErrorDialog={this.props.showErrorDialog}
                setParentShowMarketDepth={this.setShowMarketDepthCB.bind(this)}
            />
        )
        // }
    }

    render() {
        return (
            this.props.showDialog ?
                <div className={"modal disabledbackground orderentry-modal fade" + (this.props.showDialog ? "in dialog-show" : "dialog-hide")} id={this.props.id} role="dialog" >
                    {this.state.currentpage === 'orderinput' ?
                        this.getOrderInputComponent()
                        : ''}

                    {this.state.currentpage === 'orderconfirmation' ?
                        <OrderConfirmation
                            orderInputs={this.state.orderConfirmationInputs}
                            onOrderModify={this.onOrderModify.bind(this)}
                            onConfirmOrder={this.onOrderConfirmation.bind(this)}
                            sqrOffTimeLimit={this.state.sqrOffTimeLimit} />
                        : ''}

                    {this.state.currentpage === 'orderresult' ?
                        <OrderResult
                            orderResult={this.state.orderResult}
                            onNegativeButtonClick={this.onOrderSuccess}
                            onRetryClicked={this.onOrderResultRetry.bind(this)} />
                        : ''}

                    {this.state.currentpage === 'survpop'?
                        <SurvPopup 
                            onConfirmOrder={this.startPlaceOrder.bind(this)}
                            onOrderModify={this.onOrderModify.bind(this)}
                            orderInputs={this.state.orderConfirmationInputs}/>:''
                    }

                    {this.state.inProgress ? progressBar() : ''}

                </div> : null
        );
    }

};
const mapStateToProps = (state) => {
    return {
        showDialog: state.orderentry.showDialog,
        orderDetails: state.orderentry.orderDetails,
        onOrderSuccessCallback: state.orderentry.onOrderSuccessCallback,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        toggleOrderDialog: (value) => toggleOrderDialog(dispatch, value),
        showErrorDialog: (errordata) => showErrorDialog(dispatch, errordata),
        getConfigByKey: (key) => getConfigByKey(key, dispatch),
        setShowMarketDepth: (cb) => setShowMarketDepth(dispatch, cb),
        onOrderSuccess: (callback) => onOrderSuccess(dispatch, callback),
        showOrderDialog: (data) => showOrderDialog(dispatch, data),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderEntryWindow);