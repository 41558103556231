import React from 'react';
//import { TradeActions, productTypes_Equity, validityTypes } from '../../common/constants';


class SEPOrderConfirmation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            parseditems: [
                { key: "Symbol", value: "RCOM" }, { key: "Exchange", value: "NSE" }, { key: "Frequency", value: "Monthly" },
                { key: "SEP Amount", value: "1,000.00" }, { key: "No. of Installments", value: "3" }, { key: "Start Date", value: "16-Nov-2017" },
                { key: "End Date", value: "16-Dec-2018" }
            ]
        };
    }

    onViewSEPRegReportsClicked() {
        this.props.history.push("/home/sepregistrationreport");
    }

    render() {
        return (
            <div className="modal-dialog orderresult sep-modal">
                {this.props.status === "orderconfirm" ?
                    <div>
                        <div className="order-row" style={{ textAlign: "center" }}>
                            CONFIRM SEP ORDER
                        </div>
                        <div className="row hdivider contentpanel"> </div>
                        <div className="container-fluid order-row order-confirmation-body">
                            {this.state.parseditems.map(function (row, key) {
                                return (
                                    <div key={key} className="row order-confirmation-row" >
                                        <div className="col-xs-6 text-right" style={{ color: "#777" }}>
                                            {row.key}
                                        </div>
                                        <div className="col-xs-6 contentpanel">
                                            {row.value}
                                        </div>
                                    </div>
                                );
                            }, this)
                            }
                        </div>
                        <div className="row order-row text-center" style={{ marginTop: "20px" }}>
                            <button type="button" className="btn-footer order-cancel" onClick={this.props.backButtonClicked} >BACK</button>
                            <button type="button" className="btn-footer order-confirm-button active-buy" onClick={this.props.submitClicked} >SUBMIT</button>
                        </div>
                    </div>
                    : ""
                }
                {this.props.status === "ordersuccess" ?
                    <div style={{ minHeight: 170 }} >
                        <div className="order-row" style={{ textAlign: "center" }}>
                            SEP Registration Number
                            </div>
                        <div className="row hdivider contentpanel"> </div>
                        <div className="sep-reg-no" >
                            3101201102021
                            </div>
                        <div className="row order-row text-center ipo-link" style={{ marginTop: "20px" }}>
                            View <a onClick={this.onViewSEPRegReportsClicked.bind(this)}>SEP REGISTRATION REPORT</a>
                        </div>
                    </div>
                    : ""
                }
                {this.props.status === "deleteconfirm" ?
                    <div>
                        <div className="order-row" style={{ textAlign: "center" }}>
                            DELETE SEP REGISTRATION
                        </div>
                        <div className="row hdivider contentpanel"> </div>
                        <div className="container-fluid order-row order-confirmation-body">
                            {this.state.parseditems.map(function (row, key) {
                                return (
                                    <div key={key} className="row order-confirmation-row" >
                                        <div className="col-xs-6 text-right" style={{ color: "#777" }}>
                                            {row.key}
                                        </div>
                                        <div className="col-xs-6 contentpanel">
                                            {row.value}
                                        </div>
                                    </div>
                                );
                            }, this)
                            }
                        </div>
                        <div className="row order-row text-center" style={{ marginTop: "20px" }}>
                            <button type="button" className="btn-footer order-cancel" onClick={this.props.regBackButtonClicked} >BACK</button>
                            <button type="button" className="btn-footer order-confirm-button active-sell" onClick={this.props.regSubmitClicked} >DELETE</button>
                        </div>
                    </div>
                    : ""
                }
                {this.props.status === "deletesuccess" ?
                    <div style={{ minHeight: 170 }} >
                        <div className="order-row" style={{ textAlign: "center" }}>
                            SEP Registration Cancellation
                            </div>
                        <div className="row hdivider contentpanel"> </div>
                        <div className="sep-reg-no" >
                            SEP Registration successfully cancelled against number 3101201102021
                        </div>
                        <div className="row order-row text-center ipo-link" style={{ marginTop: "20px" }}>
                            View <a onClick={this.onViewSEPRegReportsClicked.bind(this)}>SEP REGISTRATION REPORT</a>
                        </div>
                    </div>
                    : ""
                }
            </div>
        );
    }

}

export default SEPOrderConfirmation;