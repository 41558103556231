import React from "react";

class OrderNumberInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inputText: this.props.defaultValue ? this.props.defaultValue : "0",
            minValue: this.props.minValue ? this.props.minValue : 0,
            maxValue: this.props.maxValue ? this.props.maxValue : 9999999
        };

        this.getValue = this.getValue.bind(this);
        this.setValue = this.setValue.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.minValue !== this.props.minValue) {
            this.setState({ minValue: nextProps.minValue });
        }
        if (nextProps.maxValue !== this.props.maxValue) {
            this.setState({ maxValue: nextProps.maxValue });
        }
        if (nextProps.defaultValue !== this.props.defaultValue) {
            this.setState({ inputText: nextProps.defaultValue });
        }
    }

    getValue() {
        return this.state.inputText;
    }

    setValue(value) {
        this.setState({ inputText: value });
    }

    onBlur(e) {
        var currentVal = parseFloat(e.target.value);
        if (isNaN(currentVal)) {
            this.setState({ inputText: "0" });
        }
    }

    onBtnMinusClick(e, type) {
        let inputValue = e.target.value;
        var decrementvalue, currentVal = parseFloat(inputValue);
        if (this.props.incrementvalue) {
            decrementvalue = parseFloat(this.props.incrementvalue);
        } else {
            decrementvalue = 1
        }
        if (!isNaN(currentVal)) {
            if (currentVal > this.state.minValue) {
                var dicimalPlaces = this.props.isnumber ? 0 : this.props.decimalplacescount ? this.props.decimalplacescount : 2;


                var multiplier = Math.pow(10, dicimalPlaces);
                var cv = Math.round(currentVal.toFixed(dicimalPlaces) * multiplier);
                var iv = Math.round(decrementvalue.toFixed(dicimalPlaces) * multiplier);
                console.log("cv: " + cv + "  iv: " + iv);
                var rem = (Math.round(cv % iv)) / multiplier;
                console.log(rem);
                var res;
                if (rem === 0) {
                    res = ((cv - iv) / multiplier).toFixed(dicimalPlaces);
                }
                else {

                    res = (cv / multiplier - rem).toFixed(dicimalPlaces);
                }
                if(this.props.allowNegativeSign)
                {
                    if (res > this.state.minValue)
                    {
                        this.setState({ inputText: res }, () => {
                            if (this.props.onChange) {
                                this.props.onChange(this.state.inputText);
                            }
                        });
                    }
                }
                else
                {
                    if (res < this.state.minValue)
                        res = 0;
                    if(parseFloat(res) === 0){
                        res = 0;
                    }
                    this.setState({ inputText: res }, () => {
                        if (this.props.onChange) {
                            this.props.onChange(this.state.inputText);
                        }
                    });
                }
            }
            if (currentVal - decrementvalue <= this.state.minValue) {
                //this.btnMinus.setAttribute("disabled", true);
            }
            //this.btnPlus.removeAttribute("disabled")
        } else {
            var dicimalPlaces = this.props.isnumber ? 0 : this.props.decimalplacescount ? this.props.decimalplacescount : 2;
            this.setState({ inputText: (0).toFixed(dicimalPlaces) }, () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.inputText);
                }
            });            
        }
    }


    onBtnNegativeClick(e, type){
        var inputValue = e.target.value;
        var currentVal = parseFloat(inputValue.split('-')[0]);

        if(inputValue=='')
        {
            var dicimalPlaces = this.props.isnumber ? 0 : this.props.decimalplacescount ? this.props.decimalplacescount : 2;
            this.setState({ inputText: (0).toFixed(dicimalPlaces) }, () => {
                if (this.props.onChange) {
                    this.props.onChange('-');
                }
            }); 
        }
        else if (!isNaN(currentVal)){
            if(currentVal<=this.state.maxValue && currentVal>this.state.minValue)
            {
                var negateValue = 0-currentVal;
                var dicimalPlaces = this.props.isnumber ? 0 : this.props.decimalplacescount ? this.props.decimalplacescount : 2;
                var multiplier = Math.pow(10, dicimalPlaces);
                var cv = Math.round(negateValue.toFixed(dicimalPlaces) * multiplier);
                console.log("negateValue===>"+negateValue)

                this.setState({ inputText: negateValue.toFixed(dicimalPlaces)}, () => {
                    if (this.props.onChange) {
                        this.props.onChange(this.state.inputText);
                    }
                });
            }
        }
        else {
            this.setState({ inputText: "0" }, () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.inputText);
                }
            });
        }
    }

    onBtnPlusClick(e, type) {
        let inputValue = e.target.value;
        var incrementvalue, currentVal = parseFloat(inputValue);
        if (this.props.incrementvalue) {
            incrementvalue = parseFloat(this.props.incrementvalue);
        } else {
            incrementvalue = 1
        }
        if (!isNaN(currentVal)) {
            if (currentVal < this.state.maxValue) {
                var dicimalPlaces = this.props.isnumber ? 0 : this.props.decimalplacescount ? this.props.decimalplacescount : 2;

                var multiplier = Math.pow(10, dicimalPlaces);
                var cv = Math.round(currentVal.toFixed(dicimalPlaces) * multiplier);
                var iv = Math.round(incrementvalue.toFixed(dicimalPlaces) * multiplier);
                console.log("cv: " + cv + "  iv: " + iv);
                var rem = (Math.round(cv % iv)) / multiplier;

                if (rem === 0) {
                    let res = (cv + iv) / multiplier;
                    if (res <= this.state.maxValue) {
                        this.setState({ inputText: res.toFixed(dicimalPlaces) }, () => {
                            if (this.props.onChange) {
                                this.props.onChange(this.state.inputText);
                            }
                        });
                    }
                }
                else {
                    let res = (cv + iv) / multiplier - rem;
                    console.log(res);
                    if (res <= this.state.maxValue) {
                        this.setState({ inputText: res.toFixed(dicimalPlaces) }, () => {
                            if (this.props.onChange) {
                                this.props.onChange(this.state.inputText);
                            }
                        });
                    }
                }
            }

            if (currentVal + incrementvalue >= this.state.maxValue) {
                //this.btnPlus.setAttribute("disabled", true);
            }
            //this.btnMinus.removeAttribute("disabled")
        } else {
            var dicimalPlaces = this.props.isnumber ? 0 : this.props.decimalplacescount ? this.props.decimalplacescount : 2;
            this.setState({ inputText: (0).toFixed(dicimalPlaces) }, () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.inputText);
                }
            });
        }
    }

    onkeydown(e) {
        if (e.keyCode === 38) { 
            this.onBtnPlusClick(e);
        }

        if (e.keyCode === 40) { 
            this.onBtnMinusClick(e);
        }

        
        if(e.keyCode === 109 && this.props.allowNegativeSign)
        {
            var value = e.target.value;
            if (value.indexOf('-')>-1) 
            {
                e.preventDefault();
            }
            else
            {
                this.onBtnNegativeClick(e);
            }
        }

        if (this.props.isnumber) {
            if (e.keyCode === 110 || e.keyCode === 190) {
                e.preventDefault();
            }
        }
        if (this.props.isdecimal) {
            var min_count = this.props.decimalplacescount ? this.props.decimalplacescount : 2;
            var value = e.target.value, cursor_position = e.target.selectionStart, dot_position = value.indexOf(".");
            if (((e.keyCode === 110 || e.keyCode === 190) && value.split('.').length >= 2) ||
                (value.indexOf(".") > -1 && cursor_position > dot_position && value.substring(dot_position, value.length).length > min_count && ![9,8, 37, 39].includes(e.keyCode))) {
                e.preventDefault();
            }            
        }
        if (
            ([46, 8, 9, 27, 13, 190].includes(e.keyCode)) ||
            (e.keyCode === 65 && e.ctrlKey === true) ||
            (e.keyCode >= 35 && e.keyCode <= 39)
        ) {
            return;
        }
        if (
            (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
            (e.keyCode < 96 || e.keyCode > 105) && (e.keyCode !== 110)
        ) {
            e.preventDefault();
        }
    }

    onChange(e) {
   
        
        var currentValString = e.target.value;
        if(this.props.allowNegativeSign)
             {
                if (currentValString === ''){
                    this.setState({ inputText: "" }, () => {
                        if (this.props.onChange) {
                            this.props.onChange(this.state.inputText);
                        }
                    });
                    return; 
                }
                else if (currentValString === '-'){
                    this.setState({ inputText: "-" }, () => {
                        if (this.props.onChange) {
                            this.props.onChange(this.state.inputText);
                        }
                    });
                    return; 
                }
                else
                {
                    var currentVal = parseFloat(e.target.value);
                    if ((currentVal <= this.state.maxValue) && (currentVal > this.state.minValue))
                    {
                        this.setState({ inputText: currentValString }, () => {
                            if (this.props.onChange) {
                                this.props.onChange(this.state.inputText);
                            }
                        });
                    }
                }
                
            }
            else
            {
                var currentVal = parseFloat(e.target.value);
                if (currentValString === '') {

                    this.setState({ inputText: "0" }, () => {
                        if (this.props.onChange) {
                            this.props.onChange(this.state.inputText);
                        }
                    });
                    return;                    
                }

                if (currentValString[0] === '0' && currentValString[1] !== '.' && currentValString[1]) {
                    this.setState({ inputText: currentValString[1] }, () => {
                        if (this.props.onChange) {
                            this.props.onChange(this.state.inputText);
                        }
                    });
                    return;
                }
                if ((currentVal <= this.state.maxValue) || e.target.value.length === 0) {
                    this.setState({ inputText: currentValString }, () => {
                        if (this.props.onChange) {
                            this.props.onChange(this.state.inputText);
                        }
                    });
                }
            }
    }

    render() {
        return (
            <div className="order-number-input">
                <input onPaste={(e) => e.preventDefault()}
                    type="text"
                    name="quant[1]"
                    tabIndex={this.props.tabIndex}
                    className="form-control input-number"
                    value={this.state.inputText}
                    onChange={e => { this.onChange(e) }}
                    onBlur={this.onBlur.bind(this)}
                    onKeyDown={e => {
                        this.onkeydown(e);
                    }}
                    autoComplete="off"
                    disabled={this.props.isDisabled}
                />
                {
                    this.props.hideDiv? '' :
                    this.props.isDisabled ?
                        <div style={{ position: 'absolute', top: '0', left: '0px', right: '0px', bottom: '0', backgroundColor: '#ffffff55' }}> </div>
                        : ''
                }
            </div>
        );
    };


};
export default OrderNumberInput;