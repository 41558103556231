
import React from "react"
import { connect } from "react-redux"
import ChartView from '../components/charts/ChartView'
import { ModuleKeys } from '../common/constants'
import { toggleChartDialog } from '../actions/chartiq/chartaction'

class ChartDialog extends React.Component {
    componentDidUpdate() {
        // if (this.props.showDialog) {
        //     var refs = this;
        //     window.$(document).click(function (e) {
        //         if (window.$("#chart-modal").find(e.target).length <= 0 && e.target.className !== "cq-close") {
        //             refs.props.toggleChartDialog();
        //         }
        //     })
        // } else {
        //     window.$(document).off("click");
        // }
    }

    render() {
        return (
            this.props.showDialog ? <div className={"modal disabledbackground " + (this.props.showDialog ? " dialog-show" : " dialog-hide")} id="chart-modal" role="dialog">
                <div className="modal-dialog chart-dialog">
                    <div className="ch-modal-content">
                        <div className="modal-body " style={{ border: '1px solid #3a3a3a', marginRight: '10px', marginLeft: '10px' }} >
                            <ChartView id="chartContainer" isChartDialog={true} componentType={ModuleKeys.CHART_1} isSmall={true} symbol={this.props.symbol} />
                        </div>
                    </div>

                </div>
            </div> : null
        );
    }
}

const mapStateToProps = state => {
    return {
        showDialog: state.charts.showChartDialog,
        symbol: state.charts.symbol
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        toggleChartDialog: () => toggleChartDialog(dispatch, false)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartDialog);
