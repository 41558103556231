import { UrlExtensions,  BaseUrl } from '../../common/constants';
import { httpPost } from '../base/api'

//========================getTradingPlan======

export const getTradingPlan = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.MYTRADINGPLAN;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('request succeeded with JSON response', response); 
        responseCallback(response);
        // return response;
    }).catch(function (error) {
        console.log('request failed', error);
        errorCallback(error);
    });
}

//========================getTradingPlan======

export const getTradingMasterPlan = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.TRADINGMASTERPLAN;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('request succeeded with JSON response', response); 
        responseCallback(response);
        // return response;
    }).catch(function (error) {
        console.log('request failed', error);
        errorCallback(error);
    });
}


//========================SUBSCRIPTIONREQUEST======

export const getSubscriptionRequest = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.SUBSCRIPTIONREQUEST;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('request succeeded with JSON response', response); 
        responseCallback(response);
        // return response;
    }).catch(function (error) {
        console.log('request failed', error);
        errorCallback(error);
    });
}