import React from "react"
//import { isValidWatchlistName } from '../../validators/watchlistValidations'

class CreateNewBasketPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {            
            error: '',
            inputtext: this.props.selectedOrderBasket,
        }     
        this.handleChange = this.handleChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.handlePossitiveButton = this.handlePossitiveButton.bind(this);
        this.handleNegativeButton = this.handleNegativeButton.bind(this);
    }

    setError(err) {
        this.setState({ error: err });
    }

    getInputText() {
        return this.state.inputtext;
    }

    setInputText(text) {
        this.setState({ 
            inputtext: '' 
        });
    }

    handleError(err) {
        if (err) {
            this.setState({ error: err });
        }
        else {
            // success case
            this.setState({ inputtext: '', error: '' });
        }
    }

    handleChange(e) {
        this.setState({ 
            inputtext: e.target.value, error: '' 
        });
    }

    onKeyPress(e) {
        if (e.key === 'Enter') {
            this.handlePossitiveButton();
        }
    }

    handleNegativeButton() {       

        if (this.props.onNegativeButtonClick)
            this.props.onNegativeButtonClick();
    }

    handlePossitiveButton() {
       
        if (this.props.onPossitiveButtonClick)
            this.props.onPossitiveButtonClick();        
    }

    render() {

        var  mbody = (
            <div className="fillwidth">
                <p className="modal-body-content"> {this.props.message} </p>
                <input className="modal-body-textbox" type="text" placeholder="Enter basket name"
                    value={this.state.inputtext} onChange={this.handleChange} onKeyPress={this.onKeyPress}
                    maxLength={200} />

                <div className="error-holder"> {this.state.error} </div>
            </div>
        );        


        return (
            <div className={"modal fade" + (this.props.showDialog ? "in dialog-show" : "dialog-hide")} id={this.props.id} role="dialog">
                <div className="modal-dialog small-medium" style={{ top: '19%', height: 250, minHeight: 0 }}>
                    <div className="product-modal-header">
                        {this.props.title}                    
                    </div>
                    <div className="modal-body">
                        {mbody}
                    </div>
                    <div className="modal-footer" style={{marginTop:'20px'}}>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <button type="button" className="footerbtn" data-dismiss="modal" onClick={this.handleNegativeButton}>{this.props.negativebuttontext}</button>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <button type="button" className="create-basket-btn" data-dismiss="modal" onClick={this.handlePossitiveButton}>{this.props.possitivebuttontext}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateNewBasketPopup;