import { wsInteractiveUri, wsInteractiveUriNetMagic, wsBroadcastUri, wsBroadcastUriNetMagic, localStorageKeys } from '../common/constants'
import { getItemByKey } from '../common/utils'

window.socketEvents = (function () {

    var websocketinteractive, websocketbroadcast;
    var interativeInstance, broadcastInstance;
    var eventLevel1 = {}, eventLevel2 = {};
    var reconnect = true, timeInterval;
    var socketType = { interative: "1", broadcast: "2" };
    var eventType = { eventlevel1: "1", eventlevel2: "2" }

    var oninit = function () {
        if ("WebSocket" in window) {
            reconnect = true
            if (!interativeInstance || !broadcastInstance) {
                interativeInstance = new websocketInstance();
                broadcastInstance = new websocketInstance();
            }
            if(getItemByKey(localStorageKeys.CUG_FLAG) === "Y"){
                interativeInstance.initialize(wsInteractiveUriNetMagic, socketType.interative);
                websocketinteractive = interativeInstance.createConnection();
                broadcastInstance.initialize(wsBroadcastUriNetMagic, socketType.broadcast);
                websocketbroadcast = broadcastInstance.createConnection();
            }
            else{
                 interativeInstance.initialize(wsInteractiveUri, socketType.interative);
                 websocketinteractive = interativeInstance.createConnection();
                 broadcastInstance.initialize(wsBroadcastUri, socketType.broadcast);
                 websocketbroadcast = broadcastInstance.createConnection();
            }

            // } else {
            //     if (!timeInterval)
            //         timeInterval = setInterval(checkAndConnect(), 3000);
            // }
        }
    }

    var checkAndConnect = function () {

        if ((websocketinteractive && websocketinteractive.readyState === 3) && (websocketbroadcast && websocketbroadcast.readyState === 3)) {
            if(getItemByKey(localStorageKeys.CUG_FLAG) === "Y"){
                websocketinteractive = interativeInstance.initialize(wsInteractiveUriNetMagic, socketType.interative);
                websocketbroadcast = broadcastInstance.initialize(wsBroadcastUriNetMagic, socketType.broadcast);
            }
            else{
                websocketinteractive = interativeInstance.initialize(wsInteractiveUri, socketType.interative);
                websocketbroadcast = broadcastInstance.initialize(wsBroadcastUri, socketType.broadcast);
            }

            clearInterval(timeInterval);
            timeInterval = null;
            console.log("stream connect");
        }

    }

    /**
     * @param {*unique key to subscribe } event 
     * @param {*conatins screen, symbol, exc } data 
     * @param {*to receive stream data} callback 
     * @param {* level1 or level2 default ---- level 1} type 
     */
    var register = function (event, data, callback, type) {
        if (type === eventType.eventlevel2) {
            eventLevel2[event] = eventLevel2[event] || {};
            eventLevel2[event].subscribers = eventLevel2[event].subscribers || {};
            eventLevel2[event].data = eventLevel2[event].data || { sym: data.sym, exc: data.exc };
            eventLevel2[event].subscribers[data.screenName] = callback;
        } else {
            eventLevel1[event] = eventLevel1[event] || {};
            eventLevel1[event].subscribers = eventLevel1[event].subscribers || {};
            eventLevel1[event].data = eventLevel1[event].data || { sym: data.sym, exc: data.exc };
            eventLevel1[event].subscribers[data.screenName] = callback;

        }
    };

    var unregister = function (screenName) {
        if (eventLevel1) {
            for (var key in eventLevel1) {
                delete eventLevel1[key].subscribers[screenName];
                if (Object.keys(eventLevel1[key].subscribers).length === 0) {
                    delete eventLevel1[key];
                }
            }
            subscribe(eventLevel1);
        }
        if (eventLevel2) {
            for (var key in eventLevel2) {
                delete eventLevel2[key].subscribers[screenName];
                if (Object.keys(eventLevel2[key].subscribers).length === 0) {
                    delete eventLevel2[key];
                }
            }
            subscribe(eventLevel2);
        }
    };

    var subscribe = function (event) {
        if (Object.keys(eventLevel1).length > 0) {
            placeRequest(eventLevel1, eventType.eventlevel1);
        }
        if (Object.keys(eventLevel2).length > 0) {
            placeRequest(eventLevel2, eventType.eventlevel2);
        }
    };

    var placeRequest = function (events, type) {
        var req = getAccReq();
        if (req) {
            req.action = "sub";
            req.type = type === eventType.eventlevel1 ? "level1" : "level2";
            req.symbols = [];
            for (var key in events) {
                req.symbols.push(events[key].data)
            };
            doSend(req);
        }
    }

    var unsubscribe = function (event) {

    };

    function getAccReq() {
        var req = {};
        var accountDet = JSON.parse(getItemByKey(localStorageKeys.ACCOUNT_ID));
        if (accountDet) {
            req.accID = accountDet.accID;
            return req;
        } else {
            disconnect();
        }
    }

    function onOpen(evt, websocket, type) {
        writeToScreen("CONNECTED");
        if (type === socketType.interative) {
            console.log("interative")
            websocketinteractive = websocket;
            if (websocketinteractive.readyState === 1) {
                subscribe();
            }
        }
        else {
            console.log("broadcast")
            websocketbroadcast = websocket;
            if (websocketbroadcast.readyState === 1)
                doSendToBroadcast(getAccReq());
        }
    }

    function onClose(evt, websocket, type) {
        writeToScreen("DISCONNECTED", type);
        try {
            if (type === socketType.interative && websocketbroadcast) {
                websocketbroadcast.close();
            }
        } catch (error) {

        }
    }

    function doSend(message) {
        if (websocketinteractive && websocketinteractive.readyState === 1) {
            var req = JSON.stringify(message) + "\n"
            writeToScreen("SENT: " + req);
            websocketinteractive.send(req);
        }
    }

    function doSendToBroadcast(message) {
        if (websocketbroadcast) {
            var req = JSON.stringify(message) + "\n"
            writeToScreen("SENT: " + req);
            websocketbroadcast.send(req);
        }
    }

    function onMessage(evt) {
        setTimeout(function () {
            var jsonData = JSON.parse(evt.data);
            if (Object.keys(jsonData).length > 0 && jsonData.type !== "excMsg") {
                var events;
                if (jsonData.type === 'level1') {
                    events = Object.assign({}, eventLevel1);
                    parseMessage(events, JSON.parse(evt.data));
                } else if (jsonData.type === 'level2') {
                    events = Object.assign({}, eventLevel2);
                    parseMessage(events, JSON.parse(evt.data));
                } else if (jsonData.type === 'level1ext') {
                    events = Object.assign({}, eventLevel1);
                    parseMessage(events, JSON.parse(evt.data), "ext");
                }
            }
        }, 0)
    }

    var parseMessage = function (events, jsonData, extra) {
        jsonData.data.sym = jsonData.data.sym.toUpperCase();
        var kayData = extra ? jsonData.data.sym + jsonData.data.exc + extra : jsonData.data.sym + jsonData.data.exc;
        if (events[kayData]) {
            for (var key in events[kayData].subscribers) {
                events[kayData].subscribers[key](jsonData);
            }
        }
    }

    var disconnect = function () {
        console.log("disconnect websocket")
        eventLevel1 = {};
        eventLevel2 = {};
        reconnect = false;
        if (websocketinteractive) {
            websocketinteractive.close();
        }
        if (websocketbroadcast) {
            websocketbroadcast.close();
        }
        interativeInstance && interativeInstance.removeAllListener();
        broadcastInstance && broadcastInstance.removeAllListener();
        websocketinteractive = null;
        interativeInstance = null;
        websocketbroadcast = null;
        broadcastInstance = null;
    }

    function writeToScreen(message, type) {
        console.log(message, type);
    }


    var getEvents = function (type) {
        return type === "level2" ? eventLevel2 : eventLevel1;
    }

    var getReconnect = function () {
        return reconnect;
    }

    var setReconnect = function (value) {
        reconnect = value;
    }

    var removeAllListener = function (wtype) {
        if (interativeInstance && wtype === socketType.interative)
            interativeInstance.removeAllListener();
        else if (broadcastInstance)
            broadcastInstance.removeAllListener();
    }

    var createConnection = function (wtype) {
        if (interativeInstance) {
            if (wtype === socketType.interative) {
                websocketinteractive = interativeInstance.createConnection();
            } else {
                websocketbroadcast = broadcastInstance.createConnection();
            }
        } else {
            oninit();
        }
    }

    return {
        register: register,
        subscribe: subscribe,
        unsubscribe: unsubscribe,
        unregister: unregister,
        getEvents: getEvents,
        oninit: oninit,
        onOpen: onOpen,
        onClose: onClose,
        onMessage: onMessage,
        disconnect: disconnect,
        getReconnect: getReconnect,
        setReconnect: setReconnect,
        createConnection: createConnection,
        removeAllListener, removeAllListener,
    }

})();

var websocketInstance = function () {
    var websocket, wtype;
    var retryCount;
    var wurl;
    this.initialize = function (url, type) {
        wtype = type;
        wurl = url;
        retryCount = 0
    }

    this.createConnection = function () {
        websocket = new WebSocket(wurl);
        websocket.addEventListener("open", this.onOpen);
        websocket.addEventListener("close", this.onClose);
        websocket.addEventListener("message", this.onMessage);
        websocket.addEventListener("error", this.onError);
        return websocket;
    }

    this.onOpen = function (evt) {
        retryCount = 0;
        window.socketEvents.onOpen(evt, websocket, wtype);
    }

    this.removeAllListener = function () {
        websocket.removeEventListener("open", this.onOpen);
        websocket.removeEventListener("close", this.onClose);
        websocket.removeEventListener("message", this.onMessage);
        websocket.removeEventListener("error", this.onError);
    }

    this.onClose = function (evt) {
        window.socketEvents.onClose(evt, websocket, wtype);
        window.socketEvents.removeAllListener(wtype);
        if (window.socketEvents.getReconnect() && retryCount < 10) {
            retryCount++;
            setTimeout(function () {
                window.socketEvents.createConnection(wtype);
            }.bind(this), 5000);
        }

        if (wtype === "1" && retryCount === 10) {
            window.socketEvents.disconnect();
        }
    }

    this.onMessage = function (evt) {
        window.socketEvents.onMessage(evt);
    }

    this.onError = function (evt) {

    }
};