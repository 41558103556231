import React from 'react';
import { setCookie } from '../../common/utils'


class CreateUpiIdMobile extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            
        }
    }
    
    componentWillMount(){
        setCookie("isCreateMobileUPI", "", 0);
    }

    render() {
        return (
            <div className="fillheight" style={{overflow:'scroll'}}>
                <div className="row findupi_id">
                    <div className="col-12 header_upi">
                        <p><i className="fa fa-chevron-left"></i> Steps to create UPI ID</p>
                    </div>
                </div>
                <div className="row">
                <div className="col-12 upi_Steps">
                        <div className="steps_circel" style={{marginTop:'30px', marginLeft:'11px'}}>
                            <span className="first_steps_dot"></span>
                            <span className="steps_line"></span>
                        </div>
                    </div>
                    <div className="col-12 upi_Steps">
                        <div className="steps_circel">
                            <span className="steps_dot"><i className="fa fa-mobile"></i></span>
                            <span className="steps_dot2"></span>
                            <span className="steps_line"></span>
                        </div>
                        <div className="upi_text">
                            <p className="FirstLatter">1</p>
                            <p> Download BHIM UPI App or your bank UPI app</p>
                        </div>
                    </div>
                    <div className="col-12 upi_Steps">
                        <div className="steps_circel">
                            <span className="steps_dot"><i className="fa fa-lock"></i></span>
                            <span className="steps_dot2"></span>
                            <span className="steps_line"></span>
                        </div>
                        <div className="upi_text">
                            <p className="FirstLatter">2</p>
                            <p>Sign Up/ Login to the app</p>
                        </div>
                    </div>
                    <div className="col-12 upi_Steps">
                        <div className="steps_circel">
                            <span className="steps_dot"><i className="fa fa-address-book" style={{fontSize:'18px'}}></i></span>
                            <span className="steps_dot2"></span>
                            <span className="steps_line"></span>
                        </div>
                        <div className="upi_text">
                            <p className="FirstLatter">3</p>
                            <p>Create your UPI ID on BHIM App or your bank supported UPI App</p>
                        </div>
                    </div>
                    <div className="col-12 upi_Steps">
                        <div className="steps_circel">
                            <span className="steps_dot"><i className="fa fa-university" style={{fontSize:'18px'}}></i></span>
                            <span className="steps_dot2"></span>
                            <span className="steps_line"></span>
                        </div>
                        <div className="upi_text">
                            <p className="FirstLatter">4</p>
                            <p>Add your bank accounts</p>
                        </div>
                    </div>
                    <div className="col-12 upi_Steps">
                        <div className="steps_circel">
                            <span className="steps_dot"><i className="fa fa-ellipsis-h"></i></span>
                            <span className="steps_dot2"></span>
                            <span className="steps_line"></span>
                        </div>
                        <div className="upi_text">
                            <p className="FirstLatter">5</p>
                            <p>Set M- PIN</p>
                        </div>
                    </div>
                    <div className="col-12 upi_Steps" style={{height:'12rem'}}>
                        <div className="steps_circel">
                            <span className="steps_dot"><i className="fa fa-exchange"></i></span>
                            <span className="steps_dot2"></span>
                            <span className="steps_line" style={{height:'8rem'}}></span>
                            <span className="first_steps_dot" style={{marginLeft:'12px'}}></span>
                        </div>
                        <div className="upi_text">
                            <p className="FirstLatter">6</p>
                            <p style={{marginBottom:'0px'}}>Start transacting using UPI</p>
                        </div>
                    </div>
                </div>
                <div className="row upi_footer">
                    {/* <img src="assets/images/upi-footer.png" style={{width:'100%'}} /> */}
                    {/* <div className="col">
                        <p><i className="fa fa-chart-bar"></i></p>
                        <p>Market</p>
                    </div>
                    <div className="col">

                    </div>
                    <div className="col"></div>
                    <div className="col"></div>
                    <div className="col"></div> */}
                </div>
            </div>
        )
    }
}

export default CreateUpiIdMobile;