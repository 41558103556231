import React from 'react';
import NetPositions from '../../../components/netpositions/NetPositions'
import '../../../css/theme-dark/margin.css'

class NetPositionsPage extends React.Component {

    render() {
        return (
            <div className="fillheight">
                <NetPositions />
            </div>
        );
    }

}

export default NetPositionsPage;