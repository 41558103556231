import React,{Component} from 'react'
import { connect } from 'react-redux';

class SummaryClient extends React.Component{
  
    componentDidMount(){
        if (this.summClientRef)
        this.summClientRef.focus();
    }

    loadingDataFromServer(e){
        this.props.onClickEventX(e.target.value);
    }
    onInputChangeClientId(e){
        this.props.onInputChangeX(e.target.value);
    }
    onKeyPressX(name, e) {
        if (e.key === 'Enter') {
            if (name === 'clientId')
                this.summClientIdRef.focus();
                this.props.onClickEventX();
        }
    }

render(){
    return(
        <div>
            <div style={{display:"block",backgroundColor: "#d3d3d369",height: "52px",width:"250px"}}>
            <input type="text" value={this.props.clientId} placeholder="Enter Client Id" name="clientId" style={{marginLeft:"10px",marginTop:"10px",fontSize:"",float:"left",textIndent : "16px",width: "180px",lineHeight: "1.5",border: "1px solid #ced4da",borderRadius: ".25rem",height: "calc(1.5em + .75rem + 2px)"}} className="black-text"
                        onChange={e => this.props.onInputChangeX(e)} 
                        onKeyPress={e => this.onKeyPressX("clientId", e)}  ref={(id) => { this.summClientIdRef = id}} //maxLength="12"
                        onPaste={e=>e.preventDefault()}></input>
                        <button type="button" className=""  onClick={this.props.onClickEventX} style={{marginTop:"10px",padding:"0.375rem -0.25rem",fontSize:"1rem",lineHeight:"1.5",borderRadius:".25rem",color: "#fff", backgroundColor: "#17a2b8",borderColor: "#17a2b8",marginLeft:"3px"}}>OK</button>
            </div>
    <div className="" role="dialog" >
    </div>
    </div>
    );
}
}
const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
       // showErrorDialog: (errordata) => showErrorDialog(dispatch, errordata),
        //commonDialog: (payload) => commonDialog(dispatch, payload),
        //toggleDialog: (payload) => toggleDialog(payload, dispatch)
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SummaryClient); ;