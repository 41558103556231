import React from "react"

class DeleteListDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columnscount: 6,
            allitems: [],
            parseditems: [],
            selecteditems: [],
            watchlist: '',            
            error: '',            
        }
        this.handleNegativeButton = this.handleNegativeButton.bind(this);
        this.handlePossitiveButton = this.handlePossitiveButton.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
       
        this.isInSelected = this.isInSelected.bind(this);
        this.getSelectedItems = this.getSelectedItems.bind(this);
        this.getEditingWatchlist = this.getEditingWatchlist.bind(this);
       
    }

    setItems(list, callback) {
       
        this.setState({ allitems: list, parseditems: this.parseInput(list) })
      
    }

    handleNegativeButton() {
        if (this.props.onNegativeButtonClick)
            this.props.onNegativeButtonClick();
    }

    handlePossitiveButton() {
        if (this.props.onPossitiveButtonClick)
            this.props.onPossitiveButtonClick();
    }

    handleCheckChange(e) {
        var itemid = (e.target.getAttribute("data-tag"));
        var existing = this.state.selecteditems.filter(function (i) {
            return i.id === itemid;
        });

        if (existing && existing.length > 0) {
            if (!e.target.checked) {
                // exising in selected and user unchecked
                let items = this.state.selecteditems;
                let indx = items.indexOf(existing[0]);
                items.splice(indx, 1);
                this.setState({ selecteditems: items });
            }
        }
        else {
            if (e.target.checked) {
                // non-exising in selected and user checked               

                let neww = this.state.allitems.filter(i => i.id === itemid)[0];
                let items = this.state.selecteditems;
                items.push(neww);
                this.setState({ selecteditems: items });

            }
        }
    }

    isInSelected(item) {
        var existing = this.state.selecteditems.filter(function (i) {
            return i.id === item.id;
        });
        return (existing.length > 0)
    }

    getEditingWatchlist() {
        return this.state.watchlist;
    }

    getSelectedItems() {
        return this.state.selecteditems;
    }

    unselectItems() {
        this.setState({ selecteditems: [] });
    }

    parseInput(data) {
        var matrix = [], i, k;

        for (i = 0, k = -1; i < data.length; i++) {
            if (i % this.state.columnscount === 0) {
                k++;
                matrix[k] = [];
            }
            matrix[k].push(data[i]);
        }
        return matrix;
    }

    render() {

        var checklist = (
            <div className="container-fluid fillwidth">
                {this.state.parseditems.map(function (row, key) {
                    return (
                        <div key={key} className="row" >
                            {row.map(function (rw, ky) {
                                return (
                                    <div key={ky} className="col-2">
                                        <label className="custom-check edit-dialog-check">
                                            <input className="custom-checkbox" type="checkbox" onChange={this.handleCheckChange} checked={this.isInSelected(rw)}
                                                data-tag={rw.id} />
                                            <span className="custom-check-label">{rw.name}</span>
                                        </label>
                                    </div>
                                );
                            }, this)}
                        </div>
                    );
                }, this)
                }
            </div>
        );

        var mbody = (
            <div className="fillwidth">
                <ul>
                    <li>
                        <p> Custom </p>
                        {checklist}
                    </li>
                </ul>
            </div>
        );

        var mhead = ("");
        var mfooternote = ("");

        return (
            <div className={"modal fade" + (this.props.showDialog ? "in dialog-show disabledbackground" : "  dialog-hide")} id={this.props.id} role="dialog" >
                <div className={"modal-dialog big " + (this.props.type === "deletewatchlist" && "delete")}>
                    <div className={"modal-header " + (this.props.type === "deletewatchlist" && "delete")}>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 contentpanel"> {this.props.title} </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 contentpanel"> {mhead} </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 contentpanel">
                            {this.props.type === "editwatchlist" ?
                                <div className="col-10 col-lg-10 col-md-10 text-right">
                                    NO. OF SCRIPS: {this.state.symbolscount}
                                </div>
                                : ""
                            }
                            <img src='assets/svg/close.svg' className="closebtn" data-toggle="tooltip" title="Close" onClick={this.handleNegativeButton} alt="close"/>
                        </div>
                    </div>
                    </div>
                    <div className={"modal-body " + (this.props.type === "editwatchlist" ? "edit" : "delete")}>
                        {mbody}
                    </div>
                    <div className="modal-footer">
                        <div className="col-6 col-lg-6 col-md-6">
                            {mfooternote}
                        </div>
                        <div className="col-3 col-lg-3 col-md-3">
                            <button type="button" className="footerbtn" onClick={this.handleNegativeButton} >{this.props.negativebuttontext}</button>
                        </div>
                        <div className="col-3 col-lg-3 col-md-3">
                            <button type="button" className="footerbtn" onClick={this.handlePossitiveButton} >{this.props.possitivebuttontext}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DeleteListDialog;