import React from "react"
//import { isValidWatchlistName } from '../../validators/watchlistValidations'
import { BasketExecutionrequest } from '../../actions/products/productrequest'


class ExecuteBasketPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            data:[1,2,3],
        }
        this.handlePossitiveButton = this.handlePossitiveButton.bind(this);
        this.handleNegativeButton = this.handleNegativeButton.bind(this);
    }

    setError(err) {
        this.setState({ error: err });
    }



    handleError(err) {
        if (err) {
            this.setState({ error: err });
        }
        else {
            // success case
            this.setState({ inputtext: '', error: '' });
        }
    }


    handleNegativeButton() {
        if (this.props.onNegativeButtonClick)
            this.props.onNegativeButtonClick();
    }

    handlePossitiveButton() {
        if (this.props.onPossitiveButtonClick)
            this.props.onPossitiveButtonClick();
            this.ExecuteBasket();
    }
    ExecuteBasket(){
        var requestData = {
            request: {
                data: {
                    id: this.props.SelectedId
                }
            }
        };
        BasketExecutionrequest(requestData, this.parseExecuteRsponse.bind(this), this.parseExecuteError.bind(this))
    }
    
    parseExecuteRsponse(responseData){
        this.setState({
            message: responseData.response.data.data
        },() => {
            // this.state.TrueFalse == true ? this.state.message = 'Your basket is execute sucessfully.' : ''
        })

    }
    parseExecuteError(){
        
    }

    render() {

        var mbody;
         mbody = (
            <div>
                <div className="row contentpanel execute-basket-header">
                    <div className="col-2 contentpanel">
                        SCRIP
                    </div>
                    <div className="col-2 contentpanel">
                        EXCHANGE
                    </div>
                     <div className="col-2 contentpanel">
                        QUANTITY
                    </div>
                    <div className="col-1 contentpanel"> 
                        PRICE
                    </div>
                    <div className="col-3  contentpanel">
                        TRANSACTION TYPE
                    </div> 
                    <div className="col-2 contentpanel">
                        ORDER TYPE
                    </div>                   
                </div>
                <div className="execute-basket-hdivider"/>
                {this.props.ExecuteData.map(function(row,key){
                    return(
                        <div className="row contentpanel create-basket-row">
                        <div className="col-2 contentpanel">
                            {row.DISP_SYM}
                        </div>
                        <div className="col-2 contentpanel">
                            {row.EXCH_ID}
                        </div>
                        <div className="col-2 contentpanel">
                            {row.QTY}
                        </div>
                        <div className="col-1 contentpanel">
                            {row.LIMIT_PRICE}
                        </div>
                        <div className="col-3 text-center contentpanel">
                            <span className={row.BUY_SELL_IND == 'BUY' ? "positive-change" : "negative-change"}>
                                <b>{row.BUY_SELL_IND}</b>
                            </span>
                        </div>
                        <div className="col-2 contentpanel" style={{textTransform:"uppercase"}}>
                            {row.ORD_TYPE}
                        </div>
                    </div>    
                    );
                })}          
                <div className="error-holder"> {this.state.error} </div>
            </div>
        );


        return (
            <div className={"modal fade" + (this.props.showDialog ? "in dialog-show" : "dialog-hide")} id={this.props.id} role="dialog">
                <div className="modal-dialog small-medium" style={{ top: '19%', height: 'auto', minHeight: 0 }}>
                    <div className="product-modal-header">
                        {this.props.title} <b>{this.props.selectedOrderBasket}</b>
                    </div>
                    <div className="modal-body" style={{ height: '305px'}}>
                        {mbody}                        
                    </div>
                    <div className="modal-footer">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <button type="button" className="footerbtn" data-dismiss="modal" onClick={this.handleNegativeButton}>{this.props.negativebuttontext}</button>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <button type="button" className="execute-basket-btn" data-dismiss="modal" onClick={this.handlePossitiveButton}>{this.props.possitivebuttontext}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ExecuteBasketPopup;