import React from "react";
import UI from "./UI";
import { CIQ } from "chartiq/js/chartiq";
import "chartiq/js/standard";
import "chartiq/js/advanced";
import RangeSelector from "./RangeSelector";
import ShareButton from "./ShareButton";
import Legend from "./Legend";
import DrawingBaseView from "../containers/DrawingBaseView";
import ChartSearchBar from '../components/UI/ChartSearchBar'
import { comaSeparator } from '../../../../common/utils'
import { blockProgressBar } from '../../../../controls/progress'
var dateFormat = require('dateformat');

class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.prependHeadsUpHR = this.prependHeadsUpHR.bind(this);
  }

  componentDidMount() {
    let container_name = this.props.id ? this.props.id : "chartContainer";
    var rootId = document.getElementById(this.props.id ? this.props.id : "chartContainer");
    if (rootId)
      this.props.setChartContainer(rootId,container_name);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.ciq !== nextProps.ciq) {
      CIQ.ChartEngine.prototype.prepend("headsUpHR", this.prependHeadsUpHR);
    }
  }

  prependHeadsUpHR() {
    var id = this.props.id ? "#" + this.props.id + "-hu" : "#chartContainer-hu"
    var tick = this.props.ciq.barFromPixel(this.props.ciq.cx);
    var prices = this.props.ciq.chart.xaxis[tick];
    window.$(id + " #huopen").html("");
    window.$(id + " #huclose").html("");
    window.$(id + " #huhigh").html("");
    window.$(id + " #hulow").html("");
    window.$(id + " #huvol").html("");
    if (prices != null) {
      if (prices.data) {
        window.$(id + " #huopen").html(this.props.ciq.formatPrice(prices.data.Open));
        window.$(id + " #huclose").html(this.props.ciq.formatPrice(prices.data.Close));
        window.$(id + " #huhigh").html(this.props.ciq.formatPrice(prices.data.High));
        window.$(id + " #hulow").html(this.props.ciq.formatPrice(prices.data.Low));
        window.$(id + " #huvol").html(comaSeparator(prices.data.Volume + ""));

        //Floating label format date fix...whenever chartiq library update please comment line in headsUpHR() in library
        //refer from current chartiq.js file

        var floatDate = this.props.ciq.controls.floatDate;
        if (floatDate && !CIQ.ChartEngine.hideDates()) {
          var interval = this.props.ciq.layout.interval;
          var isDaily = CIQ.ChartEngine.isDailyInterval(interval);
          if (prices.DT) {
            setFloatDate(floatDate, dateFormat(new Date(prices.DT), isDaily ? "dd/mm/yy" : "dd/mm/yy HH:MM"));
          } else if (prices && prices.index) {
            setFloatDate(floatDate, prices.index);
          } else {
            setFloatDate(floatDate, "");		// there is no date to display
          }
        }
      }
    }
    function setFloatDate(floatDate, val) {
      CIQ.efficientDOMUpdate(floatDate, "innerHTML", val);
    }

  }

  componentWillUnmount() {
    CIQ.ChartEngine.prototype.remove("headsUpHR");
  }
  
  render() {
    return (
      <div className="chart-content-parent">
        <UI {...this.props} />
        <ChartSearchBar {...this.props} />
        <div className={"ciq-chart-area chart-area chart-search-padding"}>
          <DrawingBaseView
            ref={drwaingRef => (this.drwaingRef = drwaingRef)}
            {...this.props}
          />
          <div
            ref={chartContainer => {
              this.chartContainer = chartContainer;
            }}
            id={this.props.id ? this.props.id : "chartContainer"}
            className="chartContainer"
          >
            {/* <div className={this.props.isLoadingPeriodicity ? "loader" : ""} /> */}
            {this.props.isLoadingPeriodicity && blockProgressBar()}
            <Legend {...this.props} />
          </div>
        </div>
        <div className="ciq-footer">
          <ShareButton {...this.props} />
          <RangeSelector {...this.props} />
        </div>
      </div>
    );
  }
}

export default Chart;
