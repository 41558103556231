import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';
import flow from 'lodash/flow';
import { instrumentType } from '../../common/constants'
import { isEquityPermitted, isFnOPermitted, isFnOSpreadPermitted, isCurrencyPermitted, isCommodityPermitted } from '../../common/permissions';

class WatchlistRowItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isdragging: false,
            prevltp: 0,
            ltpclass: '',
            prevltpclass: ''
        }
        
    }

    disableButton = ()=>{
        switch(this.props.row.sym.instr.toLowerCase())
        {
            case instrumentType.STOCK.toLowerCase() : 
            case instrumentType.STOCK_OPTIONS.toLowerCase() : 
            case instrumentType.STOCK_FUTURE_SPREAD.toLowerCase() :
            case instrumentType.STOCK_FUTURE.toLowerCase() :  return !isEquityPermitted();
            break;
            case instrumentType.INDEX.toLowerCase()         :
            case instrumentType.INDEX_FUTURE.toLowerCase() :
            case instrumentType.INDEX_OPTIONS.toLowerCase() : return !isFnOPermitted();
            break;
            case instrumentType.CURRENCY_FUTURE.toLowerCase() : 
            case instrumentType.CURRENCY_OPTIONS.toLowerCase() : 
            case instrumentType.CURRENCY_FUTURE_SPREAD.toLowerCase():
            case instrumentType.CURRENCY.toLowerCase() : return !isCurrencyPermitted();
            break;
            case instrumentType.COMMODITY_FUTURE.toLowerCase() : 
            case instrumentType.COMMODITY_OPTIONS.toLowerCase() : 
            case instrumentType.COMMODITY.toLowerCase() : return !isCommodityPermitted();
            break;
            case instrumentType.INDEX_FUTURE_SPREAD.toLowerCase() :  return (!isFnOPermitted() && !isFnOSpreadPermitted());
            break;
            default: return true;

        }
    }
    componentWillReceiveProps(newProps) {

        // console.log("pkc " + this.state.prevltp);
        // console.log("pkck " + newProps.row.ltp);

        var diff = (newProps.row.ltp - this.state.prevltp);
        // console.log("pkckd " + diff);
        if (diff > 0)
            this.setState({ prevltp: newProps.row.ltp, ltpclass: 'positive-change-bg' });
        else if (diff < 0)
            this.setState({ prevltp: newProps.row.ltp, ltpclass: 'negative-change-bg' });
        else
            this.setState({ prevltp: newProps.row.ltp, });

        return '';
    }

    emptyClick() {

    }

    render() {
        const { row, isDragging, connectDragSource, connectDropTarget } = this.props;

        var content = (
            <div className={this.props.selecttodelete ? "contentpanel watchlist-row watchlist_row" : "contentpanel watchlist-row watchlist_row draggable"} >

                <div className="row contentpanel">
                    <div className={"col-lg-" + (this.props.isExpanded ? "8" : "7") + " contentpanel"}>
                    <div className="row">
                        <div className="col-7 col-lg-7 col-md-7 contentpanel">
                        <div class="row">
                            <div className="col-8 col-lg-8 col-md-8 contentpanel wl-txt ">
                                <div style={{ marginRight: "5px", textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                    {this.props.selecttodelete  ?
                                        <label className="custom-check watch-delete-check"  >
                                            <input className="custom-checkbox" type="checkbox" data-tag={row.displaySym} onChange={this.props.symbolSelectionChanged} />
                                        </label>
                                        :
                                        <img src='assets/svg/drag_drop.svg' className="dragicon" alt="drag" />
                                    }
                                    <div className="symbol-link"
                                        onClick={() => row.sym.instr === instrumentType.COMMODITY || row.sym.instr === instrumentType.CURRENCY?
                                            this.emptyClick.bind(this) : this.props.onSymbolClick(row)}
                                        style={{ paddingLeft: '15px', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                                        data-toggle="tooltip" title={row.displaySym}  >
                                        {row.displaySym}
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 col-lg-4 col-md-4 contentpanel text-center watchlist-row-exc wl-txt ">
                                <span>
                                    <img className={"watchlist-research " + ((!row.isResearchAvail || row.isResearchAvail === "0") ? "hide" : "")} src="assets/svg/research.svg" alt="research" />
                                    {row.sym.exc}
                                </span>
                            </div>
                            </div>
                        </div>
                        <div className="col-5 col-md-5 col-lg-5 contentpanel">
                            <div className="row">
                            <div className="col-4 col-lg-4 col-md-4 text-center topgainerslosers-btn-holder">
                                {/* <img src='assets/svg/chart_icon.svg' className="watch_chartimg" data-toggle="tooltip" title="Charts" onClick={() => this.props.gotoChart(row.sym)} /> */}
                                <button type="button" className="watch_chartimg3" style={{ height: '21px' }}
                                    data-toggle="tooltip" title="Charts"
                                    disabled={row.sym.instr === instrumentType.COMMODITY || row.sym.instr === instrumentType.CURRENCY}
                                    onClick={() => { this.props.gotoChart(row.sym) }}>
                                    <img src='assets/svg/chart_icon_new2.svg' style={{ width: '100%', height: '100%' }} alt="chart" />
                                </button>

                            </div>
                            <div className="col-4 col-lg-4 col-md-4 text-center topgainerslosers-btn-holder">
                                <button type="button" className="wl-btn buy" data-tag={row.displaySym}
                                    disabled={this.disableButton()}
                                    title={this.disableButton()?"You have not subscribed to trade in this segment.\nPlease contact your RM or customer service.":""}
                                    onClick={() => this.props.gotoBuy(row)}>BUY</button>                                    
                            </div>
                            <div className="col-4 col-lg-4 col-md-4 text-center topgainerslosers-btn-holder">
                                <button type="button" className="wl-btn sell" data-tag={row.displaySym}
                                    disabled={this.disableButton()}
                                    title={this.disableButton()?"You have not subscribed to trade in this segment.\nPlease contact your RM or customer service.":""}
                                    onClick={() => this.props.gotoSell(row)}>SELL</button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className={"col-lg-" + (this.props.isExpanded ? "4" : "5") + " contentpanel"}>
                    <div className="row">
                        {row.ltp ?
                            <div className={"col-lg-4 col-lg-4 col-md-4  text-center " + this.state.ltpclass + " no-change-bg wl-ltp"}>
                                <span>{row.ltp}</span>
                            </div>
                            : <div className="col-lg-4 col-lg-4 col-md-4"></div>}
                        <div className={row.chng && row.chng.indexOf('-') ? "col-4 col-lg-4 col-md-4  text-center positive-change wl-txt " : "col-4 col-md-4 col-lg-4 text-center negative-change wl-txt "}>
                            <span>{row.chng}</span>
                        </div>
                        <div className={row.chng && row.chngPer.indexOf('-') ? "col-4 col-lg-4 col-md-4  text-center positive-change wl-txt " : "col-4 col-md-4 col-lg-4 text-center negative-change wl-txt "}>
                            <span>{row.chngPer}</span>
                        </div>
                        </div>
                    </div>
                </div>
                {
                    isDragging ?
                        <div style={{ position: "absolute", top: "0", bottom: "0", right: "0", left: "0", backgroundColor: "#131314", borderTop: '1px dashed #393944', borderBottom: '1px dashed #393944' }}>
                        </div>
                        :
                        ''
                }

            </div>
        );

        if (this.props.selecttodelete) {


            return content;
        }
        else {
            return connectDragSource(connectDropTarget(content));
        }

    }
}
const cardSource = {

    beginDrag(props) {

        if (props.onDragStart)
            props.onDragStart(props.index);

        return {
            index: props.index,
            listId: props.listId,
            row: props.row
        };
    },

    endDrag(props, monitor) {
        //const item = monitor.getItem();
        //const dropResult = monitor.getDropResult();

        if (props.onDragEnd)
            props.onDragEnd();
    }
};

const cardTarget = {

    hover(props, monitor, component) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;
        const sourceListId = monitor.getItem().listId;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
        }

        // Time to actually perform the action
        if (props.listId === sourceListId) {
            props.moveCard(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            monitor.getItem().index = hoverIndex;
        }
    }
};

export default (flow(
    DropTarget("WatchlistRowItem", cardTarget, connect => ({
        connectDropTarget: connect.dropTarget()
    })),
    DragSource("WatchlistRowItem", cardSource, (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    }))
)(WatchlistRowItem));