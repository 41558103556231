import React, { Component } from 'react';
import {PortfolioRowitems} from './PortfolioRowitems';
import GroupBySymbol from './GroupBySymbol';
import GroupBySector from './GroupBySector';
import { numericSort} from '../../common/utils';
import PropTypes from 'prop-types';


export class PortfolioHeader extends React.Component 
{

    constructor(props) {
        super(props);
        this.state = {
            sortAsc: {
                sym_sort: false, qty_sort: false, avgprice_sort: false,
                purvalue_sort: false, currmktprice_sort: false, currmktvalue_sort: false, todayplperc_sort: false,
                todaypl_sort: false, notionalpl_sort: false, notionalplperc_sort: false, realised_sort:false
            }
        }
        this.onSortClicked = this.onSortClicked.bind(this);   
    }

    onSortClicked(e) {
        var sortTemp = this.state.sortAsc;
        var sortcolumn="";
        var sortDataType="";
        switch (e.currentTarget.id) {
            case "sym_sort":
                sortTemp.sym_sort = !sortTemp.sym_sort;
                sortDataType="STRING";
                sortcolumn="symbol";
                break;
            case "qty_sort":
                sortTemp.qty_sort = !sortTemp.qty_sort;
                sortDataType="NUMBER";
                sortcolumn="qty";
                break;
            case "avgprice_sort":
                sortTemp.avgprice_sort = !sortTemp.avgprice_sort;
                sortDataType="NUMBER";
                sortcolumn="avgBuyPrice";
                break;
            case "purvalue_sort":
                sortTemp.purvalue_sort = !sortTemp.purvalue_sort;
                sortDataType="NUMBER";
                sortcolumn="purchaseValue";
                break;
            case "ltp_sort":
                sortTemp.ltp_sort = !sortTemp.ltp_sort;
                sortDataType="NUMBER";
                sortcolumn="ltp";
                break;
            case "currmktvalue_sort":
                sortTemp.currmktvalue_sort = !sortTemp.currmktvalue_sort;
                sortDataType="NUMBER";
                sortcolumn="currentValue";
                break;
            case "todaypl_sort":
                sortTemp.todaypl_sort = !sortTemp.todaypl_sort;
                sortDataType="NUMBER";
                sortcolumn="todayGainLoss";
                break;
            case "todayplperc_sort":
                sortTemp.todayplperc_sort = !sortTemp.todayplperc_sort;
                sortDataType="NUMBER";
                sortcolumn="todayGainLossPerc";
                break;
            case "notionalpl_sort":
                sortTemp.notionalpl_sort = !sortTemp.notionalpl_sort;
                sortDataType="NUMBER";
                sortcolumn="plValue";
                break;
            case "notionalplperc_sort":
                sortTemp.notionalplperc_sort = !sortTemp.notionalplperc_sort;
                sortDataType="NUMBER";
                sortcolumn="plPerc";
                break;
            case "realised_sort":
                sortTemp.realised_sort = !sortTemp.realised_sort;
                sortDataType="NUMBER";
                sortcolumn="realised";
                break;
            default: break;

        }
        this.props.callback(sortcolumn, sortTemp[e.currentTarget.id]? "ASC" : "DSC", sortDataType);
    }
    render() 
    {
        
    return (
        <div >
            <div className="tab-content expanded">
                <div className="row contentpanel portfolio-tracker ">
                    <div className="col-2 text-left portfolio-header-title cursor-pointer" id="sym_sort" onClick={(e) => this.onSortClicked(e)}>
                        <img src='assets/svg/sort.svg' className="fa-sort" alt="sort"></img>
                        {this.props.headingName}
                    </div>
                    <div className="col text-right portfolio-header-title cursor-pointer" id="qty_sort" onClick={(e) => this.onSortClicked(e)}>
                        <img src='assets/svg/sort.svg' className="fa-sort" alt="sort"></img>
                        TOTAL QTY
                    </div>
                    <div className="col text-right portfolio-header-title cursor-pointer" id="avgprice_sort" onClick={(e) => this.onSortClicked(e)}>
                        <img src='assets/svg/sort.svg' className="fa-sort" alt="sort"></img>
                        AVG PRICE
                    </div>
                    <div className="col text-right portfolio-header-title cursor-pointer" id="purvalue_sort" onClick={(e) => this.onSortClicked(e)}>
                        <img src='assets/svg/sort.svg' className="fa-sort" alt="sort"></img>
                        PURCHASE VALUE
                    </div>
                    <div className="col text-right portfolio-header-title cursor-pointer" id="ltp_sort" onClick={(e) => this.onSortClicked(e)}>
                        <img src='assets/svg/sort.svg' className="fa-sort" alt="sort"></img>
                        CURRENT MKT.PRICE
                    </div>
                    <div className="col text-right portfolio-header-title cursor-pointer" id="currmktvalue_sort" onClick={(e) => this.onSortClicked(e)}>
                        <img src='assets/svg/sort.svg' className="fa-sort" alt="sort"></img>
                        CURRENT MKT.VALUE
                    </div>
                    <div className="col text-right portfolio-header-title cursor-pointer" id="todaypl_sort" onClick={(e) => this.onSortClicked(e)}>
                    <img src='assets/svg/sort.svg' className="fa-sort" alt="sort"></img>
                        TODAYS GAIN/LOSS
                    </div>
                    <div className="col text-right portfolio-header-title cursor-pointer" id="todayplperc_sort" onClick={(e) => this.onSortClicked(e)}>
                        <img src='assets/svg/sort.svg' className="fa-sort" alt="sort"></img>
                        TODAYS GAIN/LOSS(%)
                    </div>
                    <div className="col text-right portfolio-header-title cursor-pointer" id="notionalpl_sort" onClick={(e) => this.onSortClicked(e)}>
                        <img src='assets/svg/sort.svg' className="fa-sort" alt="sort"></img>
                        NOTIONAL PROFIT/LOSS
                    </div>
                    <div className="col text-right portfolio-header-title cursor-pointer" id="notionalplperc_sort" onClick={(e) => this.onSortClicked(e)}>
                        <img src='assets/svg/sort.svg' className="fa-sort" alt="sort"></img>
                        NOTIONAL PROFIT/LOSS(%)
                    </div>
                    <div className="col text-right portfolio-header-title cursor-pointer" id="realised_sort" onClick={(e) => this.onSortClicked(e)}>
                        <img src='assets/svg/sort.svg' className="fa-sort" alt="sort"></img>
                        REALISED PROFIT/LOSS
                    </div>
                    <div className="col text-right portfolio-header-title">
                        
                    </div>
                    
                </div>
            </div>
        </div>
            )
    
        }
}

PortfolioHeader.protoTypes = {
    callback : PropTypes.func,
    }
                   
export default PortfolioHeader;