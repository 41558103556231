import React, { Component } from 'react';

class Dialog extends Component {
    constructor(props) {
        super(props);
    }

    negativeClick() {
        this.props.negativeClick();
    }

    positiveClick() {
        this.props.positiveClick();
    }

    render() {
        return (

            <div className="modal fade dialog-show in" role="dialog" style={{ display: "block" }}>
                <div className="modal-dialog appdialogmodal">
                    <div className="modal-content">
                        <div className="modal-header"> 
                        <h4 className="modal-title">{this.props.postiveIcon?<i class="fa fa-check-circle-o" style={{padding: '5px', color: '#1EBF8A', fontSize: '40px'}} aria-hidden="true"> </i> :''}{this.props.header}</h4>
                        </div>
                        <div className="modal-body fitHeight" style={{whiteSpace:'pre-line'}}>
                            <div>{this.props.message ? this.props.message : ""}</div>
                        </div>
                        <div className="modal-footer">
                            {this.props.positiveButtonName ? <button className="btn btn-default commonbtn" onClick={this.positiveClick.bind(this)}>{this.props.positiveButtonName}</button>:null}
                            {this.props.negativeButtonName ?<button className="btn btn-default commonbtn" onClick={this.negativeClick.bind(this)}>{this.props.negativeButtonName}</button>:null}
                        </div>
                    </div>

                </div>
            </div>
        );
    };
};
export default Dialog;