
import React from 'react';
import PropTypes from 'prop-types';

class TradeHistory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tradeData:''
        }
    }

componentWillReceiveProps(first){
    this.state.tradeData=first.traderow
}

  render() {
    // Render nothing if the "show" prop is false
    if(!this.props.show) {
      return null;
    }


    return (
        <div className="modal fade dialog-show in trade-history trade-overlay" id="myModal" role="dialog">
        <div className={"modal-dialog appdialogmodal tradehistory-data"}>
            <div className="modal-content">
                <div className="modal-header history-close-button">
                    <button type="button" className="close" onClick={this.props.onClose}><img src="assets/svg/close.svg" alt="close" /></button>
                    <h4 className="modal-title">Trade History</h4>
                    <h5>{this.props.symbol}</h5>
                </div>
                <div className="modal-body">
                <div id="tradehistory" className="tab-pane fade in active fillheight">
                    <div className="fillheight portfolio-tracker">
                   
                        <div className="row contentpanel portfolio-header-title">
                            <div className="col-lg-12 text-right last-traded-price">
                               <p>Last Traded Price : <span style={{color: Math.sign(this.props.ltp) === -1 ? "#E5283F" : "#1EBF8A"}}>{this.props.ltp}</span></p>
                            </div>
                            <div className="col-3 text-center valign">
                                DATE & TIME
                            </div>
                            <div className="col text-right valign">
                                TYPE
                            </div>
                            <div className="col text-right valign">
                                QUANTITY
                            </div>
                            <div className="col text-right valign">
                                TRADE PRICE
                            </div>
                            <div className="col text-right valign">
                                CHARGES
                            </div>
                            <div className="col text-right valign">
                                TRADE AMOUNT
                            </div>
                        </div>
                        {this.state.tradeData.map(function (data, index){
                        return(<div className="row table-data contentpanel table-data">
                            <div className="col-3 text-center valign" key={index}>
                               {data.dateoftransaction}
                            </div>
                            <div className="col text-right valign">
                                {data.TransactonType}
                            </div>
                            <div className="col text-right valign">
                                {data.quantity}
                            </div>
                            <div className="col text-right valign">
                                {data.price}
                            </div>
                            <div className="col text-right valign">
                                {data.brokercommission}
                            </div>
                            <div className="col text-right valign">
                                {data.value}
                            </div>
                        </div>)
                        })
                    }
                        <div className="row contentpanel last-row"></div>
                        
                    </div>
                    
                </div>
                </div>
                <div className="contentpanel">
                    <div className="row data-total1 data-total">
                        <div className="col-3 text-left valign">
                            TOTAL BUY<br/>
                            <span>{this.props.totalBuyQty}</span>
                        </div>
                        <div className="col text-right valign">
                            TOTAL SELL<br/> 
                            <span>{this.props.totalSellQty}</span>
                        </div>
                        <div className="col text-right valign">
                            NET BUY/SELL<br/> 
                            <span>{this.props.netBuySell}</span>
                        </div>
                        <div className="col valign text-right">
                            BOOKED P/L<br/>
                            <span style={{color: Math.sign(this.props.bookedPL) === -1 ? "#E5283F" : "#1EBF8A"}}>{this.props.bookedPL}</span>
                        </div>
                        <div className="col valign ">
                            NOTIONAL P/L<br/>
                            <span  style={{color: Math.sign(this.props.notionalPL) === -1 ? "#E5283F" : "#1EBF8A"}}>{this.props.notionalPL}</span>
                        </div>
                    </div>
                    <div className="row bottom-note-trade">
                    * Buy Rate includes Actual Rate + (Brokerage + GST/Service Tax + Transaction Charges)<br></br>
                    * Sell Rate includes Actual Rate - (Brokerage + GST/Service Tax + Transaction Charges)
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
  }
}

TradeHistory.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node
};

export default TradeHistory;
