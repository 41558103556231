import React from 'react';


class Loading extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            allimages: [],
            currentimage: ''
        }
    }

    render() {
        return (
            <div className="config-error-holder" >
                {this.props.configerror ?
                    <div className="config-error-holder" >
                        <div style={{ textAlign: 'center' }} >
                            <div className="config-err-title">
                                Error occured
                            </div>
                            <div className="config-err-ref " >
                                Please refresh page after some time
                            </div>
                        </div>
                    </div>
                    :
                    (this.props.inProgress ?
                        <div className="config-progress-holder" >
                            <div style={{ textAlign: 'center' }} >
                                <img src="assets/images/loading.gif" style={{ width: '50%' }} alt="loading"/>
                            </div>
                        </div>
                        : '')
                }

            </div>
        )
    }

}

export default Loading;