import React, { Component } from 'react';
import { Route } from 'react-router-dom';
// import LoginPage from '../pages/prelogin/login'
// import PreLoginPage from '../pages/prelogin/basecontainer'
import { connect } from "react-redux";


class Unauthorized extends Component {
    render() {
       
        const { component: Component, pending, logged, ...rest } = this.props
        return (
            // FOR_PRELOGIN: comment below div
            // <div className='fillheight'>
            //     <Switch>
            //         <Route path="/login" component={LoginPage} />
            //         <Redirect to="/login" />
            //     </Switch>
            // </div>

            //FOR_PRELOGIN: uncomment below route
            <Route {...rest} render={props => {

                return <Component {...props} />

            }} />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loginStatus: state.loginStatus.status,
        theme: state.common.theme,
    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Unauthorized);