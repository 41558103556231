import { StoreKeys } from '../common/constants';

const intialState = {
    scripInfo:{},
    depthDetails: undefined,
    activeWidget:'ALL'
}

const scripdetails = (state = intialState, action) => {
    if (action.type === StoreKeys.SCRIP_ACTIVE_WIDGET) {
        return Object.assign({}, state, { activeWidget: action.payload });
    } else if (action.type === StoreKeys.SCRIP_INFO) {
        var newScrip = {...action.payload}
        return Object.assign({}, state, { scripInfo: newScrip });
    } else if (action.type === StoreKeys.DEPTH_DETAILS) {
        var newDetails = {...action.payload}
        console.log(newDetails);
        return Object.assign({}, state, { depthDetails: newDetails });
    } else {
        return state;
    }
};

export default scripdetails;