import React,{Component} from 'react'
import { connect } from 'react-redux';

class SummaryPassword extends React.Component{
  
    componentDidMount(){
        if (this.summPasswordRef)
        this.summPasswordRef.focus();
    }

    loadingDataFromServer(e){
        this.props.onClickEvent(e.target.value);
    }
    onInputChangeParent(e){
        this.props.onInputChange(e.target.value);
    }
    onKeyPress(name, e) {
        
        if (e.key === 'Enter') {
            if (name === 'password')
                this.summPasswordRef.focus();
                this.props.onClickEvent();
        }
    }

render(){
    return(
    <div className="modal fade in disabledbackground dialog-show" role="dialog" >
        <div className="modal-dialog settings-cp-dialog" style={{ background: "#fff", top: '14%' ,width:'400px'}} >
            <div className="modal-header settings-header-cp">
                <div className='icon_style'>
                    {/* <span className="fa fa-briefcase login_icon "></span> */}
                    <img className="login_icon" src="assets/images/password2.png" />
                    <input type="password" value={this.props.password} className="form-control login_formcontrol black-text" 
                    placeholder="Enter your password" name="password"
                    onChange={e => this.props.onInputChange(e)} 
                    onKeyPress={e => this.onKeyPress("password", e)}  ref={(id) => { this.summPasswordRef = id; }} //maxLength="12"
                    onPaste={e=>e.preventDefault()}></input>
                </div>
            <div> <button type="button" className="form-control login_btn"  onClick={this.props.onClickEvent}>OK</button></div>                      
            </div>
        </div>
    </div>
    );
}
}
const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
       // showErrorDialog: (errordata) => showErrorDialog(dispatch, errordata),
        //commonDialog: (payload) => commonDialog(dispatch, payload),
        //toggleDialog: (payload) => toggleDialog(payload, dispatch)
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SummaryPassword); ;