import React from 'react';
import IPO from '../../../components/products/IPO'
import SEP from '../../../components/products/SEP'
import OFS from '../../../components/products/OFS'
//import OFSOrderHistory from '../../../components/products/OFSOrderHistory'
import BasketOrder from '../../../components/products/BasketOrder'
import PortfolioTracker from '../../../components/portfolioTracker/PortfolioTracker'
import PortfolioTrackerDion from '../../../components/portfolioTracker/PortfolioTrackerDion'
import ExchangePledge from '../../../components/ExchangePledge/ExchangePledge'

class Products extends React.Component {

    render() {
        if (this.props.page === 'ipo') {
            return (
                <div className="fillheight">
                    <IPO />
                </div>
            );
        } 
        else if (this.props.page === 'sep') {
            return (
                <div className="fillheight">
                    <SEP history={this.props.history} />
                </div>
            );
        }
        else if (this.props.page === 'ofs') {
            return (
                <div className="fillheight">
                    <OFS />
                </div>
            );
        }
        
        else if (this.props.page === 'basketorder') {
            return (
                <div className="fillheight">
                    <BasketOrder />
                </div>
            );
        }
        // my code strat
        else if (this.props.page === 'portfolioTracker') {
            console.log("portfolioTracker*******************")
            return (
                <div className="fillheight">
                    <PortfolioTracker history={this.props.history}/>
                </div>
            );
        }   else if (this.props.page === 'portfolioTrackerDion') {
            return (
                <div className="fillheight">
                    <PortfolioTrackerDion history={this.props.history}/>
                </div>
            );
        }  else if (this.props.page === 'ExchangePledge') {
            return (
                <div className="fillheight">
                    <ExchangePledge />
                </div>
            );
        } 
        // my code end
        else {
            return(
                <div className="fillheight">
                
                </div>
            );
        }
    }

}

export default Products;