import { wsBroadcastUriTR, wsBroadcastUriTRNetMagic, localStorageKeys } from '../common/constants'
import { getItemByKey } from '../common/utils'

window.socketEventsTR = (function () {
    var pako = require('pako');
    var  websocketbroadcastTR;
    var  broadcastInstanceTR;    
    var reconnect = true, timeInterval;
    var socketType = { interative: "1", broadcast: "2" };
    var eventLevel1 = {}, eventLevel2 = {},eventLevel3 = {};
    var eventType = { eventlevel1: "1", eventlevel2: "2",eventlevel3: "3" }
    var eventTypeTR = { marketWatch: "mw", marketDepth: "dp",scripFeed: "sfi",connect: "cn"}
  

    var oninit = function () {
        
        if ("WebSocket" in window) {           
            reconnect = true

            if (!broadcastInstanceTR) {                
                broadcastInstanceTR = new websocketInstance();
            }
            if(getItemByKey(localStorageKeys.CUG_FLAG) === "Y"){
                          broadcastInstanceTR.initialize(wsBroadcastUriTRNetMagic, socketType.broadcast);
                          websocketbroadcastTR = broadcastInstanceTR.createConnection();
            }
            else{
                broadcastInstanceTR.initialize(wsBroadcastUriTR, socketType.broadcast);
                websocketbroadcastTR = broadcastInstanceTR.createConnection();
            }

            
        }
    }

    
    /**
     * @param {*unique key to subscribe } event 
     * @param {*conatins screen, symbol, exc } data 
     * @param {*to receive stream data} callback 
     * @param {* level1 or level2 default ---- level 1} type 
     */
    var register = function (event, data, callback, type) { 

        if (type === eventType.eventlevel2) {
            eventLevel2[event] = eventLevel2[event] || {};
            eventLevel2[event].subscribers = eventLevel2[event].subscribers || {};
            eventLevel2[event].data = eventLevel2[event].data || { sym: data.sym, exc: data.exc };
            eventLevel2[event].subscribers[data.screenName] = callback;
            
        }else if(type === eventType.eventlevel3){
            eventLevel3[event] = eventLevel3[event] || {};
            eventLevel3[event].subscribers = eventLevel3[event].subscribers || {};
            eventLevel3[event].data = eventLevel3[event].data || { sym: data.sym, exc: data.exc };
            eventLevel3[event].subscribers[data.screenName] = callback;
            
        } else {
            eventLevel1[event] = eventLevel1[event] || {};
            eventLevel1[event].subscribers = eventLevel1[event].subscribers || {};
            eventLevel1[event].data = eventLevel1[event].data || { sym: data.sym, exc: data.exc };
            eventLevel1[event].subscribers[data.screenName] = callback;
            
        }       
    };

      

    var unregister = function (screenName,event) {
        
        if (event===eventType.eventLevel3 && eventLevel3) {
            
            for (var key in eventLevel3) {                
                delete eventLevel3[key].subscribers[screenName];                
                if (Object.keys(eventLevel3[key].subscribers).length === 0) {
                    delete eventLevel3[key];
                }
            }  
           
            subscribe(eventType.eventLevel3);

        }else  if (event===eventType.eventLevel2 && eventLevel2) {           
            for (var key in eventLevel2) {                
                delete eventLevel2[key].subscribers[screenName];                
                if (Object.keys(eventLevel2[key].subscribers).length === 0) {
                    delete eventLevel2[key];
                }
            }         
            subscribe(eventType.eventLevel2);

        }else  if (eventLevel1) {            
            for (var key in eventLevel1) {               
                delete eventLevel1[key].subscribers[screenName];
                if (Object.keys(eventLevel1[key].subscribers).length === 0) {                    
                    delete eventLevel1[key];
                }                
            } 
            subscribe(eventType.eventLevel1);

        }
    };

    var subscribe = function (event) {
        
        if (event === eventType.eventlevel3 && Object.keys(eventLevel3).length > 0) {            
            placeRequest(eventLevel3, eventType.eventlevel3);

        } else if (event === eventType.eventlevel2 && Object.keys(eventLevel2).length > 0) {           
            placeRequest(eventLevel2, eventType.eventlevel2);

        } else if (Object.keys(eventLevel1).length > 0) {                
                placeRequest(eventLevel1, eventType.eventlevel1);
        }

      /*  if(event==eventType.eventlevel2){
            console.log("PlaceRequest==event=="+event);
            event==eventType.eventlevel1? placeRequest({}, eventType.eventlevel1):placeRequest({}, eventType.eventlevel2);
        }*/
    };

    var placeRequest = function (events, type) {
      
        var req = getAccReq();
        if (req) {
            req.task = (type === eventType.eventlevel1) ? eventTypeTR.marketWatch : (type === eventType.eventlevel2 ? eventTypeTR.marketDepth:eventTypeTR.scripFeed);    
            var symbolsStr="";
            for (var key in events) {              
               if(events[key].data.sym&&events[key].data.exc){
                    symbolsStr=symbolsStr+events[key].data.exc+"|"+events[key].data.sym+"&";
                }
            }                    
            symbolsStr = symbolsStr.length>0?symbolsStr.slice(0, -1):"";                    
            req.channel=symbolsStr;         
            doSend(req);
        }       
       
    }

    var unsubscribe = function (event) {

    };

    function getAccReq() {
        var req = {};
        var accountDet = JSON.parse(getItemByKey(localStorageKeys.ACCOUNT_ID));
        var userSessionId=JSON.parse(getItemByKey(localStorageKeys.USERSESSION_ID));
        if (accountDet && userSessionId) {
            req.user = accountDet.accID;
            req.acctid=accountDet.accID;
            req.token =userSessionId+"";
            return req;
        } else {
            disconnect();
        }
    }

    function onOpen(evt, websocket, type) {       
        writeToScreen("CONNECTED TR");        
            websocketbroadcastTR = websocket;
            if (websocketbroadcastTR.readyState === 1)
                doSendToBroadcast(getAccReq());
       
    }

    function onClose(evt, websocket, type) {       
        writeToScreen("DISCONNECTED  TR", type);
        try {
            if (websocketbroadcastTR) {
                websocketbroadcastTR.close();
            }
        } catch (error) {
            console.log(error)
        }
    }

    function doSend(message) {            
            var req = JSON.stringify(message) + "\n"
            writeToScreen("websocketbroadcastTR doSend SENT: " + req);
            if(websocketbroadcastTR && websocketbroadcastTR!==null){
                setTimeout(function () { 
                 websocketbroadcastTR.send(req);
                },1000);
            }        
    }

    function doSendToBroadcast(req) {
        if (websocketbroadcastTR && websocketbroadcastTR!==null && websocketbroadcastTR.readyState === 1) {
            if (req) {
                req.task = eventTypeTR.connect;
                req.channel = "";
            }
            var broadReq = JSON.stringify(req) + "\n";
            writeToScreen("BoradConnect req SENT: " + broadReq);
            setTimeout(function () {                
                websocketbroadcastTR.send(broadReq);                          
            },1000);
        }
    }

    function onMessage(evt) {        
        setTimeout(function () {
           var decodedData= deCodeData(evt.data);           
           var jsonData = JSON.parse(decodedData);         
        
           var events;          
            if (Object.keys(jsonData).length > 0) {
                
                jsonData.map(value => {                  
                    if(value.name=="sf"){
                        events = Object.assign({}, eventLevel1);                        
                        parseMessage(events, value);                     
                    }else if(value.name=="dp"){
                        events = Object.assign({}, eventLevel2);                        
                        parseMessage(events, value);
                    }if(value.name=="if"){                       
                        events = Object.assign({}, eventLevel3);                        
                        parseMessage(events, value);
                    }    
                })               
               
            }
        }, 0)
    }

    function deCodeData(message){
        var decoded = window.atob(message);
        var data = _atos(pako.inflate(decoded));        
        return data;
    }

    function _atos(array) {
        var newarray = [];
        for (var i = 0; i < array.length; i++) {       
            newarray.push(String.fromCharCode(array[i]));            
        }
        return newarray.join('');
    }

    var parseMessage = function (events, jsonData) {    
    //    console.log("parseMessageTR-->jsonData-->"+JSON.stringify(jsonData))
          
        var streamData={};     
        if(jsonData.name==="sf"){
            streamData.type="level1"
            
            streamData.data={
                askPrice:jsonData.sp,
                askQty:jsonData.bs,
                avgTradedPrice:jsonData.ap,
                bidQty:jsonData.bq,
                bidPrice:jsonData.bp,
                closePrice:jsonData.c, 
                change:jsonData.cng, 
                perchange:jsonData.nc,            
                exc:jsonData.e,
                hDPR:jsonData.ucl,                
                highPrice:jsonData.h,
                lDPR:jsonData.lcl,               
                lUpdatedTime:jsonData.ut&&(jsonData.ut).length>20?jsonData.ut.substring(0,20):jsonData.ut,
                lowPrice:jsonData.lo,
                ltp:jsonData.ltp,
                ltq:jsonData.ltq,
                marketCap:jsonData.mc,
                openPrice:jsonData.op,
                OI:jsonData.oi,            
                totBuyQty:jsonData.tbq,
                totSellQty:jsonData.tsq,
                vol:jsonData.v,
                xSym:jsonData.tk,
                yearlyHigh:jsonData.yh,
                yearlyLow:jsonData.yl   
            } 
            if ((Object.keys(streamData.data).length > 0)&&jsonData.ltt&&jsonData.ltt!=='NA') {
                streamData.data.lTradedTime= jsonData.ltt
            }
                 
        }if(jsonData.name==="if"){
          //  console.log("Indices Quote  onMessage data-->"+JSON.stringify(jsonData))
            streamData.type="level3";
            streamData.data={               
                change:jsonData.cng,
                perchange:jsonData.nc,
                exc:jsonData.e,
                index:jsonData.tk,
                indexVal:jsonData.iv,
                indexHighVal:jsonData.ihv,
                indexLowVal:jsonData.ilv,                
                xSym:jsonData.tk,           
                lUpdatedTime:jsonData.tvalue
            };         
            
        }else  if(jsonData.name==="dp"){ 
            /*
            if(jsonData.e&&(jsonData.e==="mcx_fo")){
                console.log("TR socket commodity feed-->"+JSON.stringify(jsonData))
             }  */

            if (jsonData.sp4 && jsonData.bp4) {
                var asks = []
                var bids = []

                asks.push({ "askNoOrders": jsonData.sno, "askPrice": jsonData.sp, "askQty": jsonData.bs });
                asks.push({ "askNoOrders": jsonData.sno1, "askPrice": jsonData.sp1, "askQty": jsonData.bs1 });
                asks.push({ "askNoOrders": jsonData.sno2, "askPrice": jsonData.sp2, "askQty": jsonData.bs2 });
                asks.push({ "askNoOrders": jsonData.sno3, "askPrice": jsonData.sp3, "askQty": jsonData.bs3 });
                asks.push({ "askNoOrders": jsonData.sno4, "askPrice": jsonData.sp4, "askQty": jsonData.bs4 });
                
                bids.push({ "bidNoOrders": jsonData.bno, "bidPrice": jsonData.bp, "bidQty": jsonData.bq });
                bids.push({ "bidNoOrders": jsonData.bno1, "bidPrice": jsonData.bp1, "bidQty": jsonData.bq1 });
                bids.push({ "bidNoOrders": jsonData.bno2, "bidPrice": jsonData.bp2, "bidQty": jsonData.bq2 });
                bids.push({ "bidNoOrders": jsonData.bno3, "bidPrice": jsonData.bp3, "bidQty": jsonData.bq3 });
                bids.push({ "bidNoOrders": jsonData.bno4, "bidPrice": jsonData.bp4, "bidQty": jsonData.bq4 });
                
                streamData.type = "level2";
                streamData.data = {
                    asks: asks,
                    bids: bids,
                    totBuyQty: jsonData.tbq,
                    totSellQty: jsonData.tsq,
                    xSym: jsonData.tk,
                    exc:jsonData.e

                }
            }
        }        
    
        var kayData=jsonData.tk;       
        if (streamData.data&&(Object.keys(streamData.data).length) > 0) {
            if (events[kayData]) {
                for (var key in events[kayData].subscribers) {                   
                    events[kayData].subscribers[key](streamData);
                }
            }
        }
    }

    var disconnect = function () {
        console.log("disconnect websocket");        
        reconnect = false;       
        if (websocketbroadcastTR) {
            websocketbroadcastTR.close();
        }        
        broadcastInstanceTR && broadcastInstanceTR.removeAllListener();        
        websocketbroadcastTR = null;
        broadcastInstanceTR = null;
        
    }

    function writeToScreen(message, type) {
        console.log(message, type);
    }
   

    var getReconnect = function () {
        return reconnect;
    }

    var setReconnect = function (value) {
        reconnect = value;
    }

    var removeAllListener = function (wtype) {        
            broadcastInstanceTR.removeAllListener();
    }

    var createConnection = function (wtype) {
        if(broadcastInstanceTR){
            websocketbroadcastTR = broadcastInstanceTR.createConnection();
        }else{
            oninit();
        }
    }

    return {
        register: register,       
        subscribe: subscribe,
        unsubscribe: unsubscribe,
        unregister: unregister,
       // getEvents: getEvents,
        oninit: oninit,
        onOpen: onOpen,
        onClose: onClose,
        onMessage: onMessage,
        disconnect: disconnect,
        getReconnect: getReconnect,
        setReconnect: setReconnect,
        createConnection: createConnection,
        removeAllListener: removeAllListener,
    }

})();

var websocketInstance = function () {
    var websocket, wtype;
    var retryCount;
    var wurl;
    this.initialize = function (url, type) {
        wtype = type;
        wurl = url;
        retryCount = 0
    }

    this.createConnection = function () {
        websocket = new WebSocket(wurl);
        websocket.addEventListener("open", this.onOpen);
        websocket.addEventListener("close", this.onClose);
        websocket.addEventListener("message", this.onMessage);
        websocket.addEventListener("error", this.onError);
        return websocket;
    }

    this.onOpen = function (evt) {
        retryCount = 0;        
        window.socketEventsTR.onOpen(evt, websocket, wtype);
    }

    this.removeAllListener = function () {
        websocket.removeEventListener("open", this.onOpen);
        websocket.removeEventListener("close", this.onClose);
        websocket.removeEventListener("message", this.onMessage);
        websocket.removeEventListener("error", this.onError);
    }

    this.onClose = function (evt) {
        window.socketEventsTR.onClose(evt, websocket, wtype);
        window.socketEventsTR.removeAllListener(wtype);
        
        if (window.socketEventsTR.getReconnect() && retryCount < 10) {
            retryCount++;
            setTimeout(function () {
                window.socketEventsTR.createConnection(wtype);
            }.bind(this), 5000);
        }      

        if (retryCount === 10) {
            window.socketEventsTR.disconnect();
        }
    
    }

    this.onMessage = function (evt) {
        window.socketEventsTR.onMessage(evt);
    }

    this.onError = function (evt) {

    }
};