import React from 'react';
import { connect } from 'react-redux';
import SummaryRow from './SummaryRow';
import SummaryHeader from './SummaryHeader';
import SummaryGrandTotal from './SummaryGrandTotal';
import  '../css/summary.css'
class SummaryTable extends React.Component{
constructor(props){
    super(props)
    this.state = {
        data : this.props.portfolio_data
        
    }
}
componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.portfolio_data});  
}
render(){
            return(
             <table id='summaryid'>
                 {<SummaryHeader />}
                 {<SummaryRow portfolio_data={this.state.data}/>}
                 {<SummaryGrandTotal portfolio_data={this.state.data}/>}
             </table>
            );
        }
    }

    const mapStateToProps = (state) => {
        return {
    
        }
    }
    
    const mapDispatchToProps = (dispatch, ownProps) => {
        return {
           // showErrorDialog: (errordata) => showErrorDialog(dispatch, errordata),
            //commonDialog: (payload) => commonDialog(dispatch, payload),
            //toggleDialog: (payload) => toggleDialog(payload, dispatch)
        };
    };
    export default connect(mapStateToProps, mapDispatchToProps)(SummaryTable); 
    