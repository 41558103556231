import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux';
import store from './store'
import { getTheme } from './common/utils'
import './css/theme-dark/main.css';
import './css/theme-dark/atompayment.css';

const th = getTheme();
console.log( "pkc  " +  th);
if (th === 'light') {
  import('./css/theme-light/main.css');
}
else {
  import('./css/theme-dark/main.css');
}

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>
    , document.getElementById('root'));
registerServiceWorker();
