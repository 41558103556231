import React from "react"

class RadioButtons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeButton: this.props.activeButton ? this.props.activeButton : '',
            disabledlist: this.props.disabledlist ? this.props.disabledlist : []
        }
        this.setSelected = this.setSelected.bind(this);
        this.onButtonChanged = this.onButtonChanged.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        let selected = nextProps.selected;
        let disabledList = nextProps.disabledlist ? nextProps.disabledlist : [];
        if (selected >= 0) {
            this.setSelected(selected);
        }
        this.setState({ disabledlist: disabledList });
    }

    componentDidMount() {
        if (this.state.disabledlist.length === 0 &&
            this.props.buttons && this.props.buttons.length > 0 && this.props.buttons[0]) {
                if(this.state.activeButton=='')
                    this.setState({ activeButton: this.props.buttons[0].value });
        }
    }

    setSelected(index, givecallback) {
        if (this.props.buttons && this.props.buttons.length > index && this.props.buttons[index]) {
            this.setState({ activeButton: this.props.buttons[index].value });

            if (givecallback && this.props.callbackHandler) {
                this.props.callbackHandler(this.props.buttons[index].value);
            }
        }
    }

    setDisabled(index, value) {
        if (this.props.buttons && this.props.buttons.length > index && this.props.buttons[index]) {
            var prev = this.state.disabledlist;
            var i = prev.indexOf(index);
            if (value) {
                if (i === -1)
                    prev.push(index);
            }
            else {
                if (i !== -1)
                    prev.splice(i, 1);
            }

            this.setState({ disabledlist: prev });
        }
    }

    onButtonChanged(e) {
        this.setState({ activeButton: e.currentTarget.value });
        if (this.props.callbackHandler) {
            this.props.callbackHandler(e.currentTarget.value);
        }
    }

    render() {
        return (
            <div className={(this.props.className ? this.props.className : "flex-space-evenly")}>
                {this.props.buttons.map(function (row, key) {
                    return (
                        <label className="radio-inline ideas" key={key}>
                            <input type="radio" value={row.value}
                                checked={this.state.activeButton === row.value}
                                onChange={(e) => this.onButtonChanged(e)}
                                disabled={(this.state.disabledlist.indexOf(key) !== -1)}
                            />
                            <span className="ideas-radio"></span>
                            <span className={this.props.labelName?this.props.labelName:"ideas-radio-label"}>
                                {row.key}
                            </span>
                        </label>
                    );
                }, this)
                }
            </div>
        );
    }
}

export default RadioButtons;