import { createStore, combineReducers, compose } from "redux";
import loginStatus from './reducers/loginStatus'
import dashboard from './reducers/dashboard'
import common from "./reducers/common"
import watchlist from "./reducers/watchlist"
import orderentry from './reducers/orderentry'
import productentry from './reducers/productentry'
import scripdetails from './reducers/scripdetails'
import charts from './reducers/charts'
import marketstats from './reducers/marketstats'
import loginWarning from './reducers/loginWarning';
import { mtfDefnition, mtfAlert } from './reducers/mtfFacility';
const reducers = combineReducers({
    loginStatus, dashboard, marketstats, common, watchlist, orderentry, productentry, scripdetails,
    charts, loginWarning, mtfDefnition, mtfAlert
});

const devTools = process.env.REACT_APP_ENV !== "prod" && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : f => f;

const store = createStore(reducers, {}, compose(devTools));


export default store;