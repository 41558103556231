import React from 'react';
import { Popover, OverlayTrigger } from "react-bootstrap"

class DescriptionPopover extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            focussed: true,
        }
    }


    render() {

        const popoverHoverFocus = (
            <Popover className="profile-popover" id="profile-popover" >
                <div className="row contentpanel">
                    {this.props.description}
                </div>
            </Popover>
        );

        return (
            <OverlayTrigger trigger={['click']} placement="right" container={this.props.parentRef} containerPadding={25} overlay={popoverHoverFocus} rootClose>
                <img src="assets/svg/info_selected.svg" className="profile-info-icon" alt="info"/>
            </OverlayTrigger>
        )
    }
}

export default DescriptionPopover;