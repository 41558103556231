import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import Dropdown from '../../controls/Dropdown'
import { commonDropDownType } from "../../common/constants"
//import { ModuleKeys, watchlistDownloadOptions, watchlistFilterOptions } from '../../common/constants'
import ProductsPopup from '../../components/products/ProductsPopup'
import ProfilePopup from '../../components/user/ProfilePopup'
import DematHoldingsPopup from '../../components/reports/DematHoldingsPopup'

class OrderbookBaseWidget extends Component {

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedOrderBook: ''
        };
        this.onCollapseClick = this.onCollapseClick.bind(this);
    }

    onCollapseClick() {
        if (this.props.onCollapseCallback) {
            this.props.onCollapseCallback();
        }
        else
            this.props.history.push("/home/dashboard");
    }

    onCloseClick()
    {
        this.props.callback();
    }
    onDownloadOptionSelected(selected) {
        // //WARNING: considering values based on index
        // if (selected == watchlistDownloadOptions[0].name) { // excel
        //     if (this.props.onDownloadOptionSelected) {
        //         this.props.onDownloadOptionSelected(this.state.selectedWatchilist.name + ".xlsx", fileExportTypes.EXCEL);
        //     }
        // }
        // else if (selected == watchlistDownloadOptions[1].name) { // csv
        //     if (this.props.onDownloadOptionSelected) {
        //         this.props.onDownloadOptionSelected(this.state.selectedWatchilist.name + ".csv", fileExportTypes.CSV);
        //     }
        // }
    }

    render() {
        return (
            <div className="fillheight orderbookbase-content-holder fillwidth">
                <div className="row contentpanel widget-title">
                    <div className="col-xs-6 col-lg-6 col-md-6  watchlist-title-holder contentpanel">
                        {this.props.title}
                    </div>
                    <div className="col-xs-6 col-lg-6 col-md-6 contentpanel">
                        {this.props.hideexpander ? '' :
                            <div className="watchlistoptions text-right">
                                <img src="assets/svg/collapse.svg" className="expander-icon" alt="collapse"onerror="this.src='assets/images/logo.png'" onClick={this.onCollapseClick} />
                            </div>
                        }
                        {this.props.closeButton ? 
                            <div className="watchlistoptions text-right">
                                <img src="assets/svg/close.svg" className="expander-icon" alt="close" onerror="this.src='assets/images/logo.png'" onClick={this.onCloseClick.bind(this)} />
                            </div>
                            : ''
                        }
                        {this.props.showinfoicon === "products-ipo" ? <ProductsPopup /> : ''}
                        {this.props.showinfoicon === "profile" ? <ProfilePopup /> : ''}
                        {this.props.showinfoicon === "dematholdings" ? <DematHoldingsPopup /> : ''}
                        {this.props.showinfoicon === "mkt-sts-summary" ? <img src="assets/svg/info.svg" className="info-icon" style={{ float: 'right' }} alt="info"/> : ''}
                        {this.props.showinfoicon === "mktsts-eq-sectors" ?
                            <div>
                                <div className="col-xs-9 col-lg-9 col-md-9"> </div>
                                <div className="col-xs-2 col-lg-2 col-md-2">
                                    <Dropdown dropdownType={commonDropDownType.DEFAULT} list={this.props.sectorslist} onSelectionChanged={this.props.onSectorSelected} />
                                </div>
                                <div className="col-xs-1 col-lg-1 col-md-1">
                                    <img src="assets/svg/info.svg" className="info-icon" style={{ float: 'right' }} alt="info"/>
                                </div>
                            </div>
                            : ''
                        }
                        {this.props.additionalHeader && this.props.additionalHeader}
                        {this.props.showinfoicon === "sepregreport" ? <ProductsPopup /> : ''}
                        {this.props.showinfoicon === "sepexereport" ? <ProductsPopup /> : ''}
                    </div>
                </div>
                <div className="row hdivider" > </div>
                <div className="orderbookbase-content-body" >
                    {this.props.children}
                </div>
            </div>
        );
    }



}


OrderbookBaseWidget.protoTypes = {
    callback : PropTypes.func,
    };

export default withRouter(OrderbookBaseWidget);