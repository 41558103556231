import React, { Component } from 'react';
import { getCharges } from '../../actions/orderentry/orderentryrequests';
import { TradeActions, validityTypes, orderEntryTypes, assetClasses, instrumentType, productTypes_Equity, advanceOrderTypes } from '../../common/constants';
import { comaSeparator } from '../../common/utils'

class OrderConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tradeaction: "BUY",
            titlepretext: '',
            orderinputs: [],
            parseditems: [],
            columnscount: 3,
            confirmClicked: false,
            colapsed: true,
            chargesfetched:false,
            chargesdetails:[],
            exc:'NSE'
        };
        this.setOrderDetails = this.setOrderDetails.bind(this);
        this.onTransactionChargesClick = this.onTransactionChargesClick.bind(this);
    }

    componentDidMount() {
        if (this.submitBtn) {
            this.submitBtn.focus();
        }
        if (this.props.orderInputs) {
            this.setOrderDetails(this.props.orderInputs);
        }
    }

    componentWillReceiveProps(newProps) {
        this.setOrderDetails(newProps.orderInputs);
        console.log("bbbbbbbbbbbb ", newProps.orderInputs)
    }

    setOrderDetails(order) {
        if (!order) return;

        this.setState({ orderinputs: order }, () => {

            if (order.action) {
                if (order.action === TradeActions.BUY)
                    this.setState({
                        tradeaction: "BUY",
                        titlepretext: order.orderentrytype === orderEntryTypes.MODIFY ? 'MODIFY' : ''
                    });
                else if (order.action === TradeActions.SELL)
                    this.setState({
                        tradeaction: "SELL",
                        titlepretext: order.orderentrytype === orderEntryTypes.MODIFY ? 'MODIFY' : ''
                    });
            }

            var visualinputs = [];

            if (order.symobj) {
                if (order.symobj.instr.indexOf('SPR') === -1) {
                    visualinputs = this.frameVisualInputs(order);
                }
                else {
                    visualinputs = this.frameSpreadVisualInputs(order);
                }
            }

            //this.setState({ parseditems: this.parseInput(visualinputs) });
            this.setState({ parseditems: visualinputs});
        });
    }
    onTransactionChargesClick(){
        this.setState({ colapsed:!this.state.colapsed , exc : this.props.orderInputs.symobj.trExc });
        if(!this.state.chargesfetched){
            this.getTransactionCharges(this.props.orderInputs);
            this.setState({chargesfetched:true})
        }

    }
    getTransactionCharges(orderinputs){
        let lotsize = 1;
        if(orderinputs.symobj.trExc==='CDS')
        {
            lotsize = isNaN(parseInt(orderinputs.lotqualifier))?1:parseInt(orderinputs.lotqualifier);
        }


        var requestData = {
            request: {
                data: {
                    exchange : orderinputs.symobj.trExc,
                    segment : orderinputs.symobj.exc_seg,
                    transactionType : orderinputs.action,
                    quantity : orderinputs.quantity* lotsize,
                    price : orderinputs.ordertype.value==='limit'?orderinputs.price:orderinputs.ltp,
                    product : orderinputs.indpselectedpin==='intraday'?'INTRADAY':'NRML',
                    instrumentType: orderinputs.symobj.instr,
                    symbol: orderinputs.scrip,
                    lotSize: lotsize * orderinputs.requiredlotsize
                }
            }
        };

        getCharges(requestData,this.parseGetChargesResponse.bind(this),this.parseGetChargesError.bind(this));

    }
    parseGetChargesResponse(responseData){
        let { response: { data } } = responseData;
        let c = data.charges;
        var charges = [];
        charges.push({key:'Dhani Stocks Brokerage',value:c.brokerage,subtext:''});
        charges.push({key:'Securities Transaction Tax',value:c.stt,subtext:c.sttPercent+'% of the trade value'});
        charges.push({key:'GST on Brokerage',value:c.gstOnBrokerage,subtext:c.gstPercent+'% of Brokerage amount'});
        charges.push({key:'Exchange Transaction Charges',value:c.transactionCharge, subtext:c.transactionChargePercent+'% + GST(18.00%)'});
        charges.push({key:'SEBI TurnOver Fees',value:c.sebiFee, subtext:c.sebiFeePercent+'% + GST(18.00%)'});
        charges.push({key:'stamp duty',value:c.stampDuty,subtext:c.stampDutyPercent+'% on Trade Value'});
        this.setState({chargesdetails:charges});
    }
    parseGetChargesError(){
        
    }

    frameSpreadVisualInputs(orderinputs){
        var inputs = [];
        if (orderinputs.scrip)
            inputs.push({ key: "Scrip", value: orderinputs.scrip });

        if (orderinputs.symobj && orderinputs.symobj.exc)
            inputs.push({ key: "Exchange", value: orderinputs.symobj.exc });

        if (orderinputs.producttype) {
            if (!orderinputs.indp && orderinputs.action === TradeActions.SELL && orderinputs.producttype === productTypes_Equity[1]
                && orderinputs.producttype !== advanceOrderTypes[0] && orderinputs.producttype !== advanceOrderTypes[1] 
                && orderinputs.producttype !== advanceOrderTypes[2] 
                &&orderinputs.symobj.assetClass === assetClasses.equity.toLowerCase()) {
                inputs.push({ key: "Product Type", value: "Short Sell" });
            } else if (orderinputs.symobj.assetClass === assetClasses.equity.toLowerCase() && orderinputs.producttype.value === productTypes_Equity[1].value) {
                inputs.push({ key: "Product Type", value: "Cash" });
            } else {
                let producttype = orderinputs.producttype.key;
                inputs.push({ key: "Product Type", value: producttype });
            }
        }
    
        if (orderinputs.action == "BUY"){
            inputs.push({ key: "Transition Leg 1", value: "Sell" });
        }else{
            inputs.push({ key: "Transition Leg 1", value: "Buy" });
        }
            
        
        if (orderinputs.symobj && orderinputs.symobj.expiry && orderinputs.symobj.expiry.trim() !== '')
            inputs.push({ key: "Expiry Date Leg 1", value: orderinputs.symobj.expiry });

        if (orderinputs.action)
            inputs.push({ key: "Transition Leg 2", value: orderinputs.action });
        
        if (orderinputs.symobj && orderinputs.symobj.expiry && orderinputs.symobj.expiry.trim() !== '')
            inputs.push({ key: "Expiry Date Leg 2", value: orderinputs.symobj.expiry2 });

        if (orderinputs.quantity)
            inputs.push({ key: orderinputs.quantity_label, value: comaSeparator(orderinputs.quantity + '') });

        if (orderinputs.price) {
            let orderInstr = orderinputs.symobj.instr.toLowerCase();
            let noOfDec = ((instrumentType.CURRENCY_FUTURE.toLowerCase() === orderInstr) || (instrumentType.CURRENCY_OPTIONS.toLowerCase() === orderInstr) ||
                (instrumentType.CURRENCY.toLowerCase() === orderInstr)) ? 4 : 2;
            inputs.push({ key: "Price", value: parseFloat(orderinputs.price).toFixed(noOfDec) });
        }

        if (orderinputs.ordervalidity)
            inputs.push({ key: "Validity", value: orderinputs.ordervalidity.key });

        return inputs;
    }
    frameVisualInputs(orderinputs) {
        var inputs = [];
        if (orderinputs.scrip)
            inputs.push({ key: "Scrip", value: orderinputs.scrip });

        if (orderinputs.symobj && orderinputs.symobj.exc)
            inputs.push({ key: "Exchange", value: orderinputs.symobj.exc });

        if (orderinputs.quantity)
            inputs.push({ key: orderinputs.quantity_label, value: comaSeparator(orderinputs.quantity + '') });

        if (orderinputs.price) {
            let orderInstr = orderinputs.symobj.instr.toLowerCase();
            let noOfDec = ((instrumentType.CURRENCY_FUTURE.toLowerCase() === orderInstr) || (instrumentType.CURRENCY_OPTIONS.toLowerCase() === orderInstr) ||
                (instrumentType.CURRENCY.toLowerCase() === orderInstr)) ? 4 : 2;
            inputs.push({ key: "Price", value: parseFloat(orderinputs.price).toFixed(noOfDec) });
        }

        if (orderinputs.ordertype)
            inputs.push({ key: "Order Type", value: orderinputs.ordertype.key });

        if (orderinputs.producttype) {
            if (!orderinputs.indp && orderinputs.action === TradeActions.SELL && orderinputs.producttype === productTypes_Equity[1]
                && orderinputs.producttype !== advanceOrderTypes[0] && orderinputs.producttype !== advanceOrderTypes[1] 
                && orderinputs.producttype !== advanceOrderTypes[2] 
                &&orderinputs.symobj.assetClass === assetClasses.equity.toLowerCase()) {
                inputs.push({ key: "Product Type", value: "Short Sell" });
            } else if (orderinputs.symobj.assetClass === assetClasses.equity.toLowerCase() && orderinputs.producttype.value === productTypes_Equity[1].value) {
                inputs.push({ key: "Product Type", value: "Cash" });
            } else {
                let producttype = orderinputs.producttype.key;
                inputs.push({ key: "Product Type", value: producttype });
            }
        }


        if (orderinputs.symobj && orderinputs.symobj.expiry && orderinputs.symobj.expiry.trim() !== '')
            inputs.push({ key: "Expiry Date", value: orderinputs.symobj.expiry });

        if (orderinputs.symobj && orderinputs.symobj.strike && orderinputs.symobj.strike.trim() !== '')
            inputs.push({ key: "Strike Price", value: (orderinputs.symobj.strike) });

        if (orderinputs.symobj && orderinputs.symobj.optType && orderinputs.symobj.optType.trim() !== '')
            inputs.push({ key: "Option Type", value: orderinputs.symobj.optType });

        if (orderinputs.ordervalidity) {
            if (orderinputs.ordervalidity === validityTypes[2]) {
                //vtd
                if (orderinputs.vtddate)
                    inputs.push({ key: "Validity", value: orderinputs.ordervalidity.key + "(" + orderinputs.vtddate + ")" });
            }
            else {
                inputs.push({ key: "Validity", value: orderinputs.ordervalidity.key });
            }
        }

        if (orderinputs.discquantity)
            inputs.push({ key: orderinputs.discquantity_label, value: comaSeparator(orderinputs.discquantity + '') });

        if (orderinputs.slprice)
            inputs.push({ key: "Trigger (SL)(optional)", value: orderinputs.slprice.includes(".") ? orderinputs.slprice : Number(orderinputs.slprice).toFixed(2) });

        if (orderinputs.producttype && orderinputs.coTriggerPrice && orderinputs.producttype === advanceOrderTypes[1]) {
            inputs.push({ key: "Trigger Price (SL)", value: comaSeparator(orderinputs.coTriggerPrice + '') });
        }

        //bracket order
        if (orderinputs.producttype && orderinputs.boTriggerPrice
            && orderinputs.boTargetPrice && orderinputs.producttype === advanceOrderTypes[2]
            && orderinputs.orderentrytype === orderEntryTypes.NEW) {
            inputs.push({ key: "Trigger Price (SL)", value: comaSeparator(orderinputs.boTriggerPrice + '') });
            inputs.push({ key: "Target Price", value: comaSeparator(orderinputs.boTargetPrice + '') });
            inputs.push({ key: "Trailing SL", value: comaSeparator(orderinputs.boTrailingSL + '') });
        }
        return inputs;
    }

    parseInput(data) {
        var matrix = [], i, k;
        for (i = 0, k = -1; i < data.length; i++) {
            if (i % this.state.columnscount === 0) {
                k++;
                matrix[k] = [];
            }
            matrix[k].push(data[i]);
        }
        return matrix;
    }

    onConfirmationClick() {
        if (!this.state.confirmClicked) {
            this.setState({ confirmClicked: true }, () => {
                this.props.onConfirmOrder();
            });
        }
    }

    render() {
        return (
            <div className="modal-dialog orderresult ">
                <div className="order-row" style={{ textAlign: "center" }}>
                    CONFIRM {this.state.titlepretext} {this.state.tradeaction} ORDER
                </div>
                <div className="row hdivider contentpanel"> </div>
                <div className="container-fluid order-row order-confirmation-body">
                    {this.state.parseditems.map(function (row, key) {
                        return (
                            <div key={key} className="order-confirmation-row" >
                                <div className="row">
                                    <div className="col-6 col-lg-6 col-md-6 text-right" style={{ color: "#777" }}>
                                        {row.key}
                                    </div>

                                    <div className="col-6 col-lg-6 col-md-6 contentpanel">
                                        {row.value}
                                    </div>
                                </div>
                            </div>
                        );
                    }, this)
                    }
                    <div key={10} className="order-confirmation-row" >
                        <div className="row">
                            <div className="col-6 col-lg-6 col-md-6 text-right" onClick={this.onTransactionChargesClick} style={{ color: "#2C98F0" }}>
                                Charges {this.state.colapsed? '+':'-'}
                            </div>
                        </div>
                    </div>

                    {!this.state.colapsed &&
                        this.state.chargesdetails.map(function (row, key) {
                            return (
                                <div key={key} className="order-confirmation-charges-row">
                                    <div className="row">
                                        <div className="col-6 col-lg-6 col-md-6 text-right" style={{color: "#777"}}>
                                            {row.key}
                                            <div className="text-right" style={{fontSize: "10px"}}>
                                                {row.subtext}
                                            </div>
                                        </div>

                                        <div className="col-6 col-lg-6 col-md-6 contentpanel">
                                            {row.value}
                                        </div>
                                    </div>
                                </div>
                            );

                        }, this)}

                    {!this.state.colapsed&&<div style={{fontSize: "11px",color: "#777",borderTop: "1px"}}>The charges displayed above are approximate. The actual charges will reflect in your contract note </div>}

                </div>

                <div className="order-row text-center" style={{ marginTop: "25px" }} >
                    <button type="button" className="btn-footer order-cancel" onClick={this.props.onOrderModify} >CANCEL</button>
                    {/* TODO: Enable after confirmation from client and Hiran */}
                    {/* <button type="button" className="btn-footer order-modify" onClick={this.props.onOrderModify} >MODIFY</button> */}
                    <button type="button" ref={submitBtn => { this.submitBtn = submitBtn }} className={this.state.tradeaction === TradeActions.BUY ? "btn-footer order-confirm-button active-buy" : "btn-footer order-confirm-button active-sell"}
                        onClick={this.props.onConfirmOrder} >SUBMIT ORDER</button>
                </div>

                {this.state.orderinputs && this.state.orderinputs.producttype && this.state.orderinputs.producttype.value === "intraday" ?

                    <div className="row contentpanel order-confirmation-intradayfooter ">
                        NOTE: Intraday positions are squared-off usually {this.props.sqrOffTimeLimit} minutes before close of settlement. This time may be changed at the sole discretion of Dhani Stocks Limited, without any prior notice
                    </div>
                    :
                    ''

                }
            </div>
        );
    }
};
export default OrderConfirmation;