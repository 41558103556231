import React, { Component } from 'react';
import { comaSeparator } from '../../common/utils';
class CoverOrder extends Component {
    constructor() {
        super();
        this.state = {
            showCvrDetails: false
        }
    }

    onShowCvrDetailsClick = () => {
        this.setState({ showCvrDetails: !(this.state.showCvrDetails) });
    }

    render() {
        const { row,leg1, orderId } = this.props;
        return (
            <div ref={(expElement) => this.expElement = expElement}>
                {leg1 ?
                    <div className="row my-accordion-padding">
                        <div className="col-9 contentpanel" >
                            <div className="row">
                                <div className="col-2 text-right">TRIGGER PRICE</div>
                                <div className="col-2 nopadding">
                                    <div className="row">
                                        <div className="col-4 text-right nopadding">
                                            <span className="cursor-pointer" onClick={(e) => {
                                                e.stopPropagation();
                                                this.onShowCvrDetailsClick();
                                            }}>
                                                {
                                                    this.state.showCvrDetails ?
                                                        <img src="assets/svg/minus.svg" alt="plus" width="11px" height="11px" /> :
                                                        <img src="assets/svg/plus.svg" alt="plus" width="11px" height="11px" />
                                                }
                                            </span>
                                        </div>
                                        <div className="col-8 text-right nopadding">
                                            <div className="accordian-orderheader">QTY</div>
                                            <div>{leg1.qty}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3 text-right">
                                    <div className="accordian-orderheader">TRIGGER PRICE</div>
                                    <div>{leg1.triggerPrice}</div>
                                </div>
                                <div className="col-2 text-right">
                                    <div className="accordian-orderheader">ACTION</div>
                                    <div className={leg1.ordAction === 'buy' ? "positive-change-blue" : "negative-change"}>
                                        {leg1.ordAction === 'buy' ? 'BUY' : 'SELL'}</div>
                                </div>
                                <div className="col-2 text-right">
                                    <div className="accordian-orderheader">PEND.QTY</div>
                                    <div>{leg1.remQty}</div>
                                </div>
                                {/* <div className="col-2 text-right">
                                    <div className="accordian-orderheader">STATUS</div>
                                    <div>{row.ordStatus}</div>
                                </div> */}
                                <div className="col-1"></div>
                                <div className="col-1 text-right"></div>
                            </div>
                        </div>
                        <div className="col-3 contentpanel">
                            <div className="row">
                                <div className="col-4">
                                    <button type="button" className="form-control myorder-btn modify-btn"
                                        data-tag={leg1.orderId} onClick={(e) => { this.props.onModifyOrderClick(row,true,false) }}
                                        disabled={!leg1.modifiable} >
                                        MODIFY
                                </button>
                                </div>
                                <div className="col-4">
                                    {/* <button type="button" className={"form-control myorder-btn cancel-btn"}
                                        data-tag={row.orderId} onClick={(e) => { this.props.onCancelOrderClick(row) }}
                                        disabled={!row.cancellable}  >
                                        CANCEL
                                </button> */}
                                </div>
                                <div className="col-4">
                                </div>
                            </div>
                        </div>
                        <div className="col-12 nopadding">
                            <div className="myorders-panel-body advance-orders-accordion expand-inner-row " ref={(leg1) => this.leg1 = leg1}
                                style={{ maxHeight: (this.state.showCvrDetails ? this.leg1.scrollHeight : "") }}>
                                <div className="row myinner-accordion-padding">
                                    <div className="col-2 text-right">
                                    </div>
                                    <div className="col-7 text-right inner-expand">
                                        <div className="row" style={{ marginTop: "10px", marginBottom: "10px" }}>
                                            <div className="col-2 text-right nopadding">
                                                <div className="accordian-orderheader">ORDER REFERENCE NO</div>
                                                {leg1.orderId}
                                            </div>
                                            <div className="col-2 text-right nopadding">
                                                <div className="accordian-orderheader">EXCHANGE ORDER NO</div>
                                                {leg1.exchOrderId}
                                            </div>
                                            <div className="col-1 text-right nopadding">
                                                <div className="accordian-orderheader">QTY</div>
                                                {leg1.qty}
                                            </div>
                                            <div className="col-2 text-right nopadding">
                                                <div className="accordian-orderheader">PRICE</div>
                                                {parseFloat(leg1.triggerPrice) <= 0 ? comaSeparator(leg1.limitPrice) : comaSeparator(leg1.triggerPrice)}
                                            </div>
                                            <div className="col-2 text-right nopadding">
                                                <div className="accordian-orderheader">STATUS</div>
                                                {leg1.ordStatus}
                                            </div>
                                            <div className="col-3 text-right">
                                                <div className="accordian-orderheader">DATE & TIME</div>
                                                {leg1.ordDate}, {leg1.ordTime}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3 text-right">
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div> : null
                }
            </div>
        );
    }
};

export default CoverOrder;