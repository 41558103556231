import React, { Component } from "react";
import { connect } from "react-redux";
import { CIQ } from "chartiq/js/chartiq";
import "chartiq/js/standard";
import "chartiq/js/advanced";
import "chartiq/js/componentUI";
import "chartiq/js/components";
import DrawingToolbar from "../components/DrawingToolbar";

class DrawingBaseView extends Component {
  constructor(props) {
    super(props);
    this.tools = CIQ.Drawing.getDrawingToolList({ retracement: true });
    this.toolsArray = Object.keys(this.tools).map(key => {
      return this.tools[key];
    });
    this.state = {
      showDrawingToolbar: false,
      tools: this.toolsArray.sort(),
      selectedTool: null,
      fill: null,
      line: null,
      lineWidth: null,
      linePattern: null,
      fontOptions: null,
      fontFamily: "Helvetica",
      fontSize: 13,
      fontStyle: {
        bold: false,
        italic: false
      },
      color: null
    };
  }

  toggleDrawing() {
    let elem = document.getElementById(this.props.id ? this.props.id : "chartContainer"),
      flipToolbar = !this.state.showDrawingToolbar;

    if (flipToolbar) {
      elem.classList.add("toolbarOn");
    } else {
      elem.classList.remove("toolbarOn");
    }
    this.setState({ showDrawingToolbar: flipToolbar });
  }

  setFontFamily(family) {
    this.setState({ fontFamily: family });
  }

  setFontSize(size) {
    this.setState({ fontSize: size + "px" });
  }

  setFontStyle(styleType) {
    if (styleType === "bold") {
      this.setState({
        fontStyle: {
          bold: !this.state.fontStyle.bold,
          italic: this.state.fontStyle.italic
        }
      });
    } else if (styleType === "italic") {
      this.setState({
        fontStyle: {
          bold: this.state.fontStyle.bold,
          italic: !this.state.fontStyle.italic
        }
      });
    }
  }

  setFillStyle(style) {
    this.setState({ fill: style });
  }

  setFillColor(color) {
    this.setState({ fill: CIQ.hexToRgba("#" + color) });
  }

  setLineColor(color) {
    this.setState({ line: CIQ.hexToRgba("#" + color) });
  }

  changeTool(tool, params) {
    this.setState({
      selectedTool: tool.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }),
      fontOptions: params.font ? params.font : null,
      fontSize: params.font ? params.font.size : this.state.fontSize,
      fontFamily: params.font ? params.font.family : this.state.fontFamily,
      fill: params ? params.fillColor : null,
      line: params ? params.color : null,
      lineWidth: params ? params.lineWidth : null,
      linePattern: params ? params.pattern : null
    });
  }

  setLineParams(weight, pattern) {
    this.setState({
      lineWidth: weight,
      linePattern: pattern
    });
  }

  render() {
    return (
      <DrawingToolbar
        showDrawingToolbar={this.state.showDrawingToolbar}
        tools={this.state.tools}
        selectedTool={this.state.selectedTool}
        fill={this.state.fill}
        line={this.state.line}
        lineWidth={this.state.lineWidth}
        linePattern={this.state.linePattern}
        fontOptions={this.state.fontOptions}
        fontFamily={this.state.fontFamily}
        fontSize={this.state.fontSize}
        fontStyle={this.state.fontStyle}
        color={this.state.color}
        toggleDrawing={this.toggleDrawing.bind(this)}
        setFontFamily={this.setFontFamily.bind(this)}
        setFontStyle={this.setFontStyle.bind(this)}
        setFontSize={this.setFontSize.bind(this)}
        setFillColor={this.setFillColor.bind(this)}
        setLineColor={this.setLineColor.bind(this)}
        setFillStyle={this.setFillStyle.bind(this)}
        setLineParams={this.setLineParams.bind(this)}
        changeTool={this.changeTool.bind(this)}
        draw={this.props.draw}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true
})(DrawingBaseView);
