import React, { Component } from 'react';
import BasketOrderBaseWidget from '../base/BasketOrderBaseWidget';
import RenameBasketOrderPopup from './RenameBasketOrderPopup';
import DeleteBasketPopup from './DeleteBasketPopup';
import CreateNewBasketPopup from './CreateNewBasketPopup';
import ExecuteBasketPopup from './ExecuteBasketPopup';
import { getDeleteBasketequest, getCreateBasketequest, RenameBasketrequest, GetBasketSymbolBasketrequest, BasketExecutionLogrequest,
    DeleteSymbolrequest
} from '../../actions/products/productrequest'
import Dialog from "../../controls/Dialog";
import { showOrderDialog, showCancelOrderDialog, showCancelOrderDialogCallback, onOrderSuccess } from '../../actions/orderentry/orderentryaction'
import { setScripInfo } from '../../actions/scripdetails/scripdetailsaction'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import {orderEntryTypes, MyBasketcolumn} from "../../common/constants"
import { Workbook } from '../../common/exporter'
import { progressBar } from '../../controls/progress'


const FilterInput = (props) => {
    return (

        <div className={"watch-filter-parent "+(props.id === "exc" && "show")} >
            <input className="watch-input-filter" type="text"
                placeholder={props.placeholder}
                onChange={(e) => props.onChangeFilter(e.target.value.toLowerCase(), props.id)} />
        </div>
    )
}

class BasketOrder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inProgress: false,
            error: '',
            tabs: ["Ongoing IPO's", "Forthcoming IPO's"],
            selectedBasketOrder:'',
            selectedsegment: "Ongoing IPO's",
            orderData: [],
            selectedsymbolstodelete: [],
            showFilter: false,
            selecttodelete: false,   
            showOrderdialog:false,
            showDialog:false,
            message:'Your basket is execute sucessfully.',
            showDeleteBasketDialog:false,
            showCreateNewBasketDialog:false,
            showExecuteBasketDialog:false,
            showExecutionLog:false,
            afterRenameOrderBasketCallback:undefined,
            afterDeleteBasketCallback:undefined, 
            deleteSelectedId:[],
            ExcutionFlg:'',
            GetRequestStatus:'',
            willFlag:'456',
            filterIds: {
                sym: "", exc: "", ltp: "",
                chng: "", chngPer: "", bidPrice: "", askPrice: "",
                bidQty: "", askQty: "", ltq: "", totBuyQty: "", totSellQty: ""
            },
        }
        this.onClickModify = this.onClickModify.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
      
        // this.askAddScrips=this.askAddScrips.bind(this);   
    }

    onClickModify(row) {        
        var orderdetails = this.getOrderDetails(row);
        this.props.showOrderDialog(orderdetails);
    }
    getOrderDetails(row){
        var item = row;
        var orderdetails = {
            orderentrytype: orderEntryTypes.MODIFY,
            basketFlagNew:'mybasket',
            action: item.BUY_SELL_IND,
            id: item.BASKET_SYMBOL_ID,
            sprip: item.DISP_SYM,
            symobj: {
                assetClass: item.ASSET_CLASS,
                exc: item.EXCH_ID,
                exc_seg: item.EXCH_SEG,
                expiry: item.EXPIRY_DATE == "" ? '' : item.EXPIRY_DATE,
                id: item.SYMBOL,
                instr: item.INST_TYPE,
                optType:item.OPT_TYPE == "" ? '' : item.OPT_TYPE,
                strike: item.STRIKE_PRICE,
                trExc: item.TR_EXCH,
                trSymbol: item.TR_SYMBOL,
                xSym: item.XSYM
            },
            displaySym: item.TR_SYMBOL,
            company: '',
            last: "0.00",
            change: "0.00",
            changeper: "0.00",

            // fill other details
            correspondingorder: {
                
                orderid: item.BASKET_SYMBOL_ID,
                price: item.LIMIT_PRICE,
                quantity: item.QTY,
                producttype: item.SEGMENT_TYPE,
                ordertype: item.ORD_TYPE,
                validitytype: item.DAY_IOC_ORD,
                triggerprice: item.TRIGGER_PRICE,
                discquantity: item.DISC_QTY,
                isAmo: false,
                isAmo:false,
                tradedQty: '',
                isinDp:item.ISINDP,
                basketFlagNew:'mybasket'
            },
            
        };
        return orderdetails;
       
    }

    onClickDelete(symbolId) {
        this.setState({
            selectedsymbolstodelete:[symbolId]
        }, () =>{
            this.DeleteBasketSymbolRequest(this.state.selectedsymbolstodelete)
        })
        
    }

    onChangeFilter(value, filter) {
    }
       
    toggleFilterScrips() {
        
        this.setState({ showFilter: this.state.showFilter ? false : true });
    }

    askRenameBasket(callback){       
        this.refs.renamebasketorderdialog.setInputText(this.state.selectedBasketOrder);
        this.setState({ afterRenameOrderBasketCallback: callback, showOrderdialog: true });
    }
    
    askDeleteBasket(callback){
        this.setState({ 
            afterDeleteBasketCallback: callback, 
            showDeleteBasketDialog: true        
        });
    }

    toggleDeletingScrips(selected) {
        this.setState({ selecttodelete: selected, selectedsymbolstodelete: [] });
    }

    symbolSelectionChanged(e) {
        if (e.target.checked) {
            this.state.selectedsymbolstodelete.push(e.target.getAttribute('data-tag'));
        }
        else {
            this.state.selectedsymbolstodelete.pop(e.target.getAttribute('data-tag'));
        }
    }

    // dowload file

    onWatchlistSelected(filename, filetype) {
        var list = this.state.orderData.slice()
        list.forEach(element => {
            element["scrip"] = element.TR_SYMBOL,
            element["exc"] = element.EXCH_ID
        });
        
        this.setState({ exportabledata: list }, () => {
            this.refs.workbook.download(filename, filetype);
        });

    }

    deleteBasketOrderSymbols(selectedSymbolsToDelete, selectedWatchlist) {
        this.DeleteBasketSymbolRequest(selectedSymbolsToDelete, selectedWatchlist);
    }

    DeleteBasketSymbolRequest(selectedSymbolsToDelete, selectedWatchlist){
        var requestData = {
            request: {
                data: {
                    id: selectedSymbolsToDelete
                    // name: basketname
                }
            }
        };
        DeleteSymbolrequest(requestData, this.parseDeleteSymbolRsponse.bind(this, selectedSymbolsToDelete), this.parseDeleteSymbolError.bind(this))
    }
    parseDeleteSymbolRsponse(selectedSymbolsToDelete, responseData){
        this.setState({
            TrueFalse: responseData.response.data.data
        },() => {
            this.state.TrueFalse == true ? selectedSymbolsToDelete.length == 1 ?  
            this.state.message = 'Your symbol is deleted sucessfully.' : this.state.message = 'Your symbols are deleted sucessfully.' : ''
            this.ontoggleButton();
        })
    }
    parseDeleteSymbolError(){

    }

   

    cancelRenameBasket() {
        this.setState({ showOrderdialog: false });
    }

    confirmRenameBasket() {
        var name = this.refs.renamebasketorderdialog.getInputText();
        this.renameBasket(name);
    }
// rename basket
    renameBasket(basketname) {
        var requestData = {
            request: {
                data: {
                    id: this.state.deleteSelectedId,
                    name: basketname
                }
            }
        };
        RenameBasketrequest(requestData, this.parseRenameRsponse.bind(this), this.parseRenameError.bind(this))
    }
    parseRenameRsponse(responseData){
        this.setState({
            message: responseData.response.data.data
        }, () =>{
            this.ontoggleButton();
        })
        
    }

    parseRenameError(error){
        this.setState({
            message:error.message
        })
        this.ontoggleButton();
    }


    onSelectedBasketOrder(selected, index, id, status){
        this.refs.renamebasketorderdialog.setInputText(selected);
        this.setState({
            selectedBasketOrder:selected,
            deleteSelectedId:id.id,
            GetRequestStatus: status
        }, () => {
            var requestData = {
                request: {
                    data: {
                        id: this.state.deleteSelectedId
                    }
                }
            };
            
            {this.state.GetRequestStatus == 'N' ?
                GetBasketSymbolBasketrequest(requestData, this.parseGetSymbolresponse.bind(this), this.parseGetSymbolError.bind(this))
            :
                BasketExecutionLogrequest(requestData, this.parseGetSymbolresponse.bind(this), this.parseGetSymbolError.bind(this))
            }
        }) 
    }
    parseGetSymbolresponse(responseData){
        this.setState({
            orderData: responseData.response.data.data,
            selectedsymbolstodelete:[]
        }, () => {
        })
    }
    parseGetSymbolError(error){
        this.setState({
            orderData:[]
        })
    }
// end symbol selection
    confirmDeleteBasket() {
        this.deleteBasket(this.state.selectedBasketOrder, this.state.deleteSelectedId);
        // this.DeleteBasketSymbolRequest();
        
    }

    cancelDeleteBasket() {        
        this.setState({ showDeleteBasketDialog: false });
    }

    deleteBasket() {
        var requestData = {
            request: {
                data: {
                    id: this.state.deleteSelectedId
                }
            }
        };
        getDeleteBasketequest(requestData, this.parseDeleteesponse.bind(this), this.parseDeleteError.bind(this))
    }

    parseDeleteesponse(responseData){
        this.setState({
            message : responseData.response.data.data,
            selectedBasketOrder:''
        })
        this.ontoggleButton();
        
    }

    parseDeleteError(error){
        this.setState({
            message:error.message
        },()=>{
        })
        this.ontoggleButton();
    }

   
//-------------------create basket-------------------
    onClickCreateBasket(callback, flag) {
        this.setState({ 
            showCreateNewBasketDialog: true,
            Dropdownflag: flag
        });
        this.refs.createbasketdialog.setInputText(callback);
    }

    cancelCreateBasket(){
        this.setState({ showCreateNewBasketDialog: false });
    }


    confirmCreateBasket(name){
        var name = this.refs.createbasketdialog.getInputText();
        this.confirmcreate(name);        
    }

    confirmcreate(basketname){
        var requestData = {
            request: {
                data: {
                    name: basketname
                }
            }
        };
        getCreateBasketequest(requestData, this.parseCreateresponse.bind(this), this.parseCreateError.bind(this))
    }

    parseCreateresponse(responseData){
        this.setState({
            message : responseData.response.data.data
        })

        this.ontoggleButton();
    }

    parseCreateError(error){
        this.setState({
            message:error.message
        },()=>{
        })
        this.ontoggleButton();
    }

    ontoggleButton(){
        this.setState({
                showDialog:true,
                showOrderdialog:false,
                showCreateNewBasketDialog:false,
                showDeleteBasketDialog:false,
                showExecuteBasketDialog: false,
                // orderData:[],
                message:<p>{this.state.message}</p>
        }, () => {
        });
    }
    closePopup() {
        this.setState({
            showDialog: false,
            error:false                  
        }, () => {
            this.OnClickFinal();
        });
    }

    OnClickFinal(){
        this.setState({
            willFlag:'123'
        },() => {
        })
    }

    ResetProps(){
         this.setState({
            willFlag:'456'
         }, () => {
         })
     }


//--------------exicute basket-----------------

    onClickBasketExecution(flag, listdata){
        console.log("flag" , flag)
        if(listdata == 0){
            this.setState({
                orderData:[],
                ExcutionFlg : flag,                
            })
        }
        this.setState({
            ExcutionFlg : flag,
            selectedBasketOrder: ''
        })
        this.setState({showExecutionLog:true});
     }
 
     onClickCloseExecutionLog(){
         this.setState({showExecutionLog:false});
     }

    onClickExecuteBasket(){
        this.setState({ showExecuteBasketDialog: true });
    }

    cancelExecuteBasket(){
        this.setState({ showExecuteBasketDialog: false });
    }

    confirmExecuteBasket() {
        this.setState({
            selectedBasketOrder:'',
            message:'Your basket is execute sucessfully.'
        }, () =>{
            this.ontoggleButton();
        })
        
    }

    render() {
        return (
            <BasketOrderBaseWidget toggleFilterScrips={this.toggleFilterScrips.bind(this)} askRenameBasket={this.askRenameBasket.bind(this)} askDeleteBasket={this.askDeleteBasket.bind(this)}
            selectedSymbolsToDelete={this.state.selectedsymbolstodelete} toggleDeletingScrips={this.toggleDeletingScrips.bind(this)} deleteBasketOrderSymbols={this.deleteBasketOrderSymbols.bind(this)}
            onSelectedBasketOrder={this.onSelectedBasketOrder.bind(this)} onClickBasketExecution={this.onClickBasketExecution.bind(this)} onClickCreateBasket={this.onClickCreateBasket.bind(this)}
            onClickExecuteBasket={this.onClickExecuteBasket.bind(this)}  OnClickFinal={this.state.willFlag} ResetProps={this.ResetProps.bind(this)} onWatchlistSelected={this.onWatchlistSelected.bind(this)}
            setClick={click => this.clickChild = click} setClick2={click => this.clickChild2 = click} orderData = {this.state.orderData}
            >
            <div className="row products-header">
                    <div className="col">
                        SR.NO
                    </div>
                    <div className="col-1">
                        SYMBOL
                    </div>
                    <div className="col">
                        EXCHANGE 
                    </div>
                    <div className="col">
                        BUY/SELL
                    </div>
                    <div className="col-1">
                        PRODUCT
                    </div>
                    <div className="col text-right">
                        ORDER PRICE
                    </div>
                    <div className="col text-right">
                        QTY/LOT
                    </div>
                    <div className="col text-right">
                        DISC./DISC.LOT
                    </div>
                    <div className="col text-right">
                        TRIGGER PRICE SL
                    </div>
                    <div className="col text-right">
                        GOOD TILL
                    </div>
                    {this.state.ExcutionFlg == 'EXCUTIONLOG' ? 
                    <div className="col-3">
                        <div className="row">
                            <div className="col-6 text-right">
                                ORDER STATUS
                            </div>
                            <div className="col-6 text-right">
                                ORDER RESPONSE
                            </div>
                        </div>
                    </div>
                    : 
                    <div className="col-3">
                        <div className="row">
                            <div className="col-4 text-right">
                                ORDER TYPE
                            </div>
                            <div className="col-8 text-right">
                                
                            </div>
                        </div>
                    
                    </div>
                    }
                </div>
                {this.state.selectedBasketOrder == '' && this.state.ExcutionFlg == '' ?
                    <div className="row">
                        <div className="col-12 text-center">
                            <p style={{marginBottom:'5px'}}>You don't have any Basket.</p>
                            <p>Please create basket to continue</p>
                            <button type="button" className="btn-footer order-place"
                                onClick={() => this.clickChild2()}
                            ><i className="fa fa-plus watchlist-title-icon" /> CREATE A NEW BASKET</button>
                        </div>
                    </div>
                :
                <div>
                {this.state.orderData.length == 0 ? 
                    <div className="row">
                        {this.state.ExcutionFlg == "EXCUTIONLOG" ? 
                            <div className="col-12 text-center">Today you don't have any executed basket.</div>  
                        :
                        <div className="col-12 text-center">
                            <p style={{marginBottom:'5px'}}>You don't have any script in this basket.</p>
                            <p>Please add script to continue</p>
                            <button type="button" className="btn-footer order-place" onClick={() => this.clickChild()}><i className="fa fa-plus watchlist-title-icon" /> ADD TO BASKET</button>
                        </div>
                    }   
                        
                        
                    </div>
                : 
                this.state.inProgress ? progressBar() : [
                <div className="orderbookbase-content-body" style={{overflowY:'scroll'}}>
                   
                    
                {this.state.orderData.map((row, key) => {
                    return [
                        <div className="row fillwidth contentpanel" >
                            <div className="row fillwidth contentpanel products-row">
                                <div className="col">
                                    <div style={{ marginRight: "5px" }}>
                                        {this.state.ExcutionFlg == "EXCUTIONLOG" ? '' :
                                        <div>
                                        {this.state.selecttodelete ?
                                            
                                            <div>
                                                 {this.state.selectedsymbolstodelete.length == '0' ?
                                                 <div className="custom-check watch-delete-check">
                                                    <input className="custom-checkbox" type="checkbox" data-tag={row.BASKET_SYMBOL_ID} onChange={this.symbolSelectionChanged.bind(this)} />
                                                   </div>
                                                :
                                                <label className="custom-check watch-delete-check">
                                                    <input className="custom-checkbox" isd type="checkbox" data-tag={row.BASKET_SYMBOL_ID} onChange={this.symbolSelectionChanged.bind(this)} />
                                                </label>
                                                }
                                            </div>
                                            :
                                            ""
                                        }
                                        </div>}
                                        <div style={{ paddingLeft: "15px" }}>
                                            {key+1}                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="col-1">
                                    {row.DISP_SYM}
                                </div>
                                <div className="col">
                                    {row.EXCH_ID}
                                </div>
                                <div className="col">
                                    {row.BUY_SELL_IND}
                                </div>
                                <div className="col-1" style={{textTransform : 'uppercase'}}>
                                    {row.SEGMENT_TYPE}
                                </div>
                                <div className="col text-right">
                                    {row.LIMIT_PRICE}
                                </div>
                                <div className="col text-right">
                                    {row.QTY}
                                </div>
                                <div className="col text-right">
                                    {row.DISC_QTY}
                                </div>
                                <div className="col text-right">
                                    {row.TRIGGER_PRICE}
                                </div>
                                <div className="col text-right" style={{textTransform : 'uppercase'}}>
                                    {row.DAY_IOC_ORD}
                                </div>     
                                {this.state.ExcutionFlg == 'EXCUTIONLOG' ?
                                    <div className="col-3">
                                        <div className="row">
                                            <div className="col-6 text-right">
                                                {row.ORDER_STATUS}
                                            </div>
                                            <div className="col-6 text-right">
                                                {row.ORDER_RESPONSE}
                                            </div>
                                        </div>
                                        
                                    </div>
                                :
                                    <div className="col-3">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="col-12 text-right" style={{textTransform : 'uppercase'}}>
                                                    {row.ORD_TYPE}
                                                </div>
                                            </div>
                                            <div className="col-8">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <button type="button" style={{padding:'1px 18px'}} className="form-control myorder-btn modify-btn" data-tag="tag" onClick={this.onClickModify.bind(this, row)}>MODIFY</button>
                                                    </div>
                                                    <div className="col-6">
                                                        <button type="button" className="form-control myorder-btn modify-btn" style={{padding:'1px 18px', backgroundColor:'#E5283F'}} data-tag="tag" onClick={this.onClickDelete.bind(this, row.BASKET_SYMBOL_ID)}>DELETE</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                 } 
                            </div>
                            <div className="hdivider" />
                        </div>
                    ];
                }, this)}

                </div>
                   
                
            ]
            
            }
            </div>
            }

                <RenameBasketOrderPopup id="renamebasketorderdialog" ref="renamebasketorderdialog" title="RENAME BASKET" message="" type="textinput" selectedOrderBasket={this.state.selectedBasketOrder}
                    showDialog={this.state.showOrderdialog} negativebuttontext="CANCEL" possitivebuttontext="RENAME"
                    onNegativeButtonClick={this.cancelRenameBasket.bind(this)} onPossitiveButtonClick={this.confirmRenameBasket.bind(this)} />

                <DeleteBasketPopup id="deletebasketdialog" ref="deletebasketdialog" title="DELETE BASKET - " selectedOrderBasket={this.state.selectedBasketOrder} message="Do you want to delete basket?" type="message"
                                showDialog={this.state.showDeleteBasketDialog} negativebuttontext="CANCEL" possitivebuttontext="DELETE"
                                onNegativeButtonClick={this.cancelDeleteBasket.bind(this)}
                                onPossitiveButtonClick={this.confirmDeleteBasket.bind(this)} /> 

                <CreateNewBasketPopup id="createbasketdialog" ref="createbasketdialog" title="CREATE NEW BASKET" message="Create new basket to add your order."
                                showDialog={this.state.showCreateNewBasketDialog} negativebuttontext="CANCEL" possitivebuttontext="CREATE"
                                onNegativeButtonClick={this.cancelCreateBasket.bind(this)} 
                                onPossitiveButtonClick={this.confirmCreateBasket.bind(this)} 
                                /> 
                
                <ExecuteBasketPopup id="executebasketdialog" ref="executebasketdialog" title="EXECUTE BASKET - " selectedOrderBasket={this.state.selectedBasketOrder}
                                showDialog={this.state.showExecuteBasketDialog} negativebuttontext="CANCEL" possitivebuttontext="CONFIRM"
                                onNegativeButtonClick={this.cancelExecuteBasket.bind(this)} onPossitiveButtonClick={this.confirmExecuteBasket.bind(this)} 
                                ExecuteData={this.state.orderData}
                                SelectedId ={this.state.deleteSelectedId}
                                />  
                {/* <BasketExecutionLog show={this.state.showExecutionLog} onClickCloseExecutionLog={this.onClickCloseExecutionLog.bind(this)}/> */}
               
                {this.state.showDialog?
                <Dialog header="Message" 
                    message={this.state.message}
                    negativeButtonName="Ok"
                    showDialog={this.state.showDialog}
                    negativeClick={this.closePopup.bind(this)} /> :''}
                    
                    {/* workbook */}
                    <Workbook ref="workbook">
                    <Workbook.Sheet data={this.state.orderData} name="MyTrades">                      
                        {MyBasketcolumn.map(function (row, key) {
                            return (
                                <Workbook.Column label={row.name} value={row.apikey} key={key} />
                            );
                        }, this)}
                    </Workbook.Sheet>
                </Workbook>
            </BasketOrderBaseWidget>
            
            
           
        )        
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showOrderDialog: (data) => showOrderDialog(dispatch, data),
        showCancelOrderDialog: (data) => showCancelOrderDialog(dispatch, data),
        showCancelOrderDialogCallback: (callback) => showCancelOrderDialogCallback(dispatch, callback),
        setScripInfo: (data) => setScripInfo(dispatch, data),
        onOrderSuccess: (callback) => onOrderSuccess(dispatch, callback)
    };
};

export default withRouter(connect(null, mapDispatchToProps)(BasketOrder))
// export default BasketOrder;
