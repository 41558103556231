import { StoreKeys } from '../common/constants';

const intialState = {
    activeWidget: 'ALL',
    activeWidgetState: undefined,
    showChartDialog: false,
    symbol: {},
    chart1: undefined,
    chart2: undefined,
    chart3: undefined,
    chart4: undefined
}

const charts = (state = intialState, action) => {
    if (action.type === StoreKeys.CHARTS_ACTIVE_WIDGET) {
        return Object.assign({}, state, { activeWidget: action.payload });
    } else if (action.type === StoreKeys.CHARTS_ACTIVE_WIDGET_STATE) {
        return Object.assign({}, state, { activeWidgetState: action.payload });
    } else if (action.type === StoreKeys.CHART_SYMBOL) {
        return Object.assign({}, state, { symbol: action.payload });
    } else if (action.type === StoreKeys.SHOW_CHART_DIALOG) {
        return Object.assign({}, state, { showChartDialog: action.payload });
    } else if (action.type === StoreKeys.CHART_SELECT_LIST) {
        if (action.payload.type === 'Chart 1')
            return Object.assign({}, state, { chart1: action.payload.sym });
        if (action.payload.type === 'Chart 2')
            return Object.assign({}, state, { chart2: action.payload.sym });
        if (action.payload.type === 'Chart 3')
            return Object.assign({}, state, { chart3: action.payload.sym });
        if (action.payload.type === 'Chart 4')
            return Object.assign({}, state, { chart4: action.payload.sym });
    } else if (action.type ===  StoreKeys.SCRIP_OPTION_CHAIN) {
        return Object.assign({}, {}, { activeWidget : action.payload }) ; 
    } else {
        return state;
    }
};

export default charts;