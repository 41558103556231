import React, { Component } from "react";
import { connect } from "react-redux";
import { CIQ } from "chartiq/js/chartiq";
import StudyUI from "../components/UI/StudyUI";

class StudyBaseView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStudyModal: false,
      studyList: CIQ.Studies.studyLibrary,
      studyHelper: null,
      studyOverlay: {
        show: false,
        top: 0,
        left: 0
      }
    };
  }

  toggleOverlay(params) {
    var flipOverlay = !this.state.studyOverlay.show;
    this.setState({
      studyOverlay: {
        show: flipOverlay,
        top: params.stx.cy,
        left: params.stx.cx
      },
      studyHelper: flipOverlay ? new CIQ.Studies.DialogHelper(params) : null
    });
  }

  addStudy(ciq, study) {
    var studyLookup = {};
    for (let libraryEntry in this.state.studyList) {
      studyLookup[this.state.studyList[libraryEntry].name] = libraryEntry;
    }
    CIQ.Studies.addStudy(ciq, studyLookup[study.name]);
  }

  updateStudy(inputs, outputs, parameters) {
    this.state.studyHelper.updateStudy({
      inputs: inputs,
      outputs: outputs,
      parameters: parameters
    });
    this.setState({
      showStudyModal: false,
      studyOverlay: {
        show: false,
        top: 0,
        left: 0
      },
      studyHelper: null
    });
  }

  removeStudy(ciq, study) {
    let layoutStudies = this.props.ciq.layout.studies;
    if (this.state.studyHelper !== null) {
      CIQ.Studies.removeStudy(
        this.state.studyHelper.stx,
        this.state.studyHelper.sd
      );
    } else if (study && ciq) {
      let sd = layoutStudies[study];
      CIQ.Studies.removeStudy(
        ciq,
        sd
      );
    }

    this.setState({
      studyOverlay: {
        show: false,
        top: 0,
        left: 0
      }
    });
  }

  clearAllStudies() {
    let layoutStudies = this.props.ciq.layout.studies;
    if (!layoutStudies) return;
    for (let study in layoutStudies) {
      let sd = layoutStudies[study];
      CIQ.Studies.removeStudy(this.props.ciq, sd);
    }
    this.setState({
      studyOverlay: {
        show: false,
        top: 0,
        left: 0
      }
    });
  }

  openStudyModal(params) {
    //console.log(params)
    var needsStudyHelper = params.hasOwnProperty("stx");
    this.setState({
      showStudyModal: true,
      studyHelper: needsStudyHelper
        ? new CIQ.Studies.DialogHelper(params)
        : this.state.studyHelper,
      studyOverlay: {
        show: false,
        top: 0,
        left: 0
      }
    });
  }

  closeStudyModal() {
    this.setState({
      showStudyModal: false,
      studyOverlay: {
        show: false,
        top: 0,
        left: 0
      },
      studyHelper: null
    });
  }

  render() {
    return (
      <StudyUI
        studyOverlay={this.state.studyOverlay}
        showStudyModal={this.state.showStudyModal}
        studyHelper={this.state.studyHelper}
        studyList={this.state.studyList}
        openStudyModal={this.openStudyModal.bind(this)}
        closeStudyModal={this.closeStudyModal.bind(this)}
        toggleOverlay={this.toggleOverlay.bind(this)}
        addStudy={this.addStudy.bind(this)}
        updateStudy={this.updateStudy.bind(this)}
        removeStudy={this.removeStudy.bind(this)}
        clearAllStudies={this.clearAllStudies.bind(this)}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {

  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudyBaseView);
