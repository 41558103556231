import React from 'react';
import { connect } from 'react-redux';
import { verifyOTP } from '../../actions/login/preloginrequests';
import { showErrorDialog } from '../../actions/orderentry/orderentryaction';
import { commonDialog, toggleDialog } from '../../actions/common';


class SetOTPPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            digit1: '',
            digit2: '',
            digit3: '',
            digit4: '',
            digit5: '',
            digit6: '',
            resendOTP: false,
            resendCount: 0,
            countDown: 30
        }
    }

    componentDidMount() {
        if (this.digit1Ref)
            this.digit1Ref.focus();
    }

    onInputChange(id, e) {
        switch (id) {
            case "digit1":
                this.setState({ digit1: e.target.value }, () => {
                    if (this.state.digit1 !== '') {
                        this.digit2Ref.focus();
                    }
                });
                break;
            case "digit2":
                this.setState({ digit2: e.target.value }, () => {
                    if (this.state.digit2 !== '') {
                        this.digit3Ref.focus();
                    }
                });
                break;
            case "digit3":
                this.setState({ digit3: e.target.value }, () => {
                    if (this.state.digit3 !== '') {
                        this.digit4Ref.focus();
                    }
                });
                break;
            case "digit4":
                this.setState({ digit4: e.target.value }, () => {
                    if (this.props.dormantHandler && this.state.digit4 !== '') {
                        this.digit5Ref.focus();
                    }
                });
                break;
            case "digit5":
                this.setState({ digit5: e.target.value }, () => {
                    if (this.state.digit5 !== '') {
                        this.digit6Ref.focus();
                    }
                });
                break;
            case "digit6":
                this.setState({ digit6: e.target.value });
                break;
            default: break;
        }
    }

    onKeyPress(name, e) {
        var otp = this.state.digit1 + this.state.digit2 + this.state.digit3 + this.state.digit4;
        if (name === "digit4" && e.key === 'Enter' && otp.length === 4) {
            this.placeVerifyOTPRequest();
        }
        if (e.key === 'Backspace') {
            switch (name) {
                case "digit2":
                    if (this.state.digit2 === '')
                        this.digit1Ref.focus();
                    break;
                case "digit3":
                    if (this.state.digit3 === '')
                        this.digit2Ref.focus();
                    break;
                case "digit4":
                    if (this.state.digit4 === '')
                        this.digit3Ref.focus();
                    break;
                case "digit5":
                    if (this.state.digit5 === '')
                        this.digit4Ref.focus();
                    break;
                case "digit6":
                    if (this.state.digit6 === '')
                        this.digit5Ref.focus();
                    break;
                default: break;
            }
        }
        this.props.clearMsg && this.props.clearMsg();
        this.setState({ resendOTP: false })
    }

    onResendOTPClicked() {
        if (this.state.resendCount + 1 >= 3) {
            this.interval = setInterval(this.timerFunction.bind(this), 1000);
        }
        this.setState({ resendOTP: true, resendCount: this.state.resendCount + 1 }, () => {
            this.props.onResendOTPClicked();
        });
    }

    timerFunction() {
        this.setState({ countDown: this.state.countDown - 1 }, () => {
            if (this.state.countDown === 0) {
                this.setState({ countDown: 30 })
                clearInterval(this.interval);
            }
        })
    }

    otpValidation() {
        var otp = this.state.digit1 + this.state.digit2 + this.state.digit3 + this.state.digit4;
        if (this.props.dormantHandler) {
            otp = otp + this.state.digit5 + this.state.digit6;
        }
        var otplength = this.props.dormantHandler ? otp.length !== 6 : otp.length !== 4;
        if (otplength) {
            this.props.commonDialog({
                showDialog: true, type: "small", closeBtn: false, message: "Please enter valid OTP", header: "Error",
                buttons: [{
                    name: "close", action: function () {
                        this.setState({ digit1: "", digit2: "", digit3: "", digit4: "",digit5:"",digit6:"" });
                        this.props.toggleDialog(false);
                    }.bind(this)
                }]
            });
            return false;
        } else {
            return true;
        }
    }

    getOTP() {
        return this.props.dormantHandler ? this.state.digit1 + this.state.digit2 + this.state.digit3 + this.state.digit4 + this.state.digit5 + this.state.digit6
            : this.state.digit1 + this.state.digit2 + this.state.digit3 + this.state.digit4;
    }

    placeVerifyOTPRequest() {
        if (this.otpValidation()) {
            this.setState({ inProgress: true, error: '' });
            var requestData;
            if (this.props.unlockaccount) {
                requestData = {
                    request: {
                        data: {
                            userId: this.props.userid,
                            otp: this.state.digit1 + this.state.digit2 + this.state.digit3 + this.state.digit4,
                            type: "unlock"
                        }
                    }
                };
            }
            else {
                requestData = {
                    request: {
                        data: {
                            userId: this.props.userid,
                            otp: this.state.digit1 + this.state.digit2 + this.state.digit3 + this.state.digit4
                        }
                    }
                };
            }
            verifyOTP(requestData, this.parseVerifyOTPResponse.bind(this), this.parseVerifyOTPError.bind(this));
        }
    }

    parseVerifyOTPResponse(responseData) {
        this.setState({ inProgress: false, resendOTP: false }, () => {
            this.props.handler();
        });
    }

    parseVerifyOTPError(error) {
        this.setState({ inProgress: false, error: error.message, resendOTP: false, digit1: "", digit2: "", digit3: "", digit4: "" }, () => {
            this.props.showErrorDialog({ header: "Error", message: error.message });
        });
    }


    render() {
        return (
            <div className="fillheight">
                <div>
                    <div style={{ marginTop: 30, color: '#000' }}>
                        <div className="enter-otp">Enter OTP</div>
                        <div style={{ textAlign: 'left', fontSize: 12, color: '#1EBF8A' }}>
                            We have sent an OTP to your registered mobile number. Enter the OTP to continue.
                        </div>
                    </div>
                    <div className="otp-mobile" style={{ marginTop: 30 }}>
                        <div style={{ display: 'flex' }}>
                            <input className="input-square" maxlength="1" type="tel" name="digit1"
                                onChange={e => this.onInputChange("digit1", e)}
                                ref={(id) => { this.digit1Ref = id; }} value={this.state.digit1}
                            />
                            <input className="input-square" maxlength="1" type="tel" name="digit2"
                                onChange={e => this.onInputChange("digit2", e)} onKeyDown={e => this.onKeyPress("digit2", e)}
                                ref={(id) => { this.digit2Ref = id; }} value={this.state.digit2}
                            />
                            <input className="input-square" maxlength="1" type="tel" name="digit3"
                                onChange={e => this.onInputChange("digit3", e)} onKeyDown={e => this.onKeyPress("digit3", e)}
                                ref={(id) => { this.digit3Ref = id; }} value={this.state.digit3}
                            />
                            <input className="input-square" maxlength="1" type="tel" name="digit4"
                                onChange={e => this.onInputChange("digit4", e)} onKeyDown={e => this.onKeyPress("digit4", e)}
                                ref={(id) => { this.digit4Ref = id; }} value={this.state.digit4}
                            />
                            {this.props.dormantHandler && [
                                <input className="input-square" maxlength="1" type="tel" name="digit5"
                                    onChange={e => this.onInputChange("digit5", e)} onKeyDown={e => this.onKeyPress("digit5", e)}
                                    ref={(id) => { this.digit5Ref = id; }} value={this.state.digit5}
                                />,
                                <input className="input-square" maxlength="1" type="tel" name="digit6"
                                    onChange={e => this.onInputChange("digit6", e)} onKeyDown={e => this.onKeyPress("digit6", e)}
                                    ref={(id) => { this.digit6Ref = id; }} value={this.state.digit6}
                                />
                            ]
                            }
                        </div>
                        <div style={{ textAlign: "left", marginBottom: "20px" }} >
                            <a className={"btn " + (this.state.countDown !== 30 && "disabled")} onClick={() => this.onResendOTPClicked()}>
                                <span style={{ color: "#1EBF8A" }}>Resend OTP</span></a>
                            {this.state.resendOTP ? <div style={{ textAlign: 'left', color: 'red' }}><b>{this.props.resendMsg ? this.props.resendMsg : "OTP Resend Successfully"}</b></div> : ''}
                            {this.state.countDown !== 30 ? <div style={{ textAlign: 'left' }}><b>{"Resend enable after " + this.state.countDown + " seconds"}</b></div> : ''}

                        </div>
                        {this.props.dormantHandler ? <button type="button" className="form-control login_btn mobile-btn" onClick={() => this.props.dormantHandler()}>VERIFY</button> :
                            <button type="button" className="form-control login_btn " onClick={this.props.onboarding ? this.props.handler : this.placeVerifyOTPRequest.bind(this)}>CONTINUE</button>}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showErrorDialog: (errordata) => showErrorDialog(dispatch, errordata),
        commonDialog: (payload) => commonDialog(dispatch, payload),
        toggleDialog: (payload) => toggleDialog(payload, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(SetOTPPage);