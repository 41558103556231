import React, { Component } from 'react';
import TabbarBaseWidget from '../base/TabbarBaseWidget';
import { getNotifications } from '../../actions/alerts&notifications/notifications'
import AlertPopUp from '../../controls/notifications/AlertPopUp'
import { progressBar } from '../../controls/progress'

// const progressindicator = (
//     <div className="widget-progress-holder">
//         <svg class="circular-progress" viewBox="25 25 50 50">
//             <circle class="circular-progress_path" cx="50" cy="50" r="20"
//                 fill="none" stroke-width="2" stroke-miterlimit="10" />
//         </svg>
//     </div>
// );
const Order = props => {
    return (
        props.data.map(el =>
            <div className="row contentpanel np-header-row">
                <div className="ord-notify">
                    {/* <div className='buy-bar' /> */}
                    {el.alertMessage}
                </div>
                <div className="time-data">
                    <img src='assets/svg/clock.svg' className='icon-clk' alt="clock"/>{el.alertTime}</div>
            </div>
        )
    );
}
const News = props => {
    return (
        props.data.map(el =>
            <div className="row contentpanel np-header-row">
                <div className="notify"> Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker </div>
                <div className="time-data">
                    <img src='assets/svg/clock.svg' className='icon-clk' alt="clock"/> Lorem Ipsum passages, and more recently</div>
            </div>
        )
    );
}
const Alerts = props => {
    return (
        <div className="mt-np-tab-content-parent expanded">
            <div id="equity" className="np-tab-content tab-pane in active">
                <div className="fillhieght" style={{ marginLeft: 15 }}>
                    <div className="row contentpanel">
                        <div className="row contentpanel alrt-clr" style={{ marginLeft: 10, marginTop: 20 }}>
                            <div className="col-2 text-left mt-row-header lite">SYMBOL</div>
                            <div className="col-2  mt-row-header mt-even-row lite">COMPANY NAME</div>
                            <div className="col-4  mt-row-header lite">ALERT</div>
                        </div>
                        <div className="row contentpanel" style={{ marginLeft: 10, marginTop: 20 }}>
                            <div className="col-2 text-left mt-row-header lite" style={{ color: '#fff', fontSize: 15 }}>ICICIBANK<span className='alrt-clr' style={{ marginLeft: 15 }}> NSE</span></div>
                            <div className="col-2  mt-row-header mt-even-row lite alrt-clr">ICIC Bank Limited </div>
                            <div className="col-3  mt-row-header lite"> Lorem Ipsum passages, and more recently</div>
                            <div className="col-2  mt-row-header lite alrt-btn-container">
                                <button type='button' className='edit-alrt-btn'>Edit alert</button>
                                <button type='button' className='delete-alrt-btn'>Delete</button>
                            </div>
                        </div>
                        <div className="hdivider" style={{ marginBottom: 0, marginTop: 10 }} />

                        <div className="row contentpanel" style={{ marginLeft: 10, marginTop: 20 }}>
                            <div className="col-2 text-left mt-row-header lite" style={{ color: '#fff', fontSize: 15 }}>ICICIBANK<span className='alrt-clr' style={{ marginLeft: 15 }}> NSE</span></div>
                            <div className="col-2  mt-row-header mt-even-row lite alrt-clr">ICIC Bank Limited </div>
                            <div className="col-3  mt-row-header lite"> Lorem Ipsum passages, and more recently</div>
                            <div className="col-2  mt-row-header lite alrt-btn-container">
                                <button type='button' className='edit-alrt-btn'>Edit alert</button>
                                <button type='button' className='delete-alrt-btn'>Delete</button>
                            </div>
                        </div>
                        <div className="hdivider" style={{ marginBottom: 0, marginTop: 10 }} />

                        <div className="row contentpanel" style={{ marginLeft: 10, marginTop: 20 }}>
                            <div className="col-2 text-left mt-row-header lite" style={{ color: '#fff', fontSize: 15 }}>ICICIBANK<span className='alrt-clr' style={{ marginLeft: 15 }}> NSE</span></div>
                            <div className="col-2  mt-row-header mt-even-row lite alrt-clr">ICIC Bank Limited </div>
                            <div className="col-3  mt-row-header lite"> Lorem Ipsum passages, and more recently</div>
                            <div className="col-2  mt-row-header lite alrt-btn-container">
                                <button type='button' className='edit-alrt-btn'>Edit alert</button>
                                <button type='button' className='delete-alrt-btn'>Delete</button>
                            </div>
                        </div>
                        <div className="hdivider" style={{ marginBottom: 0, marginTop: 10 }} />
                    </div>
                </div>
            </div>
        </div>
    );
}
const Ideas = props => {
    return (
        props.data.map(el =>
            <div className="row contentpanel np-header-row">
                <div className="notify"> Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker </div>
                <div className="time-data">
                    <img src='assets/svg/clock.svg' className='icon-clk' alt="clock"/> Lorem Ipsum passages, and more recently</div>
            </div>
        )
    );
}
class Notifications extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inProgress: true,
            error: '',

            tabs: ["Order"], //, "News", "Alerts", "Ideas"],
            selectedsegment: 'Order',
            data: [],
            showDialog: false,
        }
    }
    componentWillMount() {
        this.placeGetNotificationsRequest();
    }

    onTabClicked(tabName) {
        switch (tabName) {
            case "Order":
                this.setState({ selectedsegment: "Order" }, this.placeGetNotificationsRequest());
                break;
            case "News":
                this.setState({ selectedsegment: "News" });
                break;
            case "Alerts":
                this.setState({ selectedsegment: "Alerts" });
                break;
            case "Ideas":
                this.setState({ selectedsegment: "Ideas" });
                break;
            default:break;
        }
    }


    placeGetNotificationsRequest() {
        var requestData = {
            request: {
                data: {
                }
            }
        };
        this.setState({ inProgress: true, error: '' });
        getNotifications(requestData, this.parseGetNotificationsResponse.bind(this), this.parseGetNotificationsError.bind(this));
    }

    parseGetNotificationsResponse(responseData) {

        this.setState({
            inProgress: false,
            error: '',
            data: responseData.response.data.alerts

        });
    }

    parseGetNotificationsError(error) {
        this.setState({ inProgress: false, error: error.message });
    }

    onSetAlert() {
        this.setState({ showDialog: true });
    }

    onCancelAlert() {
        this.setState({ showDialog: false });
    }

    onClickRefresh() {
        this.placeGetNotificationsRequest();
    }

    render() {
        // var progressindicator = (
        //     <div className="widget-progress-holder">
        //         <svg class="circular-progress" viewBox="25 25 50 50">
        //             <circle class="circular-progress_path" cx="50" cy="50" r="20"
        //                 fill="none" stroke-width="2" stroke-miterlimit="10" />
        //         </svg>
        //     </div>
        // );

        var errorHolder = (
            <div className="error-holder">
                <span> {this.state.error} </span>
            </div>
        );

        var additionalWidgetHeader = (
            <div>
                <div className="watchlistoptions">
                    {/* <ReportPopup popupMessage="Margin Trading colletral report features lets you view the colletral value marked for margin trading at the end of the day."/>                                                                                      */}
                </div>
                <div className="watchlistoptions" style={{ paddingRight: "20px" }}>
                    <i className="fa  fa-plus-square-o" aria-hidden="true" onClick={this.onSetAlert.bind(this)}> SET AN ALERT</i>
                </div>
            </div>
        );

        var additionalTabHeader = (
            <div>
                {this.state.inProgress ? '' :
                    <div className="watchlistoptions" style={{ paddingRight: "20px", cursor: 'pointer' }}>
                        <img src="assets/svg/refresh.svg" style={{ height: 16 }} onClick={this.onClickRefresh.bind(this)} alt="refresh"/>
                    </div>
                }
            </div>
        );

        return (
            <TabbarBaseWidget title="ALERTS & NOTIFICATIONS" 
                additionalWidgetHeader={this.state.selectedsegment === "Alerts" ? additionalWidgetHeader : ''} 
                additionalTabHeader={additionalTabHeader}
                tabs={this.state.tabs} 
                onTabClicked={this.onTabClicked.bind(this)} >
                <div className="notif-base">
                    {this.state.error ? errorHolder :
                        <div className="fillheight">
                            {this.state.inProgress ? progressBar() :
                                <div className="fillheight" style={{ overflowY: 'auto' }} >
                                    {this.state.selectedsegment === "Order" ?
                                        <Order data={this.state.data} />
                                        : ''
                                    }

                                    {this.state.selectedsegment === "News" ?
                                        <News data={this.state.data} />
                                        : ''
                                    }

                                    {this.state.selectedsegment === "Alerts" ?
                                        <Alerts data={this.state.data} />
                                        : ''
                                    }

                                    {this.state.selectedsegment === "Ideas" ?
                                        <Ideas data={this.state.data} />
                                        : ''
                                    }
                                </div>
                            }
                        </div>
                    }


                    <AlertPopUp showDialog={this.state.showDialog} onClose={this.onCancelAlert.bind(this)} />

                </div>
            </TabbarBaseWidget>
        )
    }

}

export default Notifications;