import React from "react";
import { connect } from "react-redux";

class CartIcon extends React.Component {

    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
    }

    onIconClicked() {
        this.props.history.push("/home/fundtransfer");
    }

    render() {
        return (
            <div className="pageheader-component cal-cart-parent "  style={{cursor: "pointer"}} onClick={this.onIconClicked.bind(this)} data-toggle="tooltip" title="Fund Transfer">              
                <img src="assets/images/fundtransfer.png" style={{ height: '28px' }} alt="fundtransfer"/>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {

    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartIcon); 