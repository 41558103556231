import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setExpandWidget } from '../../actions/dashboard'
import { ModuleKeys } from '../../common/constants'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import { progressBar } from '../../controls/progress'

class BaseWidget extends Component {

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
    }

    onExpandToggle(type) {
        if (this.props.activeWidget === ModuleKeys.ALL) {
            if (type === ModuleKeys.PENDING_ORDER) {
                this.props.history.push("/home/myorders");
            } else {
                this.props.setExpandWidget(type);
            }
        } else {
            this.props.setExpandWidget(ModuleKeys.ALL);
        }
    }

    render() {

        var progressindicator = (
            <div className="widget-progress-holder">
                <svg class="circular-progress" viewBox="25 25 50 50">
                    <circle class="circular-progress_path" cx="50" cy="50" r="20"
                        fill="none" stroke-width="2" stroke-miterlimit="10" />
                </svg>
            </div>
            // <div className="widget-progress-holder2">
            //     <img src="assets/images/loading.gif" style={{ width: '50%' }} />
            // </div>
        );

        var errorHolder = (
            <div className="error-holder">
                <span> {this.props.error} </span>
            </div>
        );

        var scrollCheck = this.props.activeWidget === ModuleKeys.ALL && (this.props.componentType === ModuleKeys.WORLD_MARKET || this.props.componentType === ModuleKeys.RESEARCH);
        return (
            <div className="fillheight">
                <div className="row contentpanel widget-title">
                    <div className="col-10 col-lg-10 col-md-10 contentpanel">
                        <div className="widget-title-component"> {this.props.title} </div>
                        <div className={"widget-title-component " + (this.props.alignRight && "right")}>
                            {this.props.additionalHeader}
                        </div>
                    </div>

                    <div className="col-2 col-lg-2 col-md-2 contentpanel text-right" >
                        {this.props.noexpand ? "" : <img alt="collapse" src={this.props.activeWidget !== ModuleKeys.ALL ? "assets/svg/collapse.svg" : "assets/svg/expand.svg"} className="expander-icon" 
                                                        onerror="this.src='assets/images/logo.png'" onClick={() => this.onExpandToggle(this.props.componentType)} />}
                    </div>
                </div>
                <div className="row hdivider" > </div>

                <div className={" contentpanel dash-content-scroller " + (scrollCheck ? "scrolly" : "")}>
                    {
                        (this.props.error) ? errorHolder :
                            <div className="fillheight">
                                {this.props.inProgress ? progressBar() : this.props.children}
                            </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        activeWidget: state.dashboard.activeWidget,
    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setExpandWidget: (activeWidget) => setExpandWidget(activeWidget, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BaseWidget)); 