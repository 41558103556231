import React from "react"

class ReceivedBid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           }
        this.onClosePopUp = this.onClosePopUp.bind(this);
        this.onClickSubmit = this.onClickSubmit.bind(this);
    }

    onClosePopUp() {
        
    }
    onClickSubmit() {

    }

    render() {
        return (
                <div className="modal-dialog prod-receive-popup" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div className="modal-header" style={{ textAlign: 'center' }}>
                        MODIFICATION REQUEST RESULT
                        </div>
                    <div className="container-fluid order-row order-confirmation-body">
                        <div  className="row order-confirmation-row" style={{fontSize:18}}>
                            Bid modification for Bid No. 2019866787 has been received
                        </div>
                    </div>
                    <div className="prod-footer">
                        <button type="button" className="btn-footer prod-blue-btn " 
                        // onClick={this.onPlaceOrder}  disabled={(this.state.symbol === undefined || this.state.error)} 
                        >OKAY</button>
                    </div>
                </div>
        )
    }
}
export default ReceivedBid;
