import { UrlExtensions, BaseUrl } from '../../common/constants';
import { httpPost } from '../base/api'

// === Symbol Search ====
export const getSymbolSearch = function ( requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.SYMBOL_SEARCH;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// === IndexQuote ====
export const getIndexQuote = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.INDEX_QUOTE;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error.message);
        errorCallback(error);
    });
}