import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showRiskDisclosure } from '../../actions/common';
import {logRiskDisc} from "../../actions/infoscreens/inforequests";

class Risk extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRisk: true
        }
    }
    componentDidMount() {
        this.setState({ showRisk: this.props.showRisk });
    }
    componentWillReceiveProps(newProps) {
        if (this.props.showRisk !== newProps.showRisk)
            this.setState({ showRisk: newProps.showRisk });
    }
    closeDialog() {
        var requestData = {
            request: {
                data: {}
            }
        };
        logRiskDisc(requestData);
        if (this.props.showRisk) {
            let payload = {
                showRisk: false
            }
            this.props.showRiskDisclosure(payload);
        }
    }

    render() {
        return (
            this.state.showRisk ?
                <div className={"modal fade " + (this.state.showRisk ? "dialog-show in" : "dialog-hide")} id="myModal" role="dialog">
                    <div className="modal-dialog risk-modal">
                        <div className="mtf-header">
                            <span>RISK DISCLOSURES ON DERIVATIVES</span>
                        </div>
                        <div className="mtf-body" style={{ textAlign: "justify" }}>
                            <span className="">
                                <li>9 out of 10 individual traders in equity Futures and Options Segment, incurred net losses.</li> 
                                <li>On an average, loss makers registered net trading loss close to ₹ 50,000.</li>
                                <li>Over and above the net trading losses incurred, loss makers expended an additional 28% of net trading losses as transaction costs.</li>
                                <li>Those making net trading profits, incurred between 15% to 50% of such profits as transaction cost.</li>                                
                            </span>
                            <br/>
                            Source : <a href={"https://www.sebi.gov.in/reports-and-statistics/research/jan-2023/study-analysis-of-profit-and-loss-of-individual-traders-dealing-in-equity-fando-segment_67525.html"} target="_blank"> 
                            SEBI study dated January 25, 2023 on “Analysis of Profit and Loss of Individual Traders dealing in equity Futures and Options (F&O) Segment”, wherein Aggregate Level findings are based on annual Profit/Loss incurred by individual traders in equity F&O during FY 2021-22.
                            </a>
                        </div>


                        <div className="risk-footer">
                            <div className="buttons">
                                <button type="button" className="mtf-buttons" onClick={this.closeDialog.bind(this)}>CONTINUE</button>
                            </div >
                        </div>
                    </div>
                </div> : null
        );
    }
};
const mapStateToProps = (state) => {
    return {
        showRisk: state.loginStatus.showRisk
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showRiskDisclosure: (value) => showRiskDisclosure(dispatch, value)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Risk);