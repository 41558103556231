import React, { Component } from 'react';
import { comaSeparator } from '../../common/utils';

class BracketOrder extends Component {
    constructor() {
        super();
        this.state = {
            showBoLeg1: false,
            showBoLeg2: false
        }
    }

    onShowBoLeg1DetailsClick = () => {
        this.setState({ showBoLeg1: !(this.state.showBoLeg1) }, () => {
        });
    }

    onShowBoLeg2DetailsClick = () => {
        this.setState({ showBoLeg2: !(this.state.showBoLeg2) }, () => {
        });
    }

    componentDidMount() {
        console.log("lengthwatchlist ", this.props.leg1)
    }

    render() {
        const { leg1, leg2, orderId } = this.props;
        const triggerOrder = leg1[0];
        const targetOrder = leg2[0];
        return (
            <div ref={(expElement) => this.expElement = expElement}>
                <div>
                    {triggerOrder ? <div className="row my-accordion-padding">
                        <div className="col-9 contentpanel" >
                            <div className="row">
                                <div className="col-2 text-right">TRIGGER PRICE SL</div>
                                <div className="col-2 nopadding">
                                    <div className="row">
                                        <div className="col-4 text-right nopadding">
                                            <span className="cursor-pointer" onClick={(e) => {
                                                e.stopPropagation();
                                                this.onShowBoLeg1DetailsClick();
                                            }}>
                                                {
                                                    this.state.showBoLeg1 ?
                                                        <img src="assets/svg/minus.svg" alt="plus" width="11px" height="11px" /> :
                                                        <img src="assets/svg/plus.svg" alt="plus" width="11px" height="11px" />
                                                }
                                            </span>
                                        </div>
                                        <div className="col-8 text-right nopadding">
                                            <div className="accordian-orderheader">QTY</div>
                                            <div>{this.props.qty}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-8 nopadding">
                                    <div className="row">
                                        <div className="col-4 text-right">
                                            <div className="accordian-orderheader">TRIGGER PRICE</div>
                                            <div>{comaSeparator(triggerOrder.triggerPrice)}</div>
                                        </div>
                                        <div className="col-4 text-right">
                                            <div className="accordian-orderheader">ACTION</div>
                                            <div className={triggerOrder.ordAction === 'buy' ? "positive-change-blue" : "negative-change"}>
                                                {triggerOrder.ordAction === 'buy' ? 'BUY' : 'SELL'}</div>
                                        </div>
                                        <div className="col-4 text-right">
                                            <div className="accordian-orderheader">PLACED BY</div>
                                            <div>{triggerOrder.user}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 contentpanel">
                            <div className="row">
                                {/* <div className="col-4">
                                    <button type="button" className="form-control myorder-btn modify-btn"
                                        data-tag={leg1.orderId} onClick={(e) => { this.props.onModifyOrderClick(leg1) }}
                                        disabled={!leg1.modifiable} >
                                        MODIFY
                                    </button>
                                </div> */}
                                {/* <div className="col-4">
                                    <button type="button" className={"form-control myorder-btn exit-btn"}
                                        data-tag={leg1.orderId} onClick={(e) => { this.props.onCancelOrderClick(leg1) }}
                                        disabled={!isLeg1Cancellable}  >
                                        EXIT
                                </button>
                                </div>
                                <div className="col-8">
                                </div> */}
                            </div>
                        </div>
                        <div className="col-12 nopadding">
                            <div className="myorders-panel-body advance-orders-accordion expand-inner-row " ref={(leg1) => this.leg1 = leg1}
                                style={{ maxHeight: (this.state.showBoLeg1 ? this.leg1.scrollHeight : "") }}>
                                <div className="row myinner-accordion-padding">
                                    <div className="col-2 text-right">
                                    </div>
                                    <div className="col-7 text-right inner-expand">
                                        <div className="row" style={{ marginTop: "10px", marginBottom: "10px" }}>
                                            <div className="col-8 nopadding">
                                                <div className="row">
                                                    <div className="col-3 text-right nopadding">
                                                        <div className="accordian-orderheader">ORDER REFERENCE NO</div>
                                                    </div>
                                                    <div className="col-3 text-right nopadding">
                                                        <div className="accordian-orderheader">EXCHANGE ORDER NO</div>
                                                    </div>
                                                    <div className="col-2 text-right nopadding">
                                                        <div className="accordian-orderheader">QTY</div>
                                                    </div>
                                                    <div className="col-2 text-right nopadding">
                                                        <div className="accordian-orderheader">PEN. QTY</div>
                                                    </div>
                                                    <div className="col-2 text-right nopadding">
                                                        <div className="accordian-orderheader">PRICE</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2 text-right nopadding">
                                                <div className="accordian-orderheader">STATUS</div>
                                            </div>
                                            <div className="col-2 text-right">
                                                <div className="accordian-orderheader">DATE & TIME</div>
                                            </div>
                                        </div>
                                        {
                                            leg1.map((row, key) => {
                                                return (
                                                    <div className="row" style={{ marginTop: "10px", marginBottom: "10px" }}>
                                                        <div className="col-8 nopadding">
                                                            <div className="row">
                                                                <div className="col-3 text-right nopadding">
                                                                    {row.orderId}
                                                                </div>
                                                                <div className="col-3 text-right nopadding">
                                                                    {row.exchOrderId}
                                                                </div>
                                                                <div className="col-2 text-right nopadding">
                                                                    {row.qty}
                                                                </div>
                                                                <div className="col-2 text-right nopadding">
                                                                    {row.remQty}
                                                                </div>
                                                                <div className="col-2 text-right nopadding">
                                                                    {parseFloat(row.triggerPrice) <= 0 ? comaSeparator(row.limitPrice) : comaSeparator(row.triggerPrice)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-2 text-right nopadding">
                                                            {row.ordStatus}
                                                        </div>
                                                        <div className="col-2 text-right">
                                                            {row.ordDate}, {row.ordTime}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="col-3 text-right">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                </div>
                <div>
                    {targetOrder ? <div className="row my-accordion-padding">
                        <div className="col-9 contentpanel" >
                            <div className="row">
                                <div className="col-2 text-right">TARGET PRICE</div>
                                <div className="col-2 nopadding">
                                    <div className="row">
                                        <div className="col-4 text-right nopadding">
                                            <span className="cursor-pointer" onClick={(e) => {
                                                e.stopPropagation();
                                                this.onShowBoLeg2DetailsClick();
                                            }}>
                                                {
                                                    this.state.showBoLeg2 ?
                                                        <img src="assets/svg/minus.svg" alt="plus" width="11px" height="11px" /> :
                                                        <img src="assets/svg/plus.svg" alt="plus" width="11px" height="11px" />
                                                }
                                            </span>
                                        </div>
                                        <div className="col-8 text-right nopadding">
                                            <div className="accordian-orderheader">QTY</div>
                                            <div>{this.props.qty}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-8 nopadding">
                                    <div className="row">
                                        <div className="col-4 text-right">
                                            <div className="accordian-orderheader">TARGET PRICE</div>
                                            <div>{comaSeparator(targetOrder.targetPrice)}</div>
                                        </div>
                                        <div className="col-4 text-right">
                                            <div className="accordian-orderheader">ACTION</div>
                                            <div className={targetOrder.ordAction === 'buy' ? "positive-change-blue" : "negative-change"}>
                                                {targetOrder.ordAction === 'buy' ? 'BUY' : 'SELL'}</div>
                                        </div>
                                        <div className="col-4 text-right">
                                            <div className="accordian-orderheader">PLACED BY</div>
                                            <div>{targetOrder.user}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 contentpanel">
                            <div className="row">
                                {/* <div className="col-4">
                                    <button type="button" className="form-control myorder-btn modify-btn"
                                        data-tag={leg2.orderId} onClick={(e) => { this.props.onModifyOrderClick(leg2) }}
                                        disabled={!leg2.modifiable} >
                                        MODIFY
                                    </button>
                                </div> */}
                                {/* <div className="col-4">
                                    <button type="button" className={"form-control myorder-btn exit-btn"}
                                        data-tag={leg2.orderId} onClick={(e) => { this.props.onCancelOrderClick(leg2) }}
                                        disabled={!isLeg2Cancellable}  >
                                        EXIT
                                    </button>
                                </div>
                                <div className="col-8">
                                </div> */}
                            </div>
                        </div>
                        <div className="col-12 nopadding">
                            <div className="myorders-panel-body advance-orders-accordion expand-inner-row " ref={(leg2) => this.leg2 = leg2}
                                style={{ maxHeight: (this.state.showBoLeg2 ? this.leg2.scrollHeight : "") }}>
                                <div className="row myinner-accordion-padding">
                                    <div className="col-2 text-right">
                                    </div>
                                    <div className="col-7 text-right inner-expand">
                                        <div className="row" style={{ marginTop: "10px", marginBottom: "10px" }}>
                                            <div className="col-8 nopadding">
                                                <div className="row">
                                                    <div className="col-3 text-right nopadding">
                                                        <div className="accordian-orderheader">ORDER REFERENCE NO</div>
                                                    </div>
                                                    <div className="col-3 text-right nopadding">
                                                        <div className="accordian-orderheader">EXCHANGE ORDER NO</div>
                                                    </div>
                                                    <div className="col-2 text-right nopadding">
                                                        <div className="accordian-orderheader">QTY</div>
                                                    </div>
                                                    <div className="col-2 text-right nopadding">
                                                        <div className="accordian-orderheader">PEN. QTY</div>
                                                    </div>
                                                    <div className="col-2 text-right nopadding">
                                                        <div className="accordian-orderheader">PRICE</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2 text-right nopadding">
                                                <div className="accordian-orderheader">STATUS</div>
                                            </div>
                                            <div className="col-2 text-right">
                                                <div className="accordian-orderheader">DATE & TIME</div>
                                            </div>
                                        </div>
                                        {
                                            leg2.map((row, key) => {
                                                return (
                                                    <div className="row" style={{ marginTop: "10px", marginBottom: "10px" }}>
                                                        <div className="col-8 nopadding">
                                                            <div className="row">
                                                                <div className="col-3 text-right nopadding">
                                                                    {row.orderId}
                                                                </div>
                                                                <div className="col-3 text-right nopadding">
                                                                    {row.exchOrderId}
                                                                </div>
                                                                <div className="col-2 text-right nopadding">
                                                                    {row.qty}
                                                                </div>
                                                                <div className="col-2 text-right nopadding">
                                                                    {row.remQty}
                                                                </div>
                                                                <div className="col-2 text-right nopadding">
                                                                    {comaSeparator(row.limitPrice)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-2 text-right nopadding">
                                                            {row.ordStatus}
                                                        </div>
                                                        <div className="col-2 text-right">
                                                            {row.ordDate}, {row.ordTime}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="col-3 text-right">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div> : null}
                </div>
            </div>
        );
    }
};

export default BracketOrder;