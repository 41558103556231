import React, { Component } from 'react';
import RadioButtons from '../../controls/RadioButtons';
import OrderNumberInput from '../../controls/OrderNumberInput';
import { comaSeparator } from '../../common/utils'
import Dropdown from '../../controls/Dropdown';
import Dialog from '../../controls/Dialog';
import {commonDropDownType, BaseUrl} from "../../common/constants"
import { validateDematAcc, addIPO } from '../../actions/products/productrequest'
import { blockProgressBar } from '../../controls/progress'
import {getItemByKey} from '../../common/utils'
import {localStorageKeys} from '../../common/constants'

class IPOPlaceOrder extends Component {
    constructor(props) {
        super(props);
        var obj = {};
        var subcategory =[];
        var defaultCategory = "Individual";
        var getCategory = this.props.placeOrderData.category  !== undefined && this.props.placeOrderData.category.length > 0 ? this.props.placeOrderData.category : [];
        if(Array.isArray(getCategory) && getCategory.length>0){
             getCategory.forEach(function(value) {
                obj['name'] = ((value === "IND") ? "Individual" : (value === "EMP") ? "Employee" : (value === "SHA") ? "Shareholder": (value === "POL") ? "Policy Holder":"Individual");
                 subcategory.push(obj);
                 obj = {};
                 });
        }
        
        this.state = {
            inProgress: false,
            tickSize:this.props.placeOrderData.tickSize,
            cbid:this.props.placeOrderData.cbid==undefined?'1':this.props.placeOrderData.cbid,
            bid1:this.props.placeOrderData.lot==undefined?'1':this.props.placeOrderData.lot,
            bid2:this.props.placeOrderData.lot2==undefined?'0':this.props.placeOrderData.lot2,
            bid3:this.props.placeOrderData.lot3==undefined?'0':this.props.placeOrderData.lot3,
            cprice:this.props.placeOrderData.cutOffPrice,
            price1:this.props.placeOrderData.price==undefined?'0.00':this.props.placeOrderData.price,
            price2:this.props.placeOrderData.price2==undefined?'0.00':this.props.placeOrderData.price2,
            price3:this.props.placeOrderData.price3==undefined?'0.00':this.props.placeOrderData.price3,
            selectedType:this.props.placeOrderData.selectedType==undefined?0:this.props.placeOrderData.selectedType,
            types:[{ key: 'Cut-Off', value: 'Cut-Off' }, { key: 'Multi-Bid', value: 'Multi-Bid' }],
            selectedBank:'0',
            bankList:[],
            totalAmount:0,
            totalOrderQty:'0',
            retailDiscount:this.props.placeOrderData.discount,
            payableAmount:'0',
            payment:false,
            showDialog:false,
            dematAccList:this.props.dematAccList,
            dematAcc:'',
            selectedDemat:'0',
            showMessageDailog:false,
            header:'',
            message:'',
            upiId:this.props.placeOrderData.upi==undefined?'':this.props.placeOrderData.upi,
            minPrice:this.props.placeOrderData.minPrice,
            maxPrice:this.props.placeOrderData.maxPrice,
            minBidQuantity:this.props.placeOrderData.minBidQuantity,
            minimumInvestmentAmt:this.props.placeOrderData.minimumInvestmentAmt,
            maximumInvestmentAmt:this.props.placeOrderData.maximumInvestmentAmt,
            reconfirm:false,
            category:subcategory,
            categoryIndex:'0',
            selectedCategory : subcategory.length>0 ? defaultCategory : "" 
        }
        this.bidValueOnChange=this.bidValueOnChange.bind(this);
        this.bankTransactionsShow=this.bankTransactionsShow.bind(this);
        
    }

    TermsCondition = () =>{
         window.open("https://www.indiabullsventures.com/terms-and-conditions", "_blank");
    }

    findUPI = () =>{
        window.open(BaseUrl+"/mobileUPI?reqType=find", "_blank");
    }

    createUPI = () =>{
        window.open(BaseUrl+"/mobileUPI?reqType=create", "_blank");
    }

    componentDidMount()
    {
        this.evaluate();
        if(this.props.placeOrderData.clientBenId!=undefined)
        {            
            if(this.props.placeOrderData.depository=="NSDL")
            {
                this.onDematSelected(this.props.placeOrderData.dpId+this.props.placeOrderData.clientBenId);
                this.setState({dematAcc:this.props.placeOrderData.dpId+this.props.placeOrderData.clientBenId});
            }
            else
            {
                this.onDematSelected(this.props.placeOrderData.clientBenId);
                this.setState({dematAcc:this.props.placeOrderData.clientBenId});
            }
        }
        if(this.props.placeOrderData.activityType === "modify"){
            if(this.props.placeOrderData.atCutOff){
                document.getElementsByClassName("radio-inline ideas")[1].style = "display:none";
            }else{
                document.getElementsByClassName("radio-inline ideas")[0].style = "display:none";
            }
           
        }
    }


    bidValueOnChange(name, value) {
        switch(name)
        {
            case 'cbid' :
                        this.setState({cbid:value, bid1:value}, ()=>this.evaluate());
            break;
            case 'bid1' :       
                        if(this.props.placeOrderData.modified!=undefined && this.props.placeOrderData.selectedType==1) 
                        {
                            this.setState({
                                cbid:value, 
                                bid1:value, 
                                bid2:this.props.placeOrderData.bidRefNum2!=''?value:0, 
                                bid3:this.props.placeOrderData.bidRefNum3!=''?value:0 }, ()=>this.evaluate()); 
                        }
                             
                        else if(value>0)
                            this.setState({cbid:value, bid1:value}, ()=>this.evaluate());
                        else
                            this.setState({bid1:value, price1:'0.00'}, ()=>this.evaluate());
            break;
            case 'bid2' :
                        if(this.props.placeOrderData.modified!=undefined && this.props.placeOrderData.selectedType==1) 
                            this.setState({cbid:value, 
                                bid1:value, 
                                bid2:value,
                                bid3:this.props.placeOrderData.bidRefNum3!=''?value:0}, ()=>this.evaluate());  
                        else if(value>0)
                            this.setState({bid2:value}, ()=>this.evaluate());
                        else
                            this.setState({bid2:value, price2:'0.00'}, ()=>this.evaluate());
            break;
            case 'bid3' :
                        if(this.props.placeOrderData.modified!=undefined && this.props.placeOrderData.selectedType==1) 
                            this.setState({cbid:value, bid1:value, bid2:value, bid3:value}, ()=>this.evaluate());  
                        else if(value>0)
                            this.setState({bid3:value}, ()=>this.evaluate());
                        else
                            this.setState({bid3:value, price3:'0.00'}, ()=>this.evaluate());
            break;
            case 'price1' :this.setState({price1:value}, ()=>this.evaluate());
            break;
            case 'price2' :this.setState({price2:value}, ()=>this.evaluate());
            break;
            case 'price3' :this.setState({price3:value}, ()=>this.evaluate());
            break;
        }
        
    }

    bankTransactionsShow()
    {
        
        if(!this.state.payment)
        {
            var check=true;
            if(this.props.placeOrderData.modified!=undefined)
            {
                if(this.state.selectedType==this.props.placeOrderData.selectedType)
                {
                    //if(this.state.dematAccList[this.state.selectedDemat].value==this.props.placeOrderData.clientBenId)
                    {
                        if(this.state.selectedType==0)
                        {
                            if(this.state.cbid==this.props.placeOrderData.cbid 
                                //&&  this.state.dematAccList[this.state.selectedDemat].value==this.props.placeOrderData.clientBenId
                                )
                            {
                                this.setState({reconfirm:true, header:'Message', message:"You haven't made any changes in the Bid. Selecting OK will take you back to your order book.\nDo you wish to continue?"});
                                return;
                            }
                        }
                        else
                        {
                            if(Number(this.state.bid1)==Number(this.props.placeOrderData.lot)
                                && Number(this.state.bid2)==Number(this.props.placeOrderData.lot2)
                                && Number(this.state.bid3)==Number(this.props.placeOrderData.lot3)
                                && Number(this.state.price1)==Number(this.props.placeOrderData.price)
                                && Number(this.state.price2)==Number(this.props.placeOrderData.price2)
                                && Number(this.state.price3)==Number(this.props.placeOrderData.price3))
                            {
                                this.setState({reconfirm:true, header:'Message', message:"You haven't made any changes in the Bid. Selecting OK will take you back to your order book.\nDo you wish to continue?"});
                                return;
                            }
                            if(this.props.placeOrderData.bidRefNum!="" &&this.props.placeOrderData.bidRefNum!=undefined && this.props.placeOrderData.bidRefNum!=null)
                            {
                                if(this.state.bid1==0)
                                {
                                    this.setState({showMessageDailog:true, header:'Error', message:'Please enter valid lot for Bid 1'});
                                    return;
                                }
                            }
                            if(this.props.placeOrderData.bidRefNum2!="" && this.props.placeOrderData.bidRefNum2!=undefined && this.props.placeOrderData.bidRefNum2!=null)
                            {
                                if(this.state.bid2==0)
                                {
                                    this.setState({showMessageDailog:true, header:'Error', message:'Please enter valid lot for Bid 2'});
                                    return;
                                }
                            }
                            if(this.props.placeOrderData.bidRefNum3!="" && this.props.placeOrderData.bidRefNum3!=undefined && this.props.placeOrderData.bidRefNum3!=null)
                            {
                                if(this.state.bid3==0)
                                {
                                    this.setState({showMessageDailog:true, header:'Error', message:'Please enter valid lot for Bid 3'});
                                    return;
                                }
                            }                    

                        }
                    }
                }
            }
            
            if(this.state.selectedType==1)
            {                            
                let decimalplaces = 2;
                let multiplier = Math.pow(10, decimalplaces);    
                if(!((this.state.bid1!=0 && this.state.bid2!=0 && this.state.bid3!=0 && this.state.bid1==this.state.bid2 && this.state.bid1==this.state.bid3)
                ||
                (this.state.bid1!=0 && this.state.bid2!=0 && this.state.bid3==0 && this.state.bid1==this.state.bid2)
                ||
                (this.state.bid1!=0 && this.state.bid3!=0 && this.state.bid2==0 && this.state.bid1==this.state.bid3)
                ||
                (this.state.bid2!=0 && this.state.bid3!=0 && this.state.bid1==0 && this.state.bid2==this.state.bid3)
                ||
                (this.state.bid1!=0 && this.state.bid2==0 && this.state.bid3==0)
                ||
                (this.state.bid2!=0 && this.state.bid3==0 && this.state.bid1==0)
                ||
                (this.state.bid3!=0 && this.state.bid2==0 && this.state.bid1==0)                
                ||
                (this.state.bid3==0 && this.state.bid2==0 && this.state.bid1==0)
                ))
                {
                    this.setState({showMessageDailog:true, header:'Error', message:'Lot size must be same or zero.'});
                    check=false;
                }
                else if(!((this.state.bid1!=0 && Number(this.state.price1)>=Number(this.state.minPrice) && Number(this.state.price1)<=Number(this.state.maxPrice)) || (this.state.bid1==0)))
                {
                    this.setState({showMessageDailog:true, header:'Error', price1:'0.00', message:'Price must be in range for Bid 1'}, ()=> {
                        this.evaluate();
                    });
                    check=false;
                }
                else if(this.state.bid1==0 && Number(this.state.price1)>0)
                {
                    this.setState({showMessageDailog:true, header:'Error', message:'Please enter number of lots for Bid 1'}, ()=> {
                        this.evaluate();
                    });
                    check=false;
                }
                else if(!((this.state.bid2!=0 && Number(this.state.price2)>=Number(this.state.minPrice) && Number(this.state.price2)<=Number(this.state.maxPrice)) || (this.state.bid2==0)))
                {
                    this.setState({showMessageDailog:true, header:'Error', price2:'0.00', message:'Price must be in range for Bid 2'}, ()=> {
                        this.evaluate();
                    });
                    check=false;
                }
                else if(this.state.bid2==0 && Number(this.state.price2)>0)
                {
                    this.setState({showMessageDailog:true, header:'Error', message:'Please enter number of lots for Bid 2'}, ()=> {
                        this.evaluate();
                    });
                    check=false;
                }
                else if(!((this.state.bid3!=0 && Number(this.state.price3)>=Number(this.state.minPrice) && Number(this.state.price3)<=Number(this.state.maxPrice)) || (this.state.bid3==0)))
                {
                    this.setState({showMessageDailog:true, header:'Error', price3:'0.00', message:'Price must be in range for Bid 3'}, ()=> {
                        this.evaluate();
                    });
                    
                    check=false;
                }
                else if(this.state.bid3==0 && Number(this.state.price3)>0)
                {
                    this.setState({showMessageDailog:true, header:'Error', message:'Please enter number of lots for Bid 3'}, ()=> {
                        this.evaluate();
                    });
                    check=false;
                }
                else if(Number(this.state.tickSize)>0)
                {
                    if(Number(this.state.bid1)>0 && Number(this.state.price1)>0)
                    {
                        let prc = parseFloat(this.state.price1).toFixed(decimalplaces);
                        let tsz = parseFloat(this.state.tickSize).toFixed(decimalplaces);
                        let pr = Math.round(prc * multiplier);
                        let ts = Math.round(tsz * multiplier);
                        let rem = (Math.round(pr % ts)) / multiplier;
                        if (rem !== 0) {
                            this.setState({showMessageDailog:true, header:'Error', message: 'Bid 1 Price should be multiple of Tick Size which is ₹ ' + this.state.tickSize + ''});
                            check=false;
                        }
                    }
                    if(Number(this.state.bid2)>0 && Number(this.state.price2)>0)
                    {
                        let prc = parseFloat(this.state.price2).toFixed(decimalplaces);
                        let tsz = parseFloat(this.state.tickSize).toFixed(decimalplaces);
                        let pr = Math.round(prc * multiplier);
                        let ts = Math.round(tsz * multiplier);
                        let rem = (Math.round(pr % ts)) / multiplier;
                        if (rem !== 0) {
                            this.setState({showMessageDailog:true, header:'Error', message: 'Bid 2 Price should be multiple of Tick Size which is ₹ ' + this.state.tickSize + ''});
                            check=false;
                        }
                    }
                    if(Number(this.state.bid3)>0 && Number(this.state.price3)>0)
                    {
                        let prc = parseFloat(this.state.price3).toFixed(decimalplaces);
                        let tsz = parseFloat(this.state.tickSize).toFixed(decimalplaces);
                        let pr = Math.round(prc * multiplier);
                        let ts = Math.round(tsz * multiplier);
                        let rem = (Math.round(pr % ts)) / multiplier;
                        if (rem !== 0) {
                            this.setState({showMessageDailog:true, header:'Error', message: 'Bid 3 Price should be multiple of Tick Size which is ₹ ' + this.state.tickSize + ''});
                            check=false;
                        }
                    }
                }
                else if(this.state.bid1!=0)
                {                    
                    if(!((this.state.bid1*this.props.placeOrderData.lotSize)>=this.state.minBidQuantity))
                    {
                        this.setState({showMessageDailog:true, header:'Error', message: 'Bid 1 Lot must be greater than '+this.state.minBidQuantity+"."});
                        check=false;
                    }
                }
                else if(this.state.bid2!=0)
                {
                    if(!((this.state.bid2*this.props.placeOrderData.lotSize)>=this.state.minBidQuantity))
                    {
                        this.setState({showMessageDailog:true, header:'Error', message: 'Bid 2 Lot must be greater than '+this.state.minBidQuantity+"."});
                        check=false;
                    }
                }
                else if(this.state.bid3!=0)
                {
                    if(!((this.state.bid3*this.props.placeOrderData.lotSize)>=this.state.minBidQuantity))
                    {
                        this.setState({showMessageDailog:true, header:'Error', message: 'Bid 3 Lot must be greater than '+this.state.minBidQuantity+"."});
                        check=false;
                    }
                }
            }
            else
            {
                if(!((this.state.cbid*this.props.placeOrderData.lotSize)>=this.state.minBidQuantity))
                {
                    this.setState({showMessageDailog:true, header:'Error', message: 'Bid Lot must be greater than '+this.state.minBidQuantity+"."});
                    check=false;
                }
            }
            if(check)
            {
                if(Number(this.state.payableAmount)!=0 && Number(this.state.minimumInvestmentAmt)<=Number(this.state.payableAmount) && Number(this.state.maximumInvestmentAmt)>=Number(this.state.payableAmount))
                {
                    if(this.state.dematAccList.length>=1)
                    {
                        this.setState({inProgress:true, header:'', message:''});
                        var requestData = {
                            request: {
                                data: {                                    
                                    dematAcc:this.state.dematAccList[this.state.selectedDemat].value
                                }
                            }
                        };        
                        validateDematAcc(requestData, this.parsevalidateDematAccResponse.bind(this), this.parsevalidateDematAccError.bind(this));
                    }
                    else
                    {
                        this.setState({showMessageDailog:true, header:'Error', message:'No Demat Account Available.'});
                    }
                }
                else
                {
                    this.setState({showMessageDailog:true, header:'Error', message:'Payable Amount must be in investment range which is ₹ ('+this.state.minimumInvestmentAmt+' - '+this.state.maximumInvestmentAmt+')'});
                }
            }
        }
        else
        {            
            this.setState({payment:!this.state.payment});
        }
    
    }

    parsevalidateDematAccResponse(responseData)
    {
        if(responseData.response.data.data)
        {
            this.setState({inProgress:false, payment:!this.state.payment});
        }  
        else
        {
            this.setState({inProgress:false, showMessageDailog:true, header:'Error', message:'Invalid Demat Account.'});
        }
    }

    parsevalidateDematAccError(error) {
        this.setState({inProgress:false, showMessageDailog:true, header:'Error', message:error.message!="Request Failed"?error.message:"Request failed, Please try again."});
    }

    evaluate()
    {
        var total=0;
        if(this.state.selectedType==0)
        {
            total=(Math.round((Number(this.state.cbid)*Number(this.state.cprice)*Number(this.props.placeOrderData.lotSize))*100/100)).toFixed(2);
            var payableAmount=((Number(this.state.cbid)*(Number(this.state.cprice)-Number(this.props.placeOrderData.discount))*Number(this.props.placeOrderData.lotSize))).toFixed(2);
            this.setState({
                totalOrderQty:(Number(this.state.cbid)*Number(this.props.placeOrderData.lotSize)),
                //retailDiscount:((Number(this.props.placeOrderData.discount)*Number(this.state.cbid)*Number(this.props.placeOrderData.lotSize)).toFixed(2)),
                totalAmount: total,
                payableAmount:payableAmount
            });
        }
        else
        {
            var price = 0;
            var bid=0;
            if(Number(this.state.bid1)!=0 && Number(this.state.price1)>price)
            {
                bid=Number(this.state.bid1);
                price = Number(this.state.price1);                
            }
            if(Number(this.state.bid2)!=0 && Number(this.state.price2)>price)
            {
                bid=Number(this.state.bid2);
                price = Number(this.state.price2);                
            }
            if(Number(this.state.bid3)!=0 && Number(this.state.price3)>price)
            {
                bid=Number(this.state.bid3);
                price = Number(this.state.price3);                
            }

            var total = (Math.round((bid)*Number(this.props.placeOrderData.lotSize)*price*100)/100).toFixed(2);
            var payableAmount = 0;
            if(price>0)
                payableAmount=(((bid)*((Number(price)-Number(this.props.placeOrderData.discount))*Number(this.props.placeOrderData.lotSize)))).toFixed(2);
            this.setState({
                totalOrderQty:(bid)*Number(this.props.placeOrderData.lotSize),
                //retailDiscount:((bid)*Number(this.props.placeOrderData.discount)*Number(this.props.placeOrderData.lotSize)).toFixed(2),
                totalAmount:total,
                payableAmount:payableAmount
            });
            
        }
    }

    onTypeSelected= (selected) => {
        var item = this.state.types.filter(i => i.value === selected)[0];
        if (item === this.state.types[0])
        {
            this.setState({selectedType:0,
            // cbid:this.props.placeOrderData.cbid==undefined?(this.props.placeOrderData.lot==undefined?'0':this.props.placeOrderData.lot):this.props.placeOrderData.cbid,
            // bid1:this.props.placeOrderData.lot==undefined?(this.props.placeOrderData.cbid==undefined?'0':this.props.placeOrderData.cbid):this.props.placeOrderData.lot,
            // bid2:this.props.placeOrderData.lot2==undefined?'0':this.props.placeOrderData.lot2,
            // bid3:this.props.placeOrderData.lot3==undefined?'0':this.props.placeOrderData.lot3
            }, ()=>this.evaluate());
        }
        else
        {
            this.setState({
                selectedType:1, 

                // cbid:this.props.placeOrderData.cbid==undefined?(this.props.placeOrderData.lot==undefined?'0':this.props.placeOrderData.lot):this.props.placeOrderData.cbid,
                // bid1:this.props.placeOrderData.lot==undefined?(this.props.placeOrderData.cbid==undefined?'0':this.props.placeOrderData.cbid):this.props.placeOrderData.lot,
                // bid2:this.props.placeOrderData.lot2==undefined?'0':this.props.placeOrderData.lot2,
                // bid3:this.props.placeOrderData.lot3==undefined?'0':this.props.placeOrderData.lot3
            }, ()=>this.evaluate());
        }
    }

    onDematSelected= (selected) => {
        var index=-1;
        this.state.dematAccList.map((o, i) => 
        { 
            if(o.name==selected) 
            {
                index=i; 

            }    

        });
        this.setState({selectedDemat:index, dematAcc:selected});
    }

    categorySelected= (selected) => {
        var index=-1;
        this.state.category.map((o, i) => 
        { 
            if(o.name==selected) 
            {
                index=i; 

            }    

        });
        this.setState({categoryIndex:index, selectedCategory:selected});
    }

    closePopup() {
        this.setState({
            showDialog: false
        });
        
        this.props.showOrders();
    }

    closePopup1() {
        this.setState({
            reconfirm: false,
            header:'',
            message:''
        });
    }

    closeMessagePopup(){
        this.setState({showMessageDailog:false, header:'', message:''});
    }

    closeReconfirmPopup(){
        this.setState({reconfirm:false, header:'', message:''});
        this.props.showOrders();
    }

    continuePayment()
    {
 
          
        var upiCheck=false;
        if(this.props.placeOrderData.modified==undefined)
        {
            if(this.state.upiId.value!=null && this.state.upiId.value!="")
            {                          
                this.props.allowedUPI.map((o, i) => 
                { 
                    if(!upiCheck)
                        upiCheck = this.state.upiId.value.endsWith(o);    
                });            
            }    
            else
            {
                this.setState({showMessageDailog:true, header:'Error', message:'BHIM UPI ID is mandatory'});
                return;
            }
        }
        else
        {
            upiCheck=true;
        }
            if(upiCheck)
            {
                this.setState({inProgress:true, header:'', message:''});

                var activityType = "";
                var lot="";
                var price="";
                var activityType2 = "";
                var lot2="";
                var price2="";
                var activityType3 = "";
                var lot3="";
                var price3="";
                if(this.props.placeOrderData.modified!=undefined)
                {       
                    if(this.state.selectedType==1)
                    {
                        if(this.props.placeOrderData.bidRefNum!="" &&this.props.placeOrderData.bidRefNum!=undefined && this.props.placeOrderData.bidRefNum!=null)
                        {                            
                            activityType="modify";  
                            lot= this.state.bid1;   
                            price = this.state.price1;   
                        }
                        else if(this.state.bid1>0)
                        {
                            activityType="new";  
                            lot= this.state.bid1;   
                            price = this.state.price1;
                        }
                        if(this.props.placeOrderData.bidRefNum2!="" && this.props.placeOrderData.bidRefNum2!=undefined && this.props.placeOrderData.bidRefNum2!=null)
                        {
                            activityType2="modify";
                            lot2=this.state.bid2;
                            price2=this.state.price2;
                        }
                        else if(this.state.bid2>0)
                        {
                            activityType2="new";  
                            lot2= this.state.bid2;   
                            price2 = this.state.price2;
                        }
                        if(this.props.placeOrderData.bidRefNum3!="" && this.props.placeOrderData.bidRefNum3!=undefined && this.props.placeOrderData.bidRefNum3!=null)
                        {
                            activityType3="modify";
                            lot3=this.state.bid3;
                            price3=this.state.price3;
                        }
                        else if(this.state.bid3>0)
                        {
                            activityType3="new";  
                            lot3= this.state.bid3;   
                            price3 = this.state.price3;
                        }
                    }
                    else
                    {
                        activityType="modify"; 
                        lot=this.state.cbid;
                        price=this.state.cprice;
                        if(this.props.placeOrderData.lot2>0)
                        {
                            activityType2="cancel"; 
                            lot2=this.props.placeOrderData.lot2;
                            price2=this.props.placeOrderData.price2;
                        }
                        if(this.props.placeOrderData.lot3>0)
                        {
                            activityType3="cancel"; 
                            lot3=this.props.placeOrderData.lot3;
                            price3=this.props.placeOrderData.price3;
                        }
                    }          
                    
                }
                else
                { 
                    if(this.state.selectedType==1)
                    {
                        if(this.state.bid1>0)
                        {
                            activityType="new";
                            lot=this.state.bid1;
                            price=this.state.price1; 
                        }
                        if(this.state.bid2>0)
                        {
                            activityType2="new";
                            lot2=this.state.bid2;
                            price2=this.state.price2; 
                        }
                        if(this.state.bid3>0)
                        {
                            activityType3="new";
                            lot3=this.state.bid3;
                            price3=this.state.price3; 
                        }
                    }
                    else if(this.state.selectedType==0)
                    {
                        activityType="new";
                        lot=this.state.cbid;
                        price=this.state.cprice;
                    }
                }

                var requestData = {
                    request: {
                        data: {
                            symbol:this.props.placeOrderData.symbol,    
                            clientName:this.state.dematAccList[this.state.selectedDemat].clientName,  
                            applicationNumber:this.props.placeOrderData.applicationNumber==undefined?'':this.props.placeOrderData.applicationNumber,   
                            atCutOff:this.state.selectedType==0?true:false,
                            multiBid:this.state.selectedType==1?true:false,
                            lot:lot,
                            lot2:lot2,
                            lot3:lot3,
                            lotSize:this.props.placeOrderData.lotSize,
                            price:price,
                            price2:price2 === "0.00"? "":price2,
                            price3:price3 === "0.00"? "":price3,
                            pan:this.state.dematAccList[this.state.selectedDemat].pan,
                            dpId:this.state.dematAccList[this.state.selectedDemat].dpId,
                            upi:this.props.placeOrderData.upi==undefined?this.state.upiId.value:this.props.placeOrderData.upi,
                            bidRefNum:(this.props.placeOrderData.bidRefNum!=undefined && this.props.placeOrderData.bidRefNum!=null)?this.props.placeOrderData.bidRefNum:'',
                            bidRefNum2:(this.props.placeOrderData.bidRefNum2!=undefined && this.props.placeOrderData.bidRefNum2!=null)?this.props.placeOrderData.bidRefNum2:'',
                            bidRefNum3:(this.props.placeOrderData.bidRefNum3!=undefined && this.props.placeOrderData.bidRefNum3!=null)?this.props.placeOrderData.bidRefNum3:'',
                            depository:this.state.dematAccList[this.state.selectedDemat].type,
                            clientBenId:this.state.dematAccList[this.state.selectedDemat].value,
                            activityType:activityType,
                            activityType2:activityType2,
                            activityType3:activityType3,
                            category: ((activityType === "modify" || activityType === "cancel") ? this.props.placeOrderData.category : this.checkCategory(this.state.selectedCategory)),
                            appKey: getItemByKey(localStorageKeys.PIB_APP_KEY) !== "" ? JSON.parse(getItemByKey(localStorageKeys.PIB_APP_KEY)) : ""
                        }
                    }
                };

                this.setState({inProgress:true, header:'', message:''});
                addIPO(requestData, this.parseAddIPOResponse.bind(this), this.parseAddIPOError.bind(this));    
            }
            else
            {
                var upis=""+(this.props.allowedUPI);
                this.setState({showMessageDailog:true, header:'Error', message:'The UPI id entered by you does not support IPO one time mandates. Request you to kindly enter valid UPI id from below handlers only:\n'+(upis).replace(/,/g, ", ")});
            }
    
        
    }

    checkCategory(name){
             var categoryVal = "";  
        switch(name){
            case "Individual":
                categoryVal = "IND";
                break;

            case "Employee":
                categoryVal = "EMP";
                break;

            case "Policy Holder":
                categoryVal = "POL";
                break;

        }
        return categoryVal;
    }

    parseAddIPOResponse(){
        this.setState({
            showDialog: true,
            inProgress:false,
            header:"",
        });
    }

    parseAddIPOError(error){
        this.setState({
            showMessageDailog:true,
            inProgress:false,
            header:"Error",
            message:error.message!="Request Failed"?error.message:"Request failed, Please try again."
        })
    }

    render()
    {
        return (
            
            <div className="orderbookbase-content-holder fillheight fillwidth" style={this.state.showMessageDailog?{pointerEvents:'none'}:{pointerEvents:'all'}}>
                {this.state.inProgress ? blockProgressBar(): ''}
                <div className="row contentpanel widget-title">
                    <div className="col-xs-6 col-lg-6 col-md-6  watchlist-title-holder contentpanel">
                        IPO - Place Order
                    </div>
                    <div className="col-xs-6 col-lg-6 col-md-6 contentpanel text-right">
                            {this.props.userId!=undefined ? <span>Client Id: {this.props.userId}</span>:null}
                    </div>
                </div>
                <div className="row hdivider" style={{marginLeft:'0px', marginRight:'0px'}}> </div>
                <div className="ipoScroll" >
                    <div className="row">
                        <div className="col-3">
                            <strong>
                                <span style={{fontSize:'14px'}}>
                                    {this.props.placeOrderData.symbol}
                                </span>
                                - {this.props.placeOrderData.name}
                            </strong>
                        </div>
                        <div className="col-3">
                        {(this.state.category.length>1?
                                        <Dropdown list={this.state.category}
                                        selected={this.props.placeOrderData.category==undefined?'':this.state.selectedCategory}
                                        dropdownType={commonDropDownType.DEFAULT}
                                        onSelectionChanged={this.categorySelected}/>:
                                        (this.state.category.length==1?this.state.category[0].name:''))}
                        </div>
                        <div className="col-6 text-right setColor">
                            <strong>{this.props.placeOrderData.type}</strong>
                        </div>
                    </div>
                    <div className="row hdivider" style={{marginLeft:'0px', marginRight:'0px'}}> </div>
                    {this.state.payment?
                        <div>
                            <div className="row" style={{marginTop:'10px', marginBottom:'10px'}}>
                                <div className="col-2">
                                    <span className="colTitle">BANK ACCOUNT</span>
                                </div>
                                <div className="col-4 middleAlign" >
                                    {this.state.dematAccList[this.state.selectedDemat].bank} - {this.state.dematAccList[this.state.selectedDemat].linkedAcc}
                                </div>
                                <div className="col-2">
                                    <span className="colTitle">UPI ID</span>
                                </div>
                                <div className="col-4">
                                    {this.props.placeOrderData.modified==undefined?
                                    <input type="text" 
                                     className="upiId" ref={(e) => this.state.upiId = e} autoFocus name="upiId" style={{color:'#FFF'}}/>
                                     :<input type="text" 
                                     className="upiId" value={this.state.upiId} autoFocus name="upiId" style={{color:'#FFF'}}/>}

                                </div>
                            </div>                           
                            
                            <div className="row">
                                <div className="col-6 term-condition-text">
                                    <span onClick={this.findUPI.bind(this)}>How to find your UPI ID?</span>
                                </div>
                                <div className="col-6 term-condition-text text-right">
                                    <span onClick={this.createUPI.bind(this)}>Step to create UPI ID?</span>
                                </div>
                            </div>

                            <div className="row hdivider" style={{marginLeft:'0px', marginRight:'0px'}}> </div>
                            <div className="row" style={{marginTop:'10px', marginBottom:'10px'}} > 
                                <div className="col-12 text-center term-condition-text">
                                    <strong style={{fontSize:'14px'}}> By Clicking Continue, you accept <span onClick={this.TermsCondition.bind(this)}>Terms & Conditions</span> </strong>                        
                                </div>
                            </div>
                            <div className="row hdivider" style={{marginLeft:'0px', marginRight:'0px'}}> </div>
                            <div className="row" >
                            <div className="col-12 text-center">
                                <strong>Eligible discount will be applied post successful bid</strong>
                            </div>
                                <div className="col-12 text-center"  style={{fontSize:'16px'}}>
                                    <span className="setColor">Payable Amount</span>
                                    <br></br>                    
                                    <strong><i className="fa fa-rupee"></i> {comaSeparator(this.state.payableAmount)} </strong> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <button className="bidButton" onClick={(e) => {
                                        (!this.state.showMessageDailog && !this.state.reconfirm && !this.state.showDialog)?this.bankTransactionsShow(e):e.preventDefault()
                                    }}
                                    style={{padding:'8px 30px', marginRight:'50px'}}>Back</button>

                                    <button className="bidButton" onClick={(e) => {
                                        (!this.state.showMessageDailog && !this.state.reconfirm && !this.state.showDialog)?this.continuePayment():e.preventDefault()
                                    }} style={{padding:'8px 30px'}}>CONTINUE</button>
                                </div>
                            </div>
                        </div>
                    :
                    <div>
                        <div className="row" style={{marginTop:'10px', marginBottom:'10px'}}>
                            <div className="col-4"></div>
                            <div className="col-4">
                                <div className="order-radio">
                                    <RadioButtons 
                                        className="flex-space-between" 
                                        buttons={this.state.types} 
                                        selected={this.state.selectedType}
                                        activeButton={this.state.types[this.state.selectedType].value}
                                        callbackHandler={this.onTypeSelected}/>
                                </div>
                            </div>
                        </div>
                        <div className="row hdivider" style={{marginLeft:'0px', marginRight:'0px'}}></div>
                        <div className="row">
                            <div className="col-3"></div>
                            <div className="col-6">
                                <div className="row addMargin">
                                    <div className="col-5 text-center">
                                        <strong style={{fontSize:'14px'}}>Lot</strong>
                                        <br></br>
                                        <span className="setColor">(Lot Size = {this.props.placeOrderData.lotSize})</span>
                                    </div>
                                    <div className="col-2"></div>
                                    <div className="col-5 text-center">
                                        <strong style={{fontSize:'14px'}}>Price</strong>
                                        <br></br>
                                        <span className="setColor"><i className="fa fa-rupee"></i>({this.state.minPrice.toFixed(2)}-{this.state.maxPrice.toFixed(2)})</span>
                                    </div>
                                </div>
                                {this.state.selectedType==0?
                                    <div className="row">
                                        <div className="col-5 text-center">
                                            <div className="row">
                                                <div className="col-3"></div>
                                                <div className="col-6 text-center">
                                                    <OrderNumberInput 
                                                    defaultValue={this.state.cbid}
                                                    onChange={(value)=>this.bidValueOnChange('cbid', value)}
                                                    isnumber={true}
                                                    minValue={0}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2 text-center" style={{fontSize:'14px'}}><strong>BID</strong></div>
                                        <div className="col-5 text-center ">
                                            <div className="row ">
                                                <div className="col-3 "></div>
                                                <div className="col-6 text-center">
                                                    <OrderNumberInput 
                                                    defaultValue={this.state.cprice}
                                                    isnumber={true}
                                                    isDisabled={true}
                                                    hideDiv={true}/>
                                                </div>
                                                {/* <div className="col-6 text-center form-control order-number-input" style={{ lineHeight: '1.5'}}>
                                                    {this.state.cprice}<br></br>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                :''}
                                {this.state.selectedType==1?
                                    <div>
                                        {(this.props.placeOrderData.lot==undefined) 
                                        || (this.props.placeOrderData.lot!=undefined && this.props.placeOrderData.lot!="0") || (this.props.placeOrderData.atCutOff)?
                                        <div className="row">
                                            <div className="col-5 text-center">
                                            <div className="row ">
                                                <div className="col-3 "></div>
                                                <div className="col-6 text-center">
                                                <OrderNumberInput 
                                                defaultValue={this.state.bid1}
                                                onChange={(value)=>this.bidValueOnChange('bid1', value)}
                                                isnumber={true}
                                                minValue={0}/>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="col-2 text-center" style={{fontSize:'14px'}}><strong>BID</strong></div>
                                            <div className="col-5 text-center">
                                            <div className="row ">
                                                <div className="col-3 "></div>
                                                <div className="col-6 text-center">
                                                <OrderNumberInput 
                                                defaultValue={this.state.price1}
                                                onChange={(value)=>this.bidValueOnChange('price1', value)}
                                                incrementvalue={this.state.tickSize==0?0.01:this.state.tickSize}
                                                isdecimal={true}/>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        :''}
                                        {(this.props.placeOrderData.lot2==undefined) || 
                                        (this.props.placeOrderData.lot2!=undefined && this.props.placeOrderData.lot2!="0") || (this.props.placeOrderData.atCutOff) ?
                                        <div className="row">
                                            <div className="col-5 text-center">
                                            <div className="row ">
                                                <div className="col-3 "></div>
                                                <div className="col-6 text-center">
                                                <OrderNumberInput 
                                                defaultValue={this.state.bid2}
                                                onChange={(value)=>this.bidValueOnChange('bid2', value)}
                                                isnumber={true}
                                                minValue={0}/>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="col-2 text-center" style={{fontSize:'14px'}}><strong>BID</strong></div>
                                            <div className="col-5 text-center">
                                            <div className="row ">
                                                <div className="col-3 "></div>
                                                <div className="col-6 text-center">
                                                <OrderNumberInput 
                                                defaultValue={this.state.price2}
                                                onChange={(value)=>this.bidValueOnChange('price2', value)}
                                                incrementvalue={this.state.tickSize==0?0.01:this.state.tickSize}
                                                isdecimal={true}/>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        :''}
                                        {(this.props.placeOrderData.lot3==undefined) 
                                        || (this.props.placeOrderData.lot3!=undefined && this.props.placeOrderData.lot3!="0")
                                        || (this.props.placeOrderData.atCutOff)?
                                        <div className="row">
                                            <div className="col-5 text-center">
                                            <div className="row ">
                                                <div className="col-3 "></div>
                                                <div className="col-6 text-center">
                                                <OrderNumberInput 
                                                    defaultValue={this.state.bid3}
                                                    onChange={(value)=>this.bidValueOnChange('bid3', value)}
                                                    isnumber={true}
                                                    minValue={0}/>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="col-2 text-center" style={{fontSize:'14px'}}><strong>BID</strong></div>
                                            <div className="col-5 text-center">
                                            <div className="row ">
                                                <div className="col-3 "></div>
                                                <div className="col-6 text-center">
                                                <OrderNumberInput 
                                                    defaultValue={this.state.price3}
                                                    onChange={(value)=>this.bidValueOnChange('price3', value)}
                                                    incrementvalue={this.state.tickSize==0?0.01:this.state.tickSize}
                                                    isdecimal={true}/>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        :''}
                                    </div>
                                :''} 
                                <div className="row">
                                    <div className="col-5 text-center">
                                        <span className="setColor">Order Qty: {this.state.totalOrderQty}</span>
                                    </div>
                                    <div className="col-2"></div>
                                    <div className="col-5 text-center">
                                        <span className="setColor">Amount: <i className="fa fa-rupee"></i>{comaSeparator(this.state.totalAmount)}</span>
                                        <br></br>
                                        <span className="setColor">Retail Discount: <i className="fa fa-rupee"></i>{comaSeparator(this.state.retailDiscount)}</span>
                                    </div>
                                </div>                           
                            </div>
                        </div>
                        <div className="row hdivider" style={{marginLeft:'0px', marginRight:'0px'}}> </div>
                        <div className="row funds-banks" style={{marginTop:'10px', marginBottom:'10px'}} > 
                            <div className={(this.state.dematAccList.length>1 && this.props.placeOrderData.modified==undefined)?"col-6 text-right marginTop":"col-6 text-right"}>
                                <strong style={{fontSize:'14px'}}>Demat Account Number (Beneficiary ID) </strong>                                                       
                            </div>
                            <div className="col-2 text-left">
                                {this.props.placeOrderData.modified!=undefined ?
                                    (this.state.dematAccList.length>1?
                                        <Dropdown list={this.state.dematAccList}
                                        selected={this.props.placeOrderData.clientBenId==undefined?'':this.state.dematAcc}
                                        dropdownType={commonDropDownType.DEFAULT} 
                                        onSelectionChanged={this.onDematSelected}/>:
                                        (this.state.dematAccList.length==1?this.state.dematAccList[0].name:'')):this.state.dematAccList[0].name}  
                            </div>
                        </div>
                        <div className="row hdivider" style={{marginLeft:'0px', marginRight:'0px'}}> </div>
                        <div className="row" >
                            <div className="col-12 text-center">
                                  <strong>Eligible discount will be applied post successful bid</strong>
                            </div>
                            <div className="col-12 text-center"  style={{fontSize:'16px'}}>
                                <span className="setColor">Payable Amount   </span>
                                <br></br>                    
                                <strong><i className="fa fa-rupee"></i> {comaSeparator(this.state.payableAmount)} </strong> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <button className="bidButton" onClick={(e) => {
                                        (!this.state.showMessageDailog && !this.state.reconfirm && !this.state.showDialog)?
                                        this.props.showIPOList(e):e.preventDefault()
                                    }}
                                style={{padding:'8px 30px', marginRight:'50px'}}>Back</button>
                                
                                <button className="bidButton" onClick={(e) => {
                                        (!this.state.showMessageDailog && !this.state.reconfirm && !this.state.showDialog)?this.bankTransactionsShow(e):e.preventDefault()
                                    }}
                                style={{padding:'8px 30px'}}>CONTINUE</button>                                
                            </div>
                        </div>
                    </div>}  
            </div>
            {this.state.showDialog ? 
                <Dialog header="Your Bid request has been received" message="Go to BHIM app or your mobile banking app to approve the UPI request for blocking of the funds. The IPO bid will only be processed after approval." 
                    positiveButtonName="OK" positiveClick={this.closePopup.bind(this)} postiveIcon={true}/>
                :''}

            {this.state.showMessageDailog ? 
                <Dialog header={this.state.header} message={this.state.message} 
                    positiveButtonName="OK" positiveClick={this.closeMessagePopup.bind(this)} />
                :''}

            {this.state.reconfirm ? 
                <Dialog header={this.state.header} message={this.state.message} 
                    positiveButtonName="OK" positiveClick={this.closeReconfirmPopup.bind(this)} 
                    negativeButtonName="Cancel" negativeClick={this.closePopup1.bind(this)}
                    />
                :''}            
            </div>           
            
        );

    }
}

export default IPOPlaceOrder;