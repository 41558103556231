import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import TabbarBaseWidget from '../base/TabbarBaseWidget'
import EquityTabContent from './EquityTabContent'
import FNOTabContent from './FNOTabContent'
import CurrencyTabContent from './CurrencyTabContent'
import CommodityTabContent from './CommodityTabContent'
//import TransferLogPopup from './TransferLogPopup'
import { assetClasses } from '../../common/constants'
import { getAssetTabs } from '../../common/permissions'

export const positionType = { today: "TODAY'S POSITION", all: "ALL POSITION" };


class NetPositions extends Component {

    constructor(props) {
        super(props);
        var tabs = getAssetTabs();
        var index=tabs.indexOf('Spread')
        if(index!=-1){
            tabs.splice(index,1)
        }
        this.state = {
            tabs: tabs,
            selectedsegment: tabs[0],
            showTransferLog: false,
            transferLogFilter: [{ name: "ALL", key: "all" }, { name: "TRANSFER TO CARRY FORWARD", key: "carryForward" }, { name: "TRANSFER TO INTRADAY", key: "intraday" }],
            selectedday: "day",
            dayDisabled: false
        }
    }


    onTabClicked(tabName) {
        switch (tabName) {
            case assetClasses.equity:
                this.setState({ selectedsegment: assetClasses.equity, selectedday: "day", dayDisabled: true });
                break;
            case assetClasses.fno:
                this.setState({ selectedsegment: assetClasses.fno, selectedday: "day",dayDisabled: false });
                break;
            case assetClasses.currency:
                this.setState({ selectedsegment: assetClasses.currency, selectedday: "day",dayDisabled: false });
                break;
            case assetClasses.commodity:
                this.setState({ selectedsegment: assetClasses.commodity, selectedday: "day",showTransferLog: false, dayDisabled: false });
                break;
            default: break;
        }
    }

    getContentTab(tab) {
        switch (tab) {
            case assetClasses.equity:
                return <EquityTabContent ref="equityRef" />
            case assetClasses.fno:
                return <FNOTabContent ref="fnoRef" selectedday={this.state.selectedday} />
            case assetClasses.currency:
                return <CurrencyTabContent ref="currencyRef" selectedday={this.state.selectedday} />
            case assetClasses.commodity:
                return <CommodityTabContent ref="commodityRef" selectedday={this.state.selectedday} />
            default: break;
        }
    }

    onCollapseClick() {
        this.props.history.push("/home/dashboard");
    }

    onClickRefresh() {
        if (this.state.showTransferLog) {
            this.refs.transferlogRef.requestTransferLog();
        }
        else {
            switch (this.state.selectedsegment) {
                case assetClasses.equity:
                    this.refs.equityRef.getWrappedInstance().requestEquityNP();
                    break;
                case assetClasses.fno:
                    this.refs.fnoRef.getWrappedInstance().requestFnONP();
                    break;
                case assetClasses.currency:
                    this.refs.currencyRef.getWrappedInstance().requestCurrencyNP();
                    break;
                case assetClasses.commodity:
                    this.refs.commodityRef.getWrappedInstance().requestCommodityNP();
                    break;
                default: break;
            }
        }
    }

    onDayChange = (day) => {

        this.setState({ selectedday: day });
    }

    render() {
        const additionalTabHeader = (
            <div className="contentpanel">
                <div className="row" style={{ minWidth: "410px" }}>
                    <div className="col-11 nopadding fillwidth">
                        <div className="row">
                            <div className="col-4 np-btn-parent fillwidth">
                                <button className={"fillwidth np-day-btn " + (this.state.selectedday === "day" ? "selected" : "")} onClick={() => {
                                    this.onDayChange('day')
                                }}>TODAY'S POSITIONS</button>
                            </div>
                            {
                                this.state.selectedsegment !== assetClasses.equity ?
                                    <div className="col-4 np-btn-parent nopadding fillwidth">
                                        <button className={"fillwidth np-day-btn " + (this.state.selectedday === "all" ? "selected " : "")
                                            /*NTO-1027    (this.state.selectedsegment === assetClasses.equity ? "disabled" : "")*/
                                        } onClick={() => {
                                            this.onDayChange('all')
                                        }} disabled={this.state.selectedsegment === assetClasses.equity}>ALL POSITION</button>
                                    </div> : null
                            }

                            {/* <div className="col-4 np-btn-parent nopadding fillwidth">
                                <button className="fillwidth np-day-btn" disabled>TRANSFER LOG</button>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-1 nopadding">
                        {this.state.inProgress ? '' :
                            <div className="watchlistoptions" style={{ paddingRight: "20px", paddingLeft: "20px", cursor: 'pointer' }}>
                                <img src="assets/svg/refresh.svg" style={{ height: 16 }} onClick={this.onClickRefresh.bind(this)} alt="refresh" />
                            </div>
                        }
                    </div>
                </div>

            </div>
        );
        return (
            <TabbarBaseWidget title="NET POSITIONS"
                tabs={this.state.tabs}
                onTabClicked={this.onTabClicked.bind(this)}
                additionalTabHeader={additionalTabHeader}
                onCollapseClick={this.onCollapseClick.bind(this)}
                id="netpostion-tab">
                <div className="np-tab-content-parent expanded">
                    {
                        this.getContentTab(this.state.selectedsegment)
                    }
                </div>
            </TabbarBaseWidget>
        );
    }
};
export default withRouter(NetPositions);