import React from 'react';
import { connect } from "react-redux";
import { inactivityTimeOut } from '../common/constants'
import { checkSessionValid } from '../actions/common'
import { setSessionCheck } from '../actions/login/submitLogin'
import { progressBar } from '../controls/progress'

class AutoLogout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            signoutTime: inactivityTimeOut,
            isSessionValid: false
        };
    }

    componentDidMount() {
        this.events = [
            'load',
            'mousemove',
            'mousedown',
            'click',
            'scroll',
            'keypress'
        ];

        for (var i in this.events) {
            window.addEventListener(this.events[i], this.resetTimeout);
        }

        this.setTimeout();
        if (this.props.checkSession)
            checkSessionValid(this.parseSessionResponse.bind(this), this.parseError.bind(this));
        else {
            this.setState({ isSessionValid: true });
            this.props.setSessionCheck();
        }
    }

    clearTimeoutFunc = () => {
        if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    };

    setTimeout = () => {
        this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
    };

    resetTimeout = () => {
        this.clearTimeoutFunc();
        this.setTimeout();
    };

    logout = () => {

        if (this.props.loginStatus) {
            console.log('Sending a logout request to the API...');
            this.destroy();
        }
    };

    destroy = () => {
        this.props.onSessionTimeout();
    };

    parseSessionResponse(response) {
        this.setState({ isSessionValid: true });
    }

    parseError(error) {
        console.log(error);
    }

    render() {
        // var progressindicator = (
        //     <div className="orderinput-progress-holder">
        //         <svg class="circular-progress" viewBox="25 25 50 50">
        //             <circle class="circular-progress_path" cx="50" cy="50" r="20"
        //                 fill="none" stroke-width="2" stroke-miterlimit="10" />
        //         </svg>
        //     </div>
        // );
        return (
            this.state.isSessionValid ? this.props.children : progressBar()
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loginStatus: state.loginStatus.status,
        checkSession: state.loginStatus.checkSession,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setSessionCheck: () => setSessionCheck(dispatch, true)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoLogout); 