import React from 'react';
import MyOrders from '../../../components/myorders/MyOrders'
import '../../../css/theme-dark/orderbook.css'
class Orderbook extends React.Component {

    render() {
        return (
            <div className="fillheight">
                <MyOrders />
            </div>
        );
    }

}

export default Orderbook;