
import React,{Component} from 'react'
import { connect } from 'react-redux';
import { UrlExtensions, StoreKeys, BaseUrl } from './common/constants'
import { httpPost } from './actions/base/api'
import  './css/summary.css'
import SummaryPassword from './portfolio/SummaryPassword'

import  SummaryTable from './portfolio/SummaryTable'
import  DetailsTable from './portfolio/DetailsTable'
import { setCookie } from './common/utils'
//import FNO_Data from './FNO_Data.json'

class Summary extends Component{
   
   constructor(props){
      super(props)
      this.state = {
          error: null,
          isLoaded: false,
          password: '',
          change_pwd_clicked: true,
          portfolio_data: {},
          isValidUser:false
       };
  }
 
  onInputChangeParent = e => {
           this.setState({ password: e.target.value });
  }
    
  getPasswordAgain(){
      alert("you have entered wrong password please enter correct password again");
  }
  
  onChangePasswordClicked() {
      this.setState({ change_pwd_clicked: !this.state.change_pwd_clicked });
  }

  componentDidMount() {
  setCookie("REDIRECT_TO", null, 0);
   document.body.style.color = "black "
   document.body.style.backgroundColor = "white"
   document.getElementById("root").style.backgroundColor = "white"
   document.getElementById("root").style.overflow = "scroll"

        setInterval(() => {
          if(!this.state.change_pwd_clicked)
            this.loadingDataFromServer();
       }, 4000)
      }

   /*
   shouldComponentUpdate(nextProps, nextState) {
         console.log("State check--->"+(JSON.stringify(this.state.portfolio_data) === JSON.stringify(nextState.portfolio_data)))
         if(this.state.change_pwd_clicked === true){
             return true;
         }

         return ((JSON.stringify(this.state.portfolio_data) === JSON.stringify(nextState.portfolio_data))) 
    }
   componentDidUpdate(prevProps, prevState){
    if (JSON.stringify(prevState.portfolio_data) !== JSON.stringify(this.state.portfolio_data)) {
        console.log('--------->'+true);
        this.state.portfolio_data= FNO_Data  
    }
   }
   */

   loadingDataFromServer=()=>{
    
      var requestData = {
          request: {
              data: {
                "isCustomized" : "0",
                "password" : this.state.password,
              }
          }
      } 
      
      this.getSummaryDetails(requestData, this.parsePortfolioResponse.bind(this), this.parseError.bind(this));
   }
  
  getSummaryDetails = function (requestData, responseCallback, errorCallback) {
   var target = BaseUrl + UrlExtensions.CLIENT_PORTFOLIO;    
   httpPost(target, requestData)
   .then(function (response) {
       //console.log('dddrequest succeeded with JSON response', response);        
       responseCallback(response);
   }).catch(function (error) {
       //console.log('dddrequest failed', error);
       errorCallback(error);
   });
}
parsePortfolioResponseRefresh(){
      this.loadingDataFromServer();  
}
parsePortfolioResponse(responseData) {
  
  this.state.isValidUser = responseData.response.data.clientsData.validUser;
  
  if(this.state.isValidUser === true){
    //Object.assign(this.state, { isLoaded: true,        portfolio_data : responseData.response.data.clientsData,        error : responseData.response.infoMsg,        change_pwd_clicked : false, });
        this.setState({
        isLoaded :  true,
        error : responseData.response.infoMsg,
        change_pwd_clicked : false,
        portfolio_data: responseData.response.data.clientsData
    });
 }else{
     this.getPasswordAgain();
 }  
    
}

  parseError(err) {
   this.setState({
       inProgress: false,
       error: err.message,
       change_pwd_clicked: false
   }, () => {
       //this.showCommonDialog(err.message);
   });
}

   render() {
       
  const { error, isLoaded, portfolio_data,change_pwd_clicked } = this.state;
  if (error) {
    return (
      <div className="errorMsgNoData">
      <div className="config-err-ref ">{error}</div></div>
    )}
    

    if(change_pwd_clicked){
      return(
      <div>
         <SummaryPassword password={this.state.password} onInputChange={this.onInputChangeParent} onClickEvent={this.loadingDataFromServer}/>
     </div>
      );
   }

  //if (!isLoaded) {
    //return <div><img src='assets/images/loader.gif' width="150px" height="60px"/></div>;  }
    else{
      return (
         <div id="RootElement" style={{fontFamily:"sans-serif",background:"white",overflow:"scroll"}}>
                <div style={{"marginTop":"5px"}}>&nbsp;</div>
                <div style={{float:"left"}}><strong>Summary</strong></div><div style={{textAlign:"center",marginRight:"45px"}}><button style={{fontSize:"1rem",lineHeight:"1.5",borderRadius:".25rem",color: "#fff", backgroundColor: "#17a2b8",borderColor: "#17a2b8"}} className="" onClick={this.parsePortfolioResponseRefresh.bind(this)}>Refresh</button></div>
                <SummaryTable  portfolio_data={this.state.portfolio_data}/>
                    <br/>
                <div style={{"fontSize":"12px"}}><strong>Position Details - Client wise</strong></div>
                <DetailsTable  portfolio_data={this.state.portfolio_data}/>        
         </div>
      )
  }
   }
}

const mapStateToProps = (state) => {
    return {
  
    }
  }
  
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
       // showErrorDialog: (errordata) => showErrorDialog(dispatch, errordata),
        //commonDialog: (payload) => commonDialog(dispatch, payload),
        //toggleDialog: (payload) => toggleDialog(payload, dispatch)
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(Summary); 