
import React, { Component } from "react"
import {connect} from 'react-redux'
import { UrlExtensions, StoreKeys, BaseUrl } from './common/constants'
import {httpPost} from './actions/base/api'
import  './css/summary.css'
import SummaryPassword from './portfolio/SummaryPassword'
import SummaryClient from './portfolio/SummaryClient'

import  SummaryTable from './portfolio/SummaryTable'
import  DetailsTable from './portfolio/DetailsTable'
import { setCookie } from './common/utils'


class Summary_New extends Component{
    constructor(props){
        super(props)
        this.state = {
            error: null,
          isLoaded: false,
          password: '',
          clientId: '',
          change_pwd_clicked: true,
          portfolio_data: {},
          isValidUser:false,
        }
    }

onInputChangeParent = e => {
    this.setState({ password: e.target.value });
}

onInputChangeClientId = e => {
    this.setState({ clientId: e.target.value });
}

getPasswordAgain(){
alert("you have entered wrong password please enter correct password again");
}

onChangePasswordClicked() {
this.setState({ change_pwd_clicked: !this.state.change_pwd_clicked });
}

componentDidMount() {
setCookie("REDIRECT_TO", null, 0);
document.body.style.color = "black "
document.body.style.backgroundColor = "white"
document.getElementById("root").style.backgroundColor = "white"
document.getElementById("root").style.overflow = "scroll"

 setInterval(() => {
   if(!this.state.change_pwd_clicked && this.state.clientId!='')
     this.loadingDataFromServer();
}, 5000)

if (this.summClientIdRef)
        this.summClientIdRef.focus();
}

loadingDataFromServer=()=>{

var requestData = {
   request: {
       data: {
           "isCustomized" : "1",
           "password" : this.state.password,
           "client_id" : this.state.clientId
       }
   }
} 

this.getSummaryDetails(requestData, this.parsePortfolioResponse.bind(this), this.parseError.bind(this));
}

getSummaryDetails = function (requestData, responseCallback, errorCallback) {
var target = BaseUrl + UrlExtensions.CLIENT_PORTFOLIO;    
httpPost(target, requestData)
.then(function (response) {     
responseCallback(response);
}).catch(function (error) {
errorCallback(error);
});
}
parsePortfolioResponseRefresh(){
this.loadingDataFromServer();  
}
parsePortfolioResponse(responseData) {

this.state.isValidUser = responseData.response.data.clientsData.validUser;

if(this.state.isValidUser === true){
 this.setState({
 isLoaded :  true,
 error : responseData.response.infoMsg,
 change_pwd_clicked : false,
 portfolio_data: responseData.response.data.clientsData
});
}else{
this.getPasswordAgain();
}  

}

parseError(err) {
this.setState({
inProgress: false,
error: err.message,
change_pwd_clicked: false,
}, () => {
//this.showCommonDialog(err.message);
//alert(err.message);
});
}

render() {

const { error, isLoaded, portfolio_data,change_pwd_clicked,is_client_nclicked } = this.state;

//console.log(Object.keys(portfolio_data).length==0);
//console.log('error-->'+error);

if(change_pwd_clicked){
return(
<div>
  <SummaryPassword password={this.state.password} onInputChange={this.onInputChangeParent} onClickEvent={this.loadingDataFromServer}/>
</div>
);
}

if (error) {
    return (
    <div>
      <div style={{"marginTop":"5px"}}>&nbsp;</div>
        <div style={{display:"block"}}>
        <SummaryClient clientId={this.state.clientId} onInputChangeX={this.onInputChangeClientId} onClickEventX={this.loadingDataFromServer}/>  
        </div>
    <div className="errorMsgNoData">
    <div className="config-err-ref ">{error}</div></div>
    </div>
    )}
//if (!isLoaded) {
//return <div><img src='assets/images/loader.gif' width="150px" height="60px"/></div>;  }
else if(!Object.keys(portfolio_data).length==0){
return (
  <div id="RootElement" style={{fontFamily:"sans-serif",background:"white",overflow:"scroll"}}>

         <div style={{"marginTop":"5px"}}>&nbsp;</div>
         <div className="" style={{ background: "#fff", top: '14%' ,width:'400px'}} >
         <SummaryClient clientId={this.state.clientId} onInputChangeX={this.onInputChangeClientId} onClickEventX={this.loadingDataFromServer}/>
         </div>                      
<br/>
         <div style={{"marginTop":"5px"}}>&nbsp;</div>
         <div style={{float:"left"}}><strong>Summary</strong></div><div style={{textAlign:"center",marginRight:"45px"}}><button style={{fontSize:"1rem",lineHeight:"1.5",borderRadius:".25rem",color: "#fff", backgroundColor: "#17a2b8",borderColor: "#17a2b8"}} className="" onClick={this.parsePortfolioResponseRefresh.bind(this)}>Refresh</button></div>
         <SummaryTable  portfolio_data={this.state.portfolio_data}/>
             <br/>
         <div style={{"fontSize":"12px"}}><strong>Position Details - Client wise</strong></div>
         <DetailsTable  portfolio_data={this.state.portfolio_data} />    

  </div>
)
}
else
return(    
    <div>
    <div>
        <div style={{"marginTop":"5px"}}>&nbsp;</div>
        <SummaryClient clientId={this.state.clientId} onInputChangeX={this.onInputChangeClientId} onClickEventX={this.loadingDataFromServer}/>
    </div>
    
    <div className="errorMsgNoData">
    <div className="config-err-ref ">{error}</div></div>
    </div>
    )
}
}

const mapStateToProps = (state) => {
return {
}
}
const mapDispatchToProps = (dispatch, ownProps) => {
return {
};
};
export default connect(mapStateToProps, mapDispatchToProps)(Summary_New); 