import React from "react";
import { commonDropDownType } from "../common/constants"

import ToggleButton from '../controls/ToggleButton';
class Dropdown extends React.Component {

    constructor(props) {
        super(props);
        var temp = '';
        if (this.props.list && this.props.list.length > 0)
            temp = this.props.selected ? this.props.selected : this.props.list[0].name;

        var tograyout = (this.props.initialSelected && this.props.initialSelected === '-');
        this.state = {
            selected: temp,
            grayout: tograyout,
            selectToggle:this.props.selectToggle
        };
        
    }

    componentWillReceiveProps(newProps)
    {
        this.setState({selectToggle:newProps.selectToggle});
    }

    setSelected(value) {
        if (value === '' || value === '-') {
            this.setState({ grayout: true, selected: this.props.list[0].name });
        }
        else {
            this.setState({ grayout: false, selected: value })
        }
    }

    onDropDownClick(e, index) {
        e.preventDefault();
        if (this.props.dropdownType === commonDropDownType.DEFAULT)
            this.setState({ selected: e.target.innerText, grayout: false });

        if (this.props.onSelectionChanged) {
            this.props.onSelectionChanged(e.target.innerText, index);
        }
    }

    ontoggleButtonChange(name, selected) {
        this.props.ontoggleButton(name, selected);
    }


    getHeader() {
        if (this.props.dropdownType === commonDropDownType.CUSTOM) {
            return (this.props.customeHeader);
        } else if (this.props.dropdownType === commonDropDownType.ICON) {
            return (
                <div>
                    {this.props.iconName.indexOf("fa") === 0 ?
                        <i className={"fa " + this.props.iconName}
                            data-toggle={this.props.tooltip ? "tooltip" : ''} title={this.props.tooltip ? this.props.tooltip : ''}></i>
                        :
                        <img src={this.props.iconName} alt="icon"/>}
                </div>);
        } else {
            return (
                <div>
                    <i className="fa fa-angle-down arrow-icon"  ></i>
                    {this.props.selected ? this.props.selected : this.state.selected}
                </div>);
        }
    }

    render() {
        var headerContent = this.getHeader();
        return (
            <div className={"dropdown col-lg-12 col-md-12 nopadding " + this.props.style}>
                <button className="btn dropdown-toggle drop-down " type="button" id={this.props.id ? this.props.id : "drop-down"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" disabled={this.props.disabled}>
                    <span className={this.state.grayout ? 'dropdown-grayout' : ''} >{headerContent} </span>
                </button>
                <ul className={"dropdown-menu " + (this.props.alignDefault ? '' : "col-lg-12 col-md-12 ") + (this.props.position ? this.props.position : 'popper-inline')} aria-labelledby={this.props.id ? this.props.id : "drop-down"}
                >
                    {this.props.list.map(function (row, key) {
                        /* sub menu drop down check */
                        return (row.submenu ?
                            [
                                <li key={row.name} className="dropdown-submenu"><a className="dropdown-toggle" data-toggle="dropdown" onClick={(e) => this.onDropDownClick(e, key)}>{row.name}</a>
                                    <ul className="dropdown-menu">
                                        {row.submenu.map(function (data, key) {
                                            return [<li key={data} ><a onClick={(e) => this.onDropDownClick(e, key)}>{data}</a></li>,
                                            key < row.submenu.length - 1 && <li key={data + 1} role="separator" className="common-dropdown-divider divider" ></li>
                                            ]
                                        }, this)
                                        }
                                    </ul>
                                </li>,
                                key < this.props.list.length - 1 && <li key={row.name + 1} role="separator" className="common-dropdown-divider divider" ></li>
                            ] :
                            [
                                <li key={row.name}>
                                    {row.name == "Limit Against Holding"? 
                                    <a>{row.name}
                                        <div className="settingsTogglebutton">
                                            <ToggleButton  button1="ON " button2="OFF" 
                                            selected={this.state.selectToggle}
                                            onChange={(e)=>this.ontoggleButtonChange("Limit Against Holding", e)}/>
                                        </div>
                                    </a>
                                :<a onClick={(e) => this.onDropDownClick(e, key)}>{row.name}</a>} 
                                </li>,
                                key < this.props.list.length - 1 && <li key={row.name + 1} role="separator" className="common-dropdown-divider divider" ></li>
                            ])
                    }, this)}
                </ul>
            </div>
        );
    }

}

export default Dropdown;

