import React from 'react';
import { setCookie } from '../../common/utils'


class FindUpiIdMobile extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            
        }
    }
    componentWillMount(){
        setCookie("isFindMobileUPI", "", 0);
    }
    
    render() {
        return (
            <div className="fillheight" style={{overflow:'scroll'}}>
                <div className="row findupi_id">
                    <div className="col-12 header_upi">
                        <p><i class="fa fa-chevron-left"></i> How to find your UPI ID</p>
                    </div>
                </div>
                <div className="row">
                <div className="col-12 upi_Steps">
                        <div className="steps_circel" style={{marginTop:'30px', marginLeft:'11px'}}>
                            <span className="first_steps_dot"></span>
                            <span className="steps_line"></span>
                        </div>
                    </div>
                    <div className="col-12 upi_Steps">
                        <div className="steps_circel">
                            <span className="steps_dot">1</span>
                            <span className="steps_dot2"></span>
                            <span className="steps_line"></span>
                        </div>
                        <div className="upi_text">
                            <p>Launch UPI App</p>
                            <img src="assets/images/upi.png" style={{width:'120px', float:'left'}} />
                        </div>
                    </div>
                    <div className="col-12 upi_Steps">
                        <div className="steps_circel">
                            <span className="steps_dot">2</span>
                            <span className="steps_dot2"></span>
                            <span className="steps_line"></span>
                        </div>
                        <div className="upi_text">
                            <p>Go to Profile</p>
                        </div>
                    </div>
                    <div className="col-12 upi_Steps" style={{height:'12rem'}}>
                        <div className="steps_circel">
                            <span className="steps_dot">3</span>
                            <span className="steps_dot2"></span>
                            <span className="steps_line" style={{height:'8rem'}}></span>
                            <span className="first_steps_dot" style={{marginLeft:'12px'}}></span>
                        </div>
                        <div className="upi_text">
                            <p style={{marginBottom:'0px'}}>Check for UPI ID mention on the screen</p>
                            <img src="assets/images/upi-barcode.png" style={{width:'130px', float:'left'}} />
                        </div>
                    </div>
                </div>
                <div className="row upi_footer">
                    {/* <img src="assets/images/upi-footer.png" style={{width:'100%'}} /> */}
                    {/* <div className="col">
                        <p><i class="fa fa-chart-bar"></i></p>
                        <p>Market</p>
                    </div>
                    <div className="col">

                    </div>
                    <div className="col"></div>
                    <div className="col"></div>
                    <div className="col"></div> */}
                </div>
            </div>
        )
    }
}

export default FindUpiIdMobile;