import React from "react"
import SymbolSearch from '../../components/home/SymbolSearch'
import Dropdown from '../../controls/Dropdown'
import { commonDropDownType } from '../../common/constants'
import RadioButtons from '../../controls/RadioButtons'
//import ToggleSwitch from '../../controls/ToggleSwitch'

class AlertPopUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paramData: [{ name: 'NSE-CM', key: "NSE-CM" }, { name: 'NSE-FNO', key: "NSE-FNO" }, { name: 'NSE-CDS', key: "NSE-CDS" }, { name: 'BSE', key: "BSE" }],
            buttonOne: [{ key: 'Greater than equal to', value: 'greater' }, { key: 'Lesser than equal to', value: 'lesser' }],
            modeButtons: [{ key: 'SMS', value: 'sms' }, { key: 'Email', value: 'email' }, { key: 'Both', value: 'both' }],
        }
        this.onClosePopUp = this.onClosePopUp.bind(this);
        this.onClickSubmit = this.onClickSubmit.bind(this);
    }

    onClosePopUp() {
        if (this.props.closeECNPopUp)
            this.props.closeECNPopUp();
    }
    onClickSubmit() {

    }

    render() {
        return (
            <div className={"modal disabledbackground fade" + (this.props.showDialog ? "in dialog-show" : "dialog-hide")} id="AlertDialog" role="dialog">
                <div className="modal-dialog alertPop" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div>
                        <div className="modal-header">
                            <div className="col-xs-5 order-toggle-btns">
                                <SymbolSearch
                                // searchType={symbolSearchType.orderentry} isForSelection={true}
                                // callbackHandler={this.onSymbolSelected.bind(this)}
                                />
                            </div>
                            {/* /===Both selected=====/
                            < div className='row'>
                                <div className='col-xs-5'>
                                    <div >ICICI BANK</div>
                                    <div>ICICI Bank Ltd</div>
                                </div>
                                <div className=" widget-title-component marginleft order-toggle">
                                    <ToggleSwitch button1="NSE" button2="BSE" />
                                </div>
                                <div className='col-xs-4' style={{ marginLeft: 20 }}>
                                    <div >11,487.05</div>
                                    <div>51.76(1.25%)</div>
                                </div>
                            </div> */}
                        </div>
                        <div style={{ padding: 15, display: 'flex' }}>
                            <div style={{ width: '25%' }}>
                                <span>SELECT PARAMETERS</span>
                                <div className="alertDropdown"><Dropdown ref={excRef => this.excRef = excRef} dropdownType={commonDropDownType.DEFAULT} alignDefault={true} list={this.state.paramData}
                                // onSelectionChanged={this.onExchangeSelected.bind(this)}
                                />
                                </div>
                            </div>
                            <div style={{ marginLeft: 50 }}>
                                <span>ALERT ME</span>
                                <div style={{ marginTop: 10, marginLeft: -25 }}> <RadioButtons ref="rbValidity" buttons={this.state.buttonOne}
                                // callbackHandler={this.onValiditySelected.bind(this)} 
                                />
                                </div>
                            </div>
                        </div>
                        <div style={{ padding: 15, display: 'flex' }}>
                            <div style={{ width: '25%' }}>
                                <span>VALUE</span>
                                <div className="alertDropdown"><input name="value" type="tel" style={{ background: 'transparent', border: 0 }} />
                                </div>
                            </div>
                            <div style={{ marginLeft: 50 }}>
                                <span>MODE OF NOTIFICATION</span>
                                <div style={{ marginTop: 10, marginLeft: -25 }}> <RadioButtons ref="mode" buttons={this.state.modeButtons}
                                // callbackHandler={this.onValiditySelected.bind(this)} 
                                />
                                </div>
                            </div>
                        </div>
                        <div style={{ padding: 15 }}>
                            <span>Add Note</span>
                            <div className="alertDropdown" style={{ height: 60, margin: 10 }}><input name="value" type="tel" style={{ background: 'transparent', border: 0, }} />
                            </div>
                            <div className="col-xs-5 mt-row-header lite " style={{ marginLeft: 10 }}>
                                <button type='button' className='edit-alrt-pop-btn'>EDIT NOTE</button>
                                <button type='button' className='delete-alrt-pop-btn' style={{ marginLeft: 7 }}>DELETE NOTE</button>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span> NOTE: Alert expires in 30 days</span>
                            <div>
                                <button type="button" className="btn-footer order-cancel" onClick={this.props.onClose} >CANCEL</button>

                                <button type="button" className="btn-footer order-place"
                                // onClick={this.onPlaceOrder}  disabled={(this.state.symbol === undefined || this.state.error)} 
                                >SAVE</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div >

        )
    }
}
export default AlertPopUp;
