import { StoreKeys } from '../common/constants';

const intialState = {
    showDialog: false,
    header: "Ibulls",
    message: "Please try again!",
    buttons: [],
    closeBtn: true,
    type:undefined,
}

const common = (state = intialState, action) => {
    if (action.type === StoreKeys.SHOW_DIALOG) {
        const { showDialog, header, message, buttons, closeBtn, type } = action.payload;
        console.log(action.payload);
        return Object.assign({}, {}, {
            showDialog: showDialog,
            header: header ? header : state.header,
            buttons: buttons ? buttons : [],
            closeBtn: closeBtn !== undefined ? closeBtn : true,
            message: message ? message : state.message,
            type: type ? type : undefined
        });
    } else {
        return state;
    }
};

export default common;