import React, { Component } from 'react';
import TabbarBaseWidget from '../base/TabbarBaseWidget'
import { progressBar } from '../../controls/progress'
import Dropdown from '../../controls/Dropdown'
import { commonDropDownType,localStorageKeys} from "../../common/constants"
import { getItemByKey, maskUserData} from '../../common/utils'
import {exchangePledgeUnpledge} from '../../actions/ExchangePledge/ExchangePledgeUpledgeRequest'
import ExchangePledgeData from './ExchangePledgeData';
import ExchangeUnpledgeData from './ExchangeUnpledgeData';


class ExchangePledge extends Component {

    constructor(props) {
        super(props);
       
        this.state = {
            tabs: [],
            data: [],
            selectedsegment: 'EXCHANGE PLEDGE',
            selectedAcount:'',
            apiStatus:'',
            error: '',
            inProgress: false,
            sortColumnId:'',
            sortType:'',
            sortDataType:'',
            rePledge :[],
            reUnpledge:[],
            exErr:[],
            isPIB:false,
            isFlagEnabled : true
        }
    }


    onTabClicked(tabName) {
        switch (tabName) {
            case "EXCHANGE PLEDGE":
                this.setState({ selectedsegment: "EXCHANGE PLEDGE" },() => this.requestPledgeUnpledge());
                break;
            case "EXCHANGE UNPLEDGE":
                this.setState({ selectedsegment: "EXCHANGE UNPLEDGE" },() => this.requestPledgeUnpledge());
                break;
            default:
                this.setState({ selectedsegment: "EXCHANGE PLEDGE" },() => this.requestPledgeUnpledge());
                break;
        }
    }
    // shorting-----
    sortSymbols(eventId, sortTemp, sortDataType) {
        this.setState({
            sortColumnId : eventId,
            sortType : sortTemp,
            sortDataType : sortDataType
        });
      }
    

    componentDidMount() {
        if(window.location.href.indexOf("/home/ExchangePledge") != -1){
            window.sessionStorage.setItem(localStorageKeys.PIB_APP_KEY, "");
          }
        this.requestPledgeUnpledge();
        var accountDet = JSON.parse(getItemByKey(localStorageKeys.ACCOUNT_ID));
        this.setState({
            Mobileclientid: accountDet ? accountDet.accID : ''
        })
    }


    requestPledgeUnpledge() {
            let appKey = getItemByKey(localStorageKeys.PIB_APP_KEY) !== "" ? JSON.parse(getItemByKey(localStorageKeys.PIB_APP_KEY)) : "";
            var requestData = {
                request: {
                    data: {
                        reqType: 'EXCHANGE_PLEDGE_UNPLEDGE',
                        userId: window.sessionStorage.getItem("userId"),
                        appKey : appKey
                    }
                }
            };
            this.setState({ 
                inProgress: true, 
                error: '',
                data: [],
                selectedAcount:'',
                apiStatus:'',
                apiKey:''
            });
            exchangePledgeUnpledge(requestData, this.parsePledgeUnpledgeResponse.bind(this, this.state.selectedsegment), this.parsePledgeUnpledgeError.bind(this, this.state.selectedsegment));
            
        }

        parsePledgeUnpledgeResponse(selectedsegment, responseData) {
            console.log(responseData.response.data.state === "DISABLED");
            if (responseData.response.data.state === "DISABLED") {
                this.setState({ isFlagEnabled: false })
            } else {
                this.setState({tabs:  ["EXCHANGE PLEDGE", "EXCHANGE UNPLEDGE"]})
                
            let resExErr = responseData.response.data.exErr;
            let exErr = [];
            if(resExErr.includes("ALL")){
                     exErr.push("ALL");
                } else{
                        resExErr.includes("NSE") || resExErr.includes("BSE") ? exErr.push("CM"): "";
                        resExErr.includes("NFO") ? exErr.push("FO"): "";
                        resExErr.includes("CDS") ? exErr.push("CDS"): "";
                 }

                this.setState({
                    rePledge:responseData.response.data.ExchangePledgeUnpledge.rePledge,
                    reUnpledge:responseData.response.data.ExchangePledgeUnpledge.reUnpledge,
                    exErr:exErr
                })
                   
                switch(selectedsegment){
                    case "EXCHANGE PLEDGE":
                        if(this.state.rePledge.length === 0){
                            this.setState({
                            error : "No data Avaliable",
                            });
                        }else{
                            this.setState({
                            inProgress: false,
                            });
                        }
                        break;
                        case "EXCHANGE UNPLEDGE":
                            if(this.state.reUnpledge.length === 0){
                                this.setState({
                                    error : "No data Avaliable"
                                    });
                            }else{
                                this.setState({
                                inProgress: false,
                            });
                            }
                            break;
                }
            }
        }
    
        parsePledgeUnpledgeError(selectedsegment, error) {
            if (selectedsegment === this.state.selectedsegment) {
                this.setState({
                    inProgress: false,
                    error: error.message
                });
            }
        }


       

    render() {
        var errorHolder = (
            <div className="error-holder fillwidth">
                <span> {this.state.error} </span>
            </div>
        );
        return (
            <TabbarBaseWidget  tabs={this.state.tabs} onTabClicked={this.onTabClicked.bind(this)}>
            <div className="row contentpanel dp-tab-content">
            {this.state.error ? errorHolder :
                    <div className="fillheight fillwidth">

                            {this.state.isFlagEnabled ? this.state.inProgress ? progressBar() :
                                this.state.data.error ? this.state.errorHolder : 
                                [
                                    this.state.selectedsegment === "EXCHANGE PLEDGE" ?
                                      <ExchangePledgeData data={this.state.rePledge} selecttab={this.state.selectedsegment} exErr= {this.state.exErr} isPIB={this.state.isPIB}
                                      callback={this.sortSymbols.bind(this)}  requestPledgeUnpledge = {this.requestPledgeUnpledge.bind(this)} apiKey= {this.state.apiKey}
                                      sortColumnId={this.state.sortColumnId} sortType={this.state.sortType} sortDataType={this.state.sortDataType}
                                      />
                                :   <ExchangeUnpledgeData data={this.state.reUnpledge} selecttab={this.state.selectedsegment} 
                                    callback={this.sortSymbols.bind(this)}  requestPledgeUnpledge = {this.requestPledgeUnpledge.bind(this)} isPIB={this.state.isPIB}
                                        sortColumnId={this.state.sortColumnId} sortType={this.state.sortType} sortDataType={this.state.sortDataType}
                                      />
                        
                                ]
                            :<div className="row"><div className="col-lg-4"></div><div className="col-lg-4"><h2>This feature will come soon !!</h2></div></div>}
                    </div> 
            }
            </div>

        </TabbarBaseWidget>
        )
    }
}

export default ExchangePledge;