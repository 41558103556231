//components
import React from "react";
import OverlayMenu from "./OverlayMenu";
import StudyModal from "../Modals/StudyModal/StudyModal";
import MenuSelect from "./MenuSelect";

const StudyUI = props => {
  let tempStudies = [];

  Object.keys(props.studyList).map(key => {
    if (props.studyList.hasOwnProperty(key)) {
      tempStudies.push(props.studyList[key]);
    }
    return '';
  });

  tempStudies.sort((a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    } else if (b.name.toLowerCase() > a.name.toLowerCase()) {
      return -1;
    } else {
      return 0;
    }
  });

  if (props.ciq) {
    props.ciq.callbackListeners.studyOverlayEdit = props.toggleOverlay;
    props.ciq.callbackListeners.studyPanelEdit = props.openStudyModal;
  }

  return (
    <span>
      <OverlayMenu {...props} />
      <StudyModal {...props} />

      <MenuSelect
        options={tempStudies}
        keyName="study"
        name="name"
        handleOptionSelect={props.addStudy}
        removeStudy = {props.removeStudy}
        openStudyModal = {props.openStudyModal}
        needsCiq={true}
        ciq={props.ciq}
        menuId="studySelect"
        title="Studies"
        menuIcon={props.menuIcon}
        showTitle={!props.isSmall}
        isStudy = {true}
        clearAllStudies = {props.clearAllStudies}
      />
    </span>
  );
};

export default StudyUI;
