import React, { Component } from 'react';

class MtfResult extends Component {
    constructor() {
        super();
        this.state = {
            result: ''
        }
    }

    componentDidMount() {
        this.setState({ result: this.props.error ? this.props.error : this.props.message })
    }
    closeDialog() {
        if (this.props.closeMTF) {
            this.props.closeMTF();
        }
    }

    render() {
        return (
            <div className="modal-dialog mtf-modal">
                <div className="mtf-header">
                    <span>SUCCESS</span>
                </div>
                <div className="mtf-body">
                    <div className="mtf-message">
                        {
                            <span className="body-message">{this.props.MTFInprogress ? '' : "THANK YOU!"}</span>
                        }
                        <div className="mtf-logo">
                            <img src={(this.props.MTFInprogress ? "assets/svg/success2.svg" : "assets/svg/success.svg")} className="img-responsive mtf-image-result" />
                        </div>
                    </div>
                    <div className="mtf-question">
                        {
                            this.state.result ? <span>{this.state.result}</span> : ''
                        }
                    </div>
                </div>
                <div className="mtf-footer">
                    <div className="buttons">
                        <button type="button" className="mtf-buttons" onClick={this.closeDialog.bind(this)}>OK</button>
                    </div >
                </div>
            </div>
        );
    }
};
export default MtfResult;