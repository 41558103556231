
import { localStorageKeys, productTypes_FUT, assetClasses, indexTabs } from './constants'
import { getItemByKey,getConfigByKey } from './utils'


// ==============================================================
// Margin permissions

export const getMarginMode = function () {
    var ad = getItemByKey(localStorageKeys.ACCOUNT_DETAILS);
    var accdetails = JSON.parse(ad);
    if (accdetails && accdetails.marginMode) {
        return accdetails.marginMode;
    }
    return 'M';
}

export const getMTA = function (exchange) {
    var ad = getItemByKey(localStorageKeys.ACCOUNT_DETAILS);
    var accdetails = JSON.parse(ad);
    if (exchange === 'NSE') {
        if (accdetails && accdetails.mta_nse_permission && accdetails.mta_nse_permission === 'Y') {
            return true;
        }
    }
    else if (exchange === 'BSE') {
        if (accdetails && accdetails.mta_bse_permission && accdetails.mta_bse_permission === 'Y') {
            return true;
        }
    }
    return false;
}
// ==============================================================
// shortsell Permissions

export const getShortsell = function(exchangeType){
    var ad = getItemByKey(localStorageKeys.ACCOUNT_DETAILS);
    var accdetails = JSON.parse(ad);
    if (accdetails && exchangeType === 'NSE' ) {
        if(accdetails.shortsell_nse_permission === 'Y')
            return true;
        else 
            return false;
    } else {
        if(accdetails.shortsell_bse_permission === 'Y')
            return true;
        else 
            return false;
    }
}

// ==============================================================
// Asset Classes Permissions

export const isEquityPermitted = function () {
    var ad = getItemByKey(localStorageKeys.ACCOUNT_DETAILS);
    var accdetails = JSON.parse(ad);

    if (accdetails) {
        return (accdetails.equity_flag === 'Y' || accdetails.bse_permission === 'Y');
    }

    return false;
}

export const isNSEPermitted = function () {
    var ad = getItemByKey(localStorageKeys.ACCOUNT_DETAILS);
    var accdetails = JSON.parse(ad);

    if (accdetails) {
        return (accdetails.equity_flag === 'Y');
    }

    return false;
}

export const isBSEPermitted = function () {
    var ad = getItemByKey(localStorageKeys.ACCOUNT_DETAILS);
    var accdetails = JSON.parse(ad);

    if (accdetails) {
        return (accdetails.bse_permission === 'Y');
    }

    return false;
}

export const isFnOPermitted = function () {
    var ad = getItemByKey(localStorageKeys.ACCOUNT_DETAILS);
    var accdetails = JSON.parse(ad);

    if (accdetails) {
        return (accdetails.fno_permission === 'Y');
    }

    return false;
}

export const isFnOSpreadPermitted = function () {
    var ad = getItemByKey(localStorageKeys.ACCOUNT_DETAILS);
    var accdetails = JSON.parse(ad);

    if (accdetails) {
        return (accdetails.fno_nse_spd_permission === 'Y');
    }

    return false;
}

export const isCurrencyPermitted = function () {
    var ad = getItemByKey(localStorageKeys.ACCOUNT_DETAILS);
    var accdetails = JSON.parse(ad);

    if (accdetails) {
        return (accdetails.cds_nse_permission === 'Y');
    }

    return false;
}

export const isCommodityPermitted = function () {
    var ad = getItemByKey(localStorageKeys.ACCOUNT_DETAILS);
    var accdetails = JSON.parse(ad);

    if (accdetails) {
        return (accdetails.commodity_flag_mcx === 'Y' || accdetails.commodity_flag_ncdex === 'Y');
    }

    return false;
}

export const isShubhPlanPermitted = function () {
    var ad = getItemByKey(localStorageKeys.ACCOUNT_DETAILS);
    var accdetails = JSON.parse(ad);

    if (accdetails) {
        return (accdetails.shubh_plan_permission === 'Y');
    }

    return false;
}

export const isCommodityAPIFlg = function () {
    var ad = getItemByKey(localStorageKeys.ACCOUNT_DETAILS);
    var accdetails = JSON.parse(ad);

    if (accdetails) {       
        return accdetails.isCommodityAPIFlag;
    }

    return false;
}

export const isMCXPermitted = function () {
    var ad = getItemByKey(localStorageKeys.ACCOUNT_DETAILS);
    var accdetails = JSON.parse(ad);

    if (accdetails) {
        return (accdetails.commodity_flag_mcx === 'Y');
    }

    return false;
}

export const isNCDEXPermitted = function () {
    var ad = getItemByKey(localStorageKeys.ACCOUNT_DETAILS);
    var accdetails = JSON.parse(ad);

    if (accdetails) {
        return (accdetails.commodity_flag_ncdex === 'Y');
    }

    return false;
}

export const isMCX_FUT_Permitted = function () {
    var ad = getItemByKey(localStorageKeys.ACCOUNT_DETAILS);
    var accdetails = JSON.parse(ad);

    if (accdetails) {
        return (accdetails.commodity_flag_mcx_future === 'Y');
    }

    return false;
}

export const isMCX_OPT_Permitted = function () {
    var ad = getItemByKey(localStorageKeys.ACCOUNT_DETAILS);
    var accdetails = JSON.parse(ad);

    if (accdetails) {
        return (accdetails.commodity_flag_mcx_option === 'Y');
    }

    return false;
}

export const isNCDEX_FUT_Permitted = function () {
    var ad = getItemByKey(localStorageKeys.ACCOUNT_DETAILS);
    var accdetails = JSON.parse(ad);

    if (accdetails) {
        return (accdetails.commodity_flag_ncdex_future === 'Y');
    }

    return false;
}

export const isNCDEX_OPT_Permitted = function () {
    var ad = getItemByKey(localStorageKeys.ACCOUNT_DETAILS);
    var accdetails = JSON.parse(ad);

    if (accdetails) {
        return (accdetails.commodity_flag_ncdex_option === 'Y');
    }

    return false;
}

export const isBasketPermitted = function (){
    
    var fetchOrder = getItemByKey(localStorageKeys.USER_EDIS_DETAILS);
        if (fetchOrder !== undefined && fetchOrder !== "undefined") {
            var orderType = JSON.parse(fetchOrder);
            console.log("orderInput -----")
            console.log(orderType);
            if (orderType !== undefined && orderType !== null) {
                return !orderType.nonPoaFlag;
            }
            else {
                return true;
            }
        }
}



export const isFnOIntradayPermitted = function () {
    var ad = getItemByKey(localStorageKeys.ACCOUNT_DETAILS);
    var accdetails = JSON.parse(ad);

    if (accdetails) {
        return (accdetails.fno_int_permission === 'Y');
    }

    return false;
}

export const isSpanCalculatorPermitted = function () {
    var ad = getItemByKey(localStorageKeys.ACCOUNT_DETAILS);
    var accdetails = JSON.parse(ad);

    if (accdetails) {
        return (accdetails.span_permission === 'Y');
    }

    return false;
}

// ===============================================================
// Tabs

export const getAssetTabs = function (listObject) {
    var tabs = [];
    var tabList = listObject ? listObject : assetClasses;
    if (isEquityPermitted() && tabList.equity)
        tabs.push(tabList.equity);
    if (isFnOPermitted() && tabList.fno)
        tabs.push(tabList.fno);
    if (isFnOPermitted() && isFnOSpreadPermitted() && tabList.spread)
        tabs.push(tabList.spread)
    if (isCurrencyPermitted() && tabList.currency)
        tabs.push(tabList.currency);
    if (isCommodityPermitted() && tabList.commodity)
        tabs.push(tabList.commodity)

    return tabs;
}

export const getAssetTabs_W_Index = function () {
    var tabs = [];

    if (isEquityPermitted())
        tabs.push(assetClasses.equity.toUpperCase());
    if (isFnOPermitted()) {
        tabs.push(assetClasses.fno.toUpperCase());
        tabs.push(assetClasses.index.toUpperCase());
    }
    if (isCurrencyPermitted())
        tabs.push(assetClasses.currency.toUpperCase());
    if (isCommodityPermitted())
        tabs.push(assetClasses.commodity.toUpperCase())

    return tabs;
}


export const getMarginTabs = function () {
    var tabs = [];

    if(getItemByKey(localStorageKeys.MergeEqCom)=="Y")
    {
        tabs.push(assetClasses.eqcom);
    }
    else
    {
        if (isEquityPermitted())
            tabs.push(assetClasses.equity);
        if (isCommodityPermitted())
            tabs.push(assetClasses.commodity)
    }

    return tabs;
}

export const getIndexTabs = function () {
    var tabs = [];

    if (isEquityPermitted() || isFnOPermitted() || isCurrencyPermitted()) {
        tabs.push(indexTabs.nse);
        tabs.push(indexTabs.bse);
    }
    if (isCommodityPermitted()) {
        isNCDEXPermitted() && tabs.push(indexTabs.ncdex);
        isMCXPermitted() && tabs.push(indexTabs.mcx);
    }

    return tabs;
}

export const getFundList = function () {
    var tabs = [];
    if(getItemByKey(localStorageKeys.MergeEqCom)=="Y")
    {
        tabs.push({ key: 'Equity/Commodity', value: 'equity' });
    }
    else
    {       
        if (isEquityPermitted()) {
            tabs.push({ key: 'Equity', value: 'equity' });
        }
        /*if (isCommodityAPIFlg()=='synchronous')  {
            tabs.push({ key: 'Commodity', value: 'commodity' })
        }*/
        if (isCommodityPermitted())  {
            tabs.push({ key: 'Commodity', value: 'commodity' })
    }
    }
    return tabs;
}

// ==============================================================
// Product Types

export const getFnOProductTypes = function () {
    var pts = [];

    pts.push(productTypes_FUT[0]); // carry forward

    if (isFnOIntradayPermitted())
        pts.push(productTypes_FUT[1]); // intraday

    return pts;
}

// ==============================================================
//Get selected commodity exchange

export const getCommoditySelExchange = (isAll) => {
    if(isAll && isMCXPermitted() && isNCDEXPermitted())
        return "ALL"
    else if (isMCXPermitted())
        return "MCX";
    else if (isNCDEXPermitted())
        return "NCDEX";
    else
        return "";
}

export const newReportPermission = function () {
    if(getConfigByKey("PROFIT_LOSS_NEW"))   return true;
    else{
        let userId = window.sessionStorage.getItem('userId');
        let allClients = getConfigByKey("PROFIT_LOSS_NEW_CLIENTS");
        if (allClients) {
            return allClients.includes(userId);
        } else
            return false;
    }
}