import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setWatchlistExpandWidget, setWatchlistExpandWidgetState, setWatchlist1Selected, setWatchlist2Selected } from '../../actions/dashboard'
import { watchlistDownloadOptions } from '../../common/constants'
import Dropdown from '../../controls/Dropdown'
import { commonDropDownType, fileExportTypes, ModuleKeys } from "../../common/constants"
import BasicDialog from '../../controls/watchlist/BasicDialog'
import WatchlistGroupsDropdown from '../../controls/watchlist/WatchlistGroupsDropdown'
import SymbolSearchDialog from '../../controls/symbolsearch/SymbolSearchDialog'
import { showWatchlistError } from "../../actions/watchlist/watchlistaction"
import { progressBar } from '../../controls/progress'

class WatchlistBaseWidget extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedWatchilist: '',
            customwatchlistgroups: [],
            initialSelectionDone: false,
            selecttodelete: false,
            showdeletescripsconfirmation: false,
            showaddscripsdialog: false,
            disabledWatchlist: {},
            onWatchlisttab: '',
        };

        this.startEditingWatchlist = this.startEditingWatchlist.bind(this);
        this.startDeletingWatchlist = this.startDeletingWatchlist.bind(this);
        this.afterDeletingWatchlist = this.afterDeletingWatchlist.bind(this);
        this.askAddScrips = this.askAddScrips.bind(this);
        this.askAddNewWatchlist = this.askAddNewWatchlist.bind(this);
        this.onFavClicked = this.onFavClicked.bind(this);
        this.onGroupsLoaded = this.onGroupsLoaded.bind(this);
    }

    componentWillReceiveProps(nextprops) {
        if (nextprops.watchListGroups !== this.props.watchListGroups) {
            // this.componentDidMount();
            // this.initializeInitialSelection();
        }
        else if (this.props.componentType === ModuleKeys.WatchlistView1 &&
            nextprops.watchSelected2 !== this.props.watchSelected2) {

            this.setState({ disabledWatchlist: nextprops.watchSelected2 });
        }
        else if (this.props.componentType === ModuleKeys.WatchlistView2 &&
            nextprops.watchSelected1 !== this.props.watchSelected1) {

            this.setState({ disabledWatchlist: nextprops.watchSelected1 });
        }
        if (this.props.activeWidgetState && this.props.activeWidgetState.selectedwatchlistindex) {
            if (this.props.activeWidgetState !== this.activeWidgetState) {
                this.activeWidgetState = this.props.activeWidgetState;
                // prev state exist
                if (this.props.activeWidget !== ModuleKeys.ALL) {
                    // expanded
                    this.initializeInitialState();
                }
            }

        }
    }
    componentDidMount() {
        //WARNING: Reloads on expanding
        if (this.props.watchListGroups && this.props.watchListGroups.length > 1) {
            var customitems = this.props.watchListGroups.filter(function (i) {
                return i.id === "1";
            });

            var disitem = {};
            if (this.props.componentType === ModuleKeys.WatchlistView1) {
                disitem = this.props.watchSelected2;
            } else if (this.props.componentType === ModuleKeys.WatchlistView2) {
                disitem = this.props.watchSelected1;
            }

            this.setState({
                customwatchlistgroups: customitems,
                disabledWatchlist: disitem,
                initialSelectionDone: true
            }, () => {
                if (this.props.activeWidgetState) {
                    // prev state exist
                    if (this.props.activeWidget !== ModuleKeys.ALL) {
                        // expanded
                        this.initializeInitialState();
                    }
                    else if (this.props.activeWidget === ModuleKeys.ALL &&
                        this.props.activeWidgetState.prevWidget === this.props.componentType) {
                        // collapsed from expanded
                        this.initializeInitialState();
                    }
                    else {
                        this.initializeInitialSelection();
                    }
                }
                else {
                    this.initializeInitialSelection();
                }
            });
        }
    }



    onGroupsLoaded() {

    }


    initializeInitialState() {
        var statedata = this.props.activeWidgetState;
        this.refs.watchlistgroupsDropdown.setSelected(statedata.selectedwatchlistindex, true);
        this.setState({ disabledWatchlist: {} });

        if (this.props.componentType === ModuleKeys.WatchlistView1) {
            console.log('pk1 ' + this.props.watchSelected2.id);
            this.setState({ disabledWatchlist: this.props.watchSelected2 });
        }
        else if (this.props.componentType === ModuleKeys.WatchlistView2) {
            console.log('pk2 ' + this.props.watchSelected1.id);
            this.setState({ disabledWatchlist: this.props.watchSelected1 });
        }

        //TODO: if view side state also has to be maintaned, uncomment
        // this.setState({selecttodelete: statedata.selecttodelete}, () => {           
        //     if (statedata.selecttodelete && this.props.toggleDeletingScrips)
        //         this.props.toggleDeletingScrips(true);
        // });

        // reset 
        this.props.setWatchlistExpandWidgetState(undefined);
    }

    initializeInitialSelection() {
        if (this.props.componentType === ModuleKeys.WatchlistView1) {
            let existing = this.props.watchListGroups.filter(i => i.id === this.props.watchSelected1.id);

            if (existing.length > 0) {
                let indx = this.props.watchListGroups.indexOf(existing[0]);
                this.refs.watchlistgroupsDropdown.setSelected(indx, true);
            }
            else {
                this.refs.watchlistgroupsDropdown.setSelected(0, true);
            }
        }
        else if (this.props.componentType === ModuleKeys.WatchlistView2) {

            let existing = this.props.watchListGroups.filter(i => i.id === this.props.watchSelected2.id);

            if (existing.length > 0) {
                let indx = this.props.watchListGroups.indexOf(existing[0]);
                this.refs.watchlistgroupsDropdown.setSelected(indx, true);
            }
            else {
                let firstexist = this.props.watchListGroups.filter(i => i.id === this.props.watchSelected1.id);
                if (firstexist.length > 0)
                    this.refs.watchlistgroupsDropdown.setSelected(0, true);
                else
                    this.refs.watchlistgroupsDropdown.setSelected(1, true);
            }
        }
    }


    onExpandToggle(type) {
        //TODO: viewstate is just example, how to implement if view side state also has to be retained
        // showFilter not used now. just added.
        var viewstate = this.props.getActiveState();

        var statedata = {
            selectedwatchlistindex: 0,
            selecttodelete: this.state.selecttodelete,
            showfilter: viewstate.showFilter,

        };
        var items = this.props.watchListGroups.filter(i => i.id === this.state.selectedWatchilist.id);
        if (items.length > 0)
            statedata.selectedwatchlistindex = this.props.watchListGroups.indexOf(items[0]);

        if (this.props.activeWidget === ModuleKeys.ALL) {
            statedata.prevWidget = undefined;
            this.props.setWatchlistExpandWidget(type, statedata);
        } else {
            statedata.prevWidget = this.props.activeWidget;
            this.props.setWatchlistExpandWidget(ModuleKeys.ALL, statedata);
        }
    }

    onWatchlistSelected(selected) {
        var item = this.props.watchListGroups.filter(function (i) {
            return i.id === selected;
        });
        if (item.length > 0) {
            this.setState({ selectedWatchilist: item[0] }, () => {
                this.props.onWatchlistGroupSelected(this.state.selectedWatchilist);
                if (this.props.componentType === ModuleKeys.WatchlistView1) {
                    this.props.setWatchlist1Selected(item[0]);
                }
                else if (this.props.componentType === ModuleKeys.WatchlistView2) {
                    this.props.setWatchlist2Selected(item[0]);
                }

            });
        }
    }

    //=====================================================
    // Set Favourite

    onFavClicked(e) {

        var newfavid = "0";

        if (this.state.selectedWatchilist.favid === "0") { // not favourite before
            var existing_1 = this.props.watchListGroups.filter(i => i.favid === "1");
            var exising_2 = this.props.watchListGroups.filter(i => i.favid === "2");

            if (existing_1.length === 0) { // fav 1 do not exist
                newfavid = "1";
            }
            else if (exising_2.length === 0) { // fav2 do not exist
                newfavid = "2";
            }
            else {
                // both favs exist. so, no updating
                this.props.showWatchlistError({ header: "Set Favourite", message: "Only two watchlists can be set as favourites" });
                return;
            }

        }
        else {
            // already fav, unselecting.
            newfavid = "0";
        }

        if (this.props.setFavourite)
            this.props.setFavourite(this.state.selectedWatchilist.id, newfavid, this.afterUpdateFavourite.bind(this));
    }

    afterUpdateFavourite() {
        var updated = this.props.watchListGroups.filter(i => i.id === this.state.selectedWatchilist.id);
        if (updated.length > 0) {
            this.setState({ selectedWatchilist: updated[0] });
        } else {
            this.setState({ selectedWatchilist: this.props.watchListGroups[0] }, () => { this.refs.watchlistgroupsDropdown.setSelected(0, true); });
        }
    }

    //=====================================================

    startDeletingWatchlist() {
        this.props.startDeleteWatchlist(this.afterDeletingWatchlist);
    }

    afterDeletingWatchlist() {
        var item = this.props.watchListGroups.filter(i => i.id === this.state.selectedWatchilist.id);
        if (item.length === 0) {
            // Selected Group got Deleted
            var customFirst = this.props.watchListGroups.indexOf(this.props.watchListGroups.filter(i => (i.isEditable))[0]);
            if (customFirst !== -1) {
                this.refs.watchlistgroupsDropdown.setSelected(customFirst, true);
            } else {
                var mobileFirst = this.props.watchListGroups.indexOf(this.props.watchListGroups.filter(i => (i.isMobile))[0]);
                if (mobileFirst !== -1) {
                    this.refs.watchlistgroupsDropdown.setSelected(mobileFirst, true);
                } else {
                    var predefinedFirst = this.props.watchListGroups.indexOf(this.props.watchListGroups.filter(i => (i.isEditable === false))[0]);
                    if (predefinedFirst !== -1) {
                        this.refs.watchlistgroupsDropdown.setSelected(predefinedFirst, true);
                    } else {
                        // this.setState({ selectedWatchilist: '' });
                    }
                }
            }
        }
    }


    askAddNewWatchlist() {
        let checkCustomFieldCount = this.props.watchListGroups.filter((list) => {
            if (list.isEditable === true) {
                return list.name;
            } return false;
        });
        if (checkCustomFieldCount.length >= 5) {
            this.props.showWatchlistError({ header: 'Add Custom Watchlist', message: "Can't Add More than 5 Watchlist" });
        }
        else {
            if (this.props.startAddNewWatchlist)
                this.props.startAddNewWatchlist((newid) => {
                    var items = this.props.watchListGroups.filter(function (i) {
                        return i.id === newid;

                    });
                    if (items.length > 0) {
                        var index = this.props.watchListGroups.indexOf(items[0]);
                        this.refs.watchlistgroupsDropdown.setSelected(index, true);
                    }
                });
        }
    }

    startEditingWatchlist() {
        this.props.showWatchlistEditOptions(this.state.selectedWatchilist, this.props.getSymbolsCount(),
            () => {

                var item = this.props.watchListGroups.filter(i => i.id === this.state.selectedWatchilist.id);
                if (item.length > 0) {

                    this.setState({ selectedWatchilist: item[0] });
                    var index = this.props.watchListGroups.indexOf(item[0]);
                    this.refs.watchlistgroupsDropdown.setSelected(index, true);
                    if (this.props.afterColsUpdated)
                        this.props.afterColsUpdated(item[0]);

                }
            });

    }

    askAddScrips() {
        this.searchDialog.resetInput();
        this.setState({ showaddscripsdialog: true }, () => { this.searchDialog.setInputFocus() })
    }

    onAddScripCancel() {
        this.setState({ showaddscripsdialog: false });
    }

    onSymbolSelected(scripdata) {
        // addWatchlistSymbols(scripdata, this.state.selectedWatchilist.id, (error) => {
        //     console.log(error);
        //     this.setState({showaddscripsdialog: false});
        // });
        if (this.props.confirmAddSymbol)
            this.props.confirmAddSymbol(scripdata, this.state.selectedWatchilist.id, (error) => {
                if (!error) {
                    //success
                    this.props.onWatchlistGroupSelected(this.state.selectedWatchilist);
                    this.setState({ showaddscripsdialog: false });
                }
                else {
                    //TODO: show error dialog
                }
            });

    }

    askDeleteScrips() {
        if (this.state.selectedWatchilist.isEditable) {
            if (!this.state.selecttodelete) {
                this.setState({ selecttodelete: true }, () => {
                    if (this.props.toggleDeletingScrips && this.state.selectedWatchilist.isEditable)
                        this.props.toggleDeletingScrips(true);
                });
            }
            else {
                if (this.props.selectedSymbolsToDelete && this.props.selectedSymbolsToDelete.length > 0) {
                    this.setState({ showdeletescripsconfirmation: true });
                }
                else {

                    this.setState({ selecttodelete: false }, () => {
                        if (this.props.toggleDeletingScrips)
                            this.props.toggleDeletingScrips(false);
                    });
                }

            }

        }
        //TODO: show confirm button

    }

    tabHandlerFunc = (tab) => {
        this.setState({ onWatchlisttab: tab, selecttodelete: false })
        this.props.toggleDeletingScrips(false)

    }

    confirmDeleteScrips() {
        this.props.deleteWatchlistSymbols(this.props.selectedSymbolsToDelete, this.state.selectedWatchilist);
        this.setState({ showdeletescripsconfirmation: false, selecttodelete: false }, () => {
            if (this.props.toggleDeletingScrips)
                this.props.toggleDeletingScrips(false);
        });

    }

    cancelDeleteScrips() {
        this.setState({ showdeletescripsconfirmation: false, selecttodelete: false }, () => {
            if (this.props.toggleDeletingScrips)
                this.props.toggleDeletingScrips(false);
        });
    }

    toggleFilterScrips() {

        if (this.props.toggleFilterScrips)
            this.props.toggleFilterScrips();
    }

    onDownloadOptionSelected(selected) {
        //WARNING: considering values based on index
        if (selected === watchlistDownloadOptions[0].name) { // excel
            if (this.props.onDownloadOptionSelected) {
                this.props.onDownloadOptionSelected(this.state.selectedWatchilist.name + ".xlsx", fileExportTypes.EXCEL);
            }
        }
        else if (selected === watchlistDownloadOptions[1].name) { // csv
            if (this.props.onDownloadOptionSelected) {
                this.props.onDownloadOptionSelected(this.state.selectedWatchilist.name + ".csv", fileExportTypes.CSV);
            }
        }
    }

    onKeyDown(e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 27 || e.keyCode === 'Escape') {
            if (this.state.showaddscripsdialog) {
                this.setState({ showaddscripsdialog: false })
                e.nativeEvent.stopImmediatePropagation();
            } else if (this.state.showdeletescripsconfirmation) {
                this.setState({ showdeletescripsconfirmation: false })
                e.nativeEvent.stopImmediatePropagation();
            }
        }
    }

    render() {
        // var progressindicator = (
        //     <div className="widget-progress-holder">
        //         <svg class="circular-progress" viewBox="25 25 50 50">
        //             <circle class="circular-progress_path" cx="50" cy="50" r="20"
        //                 fill="none" stroke-width="2" stroke-miterlimit="10" />
        //         </svg>
        //     </div>
        // );

        var errorHolder = (
            <div className="error-holder">
                {this.props.error}
            </div>
        );
        const styles = { style: "watchlist-title-icon pull_right " }
        return (
            <div className="fillheight" tabIndex="1" style={{ outline: 'none' }} onKeyDown={(e) => this.onKeyDown(e)} >
                <div className="row contentpanel widget-title">
                    <div className="col-6 watchlist-title-holder contentpanel">
                        <div className="widget-title-component ">
                            <WatchlistGroupsDropdown ref="watchlistgroupsDropdown"
                                disabled={this.props.inProgress}
                                dropdownType={commonDropDownType.DEFAULT}
                                disabledItem={this.state.disabledWatchlist}
                                list={this.props.watchListGroups}
                                onSelectionChanged={this.onWatchlistSelected.bind(this)}
                                tab={this.tabHandlerFunc}
                            />

                        </div>
                        <div className="widget-title-component" style={{ marginLeft: "20px" }} >
                            <button className="nobackgroundbutton"
                                // disabled={(this.state.selectedWatchilist.isMobile || this.state.selectedWatchilist.isEditable)}
                                disabled={this.state.selectedWatchilist}
                                onClick={this.onFavClicked}>
                                {this.state.selectedWatchilist.favid && this.state.selectedWatchilist.favid !== "0" ?
                                    <img src='assets/svg/star_filled.svg' data-toggle="tooltip" title="Remove Favourite" alt="star" />
                                    :
                                    <img src='assets/svg/star_empty.svg' data-toggle="tooltip" title="Set Favourite" alt="star" />
                                }
                            </button>
                        </div>
                        <div className="widget-title-component">
                            <img src='assets/svg/add_new_watchlist.svg' className="watchlist-title-icon" onClick={() => this.askAddNewWatchlist()}
                                data-toggle="tooltip" title="Add New Watchlist" alt="watchlist" />

                        </div>
                        <div className="widget-title-component">
                            <img src='assets/svg/delete_watchlist.png' className="watchlist-title-icon" onClick={() => this.startDeletingWatchlist()}
                                data-toggle="tooltip" title="Delete Watchlists" alt="watchlist" />

                        </div>
                    </div>
                    <div className="col-6 contentpanel ">
                        <div className="watchlistoptions text-right" onClick={() => this.onExpandToggle(this.props.componentType)}>
                            <img src={this.props.activeWidget !== ModuleKeys.ALL ? "assets/svg/collapse.svg" : "assets/svg/expand.svg"} className="expander-icon" onerror="this.src='assets/images/logo.png'" alt="logo" />
                        </div>
                        <div className="watchlistoptions" onClick={() => { this.toggleFilterScrips() }}>
                            <img src='assets/svg/filter.svg' className="watchlist-title-icon" data-toggle="tooltip" title="Filter" alt="filter"></img>
                        </div>
                        {
                            (this.props.activeWidget !== ModuleKeys.ALL) ?
                                <div>
                                    <div className="watchlistoptions">
                                        <Dropdown dropdownType={commonDropDownType.CUSTOM}
                                            customeHeader={(<img src='assets/svg/download.svg' className="watchlist-title-icon" data-toggle="tooltip" title="Export" alt="download" />)}
                                            list={watchlistDownloadOptions}
                                            style={styles.style}
                                            alignDefault={true} id={"watchlist-download-drdown"}
                                            onSelectionChanged={this.onDownloadOptionSelected.bind(this)}
                                        />
                                    </div>

                                    {/* <div className="watchlistoptions">
                                        <button type="button" className="nobackgroundbutton" 
                                        disabled={this.state.selectedWatchilist.isEditable}
                                         onClick={this.startEditingWatchlist} >
                                            <img src='assets/svg/edit_columns.svg' className="watchlist-title-icon" data-toggle="tooltip" title="Edit Watchlist" alt="edit"/>
                                        </button>
                                    </div> */}
                                </div>
                                :
                                <div />
                        }



                        <div className="watchlistoptions">
                            <button type="button" className="nobackgroundbutton"
                                disabled={!this.state.selectedWatchilist.isEditable}
                                onClick={this.askDeleteScrips.bind(this)}

                            >
                                <i className="fa fa-minus watchlist-title-icon negative-change" />

                                <span>{this.state.selecttodelete ? "Delete Scrip(s)" : "Scrip"}</span>
                            </button>

                            <BasicDialog id="deletescripsdialog" ref="deletescripsdialog" title="DELETE SCRIP(S)" message="Do you want to delete selected scrips?" type="message"
                                showDialog={this.state.showdeletescripsconfirmation} negativebuttontext="CANCEL" possitivebuttontext="DELETE"
                                onNegativeButtonClick={this.cancelDeleteScrips.bind(this)} onPossitiveButtonClick={this.confirmDeleteScrips.bind(this)} />

                        </div>




                        <div className="watchlistoptions">
                            <button type="button" className="nobackgroundbutton"
                                disabled={!this.state.selectedWatchilist.isEditable}
                                onClick={this.askAddScrips}  >
                                <i className="fa fa-plus watchlist-title-icon positive-change" />
                                <span>Scrip</span>
                            </button>

                            <SymbolSearchDialog ref={(searchDialog) => { this.searchDialog = searchDialog }}
                                showDialog={this.state.showaddscripsdialog}
                                handleNegativeButton={this.onAddScripCancel.bind(this)}
                                onSymbolSelected={this.onSymbolSelected.bind(this)}
                                spotdisable={false}
                                watchlistSearch={true} />
                        </div>
                    </div>

                </div>
                <div className="row hdivider" > </div>

                <div className="row contentpanel watchlist-content-holder">
                    {this.props.error ? errorHolder :
                        this.props.inProgress ? progressBar() : this.props.children
                    }
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        activeWidget: state.watchlist.activeWidget,
        activeWidgetState: state.watchlist.activeWidgetState,
        watchListGroups: state.watchlist.watchListGroups,
        watchSelected1: state.watchlist.watchSelected1,
        watchSelected2: state.watchlist.watchSelected2
    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setWatchlistExpandWidget: (activeWidget, statedata) => setWatchlistExpandWidget(activeWidget, statedata, dispatch),
        setWatchlistExpandWidgetState: (statedata) => setWatchlistExpandWidgetState(statedata, dispatch),
        showWatchlistError: (errordata) => showWatchlistError(dispatch, errordata),
        setWatchlist1Selected: (selected) => setWatchlist1Selected(selected, dispatch),
        setWatchlist2Selected: (selected) => setWatchlist2Selected(selected, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WatchlistBaseWidget); 