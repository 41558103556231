import React, { Component } from 'react';
import { connect } from 'react-redux';
import { commonDialog, showMTFAlert, toggleDialog } from '../../actions/common';
import { setMTFActivation } from '../../actions/user/profilerequests';
import MtfResult from './MtfResult';
import { progressBar } from '../../controls/progress'

class MtfAlert extends Component {
    constructor(props) {
        super(props);
        this.state = {
            agreed: false,
            showAlert: false,
            showResult: false,
            result: "",
            error: "",
            isInProgress: false,
            MTFInprogress: false
        };
        this.applyMTFRequest = this.applyMTFRequest.bind(this);
        this.checkAcceptance = this.checkAcceptance.bind(this);
    }

    componentDidMount() {
        this.setState({ showAlert: this.props.showAlert });
    }

    componentWillReceiveProps(newProps) {
        if (this.props.showAlert !== newProps.showAlert)
            this.setState({ showAlert: newProps.showAlert });
    }

    closeDialog = () => {
        this.setState({ showResult: false, agreed: false, error: "" }, () => {
            if (this.props.showMTFAlert) {
                let payload = {
                    showMTFAlert: false
                }
                this.props.showMTFAlert(payload);
            }
        });
    }

    accepToggle() {
        this.setState((prevState) => {
            return {
                agreed: !prevState.agreed
            }
        });
    }

    checkAcceptance() {
        if (this.state.agreed) {
            this.applyMTFRequest();
        } else {
            this.setState({ error: "*You must accept terms and conditions for activation request" });
        }
    }

    //MTF activation request
    applyMTFRequest = () => {
        this.setState({ error: "", isInProgress: true }, () => {
            let requestData = {
                request: {
                    data: {}
                }
            }
            setMTFActivation(requestData, this.mtfActivationResponse, this.mtfActivationError);
        });
    }

    mtfActivationResponse = (responseData) => {
        if (responseData && responseData.response && responseData.response.infoID && responseData.response.infoID === "EGN012") {
            this.setState({ agreed: false, showResult: true, showAlert: false, result: responseData.response.infoMsg, MTFInprogress: true, isInProgress: false });
        } else {
            if (responseData && responseData.response && responseData.response.infoMsg) {
                this.setState({ agreed: false, showResult: true, showAlert: false, result: responseData.response.infoMsg, isInProgress: false });
            } else if (responseData && responseData.response && responseData.response.data.activatedMTF) {
                this.setState({ agreed: false, showResult: true, showAlert: false, result: responseData.response.data.activatedMTF, isInProgress: false });
            }
        }
    }

    mtfActivationError = (error) => {
        if (error && error.message) {
            this.setState({ agreed: false, showResult: false, isInProgress: false }, () => {
                if (this.props.showMTFAlert) {
                    let payload = {
                        showMTFAlert: false
                    }
                    this.props.showMTFAlert(payload);
                }
                let payload = {
                    showDialog: true, closeBtn: false, message: error.message, header: "MTF ACTIVATION",
                    buttons: [{
                        name: "ok", action: function () {
                            this.props.toggleDialog(false);
                        }.bind(this)
                    }]
                }
                this.props.commonDialog(payload);
            });
        }
    }

    onTermsAndConditionsClick = () => {
        window.open("assets/margin_terms_and_conditions.pdf", "_blank");
    }
    render() {
        return (
            <div>
                {
                    this.state.showAlert ?
                        <div className={"modal fade " + (this.state.showAlert ? "dialog-show in" : "dialog-hide")} id="myModal" role="dialog">
                            <div className="modal-dialog mtf-modal">
                                <div className="mtf-header">
                                    <span>ALERT</span>
                                    <button className="mtf-close" onClick={this.closeDialog.bind(this)}>&times;</button>
                                </div>
                                {
                                    this.state.isInProgress ? progressBar() :
                                        <div className="mtf-body" >
                                            <div className="mtf-message">
                                                <div className="mtf-logo">
                                                    <img src="assets/svg/alarm-2.svg" className="img-responsive mtf-image" />
                                                </div>
                                                <div className="mtf-activated">MTF NOT ACTIVATED!</div>
                                            </div>
                                            <div className="mtf-question">
                                                <span className="mtf-question-data">Do you wish to activate Margin Trading Facility in your Trading account?</span>
                                                <div className="mtf-agree">
                                                    <label className="custom-check edit-dialog-check">
                                                        <input className="custom-checkbox" type="checkbox" data-tag="rdd-accpted" onChange={this.accepToggle.bind(this)} />
                                                    </label>
                                                    <span className="amo-label">I agree to MTF <span onClick={() => { this.onTermsAndConditionsClick() }} className="mtf-terms">Terms & Conditions</span></span>

                                                </div>
                                            </div>
                                            <div className="error" style={{ textAlign: 'center', height: "15px" }}>
                                                {
                                                    this.state.error ? <span className="negative-change">{this.state.error}</span> : ''
                                                }
                                            </div>
                                        </div>
                                }
                                <div className="mtf-footer">
                                    <div className="buttons">
                                        <button type="button" className="mtf-buttons" onClick={this.closeDialog.bind(this)}>NO</button>
                                        <button type="button" className="mtf-buttons" onClick={this.checkAcceptance}>YES</button>
                                    </div >
                                </div>

                            </div >
                        </div> : null
                }
                {
                    this.state.showResult ? <div className={"modal fade " + (this.state.showResult ? "dialog-show in" : "dialog-hide")} id="myModal" role="dialog">
                        <MtfResult message={this.state.result} closeMTF={this.closeDialog} MTFInprogress={this.state.MTFInprogress} />
                    </div> : null
                }
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        showAlert: state.mtfAlert.showMTFAlert
    }
}

const mapDispatchToProps = (dispatch, ownprops) => {
    return {
        toggleDialog: (payload) => toggleDialog(payload, dispatch),
        commonDialog: (payload) => commonDialog(dispatch, payload),
        showMTFAlert: (value) => showMTFAlert(dispatch, value)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MtfAlert);