import React, { Component } from 'react';

class ToggleButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.selected ? this.props.selected : '1'
        };
        this.handleClick = this.handleClick.bind(this);
        this.setSelected = this.setSelected.bind(this);
    }

    componentDidMount() {
        this.setState({ selected: this.props.selected ? this.props.selected : '1' });
    }

    componentWillReceiveProps(nextProps){
        this.setState({ selected: nextProps.selected });
    }

    setSelected(index) {
        this.setState({ selected: index });
    }

    handleClick(e) {
        if(this.state.selected=='1')
        {
            this.setState({ selected: '2' });
            this.props.onChange ? this.props.onChange('2') : null;
        }
        else if(this.state.selected=='2')
        {
            this.setState({ selected: '1' });
            this.props.onChange ? this.props.onChange('1') : null;
        }
        
    }
    
    render() {

        var classNames = {
            'normal': 'toggle-button',
            'selected': 'toggle-button active',
        }

        return (
            <div className="toggle-switch" >
                {this.props.button1 ?
                    <button className={(this.state.selected == '1') ? classNames.selected : classNames.normal} type="button" value='1'
                        onClick={(e) => this.handleClick(e)} disabled={this.props.isDisabled} style={this.props.additionalbtnstyle} >
                        {this.props.button1}
                    </button>
                    : ''}
                {this.props.button2 ?
                    <button className={(this.state.selected == '2') ? classNames.selected : classNames.normal} type="button" value='2'
                        onClick={(e) => this.handleClick(e)} disabled={this.props.isDisabled} style={this.props.additionalbtnstyle}>
                        {this.props.button2}
                    </button>
                    : ''}
            </div>
        );
    }
}

export default ToggleButton;