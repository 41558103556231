import React from "react"
import { connect } from 'react-redux';
import { toggleCancelOrderDialog } from '../../actions/orderentry/orderentryaction'
import { cancelOrder, exitBracketOrder, exitCoverOrder } from '../../actions/orderentry/orderentryrequests'
import { showWatchlistError } from "../../actions/watchlist/watchlistaction"
import { progressBar } from '../../controls/progress'
import { productType } from '../../common/constants';
class CancelOrderDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inProgress: false,
            currentorderdetails: {},
            cancelRequestSent: false,
            isExit: false
        }

        this.handleNegativeButton = this.handleNegativeButton.bind(this);
        this.handlePossitiveButton = this.handlePossitiveButton.bind(this);
        this.handleCloseButton = this.handleCloseButton.bind(this);
    }

    componentWillReceiveProps(newprops) {
        if (newprops.orderDetails) {
            this.setOrderDetails(newprops.orderDetails);
        }
    }

    setOrderDetails(orderdetails) {
        if (orderdetails.correspondingorder) {
            let isexit = productType[orderdetails.correspondingorder.producttype] === productType.BO ||
                productType[orderdetails.correspondingorder.producttype] === productType.CO ? true : false;
            this.setState({ currentorderdetails: orderdetails, cancelRequestSent: false, isExit: isexit });
        }
    }

    handleNegativeButton() {
        this.props.toggleCancelOrderDialog(false);
    }

    handlePossitiveButton() {
        this.startCancelOrder();
    }

    handleCloseButton() {
        if (this.props.cancelOrderCallback) {
            this.props.cancelOrderCallback();
        }
        this.props.toggleCancelOrderDialog(false);
    }

    //=====================================================
    // cancel order

    startCancelOrder() {
        this.setState({ inProgress: true });
        if (productType[this.state.currentorderdetails.correspondingorder.producttype] === productType.BO) {
            let requestData = {
                request: {
                    data: {
                        orderId: this.state.currentorderdetails.orderId,
                        syomOrderId: this.state.currentorderdetails.syomOrderId,
                        boStatus: this.state.currentorderdetails.boStatus
                    }
                }
            };
            exitBracketOrder(requestData, this.parseCancelOrderResponse.bind(this), this.parseCancelOrderError.bind(this));
        } else if (productType[this.state.currentorderdetails.correspondingorder.producttype] === productType.CO) {
            let requestData = {
                request: {
                    data: {
                        orderId: this.state.currentorderdetails.orderId
                    }
                }
            };
            exitCoverOrder(requestData, this.parseCancelOrderResponse.bind(this), this.parseCancelOrderError.bind(this))
        } else {
            let requestData = {};

            if(this.state.currentorderdetails.symobj.instr.indexOf('SPR') === -1){
                requestData = {
                    request: {
                        data: {
                            sym: this.state.currentorderdetails.symobj,
                            orderId: this.state.currentorderdetails.orderId,
                            isOffline: this.state.currentorderdetails.correspondingorder ? this.state.currentorderdetails.correspondingorder.isAmo : false,
                        }
                    }
                };
            } else {
                requestData = {
                    request: {
                        data: {
                            sym: this.state.currentorderdetails.symobj,
                            orderId: this.state.currentorderdetails.orderId,
                            isOffline: this.state.currentorderdetails.correspondingorder ? this.state.currentorderdetails.correspondingorder.isAmo : false,
                            nestRequestId : this.state.currentorderdetails.nestRequestId
                        }
                    }
                };        
            }
            cancelOrder(requestData, this.parseCancelOrderResponse.bind(this), this.parseCancelOrderError.bind(this));
        }
    }

    parseCancelOrderResponse(responseData) {
        this.setState({ inProgress: false, cancelRequestSent: true }, () => {
            // if (this.props.cancelOrderCallback) {
            //     this.props.cancelOrderCallback();
            // }
        });
    }

    parseCancelOrderError(error) {
        this.props.toggleCancelOrderDialog(false);
        this.setState({ inProgress: false });
        this.props.showWatchlistError({ header: this.state.isExit ? "Exit Order" : "Cancel Order", message: error.message ? error.message : 'Please try again.' });
    }

    //=====================================================



    render() {

        // var progressindicator = (
        //     <div className="widget-progress-holder">
        //         <svg class="circular-progress" viewBox="25 25 50 50">
        //             <circle class="circular-progress_path" cx="50" cy="50" r="20"
        //                 fill="none" stroke-width="2" stroke-miterlimit="10" />
        //         </svg>
        //     </div>
        // );

        return (
            this.props.showCancelOrderDialog ?
                <div className={"modal disabledbackground fade" + (this.props.showCancelOrderDialog ? "in dialog-show" : "dialog-hide")} id={this.props.id} role="dialog" >
                    <div className="modal-dialog cancel-dialog">
                        <div className="mtf-header" style={{ textAlign: "center" }}>
                            <div className="cancel-dialog-header">
                                <span>
                                    {this.state.isExit ? "EXIT ORDER" : "CANCEL ORDER"}
                                </span>
                                <button className="mtf-close" onClick={this.handleNegativeButton}>&times;</button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div style={{ textAlign: "center" }}>
                                <div className="mtf-logo">
                                    <img src="assets/svg/illustration_cancel_order.svg" className="img-responsive mtf-image" alt="cancel"/>
                                </div>
                            </div>
                            {
                                this.state.cancelRequestSent ?
                                    <div className="modal-body-content" style={{ paddingTop: "40px", color: "#D3D3D3" }}>
                                        {this.state.isExit ? "Your executed quantities will be squared off at market price and pending quantities will be cancelled." : "Cancel Request Sent"}
                                    </div> :
                                    <div>
                                        <p style={{ color: "#1EBF8A" }} className="modal-body-content">{this.props.orderDetails.displaySym} - {this.props.orderDetails.symobj.exc}</p>
                                        {this.props.orderDetails.orderId && <div className="modal-body-content" style={{ color: "#898787" }}> ORDER ID : {this.props.orderDetails.orderId} <br /> </div>}
                                        <p className="modal-body-content"> Do you want to {this.state.isExit ? "exit" : "cancel"} this order ?  </p>
                                    </div>
                            }
                        </div>
                        <div className="mtf-footer">
                            {
                                this.state.cancelRequestSent ?
                                    <div className="buttons">
                                        <button type="button" className="mtf-buttons" data-dismiss="modal" onClick={this.handleCloseButton}>CLOSE</button>
                                    </div> :
                                    <div className="buttons">
                                        <button type="button" className="mtf-buttons" data-dismiss="modal" onClick={this.handleNegativeButton}>NO</button>
                                        <button type="button" className="mtf-buttons" data-dismiss="modal" onClick={this.handlePossitiveButton}>YES</button>
                                    </div>
                            }

                        </div>
                    </div>
                    {this.state.inProgress ? progressBar() : ''}
                </div> :
                null
        );
    }
}

const mapStateToProps = (state) => {
    return {
        showCancelOrderDialog: state.orderentry.showCancelOrderDialog,
        orderDetails: state.orderentry.orderDetails,
        cancelOrderCallback: state.orderentry.cancelOrderCallback
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        toggleCancelOrderDialog: (value) => toggleCancelOrderDialog(dispatch, value),
        showWatchlistError: (errordata) => showWatchlistError(dispatch, errordata)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelOrderDialog);