import { StoreKeys } from '../common/constants';

const intialState = {
    activeWidget: 'ALL',
    footerActive: 'ALL'
}

const dashboard = (state = intialState, action) => {
    if (action.type === StoreKeys.DASH_ACTIVE_WIDGET) {
        return Object.assign({}, state, { activeWidget: action.payload });
    } else if (action.type === StoreKeys.FOOTER_ACTIVE_WIDGET) {
        return Object.assign({}, state, { footerActive: action.payload });
    } else {
        return state;
    }
};

export default dashboard;