import React from "react";
import { connect } from "react-redux";

class NotificationsIcon extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
           
        };
    }

    componentDidMount() {
    }

    onNotificationsIconClicked() {
        this.props.history.push("/home/notifications");
    }

    render() {
        return (
            <div className="pageheader-component notify-parent"  style={{cursor: "pointer"}} onClick={this.onNotificationsIconClicked.bind(this)} data-toggle="tooltip" title="Notifications">
                <img src="assets/svg/notifications-normal.svg" />
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {

    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsIcon); 