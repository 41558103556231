import React from "react"

class SearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            isOrderEntry: false,
        }
    }

    onChangeText(e) {
        //keyboard short issue fix for + - entry
        if (this.state.isOrderEntry && (e.target.value === '+' || e.target.value === '-')) {
            this.setState({ isOrderEntry: false })
            return;
        }
        this.setState({ searchText: e.target.value });
        if (this.props.callbackHandler) {
            this.props.callbackHandler(e.target.value, e, this.props.type);
        }
    }

    onClearClick(e) {
        var length = this.props.searchText !== undefined ? this.props.searchText.length : this.state.searchText.length;
        if (length > 0) {
            this.setState({ searchText: "" });
            if (this.props.callbackHandler) {
                this.props.callbackHandler('', e);
            }
        }
        this.setFocus();
    }

    setOrderEntry() {
        this.setState({ isOrderEntry: true }, () => {
            this.setFocus()
        })
    }

    setFocus() {
        this.input.focus();
    }

    render() {
        return (
            <div id={this.props.id} className="col-12 col-lg-12 col-md-12 nopadding btn-group">
                {this.props.displayIcon !== false ? <img className={"search-right-icon " + ((this.props.searchText !== undefined && this.props.searchText.length > 0) && "close-icon")} src={(this.props.searchText === undefined || this.props.searchText.length === 0) ? "assets/svg/search.svg" : "assets/svg/close.svg"}
                    alt="search"  onClick={(e)=>this.onClearClick(e)}/> : ''}

                <input
                    ref={input => { this.input = input }}
                    className="form-control search-input symbol-search"
                    type="search"
                    placeholder={this.props.placeholder}
                    value={this.props.searchText !== undefined ? this.props.searchText : this.state.searchText}
                    onChange={e => this.onChangeText(e)}
                />
            </div>
        );
    }
}

export default SearchInput;