import React, { Component } from 'react';
import BaseWidget from '../base/BaseWidget'
import { connect } from 'react-redux';
import { TradeActions, orderEntryTypes, instrumentType } from '../../common/constants'
import { getPendingOrders } from '../../actions/dashboard/dashboardrequests'
import { showOrderDialog, showCancelOrderDialog, showCancelOrderDialogCallback, onOrderSuccess } from '../../actions/orderentry/orderentryaction'
import OrderLogModal from '../myorders/OrderLogModal'
import { setScripInfo } from '../../actions/scripdetails/scripdetailsaction'
import { withRouter } from 'react-router-dom'
import { comaSeparator } from '../../common/utils'



class PendingOrdersSmall extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inProgress: false,
            error: "",
            pendingorders: []
        };

        this.onModifyOrderClick = this.onModifyOrderClick.bind(this);
        this.onCancelOrderClick = this.onCancelOrderClick.bind(this);
        this.onSymbolClick = this.onSymbolClick.bind(this);
        this.onOrderSuccess = this.onOrderSuccess.bind(this);
    }

    componentDidMount() {
        this.requestPendingOrders();
        this.props.onOrderSuccess(this.onOrderSuccess);
    }

    requestPendingOrders() {
        var data = {
            request: {
                data: {
                }
            }
        };
        this.setState({ inProgress: true, error: "" });
        getPendingOrders(data, this.parseResponse.bind(this), this.parseError.bind(this));
    }

    parseResponse(responseData) {
        this.setState({
            pendingorders: responseData.response.data.orders,
            inProgress: false,
            error: ""
        });
    }

    parseError(err) {
        this.setState({
            inProgress: false,
            error: err.message
        });
    }


    onSymbolClick(row) {
        this.props.setScripInfo(row.sym);
        this.props.history.push("/home/scripdetails");
    }


    onModifyOrderClick(row) {
        var orderdetails = this.getOrderDetails(row);
        this.props.showOrderDialog(orderdetails);
    }

    onCancelOrderClick(row) {
        var orderdetails = this.getOrderDetails(row);
        orderdetails.orderId = row.orderId;
        this.props.showCancelOrderDialogCallback(this.onSuccessfulOrderCancel.bind(this));
        this.props.showCancelOrderDialog(orderdetails);
    }

    onSuccessfulOrderCancel() {
        this.requestPendingOrders();
    }

    onOrderSuccess() {
        this.requestPendingOrders();
    }


    getOrderDetails(item) {
        let instrType = item.sym.instr;
        var orderdetails = {
            orderentrytype: orderEntryTypes.MODIFY,
            orderId: item.orderId,
            action: item.ordAction.toLowerCase() === "buy" ? TradeActions.BUY : TradeActions.SELL,
            symobj: item.sym,
            displaySym: item.displaySym,
            company: item.company,
            last: "0.00",
            change: "0.00",
            changeper: "0.00",

            correspondingorder: {
                orderid: item.orderId,
                price: item.limitPrice,
                quantity: item.remQty,
                producttype: item.prdType,
                ordertype: item.ordType.toLowerCase(),
                validitytype: item.ordDuration,
                triggerprice: item.triggerPrice,
                discquantity: item.disQty,
                amo: item.isOffline ? item.isOffline : false,                
                tradedQty: item.tradedQty
            }            
        };
        if(instrType === instrumentType.STOCK_FUTURE_SPREAD || instrType === instrumentType.INDEX_FUTURE_SPREAD || instrType === instrumentType.CURRENCY_FUTURE_SPREAD){
            orderdetails.nestRequestId = item.nestRequestId;
        }

        return orderdetails;
    }



    render() {
        //{this.props.componentType===this.props.activeWidget && <div> extra changes in expand </div>}
        return (
            <BaseWidget title={this.props.title} componentType={this.props.componentType}
                inProgress={this.state.inProgress} error={this.state.error} >
                <div>
                    <div className="contentpanel pendingorders-header">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3 contentpanel">
                                SYMBOL
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 contentpanel pendingorders-head-pad text-right">
                                PRICE
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 contentpanel pendingorders-head-pad text-right">
                                QTY
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 contentpanel pendingorders-head-pad">
                                BUY/SELL
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 contentpanel pendingorders-head-pad text-right">

                            </div>
                        </div>
                    </div>
                    {this.state.pendingorders.map(function (row, key) {
                        return (
                            <div key={key} className="row contentpanel pendingorders-row">
                                <div className="col-lg-3 col-md-3 col-sm-3 contentpanel" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                    <span className="symbol-link" onClick={() => this.onSymbolClick(row)} data-toggle="tooltip" title={row.displaySym} >
                                        {row.displaySym}
                                    </span>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 contentpanel pendingorders-head-pad text-right">
                                    {comaSeparator(row.limitPrice)}
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 contentpanel pendingorders-head-pad text-right">
                                    {comaSeparator(row.qty)}
                                </div>
                                <div className={"col-lg-2 col-md-2 col-sm-2 contentpanel pendingorders-head-pad" + (row.ordAction && row.ordAction.toLowerCase() === "buy" ? " positive-change" : " negative-change")}>
                                    {row.ordAction && row.ordAction.toUpperCase()}
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 contentpanel text-right">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-4 pending-orders-btn-holder">
                                            <button type="button" className="pending-orders-btn" data-toggle="tooltip" title="Modify Order"
                                                data-tag={row.orderId} onClick={() => this.onModifyOrderClick(row)}
                                                disabled={!row.modifiable} >M</button>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-4 contentpanel pending-orders-btn-holder">
                                            <button type="button" className="pending-orders-btn" data-toggle="tooltip" title="Cancel Order"
                                                data-tag={row.orderId} onClick={() => this.onCancelOrderClick(row)}
                                                disabled={!row.cancellable}>C</button>
                                        </div>
                                        {/* <OrderLogPopup orderid={row.orderId} symobj={row.sym} outstandingordershistory={true} /> */}
                                        <OrderLogModal parentRef={"order" + key} orderid={row.orderId} symobj={row.sym}
                                            selectedsegment={this.state.selectedsegment}
                                            displaySym={row.displaySym} exchange={row.sym.exc}
                                            disclosedQty={comaSeparator(row.disQty)}
                                            triggerPrice={comaSeparator(row.triggerPrice)}
                                            validity={row.ordDuration} 
                                            outstandingordershistory={true}
                                            productType={row.prdType}
                                            instrTyp={row.sym.instr}/>
                                    </div>
                                </div>
                            </div>
                        )
                    }, this)}
                </div>
            </BaseWidget>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        activeWidget: state.dashboard.activeWidget,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showOrderDialog: (data) => showOrderDialog(dispatch, data),
        showCancelOrderDialog: (data) => showCancelOrderDialog(dispatch, data),
        showCancelOrderDialogCallback: (callback) => showCancelOrderDialogCallback(dispatch, callback),
        setScripInfo: (data) => setScripInfo(dispatch, data),
        onOrderSuccess: (callback) => onOrderSuccess(dispatch, callback)
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PendingOrdersSmall)); 