import React from 'react';

class OrderResult extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            orderresult: {}
        }
        this.setOrderResult = this.setOrderResult.bind(this);
    }

    componentWillReceiveProps(newProps) {
        if (newProps.orderResult) {
            this.setOrderResult(newProps.orderResult);
        }
        this.result.focus();
    }
    setOrderResult(result) {
        this.setState({ orderresult: result });
    }

    render() {
        return (
            <div className="modal-dialog orderresult">
                <div className=" order-row text-center">
                    {this.state.orderresult.isSuccess ? 'SUCCESS' : 'FAILED'}
                </div>
                <div className="row contentpanel hdivider" > </div>
                <div className="text-center">
                    <div>
                        <center>
                            {this.state.orderresult.isSuccess ?
                                <img src="assets/svg/order_success.svg" className="img-responsive orderresult-img" />
                                :
                                <img src="assets/svg/order_failed.svg" className="img-responsive orderresult-img" />
                            }
                        </center>
                    </div>
                    <div className="orderresult-symbol">
                        {this.state.orderresult.symbol} - {this.state.orderresult.exchange}
                    </div>
                    <div className="orderresult-headline">
                        {this.state.orderresult.headline}
                    </div>
                    <div className="orderresult-message">
                        {this.state.orderresult.message}
                    </div>
                    <div className="orderresult-message" style={{ marginTop: '15px' }}>
                        {this.state.orderresult.orderId}
                    </div>
                </div>
                <div className="text-center" style={{ margin: "30px" }}>
                    {this.state.orderresult.isSuccess ?
                        <button type="button" ref={result => this.result = result} className="btn-footer order-place active-buy" onClick={() => { this.props.onNegativeButtonClick() }} >OKAY</button>
                        :
                        <button type="button" ref={result => this.result = result} className="btn-footer order-place active-buy" onClick={() => { this.props.onRetryClicked() }} >RETRY</button>
                    }
                </div>
            </div>
        );
    }

}

export default OrderResult;