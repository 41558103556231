import { StoreKeys } from '../common/constants';

const intialState = {
    showBidDialog: false,
    showCancelBidDialog: false,
    bidDetails:{},
    cancelBidCallback: undefined,
    onBidSuccessCallback: undefined
}

const productentry = (state = intialState, action) => {
    if (action.type === StoreKeys.SHOW_BID_DIALOG) {
        return Object.assign({}, state, {  showBidDialog: true, bidDetails:action.payload });
    }
    else {
        return state;
    }
};

export default productentry;