import React, { Component } from "react";
import { connect } from "react-redux";
import { CIQ } from "chartiq/js/chartiq";
import "chartiq/js/deprecated";
import Chart from "../components/Chart";
import ChartService from "../../ChartService";
import { setChartsExpandWidget, setChartsExpandWidgetState } from '../../../../actions/dashboard'
import { ModuleKeys, TradeActions, orderEntryTypes, chartId } from '../../../../common/constants';
//import { setItemByKey, getItemByKey } from '../../../../common/utils';
import { toggleChartDialog, setSelectChart } from "../../../../actions/chartiq/chartaction"
import { showOrderDialog } from '../../../../actions/orderentry/orderentryaction'
import { getChartPreferences, setChartPreferences } from '../../../../actions/chartiq/chartiqRequests';
class ChartBaseView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ciq: null,
      symObj: this.props.symbol,
      service: new ChartService(this.props.symbol).makeFeed(),
      chartType: "candle",
      chartTypeState: null,
      refreshInterval: 1,
      symbol: this.props.symbol.id,
      showCrosshairs: false,
      showTimezoneModal: false,
      chartSeries: [],
      comparisons: [],
      periodicity: {
        period: 1,
        interval: 1,
        timeUnit: "day"
      },
      shareStatus: "HIDDEN",
      shareStatusMsg: null,
      showPeriodicityLoader: false,
      studyOverlay: {
        show: false,
        top: 0,
        left: 0,
        params: null
      },
      initialTool: undefined,
      setSpan: {}
    };
    this.tag = this.props.componentType ? this.props.componentType : 'ChartView';
    this.chartPreferenceResponse = this.chartPreferenceResponse.bind(this);
    this.chartPreferenceError = this.chartPreferenceError.bind(this);
    this.setChartPreferenceResponse = this.setChartPreferenceResponse.bind(this);
    this.setChartPreferenceError = this.setChartPreferenceError.bind(this);
    this.setChartPreferenceRequest = this.setChartPreferenceRequest.bind(this);
    this.restoreDrawings = this.restoreDrawings.bind(this);
    this.storeToLocal = this.storeToLocal.bind(this);
    this.setChartContainerLocalData = this.setChartContainerLocalData.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activeWidget !== ModuleKeys.ALL ||
      (this.props.activeWidget === ModuleKeys.ALL && this.props.activeWidgetState &&
        this.props.activeWidgetState.prevWidget === this.props.componentType)) {
      this.initializeInitialState();
    }
    if (this.props.symbol !== nextProps.symbol) {
      try {
        window.$(".stx-panel-title.chart-title").html(nextProps.symbol.id);
      } catch (e) {

      }
      this.setState({ symObj: nextProps.symbol })
      this.state.service.setSymbol(nextProps.symbol);
      this.setSymbol(nextProps.symbol.id);
    }
  }

  componentDidMount() {
    if (this.props.activeWidget !== ModuleKeys.ALL ||
      (this.props.activeWidget === ModuleKeys.ALL && this.props.activeWidgetState &&
        this.props.activeWidgetState.prevWidget === this.props.componentType)) {
      this.initializeInitialState();
    }
  }

  initializeInitialState() {
    var statedata = this.props.activeWidgetState;
    if (statedata) {
      this.setState({ chartType: statedata.chartType, chartTypeState: statedata.chartTypeState, periodicity: statedata.periodicity })
    }
    this.props.setChartsExpandWidgetState(statedata);
  }

  setChartContainer(container, containerId) {
    this.changingChartData(true);
    this.container = container;
    this.containerId = chartId[containerId];
    if (this.containerId) {
      let requestData = {
        request: {
          data: {
            chartId: this.containerId
          }
        }
      };
      let localStorageData = this.getFromLocal();
      let layoutdata, symObj, drawings;
      if (localStorageData && localStorageData.preference && localStorageData.symObj) {
        layoutdata = localStorageData.preference;
        symObj = localStorageData.symObj;
        drawings = localStorageData.drawings;
        this.setChartContainerLocalData(layoutdata, symObj, drawings);
        return;
      }
      getChartPreferences(requestData, this.chartPreferenceResponse, this.chartPreferenceError);
    } else {
      this.chartPreferenceError();
    }
  }

  //will execute if local data exists
  setChartContainerLocalData(layoutdata, symObj, drawings) {
    var chartiq;
    chartiq = new CIQ.ChartEngine({ container: this.container, layout: { periodicity: 1, interval: "minute" } });
    this.setState(
      {
        ciq: chartiq,
        service: new ChartService(symObj).makeFeed(),
        setSpan: layoutdata.setSpan,
        symObj: symObj,
        symbol: symObj.id
      },
      () => {
        this.requestPending = false;
        this.state.ciq.addEventListener("drawing", this.changeCallback);
        this.state.ciq.addEventListener("symbolChange", this.changeCallback);
        this.state.ciq.addEventListener("layout", this.changeCallback);
        if (layoutdata) {
          this.state.ciq.importLayout(layoutdata);
        }

        if (layoutdata && layoutdata.setSpan && layoutdata.setSpan.multiplier && layoutdata.interval && layoutdata.periodicity && layoutdata.setSpan.base !== "day") {
          if (layoutdata.setSpan.base !== "today") {
            this.setSpanWithLoader(layoutdata.setSpan.multiplier, layoutdata.setSpan.base, layoutdata.interval, layoutdata.periodicity, layoutdata.timeUnit);
          }
        }

        this.state.ciq.attachQuoteFeed(this.state.service, {
          refreshInterval: this.state.refreshInterval
        });
        this.state.ciq.setMarketFactory(CIQ.Market.Symbology.factory);

        this.state.ciq.newChart(this.state.symbol, null, null, function () {
          this.changingChartData(false);
          this.restoreDrawings(this.state.ciq, drawings);
        }.bind(this), { stretchToFillScreen: true });
      }
    );
  }

  restoreDrawings(ciq, drawings) {
    if (drawings && drawings.length > 0) {
      ciq.importDrawings(drawings);
      ciq.draw();
    }
  }

  storeToLocal() {
    let storeKey = "CHART_" + this.containerId;
    let newLayout = this.state.ciq.exportLayout();
    console.log("performance store ", newLayout)
    let drawings = this.state.ciq.exportDrawings();
    let decode = this.fromWindows1252(JSON.stringify(newLayout));
    let charts = { preference: JSON.parse(decode), symObj: this.state.symObj, drawings: drawings };
    if (newLayout && newLayout.setSpan && this.props.showSearch) {
      window.localStorage.setItem(storeKey, JSON.stringify(charts));
    }
  }

  getFromLocal() {
    let storeKey = "CHART_" + this.containerId;
    const storedData = JSON.parse(window.localStorage.getItem(storeKey));
    return storedData;
  }

  changeCallback = () => {
    setTimeout(() => {
      this.storeToLocal();
    }, 500);
    if (this.requestPending) {
      return;
    }
    this.requestPending = true;
    setTimeout(() => {
      this.setChartPreferenceRequest();
      this.requestPending = false;
    }, 30000);
  }

  chartPreferenceResponse(responseData) {
    var chartiq;
    let layoutdata, symObj, drawings;
    if (responseData.response && responseData.response.data.preferences && responseData.response.data.preferences.symObj) {
      layoutdata = responseData.response.data.preferences.preference;
      symObj = responseData.response.data.preferences.symObj;
      drawings = responseData.response.data.preferences.drawings;
    } else {
      symObj = this.props.symbol;
    }
    chartiq = new CIQ.ChartEngine({ container: this.container, layout: { periodicity: 1, interval: "minute" } });
    this.setState(
      {
        ciq: chartiq,
        service: new ChartService(symObj).makeFeed(),
        setSpan: layoutdata.setSpan,
        symObj: symObj,
        symbol: symObj.id
      },
      () => {
        this.requestPending = false;
        this.state.ciq.addEventListener("drawing", this.changeCallback);
        this.state.ciq.addEventListener("symbolChange", this.changeCallback);
        this.state.ciq.addEventListener("layout", this.changeCallback);
        if (layoutdata) {
          this.state.ciq.importLayout(layoutdata);
        }

        if (layoutdata && layoutdata.setSpan && layoutdata.setSpan.multiplier && layoutdata.interval && layoutdata.periodicity && layoutdata.setSpan.base !== "day") {
          if (layoutdata.setSpan.base !== "today") {
            this.setSpanWithLoader(layoutdata.setSpan.multiplier, layoutdata.setSpan.base, layoutdata.interval, layoutdata.periodicity, layoutdata.timeUnit);
          }
        }

        this.state.ciq.attachQuoteFeed(this.state.service, {
          refreshInterval: this.state.refreshInterval
        });
        this.state.ciq.setMarketFactory(CIQ.Market.Symbology.factory);

        this.state.ciq.newChart(this.state.symbol, null, null, function () {
          this.changingChartData(false);
          this.restoreDrawings(this.state.ciq, drawings);
        }.bind(this), { stretchToFillScreen: true });
      }
    );
  }

  chartPreferenceError(error) {
    var chartiq;
    chartiq = new CIQ.ChartEngine({ container: this.container, layout: { periodicity: 1, interval: "minute" } });
    chartiq.draw();
    this.changingChartData(false);
    this.setState(
      {
        ciq: chartiq,
        service: new ChartService(this.props.symbol).makeFeed()
      },
      () => {
        this.requestPending = false;
        this.state.ciq.addEventListener("drawing", this.changeCallback);
        this.state.ciq.addEventListener("symbolChange", this.changeCallback);
        this.state.ciq.addEventListener("layout", this.changeCallback);
        this.state.ciq.attachQuoteFeed(this.state.service, {
          refreshInterval: this.state.refreshInterval
        });
        this.state.ciq.setMarketFactory(CIQ.Market.Symbology.factory);
        this.state.ciq.newChart(this.state.symbol, null, null, function () {
          this.changingChartData(false);
        }.bind(this), { stretchToFillScreen: true });
      }
    );
  }

  fromWindows1252(binaryString) {
    let WINDOWS_1252 = '\u0000\u0001\u0002\u0003\u0004\u0005\u0006\u0007\b\t\n\u000b\f\r\u000e\u000f\u0010\u0011\u0012\u0013\u0014\u0015\u0016\u0017\u0018\u0019\u001a\u001b\u001c\u001d\u001e\u001f ' +
      '!"#$%&\'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\]^_`abcdefghijklmnopqrstuvwxyz{|}~€�‚ƒ„…†‡ˆ‰Š‹Œ�Ž��‘’“�?•–—˜™š›œ�žŸ ¡¢£¤¥¦§¨©ª«¬­®¯°±²³´µ¶·¸¹º»¼½¾¿À�?ÂÃÄÅÆÇÈÉÊËÌ�?Î�?�?ÑÒÓÔÕÖ×ØÙÚÛÜ�?Þßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿ';
    var text = '';
    for (var i = 0; i < binaryString.length; i++) {
      text += WINDOWS_1252.charAt(binaryString.charCodeAt(i));
    }
    return text;
  }

  setChartPreferenceRequest() {
    let newLayout = this.state.ciq.exportLayout();
    let drawings = this.state.ciq.exportDrawings();
    let decode = this.fromWindows1252(JSON.stringify(newLayout));
    let charts = { preference: JSON.parse(decode), symObj: this.state.symObj, drawings: drawings };
    let requestData = {
      request: {
        data: {
          chartId: this.containerId,
          charts: charts
        }
      }
    };
    if (newLayout.setSpan && this.props.showSearch) {
      setChartPreferences(requestData, this.setChartPreferenceResponse, this.setChartPreferenceError);
    }
  }

  setChartPreferenceResponse(responseData) {
    console.log(responseData)
  }

  setChartPreferenceError(error) {

  }

  setChartType(chartType) {
    if (chartType.type !== this.state.chartType) {

      this.setState((prevState) => {
        let { ciq } = this.state;

        if (
          (chartType.aggregationEdit &&
            this.state.ciq.layout.aggregationType !== chartType.type) ||
          chartType.type === "heikinashi"
        ) {
          ciq.setChartType("candle");
          ciq.setAggregationType(chartType.type);
        } else {
          ciq.setAggregationType(null);
          ciq.setChartType(chartType.type);
        }

        return { chartType: chartType.type, chartTypeState: chartType, ciq };
      }, function () {
        this.state.ciq.draw();
      }.bind(this))


    }
  }

  addComparison(symbol, params) {
    var newSeries = this.state.ciq.addSeries(symbol, params);
    var newComparisons = this.state.comparisons.concat([newSeries]);
    this.setState({ comparisons: newComparisons });
  }

  removeComparison(comparison) {
    var newComparisons = this.state.comparisons.filter(
      comp => comp.id !== comparison
    );
    this.setState({ comparisons: newComparisons });
  }

  toggleTimezoneModal() {
    this.setState({ showTimezoneModal: !this.state.showTimezoneModal });
  }

  setTimeZone(zone) {

    this.setState((prevState) => {
      let { ciq } = prevState;
      if (zone) {

        ciq.setTimeZone(null, zone);
      } else {

        ciq.displayZone = null;
        ciq.setTimeZone();
      }

      return { ciq };
    }, () => {
      if (this.state.ciq.displayInitialized) this.state.ciq.draw();
    })


  }

  toggleCrosshairs() {

    this.setState(function (prevState) {
      let { ciq, showCrosshairs } = prevState;
      ciq.layout.crosshair = !showCrosshairs;
      showCrosshairs = !showCrosshairs;
      return { showCrosshairs, ciq };
    });
  }

  changingChartData(changing) {
    this.setState({ isLoadingPeriodicity: changing });
  }

  changeVectorParams(tool) {
    var style = this.state.ciq.canvasStyle("stx_annotation");

    if (style) {
      this.setState(function (prevState) {
        let { ciq } = prevState;
        ciq.currentVectorParameters.annotation.font.size = style.size;
        ciq.currentVectorParameters.annotation.font.family = style.family;
        ciq.currentVectorParameters.annotation.font.style = style.style;
        ciq.currentVectorParameters.annotation.font.weight = style.weight;
        return { ciq }
      });
    }
    // if (style) {
    //   this.state.ciq.currentVectorParameters.annotation.font.size = style.size;
    //   this.state.ciq.currentVectorParameters.annotation.font.family =
    //     style.family;
    //   this.state.ciq.currentVectorParameters.annotation.font.style =
    //     style.style;
    //   this.state.ciq.currentVectorParameters.annotation.font.weight =
    //     style.weight;
    // }
    var toolbarStatus = document
      .getElementById(this.props.id ? this.props.id : "chartContainer")
      .classList.contains("toolbarOn");
    if (!this.state.initialTool) {
      this.setState({ initialTool: tool });
    }
    if (tool && this.state.initialTool !== tool) {
      this.setState({ initialTool: tool });
    }
    tool =
      !tool && toolbarStatus && this.state.initialTool
        ? this.state.initialTool
        : tool;
    this.state.ciq.changeVectorType(tool);
  }

  changeVectorLineParams(weight, pattern) {
    this.state.ciq.currentVectorParameters.lineWidth = weight;
    this.state.ciq.currentVectorParameters.pattern = pattern;
  }

  changeVectorStyle(styleType, style) {
    if (styleType === "bold") {
      this.state.ciq.currentVectorParameters.annotation.font.weight = !style.bold
        ? "bold"
        : "normal";
    } else if (styleType === "italic") {
      this.state.ciq.currentVectorParameters.annotation.font.style = !style.italic
        ? "italic"
        : "normal";
    } else if (styleType === "family") {
      this.state.ciq.currentVectorParameters.annotation.font.family =
        style.family;
    } else if (styleType === "size") {
      this.state.ciq.currentVectorParameters.annotation.font.size =
        style.size + "px";
    } else if (styleType === "lineColor") {
      this.state.ciq.currentVectorParameters.currentColor = CIQ.hexToRgba(
        "#" + style.color
      );
    } else if (styleType === "fillColor") {
      this.state.ciq.currentVectorParameters.fillColor = CIQ.hexToRgba(
        "#" + style.color
      );
    }
  }

  setPeriodicity(periodicity) {
    this.setState({
      periodicity: {
        period: periodicity.period,
        interval: periodicity.interval,
        timeUnit: periodicity.timeUnit
      }
    });
  }

  setSymbol(symbol, symObj) {
    if (symObj) {
      this.setState({ symObj: symObj })
      this.state.service.setSymbol(symObj);
    }
    if (symbol && symbol !== null) {
      this.state.ciq.newChart(symbol);
      this.setState({ symbol: symbol });
    }
    if (this.props.chartcontent && symObj) {
      this.props.setSelectChart({ type: this.props.chartcontent, sym: symObj })
    }
  }

  setRefreshInterval(refreshInterval) {
    this.setState({ refreshInterval: refreshInterval });
  }

  shareChart() { }

  setShareStatus(shareStatus, shareStatusMsg) {
    this.setState({ shareStatus: shareStatus, shareStatusMsg: shareStatusMsg });
  }

  setSpanWithLoader(multiplier, base, interval, period, timeUnit) {
    var params = {
      multiplier: multiplier,
      base: base
    };

    if (interval) {
      params.periodicity = {
        interval: interval,
        period: period || 1,
        timeUnit: timeUnit || "minute"
      };
    }

    this.changingChartData(true);

    // WARNING: PAVAN's hack for intraday

    if (params.base === 'today' || params.base === 'day') {
      var dLeft = new Date();
      dLeft.setHours(9);
      dLeft.setMinutes(0);
      var dRight = new Date();
      dRight.setHours(23);
      dRight.setMinutes(0);
      this.state.ciq.setSpan(params, () => {
        this.changingChartData(false);
        this.setPeriodicity({
          period: this.state.ciq.layout.period,
          interval: this.state.ciq.layout.interval,
          timeUnit: this.state.ciq.layout.timeUnit
        });
        this.state.ciq.setRange({
          dtLeft: dLeft,
          dtRight: dRight
        });
        this.state.ciq.setMaxTicks(80);
        this.state.ciq.home();
        this.draw();
      });
    }
    else {
      this.state.ciq.setSpan(params, () => {
        this.changingChartData(false);
        this.setPeriodicity({
          period: this.state.ciq.layout.period,
          interval: this.state.ciq.layout.interval,
          timeUnit: this.state.ciq.layout.timeUnit
        });
      });
    }
  }

  setPeriodicityWithLoader(periodicity) {
    this.changingChartData(true);
    this.state.ciq.setPeriodicity(periodicity, () => {
      this.changingChartData(false);
      this.setPeriodicity({
        period: this.state.ciq.layout.period,
        interval: this.state.ciq.layout.interval,
        timeUnit: this.state.ciq.layout.timeUnit
      });
    });
  }

  toggleDrawingToolbar() {
    //toggleDrawing();
    if (this.chartRef) {
      this.chartRef.drwaingRef.getWrappedInstance().toggleDrawing();
    }
    this.changeVectorParams();
  }

  draw() {
    if (this.state.ciq) this.state.ciq.draw();
  }

  onExpandToggle(type) {
    if (this.props.isChartDialog) {
      this.props.toggleDialog();
    } else if (this.props.activeWidget === ModuleKeys.ALL) {
      let statedata = {
        chartType: this.state.chartType,
        chartTypeState: this.state.chartTypeState,
        periodicity: this.state.periodicity,
      };
      statedata.prevWidget = undefined;
      this.props.setChartsExpandWidget(type, statedata);
    } else {
      let statedata = {
        chartType: this.state.chartType,
        chartTypeState: this.state.chartTypeState,
        periodicity: this.state.periodicity,
      };
      statedata.prevWidget = this.props.activeWidget;
      this.props.setChartsExpandWidget(ModuleKeys.ALL, statedata);
    }
  }

  gotoBuy() {
    var orderdetails = this.getOrderDetails(TradeActions.BUY);
    this.props.showOrderDialog(orderdetails);
  }

  gotoSell(e) {
    var orderdetails = this.getOrderDetails(TradeActions.SELL);
    this.props.showOrderDialog(orderdetails);
  }

  getOrderDetails(taction) {
    var orderdetails = {
      orderentrytype: orderEntryTypes.NEW,
      action: taction,
      symobj: this.state.symObj,
      // displaySym: item.displaySym,
      // company: item.companyName,
      last: "0.00",
      change: "0.00",
      changeper: "0.00"
    };

    return orderdetails;
  }
  //================  Stream Functions  =================//
  onSocketRegister() {

  }

  onStreamCallback(streamData) {
    if (this.state.quoteobj.streamSym === streamData.data.sym && this.state.quoteobj.sym.exc === streamData.data.exc) {

    }
  }

  componentWillUnmount() {
    window.socketEvents.unregister(this.tag);
  }


  render() {
    return (
      <Chart
        id={this.props.id}
        isSmall={this.props.activeWidget === ModuleKeys.ALL && !this.props.isChartDialog}
        componentType={this.props.componentType}
        showSearch={this.props.showSearch}
        ref={chartRef => (this.chartRef = chartRef)}
        ciq={this.state.ciq}
        chartType={this.state.chartType}
        periodicity={this.state.periodicity}
        isLoadingPeriodicity={this.state.isLoadingPeriodicity}
        comparisons={this.state.comparisons}
        service={this.state.service}
        symbol={this.state.symbol}
        symobj={this.state.symObj}
        refreshInterval={this.state.refreshInterval}
        showCrosshairs={this.state.showCrosshairs}
        showTimezoneModal={this.state.showTimezoneModal}
        setTimeZone={this.state.setTimeZone}
        chartSeries={this.state.chartSeries}
        shareStatus={this.state.shareStatus}
        shareStatusMsg={this.state.shareStatusMsg}
        setChartContainer={this.setChartContainer.bind(this)}
        changeVectorParams={this.changeVectorParams.bind(this)}
        changeVectorLineParams={this.changeVectorLineParams.bind(this)}
        changeVectorStyle={this.changeVectorStyle.bind(this)}
        addComparison={this.addComparison.bind(this)}
        removeComparison={this.removeComparison.bind(this)}
        // addStudy={this.addStudy.bind(this)}
        // removeStudy={this.removeStudy.bind(this)}
        toggleCrosshairs={this.toggleCrosshairs.bind(this)}
        toggleTimezoneModal={this.toggleTimezoneModal.bind(this)}
        setTimeZone={this.setTimeZone.bind(this)}
        setSymbol={this.setSymbol.bind(this)}
        toggleDrawingToolbar={this.toggleDrawingToolbar.bind(this)}
        setPeriodicity={this.setPeriodicity.bind(this)}
        setPeriodicityWithLoader={this.setPeriodicityWithLoader.bind(this)}
        setChartType={this.setChartType.bind(this)}
        setSpanWithLoader={this.setSpanWithLoader.bind(this)}
        draw={this.draw.bind(this)}
        shareChart={this.shareChart.bind(this)}
        setShareStatus={this.setShareStatus.bind(this)}
        onExpandToggle={this.onExpandToggle.bind(this)}
        gotoBuy={this.gotoBuy.bind(this)}
        gotoSell={this.gotoSell.bind(this)}
        setSpan={this.state.setSpan}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeWidget: state.charts.activeWidget,
    activeWidgetState: state.charts.activeWidgetState,
  }
}


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setChartsExpandWidget: (activeWidget, statedata) => setChartsExpandWidget(activeWidget, statedata, dispatch),
    setChartsExpandWidgetState: (statedata) => setChartsExpandWidgetState(statedata, dispatch),
    toggleDialog: () => toggleChartDialog(dispatch, false),
    showOrderDialog: (data) => showOrderDialog(dispatch, data),
    setSelectChart: (data) => setSelectChart(dispatch, data)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartBaseView);
