import React from 'react';
import DetailsRow from './DetailsRow';
import { connect } from 'react-redux';
import DetailsHeader from './DetailsHeader';
import DetailsGrandTotal from './DetailsGrandTotal';
import DetailsRowClientWise from './DetailsRowClientWise'
import  '../css/summary.css'
class DetailsTable extends React.Component{
   constructor(props){
       super(props)
       this.state = {
        data : this.props.portfolio_data
       }
   }
componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.portfolio_data });  
}
render(){

            return(
                <table id='detailsid'>
                 {<DetailsHeader />}
                 {<DetailsRowClientWise portfolio_data={this.state.data}/>}
                 {<DetailsGrandTotal portfolio_data={this.state.data}/>}
                </table>
            );
        }
    }
    const mapStateToProps = (state) => {
        return {
    
        }
    }
    
    const mapDispatchToProps = (dispatch, ownProps) => {
        return {
           // showErrorDialog: (errordata) => showErrorDialog(dispatch, errordata),
            //commonDialog: (payload) => commonDialog(dispatch, payload),
            //toggleDialog: (payload) => toggleDialog(payload, dispatch)
        };
    };
    export default connect(mapStateToProps, mapDispatchToProps)(DetailsTable); 
    