import React from 'react'
import  '../css/summary.css'
class DetailsRow extends React.Component{

    
    renderTableDataDetails() {
        if(Object.keys(this.props.portfolio_data).length!==0)
      return this.props.portfolio_data.details.map((detail, i) => {
         const { contraDesc, userId, mktPrice, prevPos,prevAvgPrice,buyQty,buyAvgPrice,sellQty,sellAvgPrice,netQty,netAvgPrice,Anov,bookedPnl,unRealPnl,prdType } = detail //destructuring
         return (
            <tr key={i}>
               <td>{userId}</td>
               <td>{contraDesc}</td>
               <td>{mktPrice}</td>
               <td>{prevPos}</td>
               <td>{prevAvgPrice}</td>
               <td>{buyQty}</td>
               <td>{buyAvgPrice}</td>
               <td>{sellQty}</td>
               <td>{sellAvgPrice}</td>
               <td>{netQty}</td>
               <td>{netAvgPrice}</td>
               <td>{Anov}</td>
               <td>{bookedPnl}</td>
               <td>{unRealPnl}</td>
               <td>{prdType}</td>
            </tr>
         )
      })
   }
    
    render(){
        return(
            <tbody>
                {this.renderTableDataDetails()}
            </tbody>
        )
    }
}
export default DetailsRow;