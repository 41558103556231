import React from "react"
//import { isValidWatchlistName } from '../../validators/watchlistValidations'

class DeleteBasketPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {            
            error: ''
        }     
        this.handlePossitiveButton = this.handlePossitiveButton.bind(this);
        this.handleNegativeButton = this.handleNegativeButton.bind(this); 
    }

    handleNegativeButton() {       

        if (this.props.onNegativeButtonClick)
            this.props.onNegativeButtonClick();
    }

    handlePossitiveButton() {
       
        if (this.props.onPossitiveButtonClick)
            this.props.onPossitiveButtonClick();        
    }

    render() {

        var mbody = (
                <p className="modal-body-content"> {this.props.message} </p>
            );        


        return (
            <div className={"modal fade" + (this.props.showDialog ? "in dialog-show" : "dialog-hide")} id={this.props.id} role="dialog">
                <div className="modal-dialog small">
                    <div className="product-modal-header">
                        {this.props.title} <b>{this.props.selectedOrderBasket}</b>                    
                    </div>
                    <div className="modal-body">
                        {mbody}
                    </div>
                    <div className="modal-footer" style={{marginTop:'15px'}}>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <button type="button" className="footerbtn" data-dismiss="modal" onClick={this.handleNegativeButton}>{this.props.negativebuttontext}</button>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <button type="button" className="footerbtn" data-dismiss="modal" onClick={this.handlePossitiveButton}>{this.props.possitivebuttontext}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DeleteBasketPopup;