import React, { Component } from 'react';
import '../../css/common.css'

class PageNotFound extends Component {

    render() {   
        return (    
            <div className="unsupported_div panel">
            404! Page not found!
            </div>    
        );   
      }
}

export default PageNotFound;