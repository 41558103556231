import React,{Component} from 'react';

class SpreadOrderInput extends Component{

    render(){
        return(
            <div>
            
            </div>
        );
    }
};
export default SpreadOrderInput;