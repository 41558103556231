import React from "react"
import { connect } from "react-redux"
import { toggleDialog } from "../actions/common"

class AppDialog extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    componentWillReceiveProps(newProps) {
        if (newProps.showDialog) {
            console.log('showing new dialog');
            setTimeout(function () {
                if (this.refs.btnClose) {
                    this.refs.btnClose.focus();
                } else if (this.props.buttons && this.props.buttons.length > 0) {
                    var maxindx = this.props.buttons.length - 1;
                    this.refs[this.props.buttons[maxindx].name].focus();
                }
            }.bind(this), 100);
        }

    }

    render() {
        return (
            this.props.showDialog ? <div className={"modal fade " + (this.props.showDialog ? "dialog-show in" : "dialog-hide")} id="myModal" role="dialog">
                <div className={"modal-dialog appdialogmodal " + (this.props.type && this.props.type)}>
                    <div className="modal-content">
                        <div className="modal-header">
                            {this.props.isDismiss && <button type="button" className="close" onClick={() => this.props.toggleDialog()}><img src="assets/svg/close.svg" alt="close"/></button>}
                            <h4 className="modal-title">{this.props.header ? this.props.header : "INDIABULLS"}</h4>
                        </div>
                        <div className="modal-body">
                            <p>{this.props.message}</p>
                        </div>
                        <div className="modal-footer">
                            {this.props.buttons && this.props.buttons.map((data,key) => {
                                return <button type="button" ref={data.name} className="btn btn-default commonbtn" onClick={() => { console.log('clicked'); data.action && data.action() }}>{data.name}</button>;
                            })}
                            {this.props.isDismiss && <button ref="btnClose" type="button" className="btn btn-default commonbtn" onClick={() => this.props.toggleDialog()}>Close</button>}
                        </div>
                    </div>

                </div>
            </div> : null
        );
    }
}

const mapStateToProps = state => {
    return {
        showDialog: state.common.showDialog,
        header: state.common.header,
        message: state.common.message,
        buttons: state.common.buttons,
        isDismiss: state.common.closeBtn,
        type: state.common.type
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        toggleDialog: () => toggleDialog(false, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppDialog);
