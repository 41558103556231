import React from "react"

class AlertPopUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paramData: [{ name: 'NSE-CM', key: "NSE-CM" }, { name: 'NSE-FNO', key: "NSE-FNO" }, { name: 'NSE-CDS', key: "NSE-CDS" }, { name: 'BSE', key: "BSE" }],
            buttonOne: [{ key: 'Greater than equal to', value: 'greater' }, { key: 'Lesser than equal to', value: 'lesser' }],
            modeButtons: [{ key: 'SMS', value: 'sms' }, { key: 'Email', value: 'email' }, { key: 'Both', value: 'both' }],
            parseditems: [{ key: 'Client ID', value: 'success' }, { key: 'Exchange', value: 'success' }, { key: 'Symbol', value: 'success' }, { key: 'Category', value: 'success' }, { key: 'No of Installments', value: 'success' }, { key: 'Quantity', value: 'success' }, { key: 'End Date', value: 'success' }, { key: 'Price', value: 'success' }, { key: 'Margin', value: 'success' }]
        }
        this.onClosePopUp = this.onClosePopUp.bind(this);
        this.onClickSubmit = this.onClickSubmit.bind(this);
    }

    onClosePopUp() {
        if (this.props.closeECNPopUp)
            this.props.closeECNPopUp();
    }
    onClickSubmit() {

    }

    render() {
        return (
                <div className="modal-dialog alertPop orderresult" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div className="modal-header " style={{ textAlign: 'center' }}>
                        CONFIRM OFS BID
                        </div>
                    <div className="container-fluid order-row order-confirmation-body">
                        {this.state.parseditems.map(function (row, key) {
                            return (
                                <div key={key} className="row order-confirmation-row" >
                                <div className="row">
                                    <div className="col-6 col-lg-6 col-md-6 text-right" style={{ color: "#777" }}>
                                        {row.key}
                                    </div>
                                    <div className="col-6 col-lg-6 col-md-6 contentpanel">
                                        {row.value}
                                    </div>
                                </div>
                                </div>
                            );
                        }, this)
                        }
                    </div>
                    <div className="prod-footer">
                        <button type="button" className="btn-footer order-cancel" onClick={this.props.onClose} style={{width:100}}>CANCEL</button>

                        <button type="button" className="btn-footer prod-blue-btn" 
                        onClick={this.props.onSubmitClick}  
                        // disabled={(this.state.symbol === undefined || this.state.error)} 
                        >SUBMIT</button>
                    </div>
                </div>

        )
    }
}
export default AlertPopUp;
