import React from 'react';

const DrawingToolbarButton = (props) => {

	function toggleDrawingToolbar() {

		props.toggleDrawingToolbar()

	}

	return (
		<span onClick={() => toggleDrawingToolbar()} style={{cursor:"pointer"}} >{!props.isSmall ? "Draw" : ""}<button className='drawing-toolbar-btn' /></span>
	)
}

export default DrawingToolbarButton
