import React from 'react';

const ForthcomingTabContent = (props) => {
    return (
        <div>
              {/* <img src='assets/svg/sort.svg' className="fa-sort" id="sym_sort"></img> */}
            <div className="row products-header">
                <div className="col-xs-2 col-lg-2 col-md-2 products-white-content">
                  
                    COMPANY NAME
                        </div>
                <div className="col-xs-2 col-lg-2 col-md-2">
                    ISSUE TYPE
                        </div>
                <div className="col-xs-1 col-lg-1 col-md-1 ">
                    ISSUE PRICE
                        </div>
                <div className="col-sm-1 col-lg-1 col-md-1 ">
                    MIN QUANTITY
                        </div>
                <div className="col-sm-1 col-lg-1 col-md-1 ">
                    QTY MULTIPLES
                        </div>
                <div className="col-sm-2 col-lg-2 col-md-2 ">
                    MAX RETAIL INVESTMENT
                        </div>
                <div className="col-sm-1 col-lg-1 col-md-1">
                    START DATE
                        </div>
                <div className="col-sm-1 col-lg-1 col-md-1">
                    END DATE
                        </div>
                <div className="col-sm-1 col-lg-1 col-md-1 text-right">
                    IPO PROSPECTUS
                        </div>
            </div>
            <div className="ipo-scroll">
                {props.data.map((row) => {
                    return [
                        <div>
                            <div className="row products-ipo-row">
                                <div className="col-xs-2 col-lg-2 col-md-2">
                                    {row.lname}
                                </div>
                                <div className="col-xs-2 col-lg-2 col-md-2">
                                    {row.issueType}
                                </div>
                                <div className="col-sm-1 col-lg-1 col-md-1">
                                    {row.price}
                                </div>
                                <div className="col-sm-1 col-lg-1 col-md-1">
                                    {row.minQty}
                                </div>
                                <div className="col-sm-1 col-lg-1 col-md-1">
                                    {row.qtyMultiple}
                                </div>
                                <div className="col-sm-2 col-lg-2 col-md-2">
                                    {row.maxRetailPrice}
                                </div>
                                <div className="col-sm-1 col-lg-1 col-md-1">
                                    {row.opendate}
                                </div>
                                <div className="col-sm-1 col-lg-1 col-md-1">
                                    {row.closedate}
                                </div>
                                <div className="col-sm-1 col-lg-1 col-md-1 ipo-link">
                                    {row.prospectus ?
                                        <a href={row.prospectus} target="_blank"> PROSPECTUS </a>
                                        :
                                        ' - - '
                                    }
                                </div>
                            </div>
                            <div className="hdivider" />
                        </div>
                    ];
                }, this)}
            </div>
        </div>

    );
}

export default ForthcomingTabContent;