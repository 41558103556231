import React from 'react';
import BaseWidget from '../base/BaseWidget'
import TabBar from "../../controls/TabBar";
import ResearchBig from './ResearchBig'
import { ModuleKeys, CommonErrorMessages } from '../../common/constants'
import { connect } from 'react-redux';
import { ideasTabs, TradeActions, orderEntryTypes } from "../../common/constants"
import { getIdeas } from '../../actions/dashboard/dashboardrequests'
import { comaSeparator } from '../../common/utils'
import { showOrderDialog } from '../../actions/orderentry/orderentryaction'
import { progressBar } from '../../controls/progress'

class ResearchSmall extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inProgress: false,
            ideasTabs: [ideasTabs[0], ideasTabs[1]],
            ideasData: [],
            selectedsegment: "EQUITY",
            reqsegment: "EQUITY",
            error: ''
        };

        this.getOrderDetails = this.getOrderDetails.bind(this);
        this.gotoTrade = this.gotoTrade.bind(this);
    }

    componentDidMount() {
        if (this.props.activeWidget === ModuleKeys.ALL) {
            this.placeRequest();
        }
    }

    onTabClicked(tabName) {
        switch (tabName) {
            case "Equity":
                this.setState({ selectedsegment: "EQUITY", reqsegment: "EQUITY" }, () => this.placeRequest()); //TODO: include "CM" after confirmation
                break;
            case "FNO":
                this.setState({ selectedsegment: "FNO", reqsegment: "DERIVATIVE" }, () => this.placeRequest());
                break;
            case "Currency":
                this.setState({ selectedsegment: "CDS", reqsegment: "CDS" }, () => this.placeRequest());
                break;
            case "Commodity":
                this.setState({ selectedsegment: "COM", reqsegment: "COM" }, () => this.placeRequest());
                break;
            default :break;
        }
    }

    
    gotoTrade(row) {
        var orderdetails = this.getOrderDetails(row);
        this.props.showOrderDialog(orderdetails);
    }

    getOrderDetails(item) {
       
        var taction = item.calltype === "BUY" ? TradeActions.BUY : TradeActions.SELL;
        var orderdetails = {
            orderentrytype: orderEntryTypes.NEW,
            action: taction,
            symobj: item.sym,
            displaySym: item.displaySym,
            company: item.companyName,
            last: "0.00",
            change: "0.00",
            changeper: "0.00"
        };

        return orderdetails;
    }


    placeRequest() {
        var data = {
            request: {
                data: {
                    //TODO: include after confirmation
                    //category: category, 
                    recordcount: "3",
                    segment: this.state.reqsegment,
                    //TODO: include after confirmation
                    //callstatus: "closed" 
                }
            }
        };
        this.setState({ ideasData: [], inProgress: true, error: true });
        getIdeas(data, this.parseResponse.bind(this), this.parseError.bind(this));
    }

    parseResponse(responseData) {
        var list = responseData.response.data.ideas;
        if (list && list.length > 0) {
            this.setState({
                error: '',
                ideasData: responseData.response.data.ideas,
                inProgress: false
            });
        }
        else {
            this.setState({ error: CommonErrorMessages.NO_DATA, inProgress: false });
        }
    }

    parseError(error) {
        this.setState({
            error: error.message,
            inProgress: false
        });
    }


    render() {

        // var progressindicator = (
        //     <div className="widget-progress-holder">
        //         <svg class="circular-progress" viewBox="25 25 50 50">
        //             <circle class="circular-progress_path" cx="50" cy="50" r="20"
        //                 fill="none" stroke-width="2" stroke-miterlimit="10" />
        //         </svg>
        //     </div>
        // );

        var errorHolder = (
            <div className="error-holder" style={{ marginTop: "5px" }}>
                <span> {this.state.error} </span>
            </div>
        );

        var smallWidget = (
            <div>
                <TabBar tabs={this.state.ideasTabs} tabalign="left" highlight="below" callbackHandler={this.onTabClicked.bind(this)} />
                <div className="tab-content">
                    <div id="equity" className="tab-pane in active">
                        {this.state.error !== '' ? errorHolder :
                            <div>
                                {this.state.inProgress ? progressBar() :
                                    <div>
                                        <div className="row contentpanel ideas-sub-header small">
                                            <div className="col-5 col-sm-5 col-lg-5 col-md-5 contentpanel">
                                            </div>
                                            <div className="col-3 col-sm-3 col-lg-3 col-md-3 contentpanel text-center">
                                                VALIDITY
                                            </div>
                                            <div className="col-2 col-sm-2 col-lg-2 col-md-2 contentpanel text-center">
                                                STOPLOSS
                                            </div>
                                            <div className="col-2 col-sm-2 col-lg-2 col-md-2 contentpanel text-right">
                                                TARGET
                                            </div>
                                        </div>
                                        <div>
                                            {this.state.ideasData.map(function (row, key) {
                                                return (
                                                    <div key={key} className="ideas-row">
                                                        <div className="row contentpanel">
                                                            <div className={row.calltype === 'BUY' ? "col-1 contentpanel  positive-change" : "col-1 contentpanel  negative-change"}>
                                                                <button type="button" className={row.calltype === 'BUY' ? "ideas-btn buy" : "ideas-btn sell"}
                                                                    data-tag={row.callid} onClick={() => this.gotoTrade(row)}>
                                                                    {row.calltype}</button>
                                                            </div>
                                                            <div className="col-4 col-sm-4 col-lg-4 col-md-4 contentpanel ideas-small-col">
                                                                <div>
                                                                    {row.displaySym ? row.displaySym : row.sym.id} @ {row.entryprice1}
                                                                </div>
                                                                {/* <div className="ideas-date">
                                                                    {row.callsenttime}
                                                                </div> */}
                                                            </div>
                                                            <div className="col-3 col-sm-3 col-lg-3 col-md-3 contentpanel text-center ideas-small-col">
                                                                {row.callexpiry}
                                                            </div>
                                                            <div className="col-2 col-sm-2 col-lg-2 col-md-2 contentpanel  text-center ideas-small-col">
                                                                {comaSeparator(row.stoplossprice1)}
                                                            </div>
                                                            <div className="col-2 col-sm-2 col-lg-2 col-md-2 contentpanel text-right ideas-small-col">
                                                                {comaSeparator(row.targetprice1)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }, this)}
                                        </div>
                                    </div>}
                            </div>
                        }
                    </div>
                </div>
            </div>
        );

        return (
            <BaseWidget title={this.props.title} componentType={this.props.componentType}
            >
                {(this.props.activeWidget === ModuleKeys.RESEARCH) ?
                    <div className="fillheight">
                        <ResearchBig />
                    </div>
                    :
                    smallWidget}
            </BaseWidget>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        activeWidget: state.dashboard.activeWidget,
    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showOrderDialog: (data) => showOrderDialog(dispatch, data),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResearchSmall);