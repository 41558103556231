import { UrlExtensions,  BaseUrl } from '../../common/constants';
import { httpPost } from '../base/api'

// === Investment Overview ====
export const getInvestmentOverview = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.INVESTMENT_OVERVIEW;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// === Top Gainers ====
export const getTopGainers = function ( requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.TOP_GAINERS;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// === Top Loosers ====
export const getTopLoosers = function ( requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.TOP_LOSERS;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// === Pending Orders ====
export const getPendingOrders = function ( requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.OPEN_ORDERS;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// === Hot News ====
export const getHotNews = function (urlExtension, requestData, responseCallback, errorCallback) {
    var target = BaseUrl + urlExtension;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// === Derivative News ====
export const getDerivativeNews = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.DERIVATIVE_NEWS;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}


//======= Live News ===========
export const getLiveNewsDetail= function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.LIVE_NEWS_DETAIL;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// === World News ====
export const getWorldMarket = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.WORLD_MARKET;    
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error);
        errorCallback(error);
    });
}

// === Ideas ====
export const getIdeas = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.IDEAS;
    httpPost(target, requestData)
    .then(function (response) {
        console.log('dddrequest succeeded with JSON response', response);        
        responseCallback(response);
    }).catch(function (error) {
        console.log('dddrequest failed', error.message);
        errorCallback(error);
    });
}