import React, { Component } from 'react';
//import BaseWidget from '../base/BaseWidget'
import { getInvestmentOverview } from '../../actions/dashboard/dashboardrequests';
import { comaSeparator } from '../../common/utils';

class InvestmentOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inProgress: false,
            investmentOverviewData: {}
        };
    }

    componentDidMount() {
        var data = {
            request: {}
        };
        this.setState({ inProgress: true });
        getInvestmentOverview(data, this.parseInvestmentOverviewResponse.bind(this), this.parseError.bind(this));
    }

    parseInvestmentOverviewResponse(responseData) {
        this.setState({
            investmentOverviewData: responseData.response.data.summary,
            inProgress: false
        });
    }

    parseError(error) {
        this.setState({
            inProgress: false
        });
    }

    gotoDPHoldings() {
        this.props.history.push("/home/dpholdings");
    }

    render() {
        return (
            <div className="row contentpanel investmentoverview-container">
                <div className="col-4 col-lg-4 col-md-4 contentpanel">
                    <span className="investmentoverview-header">
                        TOTAL PORTFOLIO VALUE
                    </span>
                    <span >
                        <a onClick={() => this.gotoDPHoldings()} className="investmentoverview-value">
                            ₹ {comaSeparator(this.state.investmentOverviewData.totPtfValue)}
                        </a>
                    </span>


                </div>

                <div className="col-8 contentpanel text-right">
                    <div className="row">
                        <div className="col-6 contentpanel">
                            <span className="investmentoverview-header investmentoverview-todays-pl">
                                TODAY'S P/L
                        </span>
                            <span className={(this.state.investmentOverviewData && this.state.investmentOverviewData.todaysPl && this.state.investmentOverviewData.todaysPl.indexOf('-')) ? "investmentoverview-value positive-change" : "investmentoverview-value negative-change"}>
                                ₹ {this.state.investmentOverviewData.todaysPl && (comaSeparator(this.state.investmentOverviewData.todaysPl) + " (" + this.state.investmentOverviewData.todaysPlPer + "%)")}
                            </span>
                        </div>

                        <div className="col-2 contentpanel">
                            <span className="investmentoverview-header">
                                HOLDING
                            </span>
                            <span className="investmentoverview-value">
                                {this.state.investmentOverviewData.nHoldings}
                            </span>
                        </div>

                        <div className="col-2 contentpanel">
                            <span className="investmentoverview-header positive-change">
                                ADVANCE
                             </span>
                            <span className="investmentoverview-value positive-change">
                                {this.state.investmentOverviewData.nAdvances}
                            </span>
                        </div>
                        <div className="col-2 contentpanel text-right">
                            <span className="investmentoverview-header negative-change">
                                DECLINE
                            </span>
                            <span className="investmentoverview-value negative-change">
                                {this.state.investmentOverviewData.nDeclines}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default InvestmentOverview;