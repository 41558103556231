import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import TabBar from "../../controls/TabBar";
import { connect } from "react-redux";
// import Dropdown from '../../controls/Dropdown'
// import {commonDropDownType} from "../../common/constants"


class ReportBaseWidget extends Component {

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {

        };

        this.setActiveTab = this.setActiveTab.bind(this);
    }

    onCollapseClick() {
        
    }

    setActiveTab(tab) {
        this.refs.tab.setActiveTab(tab);
    }

   
    render() {
        return (
            <div className="fillheight tabbar-content-holder" id={this.props.id}>
            
                <div className="row contentpanel widget-title">   
                    <div className="col-6 col-lg-6 col-md-6  watchlist-title-holder contentpanel">
                        {this.props.title}
                    </div>                
                    {this.props.additionalWidgetHeader && <div className="col-xs-6 col-lg-6 col-md-6 contentpanel">
                        <div className="tabbar-expand text-right">
                            <div className="watchlistoptions text-right">
                                {this.props.additionalWidgetHeader && this.props.additionalWidgetHeader}
                            </div>                            
                        </div>                                               
                    </div>}
                </div>
                <div className="row hdivider" > </div>
                <div className="tabbar-content-body contentpanel" >
                    {this.props.tabs && <div className="row contentpanel">
                        <div className={"col-lg-"+(this.props.additionalTabHeader ?"8":"12")+ " ideas-tab-bar"}>
                            <TabBar ref="tab" tabs={this.props.tabs} highlight="above" expanded={true} callbackHandler={this.props.onTabClicked} />
                        </div>
                        <div className="tab-content-parent expanded col-lg-4 col-md-4">
                            {this.props.additionalTabHeader && this.props.additionalTabHeader}
                        </div>
                    </div>}
                    {this.props.children}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {

    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {

    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, {withRef:true})(ReportBaseWidget));